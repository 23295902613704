.styles-module__steps-wrapper___1Y_s1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.styles-module__step-wrapper___2pI_o {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.styles-module__step-wrapper___2pI_o .styles-module__step-icon-wrapper___hs22M, .styles-module__step-wrapper___2pI_o .styles-module__step-icon-wrapper-await___2ZG6_, .styles-module__step-wrapper___2pI_o .styles-module__step-icon-wrapper-done___2N8RE, .styles-module__step-wrapper___2pI_o .styles-module__step-icon-wrapper-active___AHGi6 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 26px;
  transition: all 200ms;
  -webkit-transition: all 200ms;
}
.styles-module__step-wrapper___2pI_o .styles-module__step-icon-wrapper-active___AHGi6 {
  background-color: #ffc728;
  border: solid 1px #262B31;
}
.styles-module__step-wrapper___2pI_o .styles-module__step-icon-wrapper-done___2N8RE {
  border: solid 2px #ffc728;
}
.styles-module__step-wrapper___2pI_o .styles-module__step-icon-wrapper-await___2ZG6_ {
  border: solid 1px #262B31;
  opacity: 0.4;
}
.styles-module__step-wrapper___2pI_o .styles-module__step-icon-title___DLisG, .styles-module__step-wrapper___2pI_o .styles-module__step-icon-title-await___OMB1L, .styles-module__step-wrapper___2pI_o .styles-module__step-icon-title-active___uBqNx {
  font-size: 0.9em;
  padding: 0 5px;
}
.styles-module__step-wrapper___2pI_o .styles-module__step-icon-title-active___uBqNx {
  font-weight: bold;
}
.styles-module__step-wrapper___2pI_o .styles-module__step-icon-title-await___OMB1L {
  opacity: 0.4;
}

.styles-module__step-tail___11ILE, .styles-module__step-tail-await___2B8k0 {
  flex: 1;
  height: 2px;
  background-color: #ffc728;
  margin-right: 5px;
}
.styles-module__step-tail-await___2B8k0 {
  height: 1px;
  background-color: #262B31;
  opacity: 0.4;
}