@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.order-toast-module__h1___1iMD4, .order-toast-module__h2___1bgED, .order-toast-module__h3___32jEs, .order-toast-module__h4___Rk7iu, .order-toast-module__h5___2EM7v, .order-toast-module__h6___UCXFl {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .order-toast-module__h1___1iMD4 {
  font-size: 2.5rem;
}

h2, .order-toast-module__h2___1bgED {
  font-size: 2rem;
}

h3, .order-toast-module__h3___32jEs {
  font-size: 1.75rem;
}

h4, .order-toast-module__h4___Rk7iu {
  font-size: 1.5rem;
}

h5, .order-toast-module__h5___2EM7v {
  font-size: 1.25rem;
}

h6, .order-toast-module__h6___UCXFl {
  font-size: 1rem;
}

.order-toast-module__lead___3yIUo {
  font-size: 1.25rem;
  font-weight: 300;
}

.order-toast-module__display-1___1Gy1m {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.order-toast-module__display-2___2rIS9 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.order-toast-module__display-3___CS770 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.order-toast-module__display-4___3oZbY {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.order-toast-module__small___3dTr9 {
  font-size: 80%;
  font-weight: 400;
}

mark,
.order-toast-module__mark___26nim {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.order-toast-module__list-unstyled___1D7Q_ {
  padding-left: 0;
  list-style: none;
}

.order-toast-module__list-inline___2KWyM {
  padding-left: 0;
  list-style: none;
}

.order-toast-module__list-inline-item___3dfMJ {
  display: inline-block;
}
.order-toast-module__list-inline-item___3dfMJ:not(:last-child) {
  margin-right: 0.5rem;
}

.order-toast-module__initialism___2uMHY {
  font-size: 90%;
  text-transform: uppercase;
}

.order-toast-module__blockquote___I-ZOj {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.order-toast-module__blockquote-footer___pjTiK {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.order-toast-module__blockquote-footer___pjTiK::before {
  content: "— ";
}

.order-toast-module__img-fluid___2Mh1F {
  max-width: 100%;
  height: auto;
}

.order-toast-module__img-thumbnail___1yGNM {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.order-toast-module__figure___37XCQ {
  display: inline-block;
}

.order-toast-module__figure-img___QMNU7 {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.order-toast-module__figure-caption___2D-XK {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.order-toast-module__pre-scrollable___2NIyZ {
  max-height: 340px;
  overflow-y: scroll;
}

.order-toast-module__container___hNC4l {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .order-toast-module__container___hNC4l {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .order-toast-module__container___hNC4l {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .order-toast-module__container___hNC4l {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .order-toast-module__container___hNC4l {
    max-width: 1140px;
  }
}

.order-toast-module__container-fluid___1Cjkw {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.order-toast-module__row___2SiwZ {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.order-toast-module__no-gutters___L50v9 {
  margin-right: 0;
  margin-left: 0;
}
.order-toast-module__no-gutters___L50v9 > .order-toast-module__col___14mxK,
.order-toast-module__no-gutters___L50v9 > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.order-toast-module__col-xl___3tAgp,
.order-toast-module__col-xl-auto___yoGnE, .order-toast-module__col-xl-12___1ZOm_, .order-toast-module__col-xl-11___2T5EI, .order-toast-module__col-xl-10___lNMJJ, .order-toast-module__col-xl-9___2CMuH, .order-toast-module__col-xl-8___29KdQ, .order-toast-module__col-xl-7___1rspc, .order-toast-module__col-xl-6___2zFcR, .order-toast-module__col-xl-5___1Gi5_, .order-toast-module__col-xl-4___2J9t2, .order-toast-module__col-xl-3___3Q-OX, .order-toast-module__col-xl-2___12uWv, .order-toast-module__col-xl-1___3p-ot, .order-toast-module__col-lg___2O2W1,
.order-toast-module__col-lg-auto___W3WBb, .order-toast-module__col-lg-12___1LcbH, .order-toast-module__col-lg-11___11njy, .order-toast-module__col-lg-10___37ZvP, .order-toast-module__col-lg-9___2u5to, .order-toast-module__col-lg-8___3P4k1, .order-toast-module__col-lg-7___brU9r, .order-toast-module__col-lg-6___2b1a9, .order-toast-module__col-lg-5___1pj3Q, .order-toast-module__col-lg-4___3G1K2, .order-toast-module__col-lg-3___2zKBO, .order-toast-module__col-lg-2___3m84J, .order-toast-module__col-lg-1___3jx6c, .order-toast-module__col-md___2uxiI,
.order-toast-module__col-md-auto___1IZuR, .order-toast-module__col-md-12___KQhJY, .order-toast-module__col-md-11___2uaDr, .order-toast-module__col-md-10___24tiT, .order-toast-module__col-md-9___2nJ00, .order-toast-module__col-md-8___2rnza, .order-toast-module__col-md-7___1C011, .order-toast-module__col-md-6___ijbDn, .order-toast-module__col-md-5___zu6c-, .order-toast-module__col-md-4___36z9G, .order-toast-module__col-md-3____0gBd, .order-toast-module__col-md-2___1QEpr, .order-toast-module__col-md-1___1Hbct, .order-toast-module__col-sm___1qKRY,
.order-toast-module__col-sm-auto___bfOTc, .order-toast-module__col-sm-12___1CcvL, .order-toast-module__col-sm-11___PwBL-, .order-toast-module__col-sm-10___2HSOe, .order-toast-module__col-sm-9___4olgS, .order-toast-module__col-sm-8___NX7_9, .order-toast-module__col-sm-7___19Izf, .order-toast-module__col-sm-6___3hW12, .order-toast-module__col-sm-5___EWAut, .order-toast-module__col-sm-4___1go6U, .order-toast-module__col-sm-3___2dgMK, .order-toast-module__col-sm-2___2__1R, .order-toast-module__col-sm-1___1C0Xd, .order-toast-module__col___14mxK,
.order-toast-module__col-auto___2QQVe, .order-toast-module__col-12___1z0Jw, .order-toast-module__col-11___3Tjkb, .order-toast-module__col-10___2l2ft, .order-toast-module__col-9___3o4Jt, .order-toast-module__col-8___3BDx0, .order-toast-module__col-7___t4eCH, .order-toast-module__col-6___-3dWg, .order-toast-module__col-5___1mFjA, .order-toast-module__col-4___1SbKY, .order-toast-module__col-3___2uaZT, .order-toast-module__col-2___3hud6, .order-toast-module__col-1___mwIys {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.order-toast-module__col___14mxK {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.order-toast-module__col-auto___2QQVe {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.order-toast-module__col-1___mwIys {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.order-toast-module__col-2___3hud6 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.order-toast-module__col-3___2uaZT {
  flex: 0 0 25%;
  max-width: 25%;
}

.order-toast-module__col-4___1SbKY {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.order-toast-module__col-5___1mFjA {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.order-toast-module__col-6___-3dWg {
  flex: 0 0 50%;
  max-width: 50%;
}

.order-toast-module__col-7___t4eCH {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.order-toast-module__col-8___3BDx0 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.order-toast-module__col-9___3o4Jt {
  flex: 0 0 75%;
  max-width: 75%;
}

.order-toast-module__col-10___2l2ft {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.order-toast-module__col-11___3Tjkb {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.order-toast-module__col-12___1z0Jw {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-toast-module__order-first___4J7Vm {
  order: -1;
}

.order-toast-module__order-last___1k13S {
  order: 13;
}

.order-toast-module__order-0___xVoOM {
  order: 0;
}

.order-toast-module__order-1___JgupF {
  order: 1;
}

.order-toast-module__order-2___1q7hp {
  order: 2;
}

.order-toast-module__order-3___xVtKB {
  order: 3;
}

.order-toast-module__order-4___1cD-t {
  order: 4;
}

.order-toast-module__order-5___29MCI {
  order: 5;
}

.order-toast-module__order-6___1X9Rj {
  order: 6;
}

.order-toast-module__order-7___1PAQI {
  order: 7;
}

.order-toast-module__order-8___1y4Yl {
  order: 8;
}

.order-toast-module__order-9___1ij2o {
  order: 9;
}

.order-toast-module__order-10___n8KqC {
  order: 10;
}

.order-toast-module__order-11___3vwMA {
  order: 11;
}

.order-toast-module__order-12___1I_i2 {
  order: 12;
}

.order-toast-module__offset-1___1YS6U {
  margin-left: 8.3333333333%;
}

.order-toast-module__offset-2___1HlPd {
  margin-left: 16.6666666667%;
}

.order-toast-module__offset-3___2QcGV {
  margin-left: 25%;
}

.order-toast-module__offset-4___3Ohjb {
  margin-left: 33.3333333333%;
}

.order-toast-module__offset-5___2Tk9k {
  margin-left: 41.6666666667%;
}

.order-toast-module__offset-6___2cJ6G {
  margin-left: 50%;
}

.order-toast-module__offset-7___eDAOP {
  margin-left: 58.3333333333%;
}

.order-toast-module__offset-8___2UC_L {
  margin-left: 66.6666666667%;
}

.order-toast-module__offset-9___1aS8a {
  margin-left: 75%;
}

.order-toast-module__offset-10___2EChd {
  margin-left: 83.3333333333%;
}

.order-toast-module__offset-11___2H3mq {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .order-toast-module__col-sm___1qKRY {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .order-toast-module__col-sm-auto___bfOTc {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .order-toast-module__col-sm-1___1C0Xd {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-toast-module__col-sm-2___2__1R {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-toast-module__col-sm-3___2dgMK {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .order-toast-module__col-sm-4___1go6U {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-toast-module__col-sm-5___EWAut {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-toast-module__col-sm-6___3hW12 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .order-toast-module__col-sm-7___19Izf {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-toast-module__col-sm-8___NX7_9 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-toast-module__col-sm-9___4olgS {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .order-toast-module__col-sm-10___2HSOe {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-toast-module__col-sm-11___PwBL- {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-toast-module__col-sm-12___1CcvL {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-toast-module__order-sm-first___r2qxL {
    order: -1;
  }

  .order-toast-module__order-sm-last___1gTyJ {
    order: 13;
  }

  .order-toast-module__order-sm-0___2cAy0 {
    order: 0;
  }

  .order-toast-module__order-sm-1___24a2y {
    order: 1;
  }

  .order-toast-module__order-sm-2___OqiwM {
    order: 2;
  }

  .order-toast-module__order-sm-3___1lU27 {
    order: 3;
  }

  .order-toast-module__order-sm-4___2-9ew {
    order: 4;
  }

  .order-toast-module__order-sm-5___2aNSK {
    order: 5;
  }

  .order-toast-module__order-sm-6___1ZSjH {
    order: 6;
  }

  .order-toast-module__order-sm-7___2eV56 {
    order: 7;
  }

  .order-toast-module__order-sm-8___184jn {
    order: 8;
  }

  .order-toast-module__order-sm-9___22Kiv {
    order: 9;
  }

  .order-toast-module__order-sm-10____mwxm {
    order: 10;
  }

  .order-toast-module__order-sm-11___2NAWH {
    order: 11;
  }

  .order-toast-module__order-sm-12___3Wwbq {
    order: 12;
  }

  .order-toast-module__offset-sm-0___1K9-X {
    margin-left: 0;
  }

  .order-toast-module__offset-sm-1___2TIbu {
    margin-left: 8.3333333333%;
  }

  .order-toast-module__offset-sm-2___1o7J6 {
    margin-left: 16.6666666667%;
  }

  .order-toast-module__offset-sm-3___24AZz {
    margin-left: 25%;
  }

  .order-toast-module__offset-sm-4___3Mw83 {
    margin-left: 33.3333333333%;
  }

  .order-toast-module__offset-sm-5___hV0An {
    margin-left: 41.6666666667%;
  }

  .order-toast-module__offset-sm-6___10Cgl {
    margin-left: 50%;
  }

  .order-toast-module__offset-sm-7___1dQH1 {
    margin-left: 58.3333333333%;
  }

  .order-toast-module__offset-sm-8___1qeeZ {
    margin-left: 66.6666666667%;
  }

  .order-toast-module__offset-sm-9___ttv3m {
    margin-left: 75%;
  }

  .order-toast-module__offset-sm-10___HT78I {
    margin-left: 83.3333333333%;
  }

  .order-toast-module__offset-sm-11___3nysU {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .order-toast-module__col-md___2uxiI {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .order-toast-module__col-md-auto___1IZuR {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .order-toast-module__col-md-1___1Hbct {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-toast-module__col-md-2___1QEpr {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-toast-module__col-md-3____0gBd {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .order-toast-module__col-md-4___36z9G {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-toast-module__col-md-5___zu6c- {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-toast-module__col-md-6___ijbDn {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .order-toast-module__col-md-7___1C011 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-toast-module__col-md-8___2rnza {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-toast-module__col-md-9___2nJ00 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .order-toast-module__col-md-10___24tiT {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-toast-module__col-md-11___2uaDr {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-toast-module__col-md-12___KQhJY {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-toast-module__order-md-first___1dY-_ {
    order: -1;
  }

  .order-toast-module__order-md-last___2F0r2 {
    order: 13;
  }

  .order-toast-module__order-md-0___1AMnf {
    order: 0;
  }

  .order-toast-module__order-md-1___3Ca6O {
    order: 1;
  }

  .order-toast-module__order-md-2___26wPs {
    order: 2;
  }

  .order-toast-module__order-md-3___PGIFk {
    order: 3;
  }

  .order-toast-module__order-md-4___1RNcd {
    order: 4;
  }

  .order-toast-module__order-md-5___16er8 {
    order: 5;
  }

  .order-toast-module__order-md-6___3zl6K {
    order: 6;
  }

  .order-toast-module__order-md-7___2M6VB {
    order: 7;
  }

  .order-toast-module__order-md-8___1K-Sa {
    order: 8;
  }

  .order-toast-module__order-md-9___1kXz0 {
    order: 9;
  }

  .order-toast-module__order-md-10___1pEUX {
    order: 10;
  }

  .order-toast-module__order-md-11___3rbX_ {
    order: 11;
  }

  .order-toast-module__order-md-12___2j090 {
    order: 12;
  }

  .order-toast-module__offset-md-0___2oM5K {
    margin-left: 0;
  }

  .order-toast-module__offset-md-1___3yCef {
    margin-left: 8.3333333333%;
  }

  .order-toast-module__offset-md-2___LfCGX {
    margin-left: 16.6666666667%;
  }

  .order-toast-module__offset-md-3___1ubvl {
    margin-left: 25%;
  }

  .order-toast-module__offset-md-4___3Ua5R {
    margin-left: 33.3333333333%;
  }

  .order-toast-module__offset-md-5___2yCes {
    margin-left: 41.6666666667%;
  }

  .order-toast-module__offset-md-6___2qZSX {
    margin-left: 50%;
  }

  .order-toast-module__offset-md-7___1JpCK {
    margin-left: 58.3333333333%;
  }

  .order-toast-module__offset-md-8___2Erig {
    margin-left: 66.6666666667%;
  }

  .order-toast-module__offset-md-9___2dAxD {
    margin-left: 75%;
  }

  .order-toast-module__offset-md-10___2IhRX {
    margin-left: 83.3333333333%;
  }

  .order-toast-module__offset-md-11___27d-- {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .order-toast-module__col-lg___2O2W1 {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .order-toast-module__col-lg-auto___W3WBb {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .order-toast-module__col-lg-1___3jx6c {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-toast-module__col-lg-2___3m84J {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-toast-module__col-lg-3___2zKBO {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .order-toast-module__col-lg-4___3G1K2 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-toast-module__col-lg-5___1pj3Q {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-toast-module__col-lg-6___2b1a9 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .order-toast-module__col-lg-7___brU9r {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-toast-module__col-lg-8___3P4k1 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-toast-module__col-lg-9___2u5to {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .order-toast-module__col-lg-10___37ZvP {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-toast-module__col-lg-11___11njy {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-toast-module__col-lg-12___1LcbH {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-toast-module__order-lg-first___ILo5o {
    order: -1;
  }

  .order-toast-module__order-lg-last___2BwEH {
    order: 13;
  }

  .order-toast-module__order-lg-0___2tf8u {
    order: 0;
  }

  .order-toast-module__order-lg-1___2jt7o {
    order: 1;
  }

  .order-toast-module__order-lg-2___1rl8c {
    order: 2;
  }

  .order-toast-module__order-lg-3___2dorv {
    order: 3;
  }

  .order-toast-module__order-lg-4___1I5U3 {
    order: 4;
  }

  .order-toast-module__order-lg-5___1Qch- {
    order: 5;
  }

  .order-toast-module__order-lg-6___3d2N6 {
    order: 6;
  }

  .order-toast-module__order-lg-7___313Mu {
    order: 7;
  }

  .order-toast-module__order-lg-8___367ye {
    order: 8;
  }

  .order-toast-module__order-lg-9___2W5C1 {
    order: 9;
  }

  .order-toast-module__order-lg-10___72nde {
    order: 10;
  }

  .order-toast-module__order-lg-11___M4uff {
    order: 11;
  }

  .order-toast-module__order-lg-12___3b_Q5 {
    order: 12;
  }

  .order-toast-module__offset-lg-0___2YUUN {
    margin-left: 0;
  }

  .order-toast-module__offset-lg-1___2Fnie {
    margin-left: 8.3333333333%;
  }

  .order-toast-module__offset-lg-2___1vJjp {
    margin-left: 16.6666666667%;
  }

  .order-toast-module__offset-lg-3___19dq8 {
    margin-left: 25%;
  }

  .order-toast-module__offset-lg-4___1aCao {
    margin-left: 33.3333333333%;
  }

  .order-toast-module__offset-lg-5___2nITN {
    margin-left: 41.6666666667%;
  }

  .order-toast-module__offset-lg-6___2Mc-_ {
    margin-left: 50%;
  }

  .order-toast-module__offset-lg-7___3KOFR {
    margin-left: 58.3333333333%;
  }

  .order-toast-module__offset-lg-8___1a5YV {
    margin-left: 66.6666666667%;
  }

  .order-toast-module__offset-lg-9___2meu- {
    margin-left: 75%;
  }

  .order-toast-module__offset-lg-10___RVgoA {
    margin-left: 83.3333333333%;
  }

  .order-toast-module__offset-lg-11___3_GSe {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .order-toast-module__col-xl___3tAgp {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .order-toast-module__col-xl-auto___yoGnE {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .order-toast-module__col-xl-1___3p-ot {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-toast-module__col-xl-2___12uWv {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-toast-module__col-xl-3___3Q-OX {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .order-toast-module__col-xl-4___2J9t2 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-toast-module__col-xl-5___1Gi5_ {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-toast-module__col-xl-6___2zFcR {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .order-toast-module__col-xl-7___1rspc {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-toast-module__col-xl-8___29KdQ {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-toast-module__col-xl-9___2CMuH {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .order-toast-module__col-xl-10___lNMJJ {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-toast-module__col-xl-11___2T5EI {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-toast-module__col-xl-12___1ZOm_ {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-toast-module__order-xl-first___1Ag1K {
    order: -1;
  }

  .order-toast-module__order-xl-last___2bZ7b {
    order: 13;
  }

  .order-toast-module__order-xl-0___2D6Ty {
    order: 0;
  }

  .order-toast-module__order-xl-1___6i6M3 {
    order: 1;
  }

  .order-toast-module__order-xl-2___1CEo7 {
    order: 2;
  }

  .order-toast-module__order-xl-3___2Uqub {
    order: 3;
  }

  .order-toast-module__order-xl-4___3Nj__ {
    order: 4;
  }

  .order-toast-module__order-xl-5___1O1-L {
    order: 5;
  }

  .order-toast-module__order-xl-6___3INWu {
    order: 6;
  }

  .order-toast-module__order-xl-7___3cJGO {
    order: 7;
  }

  .order-toast-module__order-xl-8___3ksgh {
    order: 8;
  }

  .order-toast-module__order-xl-9___1Kq_s {
    order: 9;
  }

  .order-toast-module__order-xl-10___3neG9 {
    order: 10;
  }

  .order-toast-module__order-xl-11___33b8o {
    order: 11;
  }

  .order-toast-module__order-xl-12___2AtBq {
    order: 12;
  }

  .order-toast-module__offset-xl-0___1t12I {
    margin-left: 0;
  }

  .order-toast-module__offset-xl-1___2i90R {
    margin-left: 8.3333333333%;
  }

  .order-toast-module__offset-xl-2___x2ztf {
    margin-left: 16.6666666667%;
  }

  .order-toast-module__offset-xl-3___3W2Vx {
    margin-left: 25%;
  }

  .order-toast-module__offset-xl-4___1bGQm {
    margin-left: 33.3333333333%;
  }

  .order-toast-module__offset-xl-5___30ZsG {
    margin-left: 41.6666666667%;
  }

  .order-toast-module__offset-xl-6___2YzSs {
    margin-left: 50%;
  }

  .order-toast-module__offset-xl-7___2yw38 {
    margin-left: 58.3333333333%;
  }

  .order-toast-module__offset-xl-8___30DLl {
    margin-left: 66.6666666667%;
  }

  .order-toast-module__offset-xl-9___NRn_a {
    margin-left: 75%;
  }

  .order-toast-module__offset-xl-10___3w564 {
    margin-left: 83.3333333333%;
  }

  .order-toast-module__offset-xl-11___10SWA {
    margin-left: 91.6666666667%;
  }
}
.order-toast-module__table___2bBye {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.order-toast-module__table___2bBye th,
.order-toast-module__table___2bBye td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.order-toast-module__table___2bBye thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.order-toast-module__table___2bBye tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.order-toast-module__table-sm___8HRz_ th,
.order-toast-module__table-sm___8HRz_ td {
  padding: 0.3rem;
}

.order-toast-module__table-bordered___ZOdBm {
  border: 1px solid #dee2e6;
}
.order-toast-module__table-bordered___ZOdBm th,
.order-toast-module__table-bordered___ZOdBm td {
  border: 1px solid #dee2e6;
}
.order-toast-module__table-bordered___ZOdBm thead th,
.order-toast-module__table-bordered___ZOdBm thead td {
  border-bottom-width: 2px;
}

.order-toast-module__table-borderless___deS7y th,
.order-toast-module__table-borderless___deS7y td,
.order-toast-module__table-borderless___deS7y thead th,
.order-toast-module__table-borderless___deS7y tbody + tbody {
  border: 0;
}

.order-toast-module__table-striped___29nAI tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.order-toast-module__table-hover___20Np8 tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.order-toast-module__table-primary___3huqr,
.order-toast-module__table-primary___3huqr > th,
.order-toast-module__table-primary___3huqr > td {
  background-color: #b8daff;
}
.order-toast-module__table-primary___3huqr th,
.order-toast-module__table-primary___3huqr td,
.order-toast-module__table-primary___3huqr thead th,
.order-toast-module__table-primary___3huqr tbody + tbody {
  border-color: #7abaff;
}

.order-toast-module__table-hover___20Np8 .order-toast-module__table-primary___3huqr:hover {
  background-color: #9fcdff;
}
.order-toast-module__table-hover___20Np8 .order-toast-module__table-primary___3huqr:hover > td,
.order-toast-module__table-hover___20Np8 .order-toast-module__table-primary___3huqr:hover > th {
  background-color: #9fcdff;
}

.order-toast-module__table-secondary___1YtpD,
.order-toast-module__table-secondary___1YtpD > th,
.order-toast-module__table-secondary___1YtpD > td {
  background-color: #d6d8db;
}
.order-toast-module__table-secondary___1YtpD th,
.order-toast-module__table-secondary___1YtpD td,
.order-toast-module__table-secondary___1YtpD thead th,
.order-toast-module__table-secondary___1YtpD tbody + tbody {
  border-color: #b3b7bb;
}

.order-toast-module__table-hover___20Np8 .order-toast-module__table-secondary___1YtpD:hover {
  background-color: #c8cbcf;
}
.order-toast-module__table-hover___20Np8 .order-toast-module__table-secondary___1YtpD:hover > td,
.order-toast-module__table-hover___20Np8 .order-toast-module__table-secondary___1YtpD:hover > th {
  background-color: #c8cbcf;
}

.order-toast-module__table-success___3CQy6,
.order-toast-module__table-success___3CQy6 > th,
.order-toast-module__table-success___3CQy6 > td {
  background-color: #c3e6cb;
}
.order-toast-module__table-success___3CQy6 th,
.order-toast-module__table-success___3CQy6 td,
.order-toast-module__table-success___3CQy6 thead th,
.order-toast-module__table-success___3CQy6 tbody + tbody {
  border-color: #8fd19e;
}

.order-toast-module__table-hover___20Np8 .order-toast-module__table-success___3CQy6:hover {
  background-color: #b1dfbb;
}
.order-toast-module__table-hover___20Np8 .order-toast-module__table-success___3CQy6:hover > td,
.order-toast-module__table-hover___20Np8 .order-toast-module__table-success___3CQy6:hover > th {
  background-color: #b1dfbb;
}

.order-toast-module__table-info___2AibN,
.order-toast-module__table-info___2AibN > th,
.order-toast-module__table-info___2AibN > td {
  background-color: #bee5eb;
}
.order-toast-module__table-info___2AibN th,
.order-toast-module__table-info___2AibN td,
.order-toast-module__table-info___2AibN thead th,
.order-toast-module__table-info___2AibN tbody + tbody {
  border-color: #86cfda;
}

.order-toast-module__table-hover___20Np8 .order-toast-module__table-info___2AibN:hover {
  background-color: #abdde5;
}
.order-toast-module__table-hover___20Np8 .order-toast-module__table-info___2AibN:hover > td,
.order-toast-module__table-hover___20Np8 .order-toast-module__table-info___2AibN:hover > th {
  background-color: #abdde5;
}

.order-toast-module__table-warning___1KBeB,
.order-toast-module__table-warning___1KBeB > th,
.order-toast-module__table-warning___1KBeB > td {
  background-color: #ffeeba;
}
.order-toast-module__table-warning___1KBeB th,
.order-toast-module__table-warning___1KBeB td,
.order-toast-module__table-warning___1KBeB thead th,
.order-toast-module__table-warning___1KBeB tbody + tbody {
  border-color: #ffdf7e;
}

.order-toast-module__table-hover___20Np8 .order-toast-module__table-warning___1KBeB:hover {
  background-color: #ffe8a1;
}
.order-toast-module__table-hover___20Np8 .order-toast-module__table-warning___1KBeB:hover > td,
.order-toast-module__table-hover___20Np8 .order-toast-module__table-warning___1KBeB:hover > th {
  background-color: #ffe8a1;
}

.order-toast-module__table-danger___TQg1w,
.order-toast-module__table-danger___TQg1w > th,
.order-toast-module__table-danger___TQg1w > td {
  background-color: #f5c6cb;
}
.order-toast-module__table-danger___TQg1w th,
.order-toast-module__table-danger___TQg1w td,
.order-toast-module__table-danger___TQg1w thead th,
.order-toast-module__table-danger___TQg1w tbody + tbody {
  border-color: #ed969e;
}

.order-toast-module__table-hover___20Np8 .order-toast-module__table-danger___TQg1w:hover {
  background-color: #f1b0b7;
}
.order-toast-module__table-hover___20Np8 .order-toast-module__table-danger___TQg1w:hover > td,
.order-toast-module__table-hover___20Np8 .order-toast-module__table-danger___TQg1w:hover > th {
  background-color: #f1b0b7;
}

.order-toast-module__table-light___1RIqk,
.order-toast-module__table-light___1RIqk > th,
.order-toast-module__table-light___1RIqk > td {
  background-color: #fdfdfe;
}
.order-toast-module__table-light___1RIqk th,
.order-toast-module__table-light___1RIqk td,
.order-toast-module__table-light___1RIqk thead th,
.order-toast-module__table-light___1RIqk tbody + tbody {
  border-color: #fbfcfc;
}

.order-toast-module__table-hover___20Np8 .order-toast-module__table-light___1RIqk:hover {
  background-color: #ececf6;
}
.order-toast-module__table-hover___20Np8 .order-toast-module__table-light___1RIqk:hover > td,
.order-toast-module__table-hover___20Np8 .order-toast-module__table-light___1RIqk:hover > th {
  background-color: #ececf6;
}

.order-toast-module__table-dark___2VXWH,
.order-toast-module__table-dark___2VXWH > th,
.order-toast-module__table-dark___2VXWH > td {
  background-color: #c6c8ca;
}
.order-toast-module__table-dark___2VXWH th,
.order-toast-module__table-dark___2VXWH td,
.order-toast-module__table-dark___2VXWH thead th,
.order-toast-module__table-dark___2VXWH tbody + tbody {
  border-color: #95999c;
}

.order-toast-module__table-hover___20Np8 .order-toast-module__table-dark___2VXWH:hover {
  background-color: #b9bbbe;
}
.order-toast-module__table-hover___20Np8 .order-toast-module__table-dark___2VXWH:hover > td,
.order-toast-module__table-hover___20Np8 .order-toast-module__table-dark___2VXWH:hover > th {
  background-color: #b9bbbe;
}

.order-toast-module__table-active___NQr08,
.order-toast-module__table-active___NQr08 > th,
.order-toast-module__table-active___NQr08 > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.order-toast-module__table-hover___20Np8 .order-toast-module__table-active___NQr08:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.order-toast-module__table-hover___20Np8 .order-toast-module__table-active___NQr08:hover > td,
.order-toast-module__table-hover___20Np8 .order-toast-module__table-active___NQr08:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.order-toast-module__table___2bBye .order-toast-module__thead-dark___3ocCw th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.order-toast-module__table___2bBye .order-toast-module__thead-light___2mTo8 th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.order-toast-module__table-dark___2VXWH {
  color: #fff;
  background-color: #343a40;
}
.order-toast-module__table-dark___2VXWH th,
.order-toast-module__table-dark___2VXWH td,
.order-toast-module__table-dark___2VXWH thead th {
  border-color: #454d55;
}
.order-toast-module__table-dark___2VXWH.order-toast-module__table-bordered___ZOdBm {
  border: 0;
}
.order-toast-module__table-dark___2VXWH.order-toast-module__table-striped___29nAI tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.order-toast-module__table-dark___2VXWH.order-toast-module__table-hover___20Np8 tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .order-toast-module__table-responsive-sm___1l970 {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .order-toast-module__table-responsive-sm___1l970 > .order-toast-module__table-bordered___ZOdBm {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .order-toast-module__table-responsive-md___3sJBY {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .order-toast-module__table-responsive-md___3sJBY > .order-toast-module__table-bordered___ZOdBm {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .order-toast-module__table-responsive-lg___12kLg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .order-toast-module__table-responsive-lg___12kLg > .order-toast-module__table-bordered___ZOdBm {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .order-toast-module__table-responsive-xl___2lwj5 {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .order-toast-module__table-responsive-xl___2lwj5 > .order-toast-module__table-bordered___ZOdBm {
    border: 0;
  }
}
.order-toast-module__table-responsive___3LEPY {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.order-toast-module__table-responsive___3LEPY > .order-toast-module__table-bordered___ZOdBm {
  border: 0;
}

.order-toast-module__form-control___3G1v6 {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__form-control___3G1v6 {
    transition: none;
  }
}
.order-toast-module__form-control___3G1v6::-ms-expand {
  background-color: transparent;
  border: 0;
}
.order-toast-module__form-control___3G1v6:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.order-toast-module__form-control___3G1v6::placeholder {
  color: #6c757d;
  opacity: 1;
}
.order-toast-module__form-control___3G1v6:disabled, .order-toast-module__form-control___3G1v6[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.order-toast-module__form-control___3G1v6:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.order-toast-module__form-control-file___2WMC0,
.order-toast-module__form-control-range___3RQ_G {
  display: block;
  width: 100%;
}

.order-toast-module__col-form-label___c0BmC {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.order-toast-module__col-form-label-lg___2lL0Z {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.order-toast-module__col-form-label-sm___IHoLg {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.order-toast-module__form-control-plaintext___1pd52 {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.order-toast-module__form-control-plaintext___1pd52.order-toast-module__form-control-sm___1thGj, .order-toast-module__form-control-plaintext___1pd52.order-toast-module__form-control-lg___1EMu1 {
  padding-right: 0;
  padding-left: 0;
}

.order-toast-module__form-control-sm___1thGj {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.order-toast-module__form-control-lg___1EMu1 {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.order-toast-module__form-control___3G1v6[size], select.order-toast-module__form-control___3G1v6[multiple] {
  height: auto;
}

textarea.order-toast-module__form-control___3G1v6 {
  height: auto;
}

.order-toast-module__form-group___IuciL {
  margin-bottom: 1rem;
}

.order-toast-module__form-text___1NEJu {
  display: block;
  margin-top: 0.25rem;
}

.order-toast-module__form-row___1gT_P {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.order-toast-module__form-row___1gT_P > .order-toast-module__col___14mxK,
.order-toast-module__form-row___1gT_P > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.order-toast-module__form-check___FkUv4 {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.order-toast-module__form-check-input___vvkoS {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.order-toast-module__form-check-input___vvkoS:disabled ~ .order-toast-module__form-check-label___3PM90 {
  color: #6c757d;
}

.order-toast-module__form-check-label___3PM90 {
  margin-bottom: 0;
}

.order-toast-module__form-check-inline___2h9jA {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.order-toast-module__form-check-inline___2h9jA .order-toast-module__form-check-input___vvkoS {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.order-toast-module__valid-feedback___oMvDt {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.order-toast-module__valid-tooltip___23qB3 {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.order-toast-module__was-validated___2Kzli .order-toast-module__form-control___3G1v6:valid, .order-toast-module__form-control___3G1v6.order-toast-module__is-valid___3HAuS {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.order-toast-module__was-validated___2Kzli .order-toast-module__form-control___3G1v6:valid:focus, .order-toast-module__form-control___3G1v6.order-toast-module__is-valid___3HAuS:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.order-toast-module__was-validated___2Kzli .order-toast-module__form-control___3G1v6:valid ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__was-validated___2Kzli .order-toast-module__form-control___3G1v6:valid ~ .order-toast-module__valid-tooltip___23qB3, .order-toast-module__form-control___3G1v6.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__form-control___3G1v6.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-tooltip___23qB3 {
  display: block;
}

.order-toast-module__was-validated___2Kzli textarea.order-toast-module__form-control___3G1v6:valid, textarea.order-toast-module__form-control___3G1v6.order-toast-module__is-valid___3HAuS {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.order-toast-module__was-validated___2Kzli .order-toast-module__custom-select___Cbhkk:valid, .order-toast-module__custom-select___Cbhkk.order-toast-module__is-valid___3HAuS {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-select___Cbhkk:valid:focus, .order-toast-module__custom-select___Cbhkk.order-toast-module__is-valid___3HAuS:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-select___Cbhkk:valid ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-select___Cbhkk:valid ~ .order-toast-module__valid-tooltip___23qB3, .order-toast-module__custom-select___Cbhkk.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__custom-select___Cbhkk.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-tooltip___23qB3 {
  display: block;
}

.order-toast-module__was-validated___2Kzli .order-toast-module__form-control-file___2WMC0:valid ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__was-validated___2Kzli .order-toast-module__form-control-file___2WMC0:valid ~ .order-toast-module__valid-tooltip___23qB3, .order-toast-module__form-control-file___2WMC0.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__form-control-file___2WMC0.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-tooltip___23qB3 {
  display: block;
}

.order-toast-module__was-validated___2Kzli .order-toast-module__form-check-input___vvkoS:valid ~ .order-toast-module__form-check-label___3PM90, .order-toast-module__form-check-input___vvkoS.order-toast-module__is-valid___3HAuS ~ .order-toast-module__form-check-label___3PM90 {
  color: #28a745;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__form-check-input___vvkoS:valid ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__was-validated___2Kzli .order-toast-module__form-check-input___vvkoS:valid ~ .order-toast-module__valid-tooltip___23qB3, .order-toast-module__form-check-input___vvkoS.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__form-check-input___vvkoS.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-tooltip___23qB3 {
  display: block;
}

.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:valid ~ .order-toast-module__custom-control-label___2sI_8, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-valid___3HAuS ~ .order-toast-module__custom-control-label___2sI_8 {
  color: #28a745;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:valid ~ .order-toast-module__custom-control-label___2sI_8::before, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-valid___3HAuS ~ .order-toast-module__custom-control-label___2sI_8::before {
  border-color: #28a745;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:valid ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:valid ~ .order-toast-module__valid-tooltip___23qB3, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__custom-control-input___32Eb7.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-tooltip___23qB3 {
  display: block;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:valid:checked ~ .order-toast-module__custom-control-label___2sI_8::before, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-valid___3HAuS:checked ~ .order-toast-module__custom-control-label___2sI_8::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:valid:focus ~ .order-toast-module__custom-control-label___2sI_8::before, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-valid___3HAuS:focus ~ .order-toast-module__custom-control-label___2sI_8::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:valid:focus:not(:checked) ~ .order-toast-module__custom-control-label___2sI_8::before, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-valid___3HAuS:focus:not(:checked) ~ .order-toast-module__custom-control-label___2sI_8::before {
  border-color: #28a745;
}

.order-toast-module__was-validated___2Kzli .order-toast-module__custom-file-input___3bTPf:valid ~ .order-toast-module__custom-file-label___VBm6O, .order-toast-module__custom-file-input___3bTPf.order-toast-module__is-valid___3HAuS ~ .order-toast-module__custom-file-label___VBm6O {
  border-color: #28a745;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-file-input___3bTPf:valid ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-file-input___3bTPf:valid ~ .order-toast-module__valid-tooltip___23qB3, .order-toast-module__custom-file-input___3bTPf.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-feedback___oMvDt,
.order-toast-module__custom-file-input___3bTPf.order-toast-module__is-valid___3HAuS ~ .order-toast-module__valid-tooltip___23qB3 {
  display: block;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-file-input___3bTPf:valid:focus ~ .order-toast-module__custom-file-label___VBm6O, .order-toast-module__custom-file-input___3bTPf.order-toast-module__is-valid___3HAuS:focus ~ .order-toast-module__custom-file-label___VBm6O {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.order-toast-module__invalid-feedback___261db {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.order-toast-module__invalid-tooltip___fTl2z {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.order-toast-module__was-validated___2Kzli .order-toast-module__form-control___3G1v6:invalid, .order-toast-module__form-control___3G1v6.order-toast-module__is-invalid___2FeuE {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.order-toast-module__was-validated___2Kzli .order-toast-module__form-control___3G1v6:invalid:focus, .order-toast-module__form-control___3G1v6.order-toast-module__is-invalid___2FeuE:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.order-toast-module__was-validated___2Kzli .order-toast-module__form-control___3G1v6:invalid ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__was-validated___2Kzli .order-toast-module__form-control___3G1v6:invalid ~ .order-toast-module__invalid-tooltip___fTl2z, .order-toast-module__form-control___3G1v6.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__form-control___3G1v6.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-tooltip___fTl2z {
  display: block;
}

.order-toast-module__was-validated___2Kzli textarea.order-toast-module__form-control___3G1v6:invalid, textarea.order-toast-module__form-control___3G1v6.order-toast-module__is-invalid___2FeuE {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.order-toast-module__was-validated___2Kzli .order-toast-module__custom-select___Cbhkk:invalid, .order-toast-module__custom-select___Cbhkk.order-toast-module__is-invalid___2FeuE {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-select___Cbhkk:invalid:focus, .order-toast-module__custom-select___Cbhkk.order-toast-module__is-invalid___2FeuE:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-select___Cbhkk:invalid ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-select___Cbhkk:invalid ~ .order-toast-module__invalid-tooltip___fTl2z, .order-toast-module__custom-select___Cbhkk.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__custom-select___Cbhkk.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-tooltip___fTl2z {
  display: block;
}

.order-toast-module__was-validated___2Kzli .order-toast-module__form-control-file___2WMC0:invalid ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__was-validated___2Kzli .order-toast-module__form-control-file___2WMC0:invalid ~ .order-toast-module__invalid-tooltip___fTl2z, .order-toast-module__form-control-file___2WMC0.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__form-control-file___2WMC0.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-tooltip___fTl2z {
  display: block;
}

.order-toast-module__was-validated___2Kzli .order-toast-module__form-check-input___vvkoS:invalid ~ .order-toast-module__form-check-label___3PM90, .order-toast-module__form-check-input___vvkoS.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__form-check-label___3PM90 {
  color: #dc3545;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__form-check-input___vvkoS:invalid ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__was-validated___2Kzli .order-toast-module__form-check-input___vvkoS:invalid ~ .order-toast-module__invalid-tooltip___fTl2z, .order-toast-module__form-check-input___vvkoS.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__form-check-input___vvkoS.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-tooltip___fTl2z {
  display: block;
}

.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:invalid ~ .order-toast-module__custom-control-label___2sI_8, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__custom-control-label___2sI_8 {
  color: #dc3545;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:invalid ~ .order-toast-module__custom-control-label___2sI_8::before, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__custom-control-label___2sI_8::before {
  border-color: #dc3545;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:invalid ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:invalid ~ .order-toast-module__invalid-tooltip___fTl2z, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__custom-control-input___32Eb7.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-tooltip___fTl2z {
  display: block;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:invalid:checked ~ .order-toast-module__custom-control-label___2sI_8::before, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-invalid___2FeuE:checked ~ .order-toast-module__custom-control-label___2sI_8::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:invalid:focus ~ .order-toast-module__custom-control-label___2sI_8::before, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-invalid___2FeuE:focus ~ .order-toast-module__custom-control-label___2sI_8::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-control-input___32Eb7:invalid:focus:not(:checked) ~ .order-toast-module__custom-control-label___2sI_8::before, .order-toast-module__custom-control-input___32Eb7.order-toast-module__is-invalid___2FeuE:focus:not(:checked) ~ .order-toast-module__custom-control-label___2sI_8::before {
  border-color: #dc3545;
}

.order-toast-module__was-validated___2Kzli .order-toast-module__custom-file-input___3bTPf:invalid ~ .order-toast-module__custom-file-label___VBm6O, .order-toast-module__custom-file-input___3bTPf.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__custom-file-label___VBm6O {
  border-color: #dc3545;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-file-input___3bTPf:invalid ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-file-input___3bTPf:invalid ~ .order-toast-module__invalid-tooltip___fTl2z, .order-toast-module__custom-file-input___3bTPf.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-feedback___261db,
.order-toast-module__custom-file-input___3bTPf.order-toast-module__is-invalid___2FeuE ~ .order-toast-module__invalid-tooltip___fTl2z {
  display: block;
}
.order-toast-module__was-validated___2Kzli .order-toast-module__custom-file-input___3bTPf:invalid:focus ~ .order-toast-module__custom-file-label___VBm6O, .order-toast-module__custom-file-input___3bTPf.order-toast-module__is-invalid___2FeuE:focus ~ .order-toast-module__custom-file-label___VBm6O {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.order-toast-module__form-inline___3XcjB {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.order-toast-module__form-inline___3XcjB .order-toast-module__form-check___FkUv4 {
  width: 100%;
}
@media (min-width: 576px) {
  .order-toast-module__form-inline___3XcjB label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .order-toast-module__form-inline___3XcjB .order-toast-module__form-group___IuciL {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .order-toast-module__form-inline___3XcjB .order-toast-module__form-control___3G1v6 {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .order-toast-module__form-inline___3XcjB .order-toast-module__form-control-plaintext___1pd52 {
    display: inline-block;
  }
  .order-toast-module__form-inline___3XcjB .order-toast-module__input-group___1Bg_b,
.order-toast-module__form-inline___3XcjB .order-toast-module__custom-select___Cbhkk {
    width: auto;
  }
  .order-toast-module__form-inline___3XcjB .order-toast-module__form-check___FkUv4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .order-toast-module__form-inline___3XcjB .order-toast-module__form-check-input___vvkoS {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .order-toast-module__form-inline___3XcjB .order-toast-module__custom-control___3axQu {
    align-items: center;
    justify-content: center;
  }
  .order-toast-module__form-inline___3XcjB .order-toast-module__custom-control-label___2sI_8 {
    margin-bottom: 0;
  }
}

.order-toast-module__btn___3K_89 {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__btn___3K_89 {
    transition: none;
  }
}
.order-toast-module__btn___3K_89:hover {
  color: #212529;
  text-decoration: none;
}
.order-toast-module__btn___3K_89:focus, .order-toast-module__btn___3K_89.order-toast-module__focus___uquVV {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.order-toast-module__btn___3K_89.order-toast-module__disabled___Tbq1L, .order-toast-module__btn___3K_89:disabled {
  opacity: 0.65;
}
a.order-toast-module__btn___3K_89.order-toast-module__disabled___Tbq1L,
fieldset:disabled a.order-toast-module__btn___3K_89 {
  pointer-events: none;
}

.order-toast-module__btn-primary___x8NXw {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.order-toast-module__btn-primary___x8NXw:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.order-toast-module__btn-primary___x8NXw:focus, .order-toast-module__btn-primary___x8NXw.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.order-toast-module__btn-primary___x8NXw.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-primary___x8NXw:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.order-toast-module__btn-primary___x8NXw:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-primary___x8NXw:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-primary___x8NXw.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.order-toast-module__btn-primary___x8NXw:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-primary___x8NXw:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-primary___x8NXw.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.order-toast-module__btn-secondary___3i5l7 {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.order-toast-module__btn-secondary___3i5l7:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.order-toast-module__btn-secondary___3i5l7:focus, .order-toast-module__btn-secondary___3i5l7.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.order-toast-module__btn-secondary___3i5l7.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-secondary___3i5l7:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.order-toast-module__btn-secondary___3i5l7:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-secondary___3i5l7:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-secondary___3i5l7.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.order-toast-module__btn-secondary___3i5l7:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-secondary___3i5l7:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-secondary___3i5l7.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.order-toast-module__btn-success___2Lyun {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.order-toast-module__btn-success___2Lyun:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.order-toast-module__btn-success___2Lyun:focus, .order-toast-module__btn-success___2Lyun.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.order-toast-module__btn-success___2Lyun.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-success___2Lyun:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.order-toast-module__btn-success___2Lyun:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-success___2Lyun:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-success___2Lyun.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.order-toast-module__btn-success___2Lyun:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-success___2Lyun:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-success___2Lyun.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.order-toast-module__btn-info___3am20 {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.order-toast-module__btn-info___3am20:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.order-toast-module__btn-info___3am20:focus, .order-toast-module__btn-info___3am20.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.order-toast-module__btn-info___3am20.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-info___3am20:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.order-toast-module__btn-info___3am20:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-info___3am20:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-info___3am20.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.order-toast-module__btn-info___3am20:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-info___3am20:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-info___3am20.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.order-toast-module__btn-warning___1bTxJ {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.order-toast-module__btn-warning___1bTxJ:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.order-toast-module__btn-warning___1bTxJ:focus, .order-toast-module__btn-warning___1bTxJ.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.order-toast-module__btn-warning___1bTxJ.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-warning___1bTxJ:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.order-toast-module__btn-warning___1bTxJ:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-warning___1bTxJ:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-warning___1bTxJ.order-toast-module__dropdown-toggle___343n3 {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.order-toast-module__btn-warning___1bTxJ:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-warning___1bTxJ:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-warning___1bTxJ.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.order-toast-module__btn-danger___eS3HV {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.order-toast-module__btn-danger___eS3HV:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.order-toast-module__btn-danger___eS3HV:focus, .order-toast-module__btn-danger___eS3HV.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.order-toast-module__btn-danger___eS3HV.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-danger___eS3HV:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.order-toast-module__btn-danger___eS3HV:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-danger___eS3HV:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-danger___eS3HV.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.order-toast-module__btn-danger___eS3HV:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-danger___eS3HV:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-danger___eS3HV.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.order-toast-module__btn-light___2rXQn {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.order-toast-module__btn-light___2rXQn:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.order-toast-module__btn-light___2rXQn:focus, .order-toast-module__btn-light___2rXQn.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.order-toast-module__btn-light___2rXQn.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-light___2rXQn:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.order-toast-module__btn-light___2rXQn:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-light___2rXQn:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-light___2rXQn.order-toast-module__dropdown-toggle___343n3 {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.order-toast-module__btn-light___2rXQn:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-light___2rXQn:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-light___2rXQn.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.order-toast-module__btn-dark___1ZylI {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.order-toast-module__btn-dark___1ZylI:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.order-toast-module__btn-dark___1ZylI:focus, .order-toast-module__btn-dark___1ZylI.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.order-toast-module__btn-dark___1ZylI.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-dark___1ZylI:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.order-toast-module__btn-dark___1ZylI:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-dark___1ZylI:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-dark___1ZylI.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.order-toast-module__btn-dark___1ZylI:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-dark___1ZylI:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-dark___1ZylI.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.order-toast-module__btn-outline-primary___cCBub {
  color: #007bff;
  border-color: #007bff;
}
.order-toast-module__btn-outline-primary___cCBub:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.order-toast-module__btn-outline-primary___cCBub:focus, .order-toast-module__btn-outline-primary___cCBub.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.order-toast-module__btn-outline-primary___cCBub.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-outline-primary___cCBub:disabled {
  color: #007bff;
  background-color: transparent;
}
.order-toast-module__btn-outline-primary___cCBub:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-outline-primary___cCBub:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-primary___cCBub.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.order-toast-module__btn-outline-primary___cCBub:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-outline-primary___cCBub:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-primary___cCBub.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.order-toast-module__btn-outline-secondary___1iI1l {
  color: #6c757d;
  border-color: #6c757d;
}
.order-toast-module__btn-outline-secondary___1iI1l:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.order-toast-module__btn-outline-secondary___1iI1l:focus, .order-toast-module__btn-outline-secondary___1iI1l.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.order-toast-module__btn-outline-secondary___1iI1l.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-outline-secondary___1iI1l:disabled {
  color: #6c757d;
  background-color: transparent;
}
.order-toast-module__btn-outline-secondary___1iI1l:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-outline-secondary___1iI1l:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-secondary___1iI1l.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.order-toast-module__btn-outline-secondary___1iI1l:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-outline-secondary___1iI1l:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-secondary___1iI1l.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.order-toast-module__btn-outline-success___Q2CnQ {
  color: #28a745;
  border-color: #28a745;
}
.order-toast-module__btn-outline-success___Q2CnQ:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.order-toast-module__btn-outline-success___Q2CnQ:focus, .order-toast-module__btn-outline-success___Q2CnQ.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.order-toast-module__btn-outline-success___Q2CnQ.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-outline-success___Q2CnQ:disabled {
  color: #28a745;
  background-color: transparent;
}
.order-toast-module__btn-outline-success___Q2CnQ:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-outline-success___Q2CnQ:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-success___Q2CnQ.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.order-toast-module__btn-outline-success___Q2CnQ:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-outline-success___Q2CnQ:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-success___Q2CnQ.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.order-toast-module__btn-outline-info___29WvI {
  color: #17a2b8;
  border-color: #17a2b8;
}
.order-toast-module__btn-outline-info___29WvI:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.order-toast-module__btn-outline-info___29WvI:focus, .order-toast-module__btn-outline-info___29WvI.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.order-toast-module__btn-outline-info___29WvI.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-outline-info___29WvI:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.order-toast-module__btn-outline-info___29WvI:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-outline-info___29WvI:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-info___29WvI.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.order-toast-module__btn-outline-info___29WvI:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-outline-info___29WvI:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-info___29WvI.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.order-toast-module__btn-outline-warning___TnHCG {
  color: #ffc107;
  border-color: #ffc107;
}
.order-toast-module__btn-outline-warning___TnHCG:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.order-toast-module__btn-outline-warning___TnHCG:focus, .order-toast-module__btn-outline-warning___TnHCG.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.order-toast-module__btn-outline-warning___TnHCG.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-outline-warning___TnHCG:disabled {
  color: #ffc107;
  background-color: transparent;
}
.order-toast-module__btn-outline-warning___TnHCG:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-outline-warning___TnHCG:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-warning___TnHCG.order-toast-module__dropdown-toggle___343n3 {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.order-toast-module__btn-outline-warning___TnHCG:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-outline-warning___TnHCG:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-warning___TnHCG.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.order-toast-module__btn-outline-danger___1pfvx {
  color: #dc3545;
  border-color: #dc3545;
}
.order-toast-module__btn-outline-danger___1pfvx:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.order-toast-module__btn-outline-danger___1pfvx:focus, .order-toast-module__btn-outline-danger___1pfvx.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.order-toast-module__btn-outline-danger___1pfvx.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-outline-danger___1pfvx:disabled {
  color: #dc3545;
  background-color: transparent;
}
.order-toast-module__btn-outline-danger___1pfvx:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-outline-danger___1pfvx:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-danger___1pfvx.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.order-toast-module__btn-outline-danger___1pfvx:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-outline-danger___1pfvx:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-danger___1pfvx.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.order-toast-module__btn-outline-light___V4xTy {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.order-toast-module__btn-outline-light___V4xTy:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.order-toast-module__btn-outline-light___V4xTy:focus, .order-toast-module__btn-outline-light___V4xTy.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.order-toast-module__btn-outline-light___V4xTy.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-outline-light___V4xTy:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.order-toast-module__btn-outline-light___V4xTy:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-outline-light___V4xTy:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-light___V4xTy.order-toast-module__dropdown-toggle___343n3 {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.order-toast-module__btn-outline-light___V4xTy:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-outline-light___V4xTy:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-light___V4xTy.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.order-toast-module__btn-outline-dark___2fNpe {
  color: #343a40;
  border-color: #343a40;
}
.order-toast-module__btn-outline-dark___2fNpe:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.order-toast-module__btn-outline-dark___2fNpe:focus, .order-toast-module__btn-outline-dark___2fNpe.order-toast-module__focus___uquVV {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.order-toast-module__btn-outline-dark___2fNpe.order-toast-module__disabled___Tbq1L, .order-toast-module__btn-outline-dark___2fNpe:disabled {
  color: #343a40;
  background-color: transparent;
}
.order-toast-module__btn-outline-dark___2fNpe:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active, .order-toast-module__btn-outline-dark___2fNpe:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-dark___2fNpe.order-toast-module__dropdown-toggle___343n3 {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.order-toast-module__btn-outline-dark___2fNpe:not(:disabled):not(.order-toast-module__disabled___Tbq1L):active:focus, .order-toast-module__btn-outline-dark___2fNpe:not(:disabled):not(.order-toast-module__disabled___Tbq1L).order-toast-module__active___143My:focus, .order-toast-module__show___2DyCB > .order-toast-module__btn-outline-dark___2fNpe.order-toast-module__dropdown-toggle___343n3:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.order-toast-module__btn-link___bA5Ep {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.order-toast-module__btn-link___bA5Ep:hover {
  color: #0056b3;
  text-decoration: underline;
}
.order-toast-module__btn-link___bA5Ep:focus, .order-toast-module__btn-link___bA5Ep.order-toast-module__focus___uquVV {
  text-decoration: underline;
  box-shadow: none;
}
.order-toast-module__btn-link___bA5Ep:disabled, .order-toast-module__btn-link___bA5Ep.order-toast-module__disabled___Tbq1L {
  color: #6c757d;
  pointer-events: none;
}

.order-toast-module__btn-lg___2ospc, .order-toast-module__btn-group-lg___2WXev > .order-toast-module__btn___3K_89 {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.order-toast-module__btn-sm___1jYqD, .order-toast-module__btn-group-sm___fojz8 > .order-toast-module__btn___3K_89 {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.order-toast-module__btn-block___1bdaH {
  display: block;
  width: 100%;
}
.order-toast-module__btn-block___1bdaH + .order-toast-module__btn-block___1bdaH {
  margin-top: 0.5rem;
}

input[type=submit].order-toast-module__btn-block___1bdaH,
input[type=reset].order-toast-module__btn-block___1bdaH,
input[type=button].order-toast-module__btn-block___1bdaH {
  width: 100%;
}

.order-toast-module__fade___2rY5m {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__fade___2rY5m {
    transition: none;
  }
}
.order-toast-module__fade___2rY5m:not(.order-toast-module__show___2DyCB) {
  opacity: 0;
}

.order-toast-module__collapse___2GhJH:not(.order-toast-module__show___2DyCB) {
  display: none;
}

.order-toast-module__collapsing___3ldKd {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__collapsing___3ldKd {
    transition: none;
  }
}

.order-toast-module__dropup___1HqX9,
.order-toast-module__dropright___3tG3G,
.order-toast-module__dropdown___1_LV_,
.order-toast-module__dropleft___1UkRp {
  position: relative;
}

.order-toast-module__dropdown-toggle___343n3 {
  white-space: nowrap;
}
.order-toast-module__dropdown-toggle___343n3::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.order-toast-module__dropdown-toggle___343n3:empty::after {
  margin-left: 0;
}

.order-toast-module__dropdown-menu___fRq4Y {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.order-toast-module__dropdown-menu-left___UWES- {
  right: auto;
  left: 0;
}

.order-toast-module__dropdown-menu-right___1tubK {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .order-toast-module__dropdown-menu-sm-left___1dTiK {
    right: auto;
    left: 0;
  }

  .order-toast-module__dropdown-menu-sm-right___2tiQ4 {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .order-toast-module__dropdown-menu-md-left___2jThL {
    right: auto;
    left: 0;
  }

  .order-toast-module__dropdown-menu-md-right___24LXA {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .order-toast-module__dropdown-menu-lg-left___2qsTx {
    right: auto;
    left: 0;
  }

  .order-toast-module__dropdown-menu-lg-right___2QveR {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .order-toast-module__dropdown-menu-xl-left___2hD-e {
    right: auto;
    left: 0;
  }

  .order-toast-module__dropdown-menu-xl-right___3LZ9c {
    right: 0;
    left: auto;
  }
}
.order-toast-module__dropup___1HqX9 .order-toast-module__dropdown-menu___fRq4Y {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.order-toast-module__dropup___1HqX9 .order-toast-module__dropdown-toggle___343n3::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.order-toast-module__dropup___1HqX9 .order-toast-module__dropdown-toggle___343n3:empty::after {
  margin-left: 0;
}

.order-toast-module__dropright___3tG3G .order-toast-module__dropdown-menu___fRq4Y {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.order-toast-module__dropright___3tG3G .order-toast-module__dropdown-toggle___343n3::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.order-toast-module__dropright___3tG3G .order-toast-module__dropdown-toggle___343n3:empty::after {
  margin-left: 0;
}
.order-toast-module__dropright___3tG3G .order-toast-module__dropdown-toggle___343n3::after {
  vertical-align: 0;
}

.order-toast-module__dropleft___1UkRp .order-toast-module__dropdown-menu___fRq4Y {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.order-toast-module__dropleft___1UkRp .order-toast-module__dropdown-toggle___343n3::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.order-toast-module__dropleft___1UkRp .order-toast-module__dropdown-toggle___343n3::after {
  display: none;
}
.order-toast-module__dropleft___1UkRp .order-toast-module__dropdown-toggle___343n3::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.order-toast-module__dropleft___1UkRp .order-toast-module__dropdown-toggle___343n3:empty::after {
  margin-left: 0;
}
.order-toast-module__dropleft___1UkRp .order-toast-module__dropdown-toggle___343n3::before {
  vertical-align: 0;
}

.order-toast-module__dropdown-menu___fRq4Y[x-placement^=top], .order-toast-module__dropdown-menu___fRq4Y[x-placement^=right], .order-toast-module__dropdown-menu___fRq4Y[x-placement^=bottom], .order-toast-module__dropdown-menu___fRq4Y[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.order-toast-module__dropdown-divider___1e77o {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.order-toast-module__dropdown-item___1pno- {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.order-toast-module__dropdown-item___1pno-:hover, .order-toast-module__dropdown-item___1pno-:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.order-toast-module__dropdown-item___1pno-.order-toast-module__active___143My, .order-toast-module__dropdown-item___1pno-:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.order-toast-module__dropdown-item___1pno-.order-toast-module__disabled___Tbq1L, .order-toast-module__dropdown-item___1pno-:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.order-toast-module__dropdown-menu___fRq4Y.order-toast-module__show___2DyCB {
  display: block;
}

.order-toast-module__dropdown-header___3pZY8 {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.order-toast-module__dropdown-item-text___myU3_ {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.order-toast-module__btn-group___1O8Ej,
.order-toast-module__btn-group-vertical___2BQBZ {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.order-toast-module__btn-group___1O8Ej > .order-toast-module__btn___3K_89,
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn___3K_89 {
  position: relative;
  flex: 1 1 auto;
}
.order-toast-module__btn-group___1O8Ej > .order-toast-module__btn___3K_89:hover,
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn___3K_89:hover {
  z-index: 1;
}
.order-toast-module__btn-group___1O8Ej > .order-toast-module__btn___3K_89:focus, .order-toast-module__btn-group___1O8Ej > .order-toast-module__btn___3K_89:active, .order-toast-module__btn-group___1O8Ej > .order-toast-module__btn___3K_89.order-toast-module__active___143My,
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn___3K_89:focus,
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn___3K_89:active,
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn___3K_89.order-toast-module__active___143My {
  z-index: 1;
}

.order-toast-module__btn-toolbar___7JMBJ {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.order-toast-module__btn-toolbar___7JMBJ .order-toast-module__input-group___1Bg_b {
  width: auto;
}

.order-toast-module__btn-group___1O8Ej > .order-toast-module__btn___3K_89:not(:first-child),
.order-toast-module__btn-group___1O8Ej > .order-toast-module__btn-group___1O8Ej:not(:first-child) {
  margin-left: -1px;
}
.order-toast-module__btn-group___1O8Ej > .order-toast-module__btn___3K_89:not(:last-child):not(.order-toast-module__dropdown-toggle___343n3),
.order-toast-module__btn-group___1O8Ej > .order-toast-module__btn-group___1O8Ej:not(:last-child) > .order-toast-module__btn___3K_89 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.order-toast-module__btn-group___1O8Ej > .order-toast-module__btn___3K_89:not(:first-child),
.order-toast-module__btn-group___1O8Ej > .order-toast-module__btn-group___1O8Ej:not(:first-child) > .order-toast-module__btn___3K_89 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.order-toast-module__dropdown-toggle-split___1WZUj {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.order-toast-module__dropdown-toggle-split___1WZUj::after, .order-toast-module__dropup___1HqX9 .order-toast-module__dropdown-toggle-split___1WZUj::after, .order-toast-module__dropright___3tG3G .order-toast-module__dropdown-toggle-split___1WZUj::after {
  margin-left: 0;
}
.order-toast-module__dropleft___1UkRp .order-toast-module__dropdown-toggle-split___1WZUj::before {
  margin-right: 0;
}

.order-toast-module__btn-sm___1jYqD + .order-toast-module__dropdown-toggle-split___1WZUj, .order-toast-module__btn-group-sm___fojz8 > .order-toast-module__btn___3K_89 + .order-toast-module__dropdown-toggle-split___1WZUj {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.order-toast-module__btn-lg___2ospc + .order-toast-module__dropdown-toggle-split___1WZUj, .order-toast-module__btn-group-lg___2WXev > .order-toast-module__btn___3K_89 + .order-toast-module__dropdown-toggle-split___1WZUj {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.order-toast-module__btn-group-vertical___2BQBZ {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn___3K_89,
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn-group___1O8Ej {
  width: 100%;
}
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn___3K_89:not(:first-child),
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn-group___1O8Ej:not(:first-child) {
  margin-top: -1px;
}
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn___3K_89:not(:last-child):not(.order-toast-module__dropdown-toggle___343n3),
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn-group___1O8Ej:not(:last-child) > .order-toast-module__btn___3K_89 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn___3K_89:not(:first-child),
.order-toast-module__btn-group-vertical___2BQBZ > .order-toast-module__btn-group___1O8Ej:not(:first-child) > .order-toast-module__btn___3K_89 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.order-toast-module__btn-group-toggle___qvGNq > .order-toast-module__btn___3K_89,
.order-toast-module__btn-group-toggle___qvGNq > .order-toast-module__btn-group___1O8Ej > .order-toast-module__btn___3K_89 {
  margin-bottom: 0;
}
.order-toast-module__btn-group-toggle___qvGNq > .order-toast-module__btn___3K_89 input[type=radio],
.order-toast-module__btn-group-toggle___qvGNq > .order-toast-module__btn___3K_89 input[type=checkbox],
.order-toast-module__btn-group-toggle___qvGNq > .order-toast-module__btn-group___1O8Ej > .order-toast-module__btn___3K_89 input[type=radio],
.order-toast-module__btn-group-toggle___qvGNq > .order-toast-module__btn-group___1O8Ej > .order-toast-module__btn___3K_89 input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.order-toast-module__input-group___1Bg_b {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.order-toast-module__input-group___1Bg_b > .order-toast-module__form-control___3G1v6,
.order-toast-module__input-group___1Bg_b > .order-toast-module__form-control-plaintext___1pd52,
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-select___Cbhkk,
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-file___34VUt {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.order-toast-module__input-group___1Bg_b > .order-toast-module__form-control___3G1v6 + .order-toast-module__form-control___3G1v6,
.order-toast-module__input-group___1Bg_b > .order-toast-module__form-control___3G1v6 + .order-toast-module__custom-select___Cbhkk,
.order-toast-module__input-group___1Bg_b > .order-toast-module__form-control___3G1v6 + .order-toast-module__custom-file___34VUt,
.order-toast-module__input-group___1Bg_b > .order-toast-module__form-control-plaintext___1pd52 + .order-toast-module__form-control___3G1v6,
.order-toast-module__input-group___1Bg_b > .order-toast-module__form-control-plaintext___1pd52 + .order-toast-module__custom-select___Cbhkk,
.order-toast-module__input-group___1Bg_b > .order-toast-module__form-control-plaintext___1pd52 + .order-toast-module__custom-file___34VUt,
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-select___Cbhkk + .order-toast-module__form-control___3G1v6,
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-select___Cbhkk + .order-toast-module__custom-select___Cbhkk,
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-select___Cbhkk + .order-toast-module__custom-file___34VUt,
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-file___34VUt + .order-toast-module__form-control___3G1v6,
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-file___34VUt + .order-toast-module__custom-select___Cbhkk,
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-file___34VUt + .order-toast-module__custom-file___34VUt {
  margin-left: -1px;
}
.order-toast-module__input-group___1Bg_b > .order-toast-module__form-control___3G1v6:focus,
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-select___Cbhkk:focus,
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-file___34VUt .order-toast-module__custom-file-input___3bTPf:focus ~ .order-toast-module__custom-file-label___VBm6O {
  z-index: 3;
}
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-file___34VUt .order-toast-module__custom-file-input___3bTPf:focus {
  z-index: 4;
}
.order-toast-module__input-group___1Bg_b > .order-toast-module__form-control___3G1v6:not(:last-child),
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-select___Cbhkk:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.order-toast-module__input-group___1Bg_b > .order-toast-module__form-control___3G1v6:not(:first-child),
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-select___Cbhkk:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-file___34VUt {
  display: flex;
  align-items: center;
}
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-file___34VUt:not(:last-child) .order-toast-module__custom-file-label___VBm6O, .order-toast-module__input-group___1Bg_b > .order-toast-module__custom-file___34VUt:not(:last-child) .order-toast-module__custom-file-label___VBm6O::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.order-toast-module__input-group___1Bg_b > .order-toast-module__custom-file___34VUt:not(:first-child) .order-toast-module__custom-file-label___VBm6O {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.order-toast-module__input-group-prepend___2i6yv,
.order-toast-module__input-group-append___2aKiW {
  display: flex;
}
.order-toast-module__input-group-prepend___2i6yv .order-toast-module__btn___3K_89,
.order-toast-module__input-group-append___2aKiW .order-toast-module__btn___3K_89 {
  position: relative;
  z-index: 2;
}
.order-toast-module__input-group-prepend___2i6yv .order-toast-module__btn___3K_89:focus,
.order-toast-module__input-group-append___2aKiW .order-toast-module__btn___3K_89:focus {
  z-index: 3;
}
.order-toast-module__input-group-prepend___2i6yv .order-toast-module__btn___3K_89 + .order-toast-module__btn___3K_89,
.order-toast-module__input-group-prepend___2i6yv .order-toast-module__btn___3K_89 + .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group-prepend___2i6yv .order-toast-module__input-group-text___1WDWx + .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group-prepend___2i6yv .order-toast-module__input-group-text___1WDWx + .order-toast-module__btn___3K_89,
.order-toast-module__input-group-append___2aKiW .order-toast-module__btn___3K_89 + .order-toast-module__btn___3K_89,
.order-toast-module__input-group-append___2aKiW .order-toast-module__btn___3K_89 + .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group-append___2aKiW .order-toast-module__input-group-text___1WDWx + .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group-append___2aKiW .order-toast-module__input-group-text___1WDWx + .order-toast-module__btn___3K_89 {
  margin-left: -1px;
}

.order-toast-module__input-group-prepend___2i6yv {
  margin-right: -1px;
}

.order-toast-module__input-group-append___2aKiW {
  margin-left: -1px;
}

.order-toast-module__input-group-text___1WDWx {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.order-toast-module__input-group-text___1WDWx input[type=radio],
.order-toast-module__input-group-text___1WDWx input[type=checkbox] {
  margin-top: 0;
}

.order-toast-module__input-group-lg___28Cw7 > .order-toast-module__form-control___3G1v6:not(textarea),
.order-toast-module__input-group-lg___28Cw7 > .order-toast-module__custom-select___Cbhkk {
  height: calc(1.5em + 1rem + 2px);
}

.order-toast-module__input-group-lg___28Cw7 > .order-toast-module__form-control___3G1v6,
.order-toast-module__input-group-lg___28Cw7 > .order-toast-module__custom-select___Cbhkk,
.order-toast-module__input-group-lg___28Cw7 > .order-toast-module__input-group-prepend___2i6yv > .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group-lg___28Cw7 > .order-toast-module__input-group-append___2aKiW > .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group-lg___28Cw7 > .order-toast-module__input-group-prepend___2i6yv > .order-toast-module__btn___3K_89,
.order-toast-module__input-group-lg___28Cw7 > .order-toast-module__input-group-append___2aKiW > .order-toast-module__btn___3K_89 {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.order-toast-module__input-group-sm___2smqO > .order-toast-module__form-control___3G1v6:not(textarea),
.order-toast-module__input-group-sm___2smqO > .order-toast-module__custom-select___Cbhkk {
  height: calc(1.5em + 0.5rem + 2px);
}

.order-toast-module__input-group-sm___2smqO > .order-toast-module__form-control___3G1v6,
.order-toast-module__input-group-sm___2smqO > .order-toast-module__custom-select___Cbhkk,
.order-toast-module__input-group-sm___2smqO > .order-toast-module__input-group-prepend___2i6yv > .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group-sm___2smqO > .order-toast-module__input-group-append___2aKiW > .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group-sm___2smqO > .order-toast-module__input-group-prepend___2i6yv > .order-toast-module__btn___3K_89,
.order-toast-module__input-group-sm___2smqO > .order-toast-module__input-group-append___2aKiW > .order-toast-module__btn___3K_89 {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.order-toast-module__input-group-lg___28Cw7 > .order-toast-module__custom-select___Cbhkk,
.order-toast-module__input-group-sm___2smqO > .order-toast-module__custom-select___Cbhkk {
  padding-right: 1.75rem;
}

.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-prepend___2i6yv > .order-toast-module__btn___3K_89,
.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-prepend___2i6yv > .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-append___2aKiW:not(:last-child) > .order-toast-module__btn___3K_89,
.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-append___2aKiW:not(:last-child) > .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-append___2aKiW:last-child > .order-toast-module__btn___3K_89:not(:last-child):not(.order-toast-module__dropdown-toggle___343n3),
.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-append___2aKiW:last-child > .order-toast-module__input-group-text___1WDWx:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-append___2aKiW > .order-toast-module__btn___3K_89,
.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-append___2aKiW > .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-prepend___2i6yv:not(:first-child) > .order-toast-module__btn___3K_89,
.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-prepend___2i6yv:not(:first-child) > .order-toast-module__input-group-text___1WDWx,
.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-prepend___2i6yv:first-child > .order-toast-module__btn___3K_89:not(:first-child),
.order-toast-module__input-group___1Bg_b > .order-toast-module__input-group-prepend___2i6yv:first-child > .order-toast-module__input-group-text___1WDWx:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.order-toast-module__custom-control___3axQu {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.order-toast-module__custom-control-inline___35tuS {
  display: inline-flex;
  margin-right: 1rem;
}

.order-toast-module__custom-control-input___32Eb7 {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.order-toast-module__custom-control-input___32Eb7:checked ~ .order-toast-module__custom-control-label___2sI_8::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.order-toast-module__custom-control-input___32Eb7:focus ~ .order-toast-module__custom-control-label___2sI_8::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.order-toast-module__custom-control-input___32Eb7:focus:not(:checked) ~ .order-toast-module__custom-control-label___2sI_8::before {
  border-color: #80bdff;
}
.order-toast-module__custom-control-input___32Eb7:not(:disabled):active ~ .order-toast-module__custom-control-label___2sI_8::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.order-toast-module__custom-control-input___32Eb7:disabled ~ .order-toast-module__custom-control-label___2sI_8 {
  color: #6c757d;
}
.order-toast-module__custom-control-input___32Eb7:disabled ~ .order-toast-module__custom-control-label___2sI_8::before {
  background-color: #e9ecef;
}

.order-toast-module__custom-control-label___2sI_8 {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.order-toast-module__custom-control-label___2sI_8::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.order-toast-module__custom-control-label___2sI_8::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.order-toast-module__custom-checkbox___3TxNl .order-toast-module__custom-control-label___2sI_8::before {
  border-radius: 0.25rem;
}
.order-toast-module__custom-checkbox___3TxNl .order-toast-module__custom-control-input___32Eb7:checked ~ .order-toast-module__custom-control-label___2sI_8::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.order-toast-module__custom-checkbox___3TxNl .order-toast-module__custom-control-input___32Eb7:indeterminate ~ .order-toast-module__custom-control-label___2sI_8::before {
  border-color: #007bff;
  background-color: #007bff;
}
.order-toast-module__custom-checkbox___3TxNl .order-toast-module__custom-control-input___32Eb7:indeterminate ~ .order-toast-module__custom-control-label___2sI_8::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.order-toast-module__custom-checkbox___3TxNl .order-toast-module__custom-control-input___32Eb7:disabled:checked ~ .order-toast-module__custom-control-label___2sI_8::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.order-toast-module__custom-checkbox___3TxNl .order-toast-module__custom-control-input___32Eb7:disabled:indeterminate ~ .order-toast-module__custom-control-label___2sI_8::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.order-toast-module__custom-radio___4jgQ2 .order-toast-module__custom-control-label___2sI_8::before {
  border-radius: 50%;
}
.order-toast-module__custom-radio___4jgQ2 .order-toast-module__custom-control-input___32Eb7:checked ~ .order-toast-module__custom-control-label___2sI_8::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.order-toast-module__custom-radio___4jgQ2 .order-toast-module__custom-control-input___32Eb7:disabled:checked ~ .order-toast-module__custom-control-label___2sI_8::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.order-toast-module__custom-switch___2oWtZ {
  padding-left: 2.25rem;
}
.order-toast-module__custom-switch___2oWtZ .order-toast-module__custom-control-label___2sI_8::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.order-toast-module__custom-switch___2oWtZ .order-toast-module__custom-control-label___2sI_8::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__custom-switch___2oWtZ .order-toast-module__custom-control-label___2sI_8::after {
    transition: none;
  }
}
.order-toast-module__custom-switch___2oWtZ .order-toast-module__custom-control-input___32Eb7:checked ~ .order-toast-module__custom-control-label___2sI_8::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.order-toast-module__custom-switch___2oWtZ .order-toast-module__custom-control-input___32Eb7:disabled:checked ~ .order-toast-module__custom-control-label___2sI_8::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.order-toast-module__custom-select___Cbhkk {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.order-toast-module__custom-select___Cbhkk:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.order-toast-module__custom-select___Cbhkk:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.order-toast-module__custom-select___Cbhkk[multiple], .order-toast-module__custom-select___Cbhkk[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.order-toast-module__custom-select___Cbhkk:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.order-toast-module__custom-select___Cbhkk::-ms-expand {
  display: none;
}

.order-toast-module__custom-select-sm___zlElN {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.order-toast-module__custom-select-lg___2hYMQ {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.order-toast-module__custom-file___34VUt {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.order-toast-module__custom-file-input___3bTPf {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.order-toast-module__custom-file-input___3bTPf:focus ~ .order-toast-module__custom-file-label___VBm6O {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.order-toast-module__custom-file-input___3bTPf:disabled ~ .order-toast-module__custom-file-label___VBm6O {
  background-color: #e9ecef;
}
.order-toast-module__custom-file-input___3bTPf:lang(en) ~ .order-toast-module__custom-file-label___VBm6O::after {
  content: "Browse";
}
.order-toast-module__custom-file-input___3bTPf ~ .order-toast-module__custom-file-label___VBm6O[data-browse]::after {
  content: attr(data-browse);
}

.order-toast-module__custom-file-label___VBm6O {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.order-toast-module__custom-file-label___VBm6O::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.order-toast-module__custom-range___20BTh {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.order-toast-module__custom-range___20BTh:focus {
  outline: none;
}
.order-toast-module__custom-range___20BTh:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.order-toast-module__custom-range___20BTh:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.order-toast-module__custom-range___20BTh:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.order-toast-module__custom-range___20BTh::-moz-focus-outer {
  border: 0;
}
.order-toast-module__custom-range___20BTh::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__custom-range___20BTh::-webkit-slider-thumb {
    transition: none;
  }
}
.order-toast-module__custom-range___20BTh::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.order-toast-module__custom-range___20BTh::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.order-toast-module__custom-range___20BTh::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__custom-range___20BTh::-moz-range-thumb {
    transition: none;
  }
}
.order-toast-module__custom-range___20BTh::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.order-toast-module__custom-range___20BTh::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.order-toast-module__custom-range___20BTh::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__custom-range___20BTh::-ms-thumb {
    transition: none;
  }
}
.order-toast-module__custom-range___20BTh::-ms-thumb:active {
  background-color: #b3d7ff;
}
.order-toast-module__custom-range___20BTh::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.order-toast-module__custom-range___20BTh::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.order-toast-module__custom-range___20BTh::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.order-toast-module__custom-range___20BTh:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.order-toast-module__custom-range___20BTh:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.order-toast-module__custom-range___20BTh:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.order-toast-module__custom-range___20BTh:disabled::-moz-range-track {
  cursor: default;
}
.order-toast-module__custom-range___20BTh:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.order-toast-module__custom-control-label___2sI_8::before,
.order-toast-module__custom-file-label___VBm6O,
.order-toast-module__custom-select___Cbhkk {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__custom-control-label___2sI_8::before,
.order-toast-module__custom-file-label___VBm6O,
.order-toast-module__custom-select___Cbhkk {
    transition: none;
  }
}

.order-toast-module__nav___VQvsT {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.order-toast-module__nav-link___FadIa {
  display: block;
  padding: 0.5rem 1rem;
}
.order-toast-module__nav-link___FadIa:hover, .order-toast-module__nav-link___FadIa:focus {
  text-decoration: none;
}
.order-toast-module__nav-link___FadIa.order-toast-module__disabled___Tbq1L {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.order-toast-module__nav-tabs___3RcGf {
  border-bottom: 1px solid #dee2e6;
}
.order-toast-module__nav-tabs___3RcGf .order-toast-module__nav-item___3NH6V {
  margin-bottom: -1px;
}
.order-toast-module__nav-tabs___3RcGf .order-toast-module__nav-link___FadIa {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.order-toast-module__nav-tabs___3RcGf .order-toast-module__nav-link___FadIa:hover, .order-toast-module__nav-tabs___3RcGf .order-toast-module__nav-link___FadIa:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.order-toast-module__nav-tabs___3RcGf .order-toast-module__nav-link___FadIa.order-toast-module__disabled___Tbq1L {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.order-toast-module__nav-tabs___3RcGf .order-toast-module__nav-link___FadIa.order-toast-module__active___143My,
.order-toast-module__nav-tabs___3RcGf .order-toast-module__nav-item___3NH6V.order-toast-module__show___2DyCB .order-toast-module__nav-link___FadIa {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.order-toast-module__nav-tabs___3RcGf .order-toast-module__dropdown-menu___fRq4Y {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.order-toast-module__nav-pills___UI5De .order-toast-module__nav-link___FadIa {
  border-radius: 0.25rem;
}
.order-toast-module__nav-pills___UI5De .order-toast-module__nav-link___FadIa.order-toast-module__active___143My,
.order-toast-module__nav-pills___UI5De .order-toast-module__show___2DyCB > .order-toast-module__nav-link___FadIa {
  color: #fff;
  background-color: #007bff;
}

.order-toast-module__nav-fill___QNkBe .order-toast-module__nav-item___3NH6V {
  flex: 1 1 auto;
  text-align: center;
}

.order-toast-module__nav-justified___3WMmr .order-toast-module__nav-item___3NH6V {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.order-toast-module__tab-content___3kzQK > .order-toast-module__tab-pane___1H5aX {
  display: none;
}
.order-toast-module__tab-content___3kzQK > .order-toast-module__active___143My {
  display: block;
}

.order-toast-module__navbar___3xkcF {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.order-toast-module__navbar___3xkcF > .order-toast-module__container___hNC4l,
.order-toast-module__navbar___3xkcF > .order-toast-module__container-fluid___1Cjkw {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.order-toast-module__navbar-brand___2wSX3 {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.order-toast-module__navbar-brand___2wSX3:hover, .order-toast-module__navbar-brand___2wSX3:focus {
  text-decoration: none;
}

.order-toast-module__navbar-nav___1zUsx {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa {
  padding-right: 0;
  padding-left: 0;
}
.order-toast-module__navbar-nav___1zUsx .order-toast-module__dropdown-menu___fRq4Y {
  position: static;
  float: none;
}

.order-toast-module__navbar-text___1k11l {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.order-toast-module__navbar-collapse___1epiw {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.order-toast-module__navbar-toggler___22QaG {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.order-toast-module__navbar-toggler___22QaG:hover, .order-toast-module__navbar-toggler___22QaG:focus {
  text-decoration: none;
}

.order-toast-module__navbar-toggler-icon___Avpiu {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .order-toast-module__navbar-expand-sm___fw-Gi > .order-toast-module__container___hNC4l,
.order-toast-module__navbar-expand-sm___fw-Gi > .order-toast-module__container-fluid___1Cjkw {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .order-toast-module__navbar-expand-sm___fw-Gi {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .order-toast-module__navbar-expand-sm___fw-Gi .order-toast-module__navbar-nav___1zUsx {
    flex-direction: row;
  }
  .order-toast-module__navbar-expand-sm___fw-Gi .order-toast-module__navbar-nav___1zUsx .order-toast-module__dropdown-menu___fRq4Y {
    position: absolute;
  }
  .order-toast-module__navbar-expand-sm___fw-Gi .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .order-toast-module__navbar-expand-sm___fw-Gi > .order-toast-module__container___hNC4l,
.order-toast-module__navbar-expand-sm___fw-Gi > .order-toast-module__container-fluid___1Cjkw {
    flex-wrap: nowrap;
  }
  .order-toast-module__navbar-expand-sm___fw-Gi .order-toast-module__navbar-collapse___1epiw {
    display: flex !important;
    flex-basis: auto;
  }
  .order-toast-module__navbar-expand-sm___fw-Gi .order-toast-module__navbar-toggler___22QaG {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .order-toast-module__navbar-expand-md___Depdj > .order-toast-module__container___hNC4l,
.order-toast-module__navbar-expand-md___Depdj > .order-toast-module__container-fluid___1Cjkw {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .order-toast-module__navbar-expand-md___Depdj {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .order-toast-module__navbar-expand-md___Depdj .order-toast-module__navbar-nav___1zUsx {
    flex-direction: row;
  }
  .order-toast-module__navbar-expand-md___Depdj .order-toast-module__navbar-nav___1zUsx .order-toast-module__dropdown-menu___fRq4Y {
    position: absolute;
  }
  .order-toast-module__navbar-expand-md___Depdj .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .order-toast-module__navbar-expand-md___Depdj > .order-toast-module__container___hNC4l,
.order-toast-module__navbar-expand-md___Depdj > .order-toast-module__container-fluid___1Cjkw {
    flex-wrap: nowrap;
  }
  .order-toast-module__navbar-expand-md___Depdj .order-toast-module__navbar-collapse___1epiw {
    display: flex !important;
    flex-basis: auto;
  }
  .order-toast-module__navbar-expand-md___Depdj .order-toast-module__navbar-toggler___22QaG {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .order-toast-module__navbar-expand-lg___1oMUU > .order-toast-module__container___hNC4l,
.order-toast-module__navbar-expand-lg___1oMUU > .order-toast-module__container-fluid___1Cjkw {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .order-toast-module__navbar-expand-lg___1oMUU {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .order-toast-module__navbar-expand-lg___1oMUU .order-toast-module__navbar-nav___1zUsx {
    flex-direction: row;
  }
  .order-toast-module__navbar-expand-lg___1oMUU .order-toast-module__navbar-nav___1zUsx .order-toast-module__dropdown-menu___fRq4Y {
    position: absolute;
  }
  .order-toast-module__navbar-expand-lg___1oMUU .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .order-toast-module__navbar-expand-lg___1oMUU > .order-toast-module__container___hNC4l,
.order-toast-module__navbar-expand-lg___1oMUU > .order-toast-module__container-fluid___1Cjkw {
    flex-wrap: nowrap;
  }
  .order-toast-module__navbar-expand-lg___1oMUU .order-toast-module__navbar-collapse___1epiw {
    display: flex !important;
    flex-basis: auto;
  }
  .order-toast-module__navbar-expand-lg___1oMUU .order-toast-module__navbar-toggler___22QaG {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .order-toast-module__navbar-expand-xl___2CMF1 > .order-toast-module__container___hNC4l,
.order-toast-module__navbar-expand-xl___2CMF1 > .order-toast-module__container-fluid___1Cjkw {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .order-toast-module__navbar-expand-xl___2CMF1 {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .order-toast-module__navbar-expand-xl___2CMF1 .order-toast-module__navbar-nav___1zUsx {
    flex-direction: row;
  }
  .order-toast-module__navbar-expand-xl___2CMF1 .order-toast-module__navbar-nav___1zUsx .order-toast-module__dropdown-menu___fRq4Y {
    position: absolute;
  }
  .order-toast-module__navbar-expand-xl___2CMF1 .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .order-toast-module__navbar-expand-xl___2CMF1 > .order-toast-module__container___hNC4l,
.order-toast-module__navbar-expand-xl___2CMF1 > .order-toast-module__container-fluid___1Cjkw {
    flex-wrap: nowrap;
  }
  .order-toast-module__navbar-expand-xl___2CMF1 .order-toast-module__navbar-collapse___1epiw {
    display: flex !important;
    flex-basis: auto;
  }
  .order-toast-module__navbar-expand-xl___2CMF1 .order-toast-module__navbar-toggler___22QaG {
    display: none;
  }
}
.order-toast-module__navbar-expand___Zehxa {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.order-toast-module__navbar-expand___Zehxa > .order-toast-module__container___hNC4l,
.order-toast-module__navbar-expand___Zehxa > .order-toast-module__container-fluid___1Cjkw {
  padding-right: 0;
  padding-left: 0;
}
.order-toast-module__navbar-expand___Zehxa .order-toast-module__navbar-nav___1zUsx {
  flex-direction: row;
}
.order-toast-module__navbar-expand___Zehxa .order-toast-module__navbar-nav___1zUsx .order-toast-module__dropdown-menu___fRq4Y {
  position: absolute;
}
.order-toast-module__navbar-expand___Zehxa .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.order-toast-module__navbar-expand___Zehxa > .order-toast-module__container___hNC4l,
.order-toast-module__navbar-expand___Zehxa > .order-toast-module__container-fluid___1Cjkw {
  flex-wrap: nowrap;
}
.order-toast-module__navbar-expand___Zehxa .order-toast-module__navbar-collapse___1epiw {
  display: flex !important;
  flex-basis: auto;
}
.order-toast-module__navbar-expand___Zehxa .order-toast-module__navbar-toggler___22QaG {
  display: none;
}

.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-brand___2wSX3 {
  color: rgba(0, 0, 0, 0.9);
}
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-brand___2wSX3:hover, .order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-brand___2wSX3:focus {
  color: rgba(0, 0, 0, 0.9);
}
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa {
  color: rgba(0, 0, 0, 0.5);
}
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa:hover, .order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa:focus {
  color: rgba(0, 0, 0, 0.7);
}
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa.order-toast-module__disabled___Tbq1L {
  color: rgba(0, 0, 0, 0.3);
}
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-nav___1zUsx .order-toast-module__show___2DyCB > .order-toast-module__nav-link___FadIa,
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-nav___1zUsx .order-toast-module__active___143My > .order-toast-module__nav-link___FadIa,
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa.order-toast-module__show___2DyCB,
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa.order-toast-module__active___143My {
  color: rgba(0, 0, 0, 0.9);
}
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-toggler___22QaG {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-toggler-icon___Avpiu {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-text___1k11l {
  color: rgba(0, 0, 0, 0.5);
}
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-text___1k11l a {
  color: rgba(0, 0, 0, 0.9);
}
.order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-text___1k11l a:hover, .order-toast-module__navbar-light___3oNiB .order-toast-module__navbar-text___1k11l a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-brand___2wSX3 {
  color: #fff;
}
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-brand___2wSX3:hover, .order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-brand___2wSX3:focus {
  color: #fff;
}
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa {
  color: rgba(255, 255, 255, 0.5);
}
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa:hover, .order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa:focus {
  color: rgba(255, 255, 255, 0.75);
}
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa.order-toast-module__disabled___Tbq1L {
  color: rgba(255, 255, 255, 0.25);
}
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-nav___1zUsx .order-toast-module__show___2DyCB > .order-toast-module__nav-link___FadIa,
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-nav___1zUsx .order-toast-module__active___143My > .order-toast-module__nav-link___FadIa,
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa.order-toast-module__show___2DyCB,
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-nav___1zUsx .order-toast-module__nav-link___FadIa.order-toast-module__active___143My {
  color: #fff;
}
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-toggler___22QaG {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-toggler-icon___Avpiu {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-text___1k11l {
  color: rgba(255, 255, 255, 0.5);
}
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-text___1k11l a {
  color: #fff;
}
.order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-text___1k11l a:hover, .order-toast-module__navbar-dark___Yu1Hj .order-toast-module__navbar-text___1k11l a:focus {
  color: #fff;
}

.order-toast-module__card___fruTq {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.order-toast-module__card___fruTq > hr {
  margin-right: 0;
  margin-left: 0;
}
.order-toast-module__card___fruTq > .order-toast-module__list-group___1REmQ:first-child .order-toast-module__list-group-item___3oDNi:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.order-toast-module__card___fruTq > .order-toast-module__list-group___1REmQ:last-child .order-toast-module__list-group-item___3oDNi:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.order-toast-module__card-body___3Dwxx {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.order-toast-module__card-title___3enQl {
  margin-bottom: 0.75rem;
}

.order-toast-module__card-subtitle___2SSex {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.order-toast-module__card-text___1C1mL:last-child {
  margin-bottom: 0;
}

.order-toast-module__card-link___1FiZ9:hover {
  text-decoration: none;
}
.order-toast-module__card-link___1FiZ9 + .order-toast-module__card-link___1FiZ9 {
  margin-left: 1.25rem;
}

.order-toast-module__card-header___VFAkw {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.order-toast-module__card-header___VFAkw:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.order-toast-module__card-header___VFAkw + .order-toast-module__list-group___1REmQ .order-toast-module__list-group-item___3oDNi:first-child {
  border-top: 0;
}

.order-toast-module__card-footer___3Ahka {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.order-toast-module__card-footer___3Ahka:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.order-toast-module__card-header-tabs___3tbwp {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.order-toast-module__card-header-pills___1OjCz {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.order-toast-module__card-img-overlay___258qI {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.order-toast-module__card-img___2V4HZ {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.order-toast-module__card-img-top___3eFSk {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.order-toast-module__card-img-bottom___1oQoX {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.order-toast-module__card-deck___1xfNK {
  display: flex;
  flex-direction: column;
}
.order-toast-module__card-deck___1xfNK .order-toast-module__card___fruTq {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .order-toast-module__card-deck___1xfNK {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .order-toast-module__card-deck___1xfNK .order-toast-module__card___fruTq {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.order-toast-module__card-group___1Qvcf {
  display: flex;
  flex-direction: column;
}
.order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .order-toast-module__card-group___1Qvcf {
    flex-flow: row wrap;
  }
  .order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq + .order-toast-module__card___fruTq {
    margin-left: 0;
    border-left: 0;
  }
  .order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq:not(:last-child) .order-toast-module__card-img-top___3eFSk,
.order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq:not(:last-child) .order-toast-module__card-header___VFAkw {
    border-top-right-radius: 0;
  }
  .order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq:not(:last-child) .order-toast-module__card-img-bottom___1oQoX,
.order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq:not(:last-child) .order-toast-module__card-footer___3Ahka {
    border-bottom-right-radius: 0;
  }
  .order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq:not(:first-child) .order-toast-module__card-img-top___3eFSk,
.order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq:not(:first-child) .order-toast-module__card-header___VFAkw {
    border-top-left-radius: 0;
  }
  .order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq:not(:first-child) .order-toast-module__card-img-bottom___1oQoX,
.order-toast-module__card-group___1Qvcf > .order-toast-module__card___fruTq:not(:first-child) .order-toast-module__card-footer___3Ahka {
    border-bottom-left-radius: 0;
  }
}

.order-toast-module__card-columns___KbfjY .order-toast-module__card___fruTq {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .order-toast-module__card-columns___KbfjY {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .order-toast-module__card-columns___KbfjY .order-toast-module__card___fruTq {
    display: inline-block;
    width: 100%;
  }
}

.order-toast-module__accordion___3xStZ > .order-toast-module__card___fruTq {
  overflow: hidden;
}
.order-toast-module__accordion___3xStZ > .order-toast-module__card___fruTq:not(:first-of-type) .order-toast-module__card-header___VFAkw:first-child {
  border-radius: 0;
}
.order-toast-module__accordion___3xStZ > .order-toast-module__card___fruTq:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.order-toast-module__accordion___3xStZ > .order-toast-module__card___fruTq:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.order-toast-module__accordion___3xStZ > .order-toast-module__card___fruTq:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.order-toast-module__accordion___3xStZ > .order-toast-module__card___fruTq .order-toast-module__card-header___VFAkw {
  margin-bottom: -1px;
}

.order-toast-module__breadcrumb___3hdix {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.order-toast-module__breadcrumb-item___3K4Yx + .order-toast-module__breadcrumb-item___3K4Yx {
  padding-left: 0.5rem;
}
.order-toast-module__breadcrumb-item___3K4Yx + .order-toast-module__breadcrumb-item___3K4Yx::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.order-toast-module__breadcrumb-item___3K4Yx + .order-toast-module__breadcrumb-item___3K4Yx:hover::before {
  text-decoration: underline;
}
.order-toast-module__breadcrumb-item___3K4Yx + .order-toast-module__breadcrumb-item___3K4Yx:hover::before {
  text-decoration: none;
}
.order-toast-module__breadcrumb-item___3K4Yx.order-toast-module__active___143My {
  color: #6c757d;
}

.order-toast-module__pagination___2OYtI {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.order-toast-module__page-link___3SHEL {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.order-toast-module__page-link___3SHEL:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.order-toast-module__page-link___3SHEL:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.order-toast-module__page-item___2ZrIE:first-child .order-toast-module__page-link___3SHEL {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.order-toast-module__page-item___2ZrIE:last-child .order-toast-module__page-link___3SHEL {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.order-toast-module__page-item___2ZrIE.order-toast-module__active___143My .order-toast-module__page-link___3SHEL {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.order-toast-module__page-item___2ZrIE.order-toast-module__disabled___Tbq1L .order-toast-module__page-link___3SHEL {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.order-toast-module__pagination-lg___6Fw4v .order-toast-module__page-link___3SHEL {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.order-toast-module__pagination-lg___6Fw4v .order-toast-module__page-item___2ZrIE:first-child .order-toast-module__page-link___3SHEL {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.order-toast-module__pagination-lg___6Fw4v .order-toast-module__page-item___2ZrIE:last-child .order-toast-module__page-link___3SHEL {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.order-toast-module__pagination-sm___3B3UV .order-toast-module__page-link___3SHEL {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.order-toast-module__pagination-sm___3B3UV .order-toast-module__page-item___2ZrIE:first-child .order-toast-module__page-link___3SHEL {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.order-toast-module__pagination-sm___3B3UV .order-toast-module__page-item___2ZrIE:last-child .order-toast-module__page-link___3SHEL {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.order-toast-module__badge___gp07i {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__badge___gp07i {
    transition: none;
  }
}
a.order-toast-module__badge___gp07i:hover, a.order-toast-module__badge___gp07i:focus {
  text-decoration: none;
}

.order-toast-module__badge___gp07i:empty {
  display: none;
}

.order-toast-module__btn___3K_89 .order-toast-module__badge___gp07i {
  position: relative;
  top: -1px;
}

.order-toast-module__badge-pill___t_UT6 {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.order-toast-module__badge-primary___3xCEW {
  color: #fff;
  background-color: #007bff;
}
a.order-toast-module__badge-primary___3xCEW:hover, a.order-toast-module__badge-primary___3xCEW:focus {
  color: #fff;
  background-color: #0062cc;
}
a.order-toast-module__badge-primary___3xCEW:focus, a.order-toast-module__badge-primary___3xCEW.order-toast-module__focus___uquVV {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.order-toast-module__badge-secondary___WTozM {
  color: #fff;
  background-color: #6c757d;
}
a.order-toast-module__badge-secondary___WTozM:hover, a.order-toast-module__badge-secondary___WTozM:focus {
  color: #fff;
  background-color: #545b62;
}
a.order-toast-module__badge-secondary___WTozM:focus, a.order-toast-module__badge-secondary___WTozM.order-toast-module__focus___uquVV {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.order-toast-module__badge-success___3P-Al {
  color: #fff;
  background-color: #28a745;
}
a.order-toast-module__badge-success___3P-Al:hover, a.order-toast-module__badge-success___3P-Al:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.order-toast-module__badge-success___3P-Al:focus, a.order-toast-module__badge-success___3P-Al.order-toast-module__focus___uquVV {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.order-toast-module__badge-info___1mLhl {
  color: #fff;
  background-color: #17a2b8;
}
a.order-toast-module__badge-info___1mLhl:hover, a.order-toast-module__badge-info___1mLhl:focus {
  color: #fff;
  background-color: #117a8b;
}
a.order-toast-module__badge-info___1mLhl:focus, a.order-toast-module__badge-info___1mLhl.order-toast-module__focus___uquVV {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.order-toast-module__badge-warning___9UlyR {
  color: #212529;
  background-color: #ffc107;
}
a.order-toast-module__badge-warning___9UlyR:hover, a.order-toast-module__badge-warning___9UlyR:focus {
  color: #212529;
  background-color: #d39e00;
}
a.order-toast-module__badge-warning___9UlyR:focus, a.order-toast-module__badge-warning___9UlyR.order-toast-module__focus___uquVV {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.order-toast-module__badge-danger___3bWFm {
  color: #fff;
  background-color: #dc3545;
}
a.order-toast-module__badge-danger___3bWFm:hover, a.order-toast-module__badge-danger___3bWFm:focus {
  color: #fff;
  background-color: #bd2130;
}
a.order-toast-module__badge-danger___3bWFm:focus, a.order-toast-module__badge-danger___3bWFm.order-toast-module__focus___uquVV {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.order-toast-module__badge-light___1wmZz {
  color: #212529;
  background-color: #f8f9fa;
}
a.order-toast-module__badge-light___1wmZz:hover, a.order-toast-module__badge-light___1wmZz:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.order-toast-module__badge-light___1wmZz:focus, a.order-toast-module__badge-light___1wmZz.order-toast-module__focus___uquVV {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.order-toast-module__badge-dark___uSRfN {
  color: #fff;
  background-color: #343a40;
}
a.order-toast-module__badge-dark___uSRfN:hover, a.order-toast-module__badge-dark___uSRfN:focus {
  color: #fff;
  background-color: #1d2124;
}
a.order-toast-module__badge-dark___uSRfN:focus, a.order-toast-module__badge-dark___uSRfN.order-toast-module__focus___uquVV {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.order-toast-module__jumbotron___2xSHd {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .order-toast-module__jumbotron___2xSHd {
    padding: 4rem 2rem;
  }
}

.order-toast-module__jumbotron-fluid___2LQe8 {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.order-toast-module__alert___jX8Iw {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.order-toast-module__alert-heading___1iKRr {
  color: inherit;
}

.order-toast-module__alert-link___Mz1DL {
  font-weight: 700;
}

.order-toast-module__alert-dismissible___2Ks_O {
  padding-right: 4rem;
}
.order-toast-module__alert-dismissible___2Ks_O .order-toast-module__close___21Ndr {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.order-toast-module__alert-primary___EVi8l {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.order-toast-module__alert-primary___EVi8l hr {
  border-top-color: #9fcdff;
}
.order-toast-module__alert-primary___EVi8l .order-toast-module__alert-link___Mz1DL {
  color: #002752;
}

.order-toast-module__alert-secondary___PPWM1 {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.order-toast-module__alert-secondary___PPWM1 hr {
  border-top-color: #c8cbcf;
}
.order-toast-module__alert-secondary___PPWM1 .order-toast-module__alert-link___Mz1DL {
  color: #202326;
}

.order-toast-module__alert-success___3ua1d {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.order-toast-module__alert-success___3ua1d hr {
  border-top-color: #b1dfbb;
}
.order-toast-module__alert-success___3ua1d .order-toast-module__alert-link___Mz1DL {
  color: #0b2e13;
}

.order-toast-module__alert-info___1o_Sz {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.order-toast-module__alert-info___1o_Sz hr {
  border-top-color: #abdde5;
}
.order-toast-module__alert-info___1o_Sz .order-toast-module__alert-link___Mz1DL {
  color: #062c33;
}

.order-toast-module__alert-warning___YnB8X {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.order-toast-module__alert-warning___YnB8X hr {
  border-top-color: #ffe8a1;
}
.order-toast-module__alert-warning___YnB8X .order-toast-module__alert-link___Mz1DL {
  color: #533f03;
}

.order-toast-module__alert-danger___2ojKr {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.order-toast-module__alert-danger___2ojKr hr {
  border-top-color: #f1b0b7;
}
.order-toast-module__alert-danger___2ojKr .order-toast-module__alert-link___Mz1DL {
  color: #491217;
}

.order-toast-module__alert-light___3lLg9 {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.order-toast-module__alert-light___3lLg9 hr {
  border-top-color: #ececf6;
}
.order-toast-module__alert-light___3lLg9 .order-toast-module__alert-link___Mz1DL {
  color: #686868;
}

.order-toast-module__alert-dark___2yft- {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.order-toast-module__alert-dark___2yft- hr {
  border-top-color: #b9bbbe;
}
.order-toast-module__alert-dark___2yft- .order-toast-module__alert-link___Mz1DL {
  color: #040505;
}

@keyframes order-toast-module__progress-bar-stripes___3KEIQ {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.order-toast-module__progress___h0Fyg {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.order-toast-module__progress-bar___LYsOq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__progress-bar___LYsOq {
    transition: none;
  }
}

.order-toast-module__progress-bar-striped___1abhY {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.order-toast-module__progress-bar-animated___26LtF {
  animation: order-toast-module__progress-bar-stripes___3KEIQ 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__progress-bar-animated___26LtF {
    animation: none;
  }
}

.order-toast-module__media___1z499 {
  display: flex;
  align-items: flex-start;
}

.order-toast-module__media-body___3rPUn {
  flex: 1;
}

.order-toast-module__list-group___1REmQ {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.order-toast-module__list-group-item-action___Rop1v {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.order-toast-module__list-group-item-action___Rop1v:hover, .order-toast-module__list-group-item-action___Rop1v:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.order-toast-module__list-group-item-action___Rop1v:active {
  color: #212529;
  background-color: #e9ecef;
}

.order-toast-module__list-group-item___3oDNi {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.order-toast-module__list-group-item___3oDNi:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.order-toast-module__list-group-item___3oDNi:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.order-toast-module__list-group-item___3oDNi.order-toast-module__disabled___Tbq1L, .order-toast-module__list-group-item___3oDNi:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.order-toast-module__list-group-item___3oDNi.order-toast-module__active___143My {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.order-toast-module__list-group-horizontal___2PKay {
  flex-direction: row;
}
.order-toast-module__list-group-horizontal___2PKay .order-toast-module__list-group-item___3oDNi {
  margin-right: -1px;
  margin-bottom: 0;
}
.order-toast-module__list-group-horizontal___2PKay .order-toast-module__list-group-item___3oDNi:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.order-toast-module__list-group-horizontal___2PKay .order-toast-module__list-group-item___3oDNi:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .order-toast-module__list-group-horizontal-sm___3c0al {
    flex-direction: row;
  }
  .order-toast-module__list-group-horizontal-sm___3c0al .order-toast-module__list-group-item___3oDNi {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .order-toast-module__list-group-horizontal-sm___3c0al .order-toast-module__list-group-item___3oDNi:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .order-toast-module__list-group-horizontal-sm___3c0al .order-toast-module__list-group-item___3oDNi:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .order-toast-module__list-group-horizontal-md___3CulZ {
    flex-direction: row;
  }
  .order-toast-module__list-group-horizontal-md___3CulZ .order-toast-module__list-group-item___3oDNi {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .order-toast-module__list-group-horizontal-md___3CulZ .order-toast-module__list-group-item___3oDNi:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .order-toast-module__list-group-horizontal-md___3CulZ .order-toast-module__list-group-item___3oDNi:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .order-toast-module__list-group-horizontal-lg___3bvyh {
    flex-direction: row;
  }
  .order-toast-module__list-group-horizontal-lg___3bvyh .order-toast-module__list-group-item___3oDNi {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .order-toast-module__list-group-horizontal-lg___3bvyh .order-toast-module__list-group-item___3oDNi:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .order-toast-module__list-group-horizontal-lg___3bvyh .order-toast-module__list-group-item___3oDNi:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .order-toast-module__list-group-horizontal-xl___2FAe7 {
    flex-direction: row;
  }
  .order-toast-module__list-group-horizontal-xl___2FAe7 .order-toast-module__list-group-item___3oDNi {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .order-toast-module__list-group-horizontal-xl___2FAe7 .order-toast-module__list-group-item___3oDNi:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .order-toast-module__list-group-horizontal-xl___2FAe7 .order-toast-module__list-group-item___3oDNi:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.order-toast-module__list-group-flush___3NrdD .order-toast-module__list-group-item___3oDNi {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.order-toast-module__list-group-flush___3NrdD .order-toast-module__list-group-item___3oDNi:last-child {
  margin-bottom: -1px;
}
.order-toast-module__list-group-flush___3NrdD:first-child .order-toast-module__list-group-item___3oDNi:first-child {
  border-top: 0;
}
.order-toast-module__list-group-flush___3NrdD:last-child .order-toast-module__list-group-item___3oDNi:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.order-toast-module__list-group-item-primary___37WWJ {
  color: #004085;
  background-color: #b8daff;
}
.order-toast-module__list-group-item-primary___37WWJ.order-toast-module__list-group-item-action___Rop1v:hover, .order-toast-module__list-group-item-primary___37WWJ.order-toast-module__list-group-item-action___Rop1v:focus {
  color: #004085;
  background-color: #9fcdff;
}
.order-toast-module__list-group-item-primary___37WWJ.order-toast-module__list-group-item-action___Rop1v.order-toast-module__active___143My {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.order-toast-module__list-group-item-secondary___376mk {
  color: #383d41;
  background-color: #d6d8db;
}
.order-toast-module__list-group-item-secondary___376mk.order-toast-module__list-group-item-action___Rop1v:hover, .order-toast-module__list-group-item-secondary___376mk.order-toast-module__list-group-item-action___Rop1v:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.order-toast-module__list-group-item-secondary___376mk.order-toast-module__list-group-item-action___Rop1v.order-toast-module__active___143My {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.order-toast-module__list-group-item-success___1R3jx {
  color: #155724;
  background-color: #c3e6cb;
}
.order-toast-module__list-group-item-success___1R3jx.order-toast-module__list-group-item-action___Rop1v:hover, .order-toast-module__list-group-item-success___1R3jx.order-toast-module__list-group-item-action___Rop1v:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.order-toast-module__list-group-item-success___1R3jx.order-toast-module__list-group-item-action___Rop1v.order-toast-module__active___143My {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.order-toast-module__list-group-item-info___3t6SG {
  color: #0c5460;
  background-color: #bee5eb;
}
.order-toast-module__list-group-item-info___3t6SG.order-toast-module__list-group-item-action___Rop1v:hover, .order-toast-module__list-group-item-info___3t6SG.order-toast-module__list-group-item-action___Rop1v:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.order-toast-module__list-group-item-info___3t6SG.order-toast-module__list-group-item-action___Rop1v.order-toast-module__active___143My {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.order-toast-module__list-group-item-warning___hI1bJ {
  color: #856404;
  background-color: #ffeeba;
}
.order-toast-module__list-group-item-warning___hI1bJ.order-toast-module__list-group-item-action___Rop1v:hover, .order-toast-module__list-group-item-warning___hI1bJ.order-toast-module__list-group-item-action___Rop1v:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.order-toast-module__list-group-item-warning___hI1bJ.order-toast-module__list-group-item-action___Rop1v.order-toast-module__active___143My {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.order-toast-module__list-group-item-danger___3Fpow {
  color: #721c24;
  background-color: #f5c6cb;
}
.order-toast-module__list-group-item-danger___3Fpow.order-toast-module__list-group-item-action___Rop1v:hover, .order-toast-module__list-group-item-danger___3Fpow.order-toast-module__list-group-item-action___Rop1v:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.order-toast-module__list-group-item-danger___3Fpow.order-toast-module__list-group-item-action___Rop1v.order-toast-module__active___143My {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.order-toast-module__list-group-item-light___1XeFm {
  color: #818182;
  background-color: #fdfdfe;
}
.order-toast-module__list-group-item-light___1XeFm.order-toast-module__list-group-item-action___Rop1v:hover, .order-toast-module__list-group-item-light___1XeFm.order-toast-module__list-group-item-action___Rop1v:focus {
  color: #818182;
  background-color: #ececf6;
}
.order-toast-module__list-group-item-light___1XeFm.order-toast-module__list-group-item-action___Rop1v.order-toast-module__active___143My {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.order-toast-module__list-group-item-dark___3J8lz {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.order-toast-module__list-group-item-dark___3J8lz.order-toast-module__list-group-item-action___Rop1v:hover, .order-toast-module__list-group-item-dark___3J8lz.order-toast-module__list-group-item-action___Rop1v:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.order-toast-module__list-group-item-dark___3J8lz.order-toast-module__list-group-item-action___Rop1v.order-toast-module__active___143My {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.order-toast-module__close___21Ndr {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.order-toast-module__close___21Ndr:hover {
  color: #000;
  text-decoration: none;
}
.order-toast-module__close___21Ndr:not(:disabled):not(.order-toast-module__disabled___Tbq1L):hover, .order-toast-module__close___21Ndr:not(:disabled):not(.order-toast-module__disabled___Tbq1L):focus {
  opacity: 0.75;
}

button.order-toast-module__close___21Ndr {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.order-toast-module__close___21Ndr.order-toast-module__disabled___Tbq1L {
  pointer-events: none;
}

.order-toast-module__toast___3Of6e {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.order-toast-module__toast___3Of6e:not(:last-child) {
  margin-bottom: 0.75rem;
}
.order-toast-module__toast___3Of6e.order-toast-module__showing___2Svzb {
  opacity: 1;
}
.order-toast-module__toast___3Of6e.order-toast-module__show___2DyCB {
  display: block;
  opacity: 1;
}
.order-toast-module__toast___3Of6e.order-toast-module__hide___395sO {
  display: none;
}

.order-toast-module__toast-header___zwSbV {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.order-toast-module__toast-body___1bRAx {
  padding: 0.75rem;
}

.order-toast-module__modal-open___1ut5l {
  overflow: hidden;
}
.order-toast-module__modal-open___1ut5l .order-toast-module__modal___sGsa5 {
  overflow-x: hidden;
  overflow-y: auto;
}

.order-toast-module__modal___sGsa5 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.order-toast-module__modal-dialog___3q4Js {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.order-toast-module__modal___sGsa5.order-toast-module__fade___2rY5m .order-toast-module__modal-dialog___3q4Js {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__modal___sGsa5.order-toast-module__fade___2rY5m .order-toast-module__modal-dialog___3q4Js {
    transition: none;
  }
}
.order-toast-module__modal___sGsa5.order-toast-module__show___2DyCB .order-toast-module__modal-dialog___3q4Js {
  transform: none;
}

.order-toast-module__modal-dialog-scrollable___nICPn {
  display: flex;
  max-height: calc(100% - 1rem);
}
.order-toast-module__modal-dialog-scrollable___nICPn .order-toast-module__modal-content___31Giu {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.order-toast-module__modal-dialog-scrollable___nICPn .order-toast-module__modal-header___3A3Dz,
.order-toast-module__modal-dialog-scrollable___nICPn .order-toast-module__modal-footer___3GPCH {
  flex-shrink: 0;
}
.order-toast-module__modal-dialog-scrollable___nICPn .order-toast-module__modal-body___1E4dc {
  overflow-y: auto;
}

.order-toast-module__modal-dialog-centered___1aG3J {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.order-toast-module__modal-dialog-centered___1aG3J::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.order-toast-module__modal-dialog-centered___1aG3J.order-toast-module__modal-dialog-scrollable___nICPn {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.order-toast-module__modal-dialog-centered___1aG3J.order-toast-module__modal-dialog-scrollable___nICPn .order-toast-module__modal-content___31Giu {
  max-height: none;
}
.order-toast-module__modal-dialog-centered___1aG3J.order-toast-module__modal-dialog-scrollable___nICPn::before {
  content: none;
}

.order-toast-module__modal-content___31Giu {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.order-toast-module__modal-backdrop___2QaQ5 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.order-toast-module__modal-backdrop___2QaQ5.order-toast-module__fade___2rY5m {
  opacity: 0;
}
.order-toast-module__modal-backdrop___2QaQ5.order-toast-module__show___2DyCB {
  opacity: 0.5;
}

.order-toast-module__modal-header___3A3Dz {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.order-toast-module__modal-header___3A3Dz .order-toast-module__close___21Ndr {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.order-toast-module__modal-title___1Q_Q3 {
  margin-bottom: 0;
  line-height: 1.5;
}

.order-toast-module__modal-body___1E4dc {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.order-toast-module__modal-footer___3GPCH {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.order-toast-module__modal-footer___3GPCH > :not(:first-child) {
  margin-left: 0.25rem;
}
.order-toast-module__modal-footer___3GPCH > :not(:last-child) {
  margin-right: 0.25rem;
}

.order-toast-module__modal-scrollbar-measure___2L7Ue {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .order-toast-module__modal-dialog___3q4Js {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .order-toast-module__modal-dialog-scrollable___nICPn {
    max-height: calc(100% - 3.5rem);
  }
  .order-toast-module__modal-dialog-scrollable___nICPn .order-toast-module__modal-content___31Giu {
    max-height: calc(100vh - 3.5rem);
  }

  .order-toast-module__modal-dialog-centered___1aG3J {
    min-height: calc(100% - 3.5rem);
  }
  .order-toast-module__modal-dialog-centered___1aG3J::before {
    height: calc(100vh - 3.5rem);
  }

  .order-toast-module__modal-sm___2dcIk {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .order-toast-module__modal-lg___23Ek_,
.order-toast-module__modal-xl___3pzMp {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .order-toast-module__modal-xl___3pzMp {
    max-width: 1140px;
  }
}
.order-toast-module__tooltip___2t2ZS {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.order-toast-module__tooltip___2t2ZS.order-toast-module__show___2DyCB {
  opacity: 0.9;
}
.order-toast-module__tooltip___2t2ZS .order-toast-module__arrow___2cSHP {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.order-toast-module__tooltip___2t2ZS .order-toast-module__arrow___2cSHP::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.order-toast-module__bs-tooltip-top___3Lt-5, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=top] {
  padding: 0.4rem 0;
}
.order-toast-module__bs-tooltip-top___3Lt-5 .order-toast-module__arrow___2cSHP, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=top] .order-toast-module__arrow___2cSHP {
  bottom: 0;
}
.order-toast-module__bs-tooltip-top___3Lt-5 .order-toast-module__arrow___2cSHP::before, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=top] .order-toast-module__arrow___2cSHP::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.order-toast-module__bs-tooltip-right___1PVKH, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=right] {
  padding: 0 0.4rem;
}
.order-toast-module__bs-tooltip-right___1PVKH .order-toast-module__arrow___2cSHP, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=right] .order-toast-module__arrow___2cSHP {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.order-toast-module__bs-tooltip-right___1PVKH .order-toast-module__arrow___2cSHP::before, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=right] .order-toast-module__arrow___2cSHP::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.order-toast-module__bs-tooltip-bottom___1vW1O, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.order-toast-module__bs-tooltip-bottom___1vW1O .order-toast-module__arrow___2cSHP, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=bottom] .order-toast-module__arrow___2cSHP {
  top: 0;
}
.order-toast-module__bs-tooltip-bottom___1vW1O .order-toast-module__arrow___2cSHP::before, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=bottom] .order-toast-module__arrow___2cSHP::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.order-toast-module__bs-tooltip-left___2764d, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=left] {
  padding: 0 0.4rem;
}
.order-toast-module__bs-tooltip-left___2764d .order-toast-module__arrow___2cSHP, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=left] .order-toast-module__arrow___2cSHP {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.order-toast-module__bs-tooltip-left___2764d .order-toast-module__arrow___2cSHP::before, .order-toast-module__bs-tooltip-auto___1O3sx[x-placement^=left] .order-toast-module__arrow___2cSHP::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.order-toast-module__tooltip-inner___KYWxV {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.order-toast-module__popover___2qKL2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.order-toast-module__popover___2qKL2 .order-toast-module__arrow___2cSHP {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.order-toast-module__popover___2qKL2 .order-toast-module__arrow___2cSHP::before, .order-toast-module__popover___2qKL2 .order-toast-module__arrow___2cSHP::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.order-toast-module__bs-popover-top___7fQm5, .order-toast-module__bs-popover-auto___1TJao[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.order-toast-module__bs-popover-top___7fQm5 > .order-toast-module__arrow___2cSHP, .order-toast-module__bs-popover-auto___1TJao[x-placement^=top] > .order-toast-module__arrow___2cSHP {
  bottom: calc((0.5rem + 1px) * -1);
}
.order-toast-module__bs-popover-top___7fQm5 > .order-toast-module__arrow___2cSHP::before, .order-toast-module__bs-popover-auto___1TJao[x-placement^=top] > .order-toast-module__arrow___2cSHP::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.order-toast-module__bs-popover-top___7fQm5 > .order-toast-module__arrow___2cSHP::after, .order-toast-module__bs-popover-auto___1TJao[x-placement^=top] > .order-toast-module__arrow___2cSHP::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.order-toast-module__bs-popover-right___2iJ8g, .order-toast-module__bs-popover-auto___1TJao[x-placement^=right] {
  margin-left: 0.5rem;
}
.order-toast-module__bs-popover-right___2iJ8g > .order-toast-module__arrow___2cSHP, .order-toast-module__bs-popover-auto___1TJao[x-placement^=right] > .order-toast-module__arrow___2cSHP {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.order-toast-module__bs-popover-right___2iJ8g > .order-toast-module__arrow___2cSHP::before, .order-toast-module__bs-popover-auto___1TJao[x-placement^=right] > .order-toast-module__arrow___2cSHP::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.order-toast-module__bs-popover-right___2iJ8g > .order-toast-module__arrow___2cSHP::after, .order-toast-module__bs-popover-auto___1TJao[x-placement^=right] > .order-toast-module__arrow___2cSHP::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.order-toast-module__bs-popover-bottom___2UnZC, .order-toast-module__bs-popover-auto___1TJao[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.order-toast-module__bs-popover-bottom___2UnZC > .order-toast-module__arrow___2cSHP, .order-toast-module__bs-popover-auto___1TJao[x-placement^=bottom] > .order-toast-module__arrow___2cSHP {
  top: calc((0.5rem + 1px) * -1);
}
.order-toast-module__bs-popover-bottom___2UnZC > .order-toast-module__arrow___2cSHP::before, .order-toast-module__bs-popover-auto___1TJao[x-placement^=bottom] > .order-toast-module__arrow___2cSHP::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.order-toast-module__bs-popover-bottom___2UnZC > .order-toast-module__arrow___2cSHP::after, .order-toast-module__bs-popover-auto___1TJao[x-placement^=bottom] > .order-toast-module__arrow___2cSHP::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.order-toast-module__bs-popover-bottom___2UnZC .order-toast-module__popover-header___2C02w::before, .order-toast-module__bs-popover-auto___1TJao[x-placement^=bottom] .order-toast-module__popover-header___2C02w::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.order-toast-module__bs-popover-left___1MlHs, .order-toast-module__bs-popover-auto___1TJao[x-placement^=left] {
  margin-right: 0.5rem;
}
.order-toast-module__bs-popover-left___1MlHs > .order-toast-module__arrow___2cSHP, .order-toast-module__bs-popover-auto___1TJao[x-placement^=left] > .order-toast-module__arrow___2cSHP {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.order-toast-module__bs-popover-left___1MlHs > .order-toast-module__arrow___2cSHP::before, .order-toast-module__bs-popover-auto___1TJao[x-placement^=left] > .order-toast-module__arrow___2cSHP::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.order-toast-module__bs-popover-left___1MlHs > .order-toast-module__arrow___2cSHP::after, .order-toast-module__bs-popover-auto___1TJao[x-placement^=left] > .order-toast-module__arrow___2cSHP::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.order-toast-module__popover-header___2C02w {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.order-toast-module__popover-header___2C02w:empty {
  display: none;
}

.order-toast-module__popover-body___7bMMb {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.order-toast-module__carousel___3M4EX {
  position: relative;
}

.order-toast-module__carousel___3M4EX.order-toast-module__pointer-event___3B-u7 {
  touch-action: pan-y;
}

.order-toast-module__carousel-inner___2gWUp {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.order-toast-module__carousel-inner___2gWUp::after {
  display: block;
  clear: both;
  content: "";
}

.order-toast-module__carousel-item___2XrMC {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__carousel-item___2XrMC {
    transition: none;
  }
}

.order-toast-module__carousel-item___2XrMC.order-toast-module__active___143My,
.order-toast-module__carousel-item-next___1jblm,
.order-toast-module__carousel-item-prev___13OVO {
  display: block;
}

.order-toast-module__carousel-item-next___1jblm:not(.order-toast-module__carousel-item-left___3FPgv),
.order-toast-module__active___143My.order-toast-module__carousel-item-right___2s3xQ {
  transform: translateX(100%);
}

.order-toast-module__carousel-item-prev___13OVO:not(.order-toast-module__carousel-item-right___2s3xQ),
.order-toast-module__active___143My.order-toast-module__carousel-item-left___3FPgv {
  transform: translateX(-100%);
}

.order-toast-module__carousel-fade___GxNjM .order-toast-module__carousel-item___2XrMC {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.order-toast-module__carousel-fade___GxNjM .order-toast-module__carousel-item___2XrMC.order-toast-module__active___143My,
.order-toast-module__carousel-fade___GxNjM .order-toast-module__carousel-item-next___1jblm.order-toast-module__carousel-item-left___3FPgv,
.order-toast-module__carousel-fade___GxNjM .order-toast-module__carousel-item-prev___13OVO.order-toast-module__carousel-item-right___2s3xQ {
  z-index: 1;
  opacity: 1;
}
.order-toast-module__carousel-fade___GxNjM .order-toast-module__active___143My.order-toast-module__carousel-item-left___3FPgv,
.order-toast-module__carousel-fade___GxNjM .order-toast-module__active___143My.order-toast-module__carousel-item-right___2s3xQ {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__carousel-fade___GxNjM .order-toast-module__active___143My.order-toast-module__carousel-item-left___3FPgv,
.order-toast-module__carousel-fade___GxNjM .order-toast-module__active___143My.order-toast-module__carousel-item-right___2s3xQ {
    transition: none;
  }
}

.order-toast-module__carousel-control-prev___1oKXb,
.order-toast-module__carousel-control-next___3zriZ {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__carousel-control-prev___1oKXb,
.order-toast-module__carousel-control-next___3zriZ {
    transition: none;
  }
}
.order-toast-module__carousel-control-prev___1oKXb:hover, .order-toast-module__carousel-control-prev___1oKXb:focus,
.order-toast-module__carousel-control-next___3zriZ:hover,
.order-toast-module__carousel-control-next___3zriZ:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.order-toast-module__carousel-control-prev___1oKXb {
  left: 0;
}

.order-toast-module__carousel-control-next___3zriZ {
  right: 0;
}

.order-toast-module__carousel-control-prev-icon___2MDbY,
.order-toast-module__carousel-control-next-icon___3qDbw {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.order-toast-module__carousel-control-prev-icon___2MDbY {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.order-toast-module__carousel-control-next-icon___3qDbw {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.order-toast-module__carousel-indicators___1aEQz {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.order-toast-module__carousel-indicators___1aEQz li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .order-toast-module__carousel-indicators___1aEQz li {
    transition: none;
  }
}
.order-toast-module__carousel-indicators___1aEQz .order-toast-module__active___143My {
  opacity: 1;
}

.order-toast-module__carousel-caption___2vPjH {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes order-toast-module__spinner-border___N3nEy {
  to {
    transform: rotate(360deg);
  }
}
.order-toast-module__spinner-border___N3nEy {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: order-toast-module__spinner-border___N3nEy 0.75s linear infinite;
}

.order-toast-module__spinner-border-sm___3bPE8 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes order-toast-module__spinner-grow___2fX5k {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.order-toast-module__spinner-grow___2fX5k {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: order-toast-module__spinner-grow___2fX5k 0.75s linear infinite;
}

.order-toast-module__spinner-grow-sm___3Yiwf {
  width: 1rem;
  height: 1rem;
}

.order-toast-module__align-baseline___1zh8Y {
  vertical-align: baseline !important;
}

.order-toast-module__align-top___1oXR0 {
  vertical-align: top !important;
}

.order-toast-module__align-middle___3c_7G {
  vertical-align: middle !important;
}

.order-toast-module__align-bottom___R7Gix {
  vertical-align: bottom !important;
}

.order-toast-module__align-text-bottom___eZuAS {
  vertical-align: text-bottom !important;
}

.order-toast-module__align-text-top___1osKw {
  vertical-align: text-top !important;
}

.order-toast-module__bg-primary___3vtzr {
  background-color: #007bff !important;
}

a.order-toast-module__bg-primary___3vtzr:hover, a.order-toast-module__bg-primary___3vtzr:focus,
button.order-toast-module__bg-primary___3vtzr:hover,
button.order-toast-module__bg-primary___3vtzr:focus {
  background-color: #0062cc !important;
}

.order-toast-module__bg-secondary___2xeVb {
  background-color: #6c757d !important;
}

a.order-toast-module__bg-secondary___2xeVb:hover, a.order-toast-module__bg-secondary___2xeVb:focus,
button.order-toast-module__bg-secondary___2xeVb:hover,
button.order-toast-module__bg-secondary___2xeVb:focus {
  background-color: #545b62 !important;
}

.order-toast-module__bg-success___27Bf1 {
  background-color: #28a745 !important;
}

a.order-toast-module__bg-success___27Bf1:hover, a.order-toast-module__bg-success___27Bf1:focus,
button.order-toast-module__bg-success___27Bf1:hover,
button.order-toast-module__bg-success___27Bf1:focus {
  background-color: #1e7e34 !important;
}

.order-toast-module__bg-info___3vpvs {
  background-color: #17a2b8 !important;
}

a.order-toast-module__bg-info___3vpvs:hover, a.order-toast-module__bg-info___3vpvs:focus,
button.order-toast-module__bg-info___3vpvs:hover,
button.order-toast-module__bg-info___3vpvs:focus {
  background-color: #117a8b !important;
}

.order-toast-module__bg-warning___1y8EL {
  background-color: #ffc107 !important;
}

a.order-toast-module__bg-warning___1y8EL:hover, a.order-toast-module__bg-warning___1y8EL:focus,
button.order-toast-module__bg-warning___1y8EL:hover,
button.order-toast-module__bg-warning___1y8EL:focus {
  background-color: #d39e00 !important;
}

.order-toast-module__bg-danger___3OddB {
  background-color: #dc3545 !important;
}

a.order-toast-module__bg-danger___3OddB:hover, a.order-toast-module__bg-danger___3OddB:focus,
button.order-toast-module__bg-danger___3OddB:hover,
button.order-toast-module__bg-danger___3OddB:focus {
  background-color: #bd2130 !important;
}

.order-toast-module__bg-light___2Pl0W {
  background-color: #f8f9fa !important;
}

a.order-toast-module__bg-light___2Pl0W:hover, a.order-toast-module__bg-light___2Pl0W:focus,
button.order-toast-module__bg-light___2Pl0W:hover,
button.order-toast-module__bg-light___2Pl0W:focus {
  background-color: #dae0e5 !important;
}

.order-toast-module__bg-dark___34XzE {
  background-color: #343a40 !important;
}

a.order-toast-module__bg-dark___34XzE:hover, a.order-toast-module__bg-dark___34XzE:focus,
button.order-toast-module__bg-dark___34XzE:hover,
button.order-toast-module__bg-dark___34XzE:focus {
  background-color: #1d2124 !important;
}

.order-toast-module__bg-white___SFqzf {
  background-color: #fff !important;
}

.order-toast-module__bg-transparent___2nMMW {
  background-color: transparent !important;
}

.order-toast-module__border___2v0zt {
  border: 1px solid #dee2e6 !important;
}

.order-toast-module__border-top___3GqZ7 {
  border-top: 1px solid #dee2e6 !important;
}

.order-toast-module__border-right___1pgu6 {
  border-right: 1px solid #dee2e6 !important;
}

.order-toast-module__border-bottom___3HFEj {
  border-bottom: 1px solid #dee2e6 !important;
}

.order-toast-module__border-left___z_bul {
  border-left: 1px solid #dee2e6 !important;
}

.order-toast-module__border-0___3sdpF {
  border: 0 !important;
}

.order-toast-module__border-top-0___1w1bM {
  border-top: 0 !important;
}

.order-toast-module__border-right-0___iPhpO {
  border-right: 0 !important;
}

.order-toast-module__border-bottom-0___1uTpC {
  border-bottom: 0 !important;
}

.order-toast-module__border-left-0___2Ob0n {
  border-left: 0 !important;
}

.order-toast-module__border-primary___6AQuk {
  border-color: #007bff !important;
}

.order-toast-module__border-secondary___r7oEn {
  border-color: #6c757d !important;
}

.order-toast-module__border-success___23FY3 {
  border-color: #28a745 !important;
}

.order-toast-module__border-info___2jWMp {
  border-color: #17a2b8 !important;
}

.order-toast-module__border-warning___1oZvZ {
  border-color: #ffc107 !important;
}

.order-toast-module__border-danger___3YkwU {
  border-color: #dc3545 !important;
}

.order-toast-module__border-light___3dp6H {
  border-color: #f8f9fa !important;
}

.order-toast-module__border-dark___15pUt {
  border-color: #343a40 !important;
}

.order-toast-module__border-white___3ykvD {
  border-color: #fff !important;
}

.order-toast-module__rounded-sm___3IbSM {
  border-radius: 0.2rem !important;
}

.order-toast-module__rounded___37ei4 {
  border-radius: 0.25rem !important;
}

.order-toast-module__rounded-top___2JZHt {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.order-toast-module__rounded-right___3E1tP {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.order-toast-module__rounded-bottom___32PAf {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.order-toast-module__rounded-left___3feLB {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.order-toast-module__rounded-lg___3mPlL {
  border-radius: 0.3rem !important;
}

.order-toast-module__rounded-circle___17jFO {
  border-radius: 50% !important;
}

.order-toast-module__rounded-pill___1C8oo {
  border-radius: 50rem !important;
}

.order-toast-module__rounded-0___2zNhQ {
  border-radius: 0 !important;
}

.order-toast-module__clearfix___3Pn1U::after {
  display: block;
  clear: both;
  content: "";
}

.order-toast-module__d-none___1HprQ {
  display: none !important;
}

.order-toast-module__d-inline___2gs3V {
  display: inline !important;
}

.order-toast-module__d-inline-block___blT_5 {
  display: inline-block !important;
}

.order-toast-module__d-block___brGBC {
  display: block !important;
}

.order-toast-module__d-table___1wCzx {
  display: table !important;
}

.order-toast-module__d-table-row___301Ow {
  display: table-row !important;
}

.order-toast-module__d-table-cell___UXjb7 {
  display: table-cell !important;
}

.order-toast-module__d-flex___3RbwH {
  display: flex !important;
}

.order-toast-module__d-inline-flex___23JU5 {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .order-toast-module__d-sm-none___mfogB {
    display: none !important;
  }

  .order-toast-module__d-sm-inline___3FCtP {
    display: inline !important;
  }

  .order-toast-module__d-sm-inline-block___2Cdkc {
    display: inline-block !important;
  }

  .order-toast-module__d-sm-block___3mHcZ {
    display: block !important;
  }

  .order-toast-module__d-sm-table____71FD {
    display: table !important;
  }

  .order-toast-module__d-sm-table-row___IxRJr {
    display: table-row !important;
  }

  .order-toast-module__d-sm-table-cell___DwdYp {
    display: table-cell !important;
  }

  .order-toast-module__d-sm-flex___2wTmT {
    display: flex !important;
  }

  .order-toast-module__d-sm-inline-flex___14RsK {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .order-toast-module__d-md-none___25a2y {
    display: none !important;
  }

  .order-toast-module__d-md-inline___1C_30 {
    display: inline !important;
  }

  .order-toast-module__d-md-inline-block___voDhs {
    display: inline-block !important;
  }

  .order-toast-module__d-md-block___qtOpR {
    display: block !important;
  }

  .order-toast-module__d-md-table___2WgwR {
    display: table !important;
  }

  .order-toast-module__d-md-table-row___3sxbg {
    display: table-row !important;
  }

  .order-toast-module__d-md-table-cell___3lhIx {
    display: table-cell !important;
  }

  .order-toast-module__d-md-flex___1boVn {
    display: flex !important;
  }

  .order-toast-module__d-md-inline-flex___3KCPZ {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .order-toast-module__d-lg-none___6u69Z {
    display: none !important;
  }

  .order-toast-module__d-lg-inline___2EgRo {
    display: inline !important;
  }

  .order-toast-module__d-lg-inline-block___2j4fE {
    display: inline-block !important;
  }

  .order-toast-module__d-lg-block___2w641 {
    display: block !important;
  }

  .order-toast-module__d-lg-table___17Dyq {
    display: table !important;
  }

  .order-toast-module__d-lg-table-row___2NzYW {
    display: table-row !important;
  }

  .order-toast-module__d-lg-table-cell___2hhdi {
    display: table-cell !important;
  }

  .order-toast-module__d-lg-flex___b47r1 {
    display: flex !important;
  }

  .order-toast-module__d-lg-inline-flex___3NDEG {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .order-toast-module__d-xl-none___jpf5x {
    display: none !important;
  }

  .order-toast-module__d-xl-inline___3_bbg {
    display: inline !important;
  }

  .order-toast-module__d-xl-inline-block___3tb-_ {
    display: inline-block !important;
  }

  .order-toast-module__d-xl-block___2wnur {
    display: block !important;
  }

  .order-toast-module__d-xl-table___dbvEA {
    display: table !important;
  }

  .order-toast-module__d-xl-table-row___1YPER {
    display: table-row !important;
  }

  .order-toast-module__d-xl-table-cell___1CSJl {
    display: table-cell !important;
  }

  .order-toast-module__d-xl-flex___1dnZ6 {
    display: flex !important;
  }

  .order-toast-module__d-xl-inline-flex___27kR5 {
    display: inline-flex !important;
  }
}
@media print {
  .order-toast-module__d-print-none___15-c6 {
    display: none !important;
  }

  .order-toast-module__d-print-inline___oXqY4 {
    display: inline !important;
  }

  .order-toast-module__d-print-inline-block___3LbiM {
    display: inline-block !important;
  }

  .order-toast-module__d-print-block___2t0n_ {
    display: block !important;
  }

  .order-toast-module__d-print-table___OjOaw {
    display: table !important;
  }

  .order-toast-module__d-print-table-row___1W_76 {
    display: table-row !important;
  }

  .order-toast-module__d-print-table-cell___yQF0P {
    display: table-cell !important;
  }

  .order-toast-module__d-print-flex___28hey {
    display: flex !important;
  }

  .order-toast-module__d-print-inline-flex___iIkqW {
    display: inline-flex !important;
  }
}
.order-toast-module__embed-responsive___3raH0 {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.order-toast-module__embed-responsive___3raH0::before {
  display: block;
  content: "";
}
.order-toast-module__embed-responsive___3raH0 .order-toast-module__embed-responsive-item___I3w5R,
.order-toast-module__embed-responsive___3raH0 iframe,
.order-toast-module__embed-responsive___3raH0 embed,
.order-toast-module__embed-responsive___3raH0 object,
.order-toast-module__embed-responsive___3raH0 video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.order-toast-module__embed-responsive-21by9___LAfKG::before {
  padding-top: 42.8571428571%;
}

.order-toast-module__embed-responsive-16by9___28PW-::before {
  padding-top: 56.25%;
}

.order-toast-module__embed-responsive-4by3___3zwe1::before {
  padding-top: 75%;
}

.order-toast-module__embed-responsive-1by1___288Kb::before {
  padding-top: 100%;
}

.order-toast-module__flex-row___2iCTT {
  flex-direction: row !important;
}

.order-toast-module__flex-column___2UKip {
  flex-direction: column !important;
}

.order-toast-module__flex-row-reverse___3QMkn {
  flex-direction: row-reverse !important;
}

.order-toast-module__flex-column-reverse___22gKp {
  flex-direction: column-reverse !important;
}

.order-toast-module__flex-wrap___jHYQD {
  flex-wrap: wrap !important;
}

.order-toast-module__flex-nowrap___3MR9t {
  flex-wrap: nowrap !important;
}

.order-toast-module__flex-wrap-reverse___3m9eD {
  flex-wrap: wrap-reverse !important;
}

.order-toast-module__flex-fill___g9L6D {
  flex: 1 1 auto !important;
}

.order-toast-module__flex-grow-0___O0wUG {
  flex-grow: 0 !important;
}

.order-toast-module__flex-grow-1___2j4ch {
  flex-grow: 1 !important;
}

.order-toast-module__flex-shrink-0___2jovr {
  flex-shrink: 0 !important;
}

.order-toast-module__flex-shrink-1___YM_SF {
  flex-shrink: 1 !important;
}

.order-toast-module__justify-content-start___-_mRI {
  justify-content: flex-start !important;
}

.order-toast-module__justify-content-end___1Kfsw {
  justify-content: flex-end !important;
}

.order-toast-module__justify-content-center___a9Qnc {
  justify-content: center !important;
}

.order-toast-module__justify-content-between___3e3vS {
  justify-content: space-between !important;
}

.order-toast-module__justify-content-around___3XPlX {
  justify-content: space-around !important;
}

.order-toast-module__align-items-start___1Wh6I {
  align-items: flex-start !important;
}

.order-toast-module__align-items-end___1aROu {
  align-items: flex-end !important;
}

.order-toast-module__align-items-center___1OoqH {
  align-items: center !important;
}

.order-toast-module__align-items-baseline___2jBUz {
  align-items: baseline !important;
}

.order-toast-module__align-items-stretch___3V6U4 {
  align-items: stretch !important;
}

.order-toast-module__align-content-start___XMCkU {
  align-content: flex-start !important;
}

.order-toast-module__align-content-end___289Yj {
  align-content: flex-end !important;
}

.order-toast-module__align-content-center___1I0vj {
  align-content: center !important;
}

.order-toast-module__align-content-between___kkrMQ {
  align-content: space-between !important;
}

.order-toast-module__align-content-around___19edd {
  align-content: space-around !important;
}

.order-toast-module__align-content-stretch___3KTdf {
  align-content: stretch !important;
}

.order-toast-module__align-self-auto___1a-h7 {
  align-self: auto !important;
}

.order-toast-module__align-self-start___10FQL {
  align-self: flex-start !important;
}

.order-toast-module__align-self-end___R1P08 {
  align-self: flex-end !important;
}

.order-toast-module__align-self-center___aT1hi {
  align-self: center !important;
}

.order-toast-module__align-self-baseline___2_KZg {
  align-self: baseline !important;
}

.order-toast-module__align-self-stretch___3VW8i {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .order-toast-module__flex-sm-row___1lCRl {
    flex-direction: row !important;
  }

  .order-toast-module__flex-sm-column___18T7c {
    flex-direction: column !important;
  }

  .order-toast-module__flex-sm-row-reverse___36Hkz {
    flex-direction: row-reverse !important;
  }

  .order-toast-module__flex-sm-column-reverse___AWVJ6 {
    flex-direction: column-reverse !important;
  }

  .order-toast-module__flex-sm-wrap___38cwN {
    flex-wrap: wrap !important;
  }

  .order-toast-module__flex-sm-nowrap___jDxxl {
    flex-wrap: nowrap !important;
  }

  .order-toast-module__flex-sm-wrap-reverse___1s8oH {
    flex-wrap: wrap-reverse !important;
  }

  .order-toast-module__flex-sm-fill___VZ0P7 {
    flex: 1 1 auto !important;
  }

  .order-toast-module__flex-sm-grow-0___2egcz {
    flex-grow: 0 !important;
  }

  .order-toast-module__flex-sm-grow-1___2DDaG {
    flex-grow: 1 !important;
  }

  .order-toast-module__flex-sm-shrink-0___2fnOR {
    flex-shrink: 0 !important;
  }

  .order-toast-module__flex-sm-shrink-1___1PO_x {
    flex-shrink: 1 !important;
  }

  .order-toast-module__justify-content-sm-start___26PtY {
    justify-content: flex-start !important;
  }

  .order-toast-module__justify-content-sm-end___1gvCX {
    justify-content: flex-end !important;
  }

  .order-toast-module__justify-content-sm-center___1gOZN {
    justify-content: center !important;
  }

  .order-toast-module__justify-content-sm-between___3L-e7 {
    justify-content: space-between !important;
  }

  .order-toast-module__justify-content-sm-around___zQY8p {
    justify-content: space-around !important;
  }

  .order-toast-module__align-items-sm-start___A1yRi {
    align-items: flex-start !important;
  }

  .order-toast-module__align-items-sm-end___G6SHl {
    align-items: flex-end !important;
  }

  .order-toast-module__align-items-sm-center___13X7o {
    align-items: center !important;
  }

  .order-toast-module__align-items-sm-baseline___2F4sc {
    align-items: baseline !important;
  }

  .order-toast-module__align-items-sm-stretch___2YGOg {
    align-items: stretch !important;
  }

  .order-toast-module__align-content-sm-start___1j84E {
    align-content: flex-start !important;
  }

  .order-toast-module__align-content-sm-end___2mShz {
    align-content: flex-end !important;
  }

  .order-toast-module__align-content-sm-center___1QTDk {
    align-content: center !important;
  }

  .order-toast-module__align-content-sm-between___2yGqw {
    align-content: space-between !important;
  }

  .order-toast-module__align-content-sm-around___1fKJv {
    align-content: space-around !important;
  }

  .order-toast-module__align-content-sm-stretch___2vyje {
    align-content: stretch !important;
  }

  .order-toast-module__align-self-sm-auto___B0wrl {
    align-self: auto !important;
  }

  .order-toast-module__align-self-sm-start___3wQTU {
    align-self: flex-start !important;
  }

  .order-toast-module__align-self-sm-end___3DgN7 {
    align-self: flex-end !important;
  }

  .order-toast-module__align-self-sm-center___1hDsu {
    align-self: center !important;
  }

  .order-toast-module__align-self-sm-baseline___2Uci0 {
    align-self: baseline !important;
  }

  .order-toast-module__align-self-sm-stretch___3uAlO {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .order-toast-module__flex-md-row___3QAkQ {
    flex-direction: row !important;
  }

  .order-toast-module__flex-md-column___zqiW_ {
    flex-direction: column !important;
  }

  .order-toast-module__flex-md-row-reverse___CgIxF {
    flex-direction: row-reverse !important;
  }

  .order-toast-module__flex-md-column-reverse___1EMBI {
    flex-direction: column-reverse !important;
  }

  .order-toast-module__flex-md-wrap___3hEVv {
    flex-wrap: wrap !important;
  }

  .order-toast-module__flex-md-nowrap___8VcXT {
    flex-wrap: nowrap !important;
  }

  .order-toast-module__flex-md-wrap-reverse___3Odj4 {
    flex-wrap: wrap-reverse !important;
  }

  .order-toast-module__flex-md-fill___3SS6X {
    flex: 1 1 auto !important;
  }

  .order-toast-module__flex-md-grow-0___7etFz {
    flex-grow: 0 !important;
  }

  .order-toast-module__flex-md-grow-1___2Mqx4 {
    flex-grow: 1 !important;
  }

  .order-toast-module__flex-md-shrink-0___1pcNu {
    flex-shrink: 0 !important;
  }

  .order-toast-module__flex-md-shrink-1___1QueS {
    flex-shrink: 1 !important;
  }

  .order-toast-module__justify-content-md-start___3ceXd {
    justify-content: flex-start !important;
  }

  .order-toast-module__justify-content-md-end___3y2TH {
    justify-content: flex-end !important;
  }

  .order-toast-module__justify-content-md-center___1uQf0 {
    justify-content: center !important;
  }

  .order-toast-module__justify-content-md-between___nxtdF {
    justify-content: space-between !important;
  }

  .order-toast-module__justify-content-md-around___kLQCP {
    justify-content: space-around !important;
  }

  .order-toast-module__align-items-md-start___39_3u {
    align-items: flex-start !important;
  }

  .order-toast-module__align-items-md-end___3Ay5F {
    align-items: flex-end !important;
  }

  .order-toast-module__align-items-md-center___2WoZM {
    align-items: center !important;
  }

  .order-toast-module__align-items-md-baseline___1fD5P {
    align-items: baseline !important;
  }

  .order-toast-module__align-items-md-stretch___2UVGe {
    align-items: stretch !important;
  }

  .order-toast-module__align-content-md-start___1m2Dm {
    align-content: flex-start !important;
  }

  .order-toast-module__align-content-md-end___1xuRI {
    align-content: flex-end !important;
  }

  .order-toast-module__align-content-md-center___1DZgk {
    align-content: center !important;
  }

  .order-toast-module__align-content-md-between___arXfQ {
    align-content: space-between !important;
  }

  .order-toast-module__align-content-md-around___1_yv_ {
    align-content: space-around !important;
  }

  .order-toast-module__align-content-md-stretch___dBWnF {
    align-content: stretch !important;
  }

  .order-toast-module__align-self-md-auto___gr6Vf {
    align-self: auto !important;
  }

  .order-toast-module__align-self-md-start___1LAM_ {
    align-self: flex-start !important;
  }

  .order-toast-module__align-self-md-end___1mGD7 {
    align-self: flex-end !important;
  }

  .order-toast-module__align-self-md-center___1MY4o {
    align-self: center !important;
  }

  .order-toast-module__align-self-md-baseline___YOSb4 {
    align-self: baseline !important;
  }

  .order-toast-module__align-self-md-stretch___1Xi_J {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .order-toast-module__flex-lg-row___oE3rM {
    flex-direction: row !important;
  }

  .order-toast-module__flex-lg-column___38g2P {
    flex-direction: column !important;
  }

  .order-toast-module__flex-lg-row-reverse___3che4 {
    flex-direction: row-reverse !important;
  }

  .order-toast-module__flex-lg-column-reverse___18cOy {
    flex-direction: column-reverse !important;
  }

  .order-toast-module__flex-lg-wrap___NZB-- {
    flex-wrap: wrap !important;
  }

  .order-toast-module__flex-lg-nowrap___wL7S- {
    flex-wrap: nowrap !important;
  }

  .order-toast-module__flex-lg-wrap-reverse___1APxZ {
    flex-wrap: wrap-reverse !important;
  }

  .order-toast-module__flex-lg-fill___2e0pH {
    flex: 1 1 auto !important;
  }

  .order-toast-module__flex-lg-grow-0___sXJ7c {
    flex-grow: 0 !important;
  }

  .order-toast-module__flex-lg-grow-1___22fSk {
    flex-grow: 1 !important;
  }

  .order-toast-module__flex-lg-shrink-0___29j3B {
    flex-shrink: 0 !important;
  }

  .order-toast-module__flex-lg-shrink-1___XVchy {
    flex-shrink: 1 !important;
  }

  .order-toast-module__justify-content-lg-start___1jFp3 {
    justify-content: flex-start !important;
  }

  .order-toast-module__justify-content-lg-end___1J25K {
    justify-content: flex-end !important;
  }

  .order-toast-module__justify-content-lg-center___3XtN8 {
    justify-content: center !important;
  }

  .order-toast-module__justify-content-lg-between___2bdnd {
    justify-content: space-between !important;
  }

  .order-toast-module__justify-content-lg-around___3eZin {
    justify-content: space-around !important;
  }

  .order-toast-module__align-items-lg-start___1oYNG {
    align-items: flex-start !important;
  }

  .order-toast-module__align-items-lg-end___2gxlf {
    align-items: flex-end !important;
  }

  .order-toast-module__align-items-lg-center___1gSox {
    align-items: center !important;
  }

  .order-toast-module__align-items-lg-baseline___3tJRU {
    align-items: baseline !important;
  }

  .order-toast-module__align-items-lg-stretch___k0cc- {
    align-items: stretch !important;
  }

  .order-toast-module__align-content-lg-start___2aME5 {
    align-content: flex-start !important;
  }

  .order-toast-module__align-content-lg-end___3Dt15 {
    align-content: flex-end !important;
  }

  .order-toast-module__align-content-lg-center___3O-_E {
    align-content: center !important;
  }

  .order-toast-module__align-content-lg-between___3BbsV {
    align-content: space-between !important;
  }

  .order-toast-module__align-content-lg-around___2naJh {
    align-content: space-around !important;
  }

  .order-toast-module__align-content-lg-stretch___4A9Rh {
    align-content: stretch !important;
  }

  .order-toast-module__align-self-lg-auto___3lvac {
    align-self: auto !important;
  }

  .order-toast-module__align-self-lg-start___3yC8Q {
    align-self: flex-start !important;
  }

  .order-toast-module__align-self-lg-end___Wmagf {
    align-self: flex-end !important;
  }

  .order-toast-module__align-self-lg-center___2Pr16 {
    align-self: center !important;
  }

  .order-toast-module__align-self-lg-baseline___OzPwl {
    align-self: baseline !important;
  }

  .order-toast-module__align-self-lg-stretch___3AWbV {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .order-toast-module__flex-xl-row___1gHx4 {
    flex-direction: row !important;
  }

  .order-toast-module__flex-xl-column___VEz-H {
    flex-direction: column !important;
  }

  .order-toast-module__flex-xl-row-reverse___3eC0i {
    flex-direction: row-reverse !important;
  }

  .order-toast-module__flex-xl-column-reverse___1xOAK {
    flex-direction: column-reverse !important;
  }

  .order-toast-module__flex-xl-wrap___1KHNT {
    flex-wrap: wrap !important;
  }

  .order-toast-module__flex-xl-nowrap___1tLrJ {
    flex-wrap: nowrap !important;
  }

  .order-toast-module__flex-xl-wrap-reverse___4fApZ {
    flex-wrap: wrap-reverse !important;
  }

  .order-toast-module__flex-xl-fill___33kyw {
    flex: 1 1 auto !important;
  }

  .order-toast-module__flex-xl-grow-0___2SLjy {
    flex-grow: 0 !important;
  }

  .order-toast-module__flex-xl-grow-1___3Nvqm {
    flex-grow: 1 !important;
  }

  .order-toast-module__flex-xl-shrink-0___3qgOY {
    flex-shrink: 0 !important;
  }

  .order-toast-module__flex-xl-shrink-1___2LPF0 {
    flex-shrink: 1 !important;
  }

  .order-toast-module__justify-content-xl-start___1HS0q {
    justify-content: flex-start !important;
  }

  .order-toast-module__justify-content-xl-end___2Kt9I {
    justify-content: flex-end !important;
  }

  .order-toast-module__justify-content-xl-center___1YuyB {
    justify-content: center !important;
  }

  .order-toast-module__justify-content-xl-between___3mkD0 {
    justify-content: space-between !important;
  }

  .order-toast-module__justify-content-xl-around___2ovtH {
    justify-content: space-around !important;
  }

  .order-toast-module__align-items-xl-start___1ukoM {
    align-items: flex-start !important;
  }

  .order-toast-module__align-items-xl-end___M5Jxe {
    align-items: flex-end !important;
  }

  .order-toast-module__align-items-xl-center___2wxLq {
    align-items: center !important;
  }

  .order-toast-module__align-items-xl-baseline___32ZWz {
    align-items: baseline !important;
  }

  .order-toast-module__align-items-xl-stretch___2ljv6 {
    align-items: stretch !important;
  }

  .order-toast-module__align-content-xl-start___MG6l0 {
    align-content: flex-start !important;
  }

  .order-toast-module__align-content-xl-end___PJG1T {
    align-content: flex-end !important;
  }

  .order-toast-module__align-content-xl-center___1VlUH {
    align-content: center !important;
  }

  .order-toast-module__align-content-xl-between___1xpVX {
    align-content: space-between !important;
  }

  .order-toast-module__align-content-xl-around___1qYCH {
    align-content: space-around !important;
  }

  .order-toast-module__align-content-xl-stretch___1wx_P {
    align-content: stretch !important;
  }

  .order-toast-module__align-self-xl-auto___P3hXB {
    align-self: auto !important;
  }

  .order-toast-module__align-self-xl-start___NHPMh {
    align-self: flex-start !important;
  }

  .order-toast-module__align-self-xl-end___byZBp {
    align-self: flex-end !important;
  }

  .order-toast-module__align-self-xl-center___1gN1L {
    align-self: center !important;
  }

  .order-toast-module__align-self-xl-baseline___IAtmp {
    align-self: baseline !important;
  }

  .order-toast-module__align-self-xl-stretch___3z5dx {
    align-self: stretch !important;
  }
}
.order-toast-module__float-left___36nRJ {
  float: left !important;
}

.order-toast-module__float-right___2EBU5 {
  float: right !important;
}

.order-toast-module__float-none___1yLsl {
  float: none !important;
}

@media (min-width: 576px) {
  .order-toast-module__float-sm-left___3abse {
    float: left !important;
  }

  .order-toast-module__float-sm-right___2AJK4 {
    float: right !important;
  }

  .order-toast-module__float-sm-none___2Nqts {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .order-toast-module__float-md-left___BoJ-O {
    float: left !important;
  }

  .order-toast-module__float-md-right___PtpsW {
    float: right !important;
  }

  .order-toast-module__float-md-none___uTuOk {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .order-toast-module__float-lg-left___lDE9p {
    float: left !important;
  }

  .order-toast-module__float-lg-right___1_KUJ {
    float: right !important;
  }

  .order-toast-module__float-lg-none___gGo6k {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .order-toast-module__float-xl-left___Ezgs1 {
    float: left !important;
  }

  .order-toast-module__float-xl-right___3jXPt {
    float: right !important;
  }

  .order-toast-module__float-xl-none___2sS38 {
    float: none !important;
  }
}
.order-toast-module__overflow-auto___2jGiG {
  overflow: auto !important;
}

.order-toast-module__overflow-hidden___d6aXo {
  overflow: hidden !important;
}

.order-toast-module__position-static___3BAPz {
  position: static !important;
}

.order-toast-module__position-relative___2E37d {
  position: relative !important;
}

.order-toast-module__position-absolute___2Vpld {
  position: absolute !important;
}

.order-toast-module__position-fixed___2nsID, .order-toast-module__toast___3Of6e {
  position: fixed !important;
}

.order-toast-module__position-sticky___25JEC {
  position: sticky !important;
}

.order-toast-module__fixed-top___nBQJz {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.order-toast-module__fixed-bottom___1BYDk {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .order-toast-module__sticky-top___2G9Dg {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.order-toast-module__sr-only___2cRgS {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.order-toast-module__sr-only-focusable___1MPZv:active, .order-toast-module__sr-only-focusable___1MPZv:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.order-toast-module__shadow-sm___1LDaS {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.order-toast-module__shadow___zcSNA {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.order-toast-module__shadow-lg___3pkOx {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.order-toast-module__shadow-none___86cC7 {
  box-shadow: none !important;
}

.order-toast-module__w-25___3hXIy {
  width: 25% !important;
}

.order-toast-module__w-50___3HpCQ {
  width: 50% !important;
}

.order-toast-module__w-75___1Bmrj {
  width: 75% !important;
}

.order-toast-module__w-100___eK3cE {
  width: 100% !important;
}

.order-toast-module__w-auto___OmOj7 {
  width: auto !important;
}

.order-toast-module__h-25___verQs {
  height: 25% !important;
}

.order-toast-module__h-50___3m2CR {
  height: 50% !important;
}

.order-toast-module__h-75___3_P11 {
  height: 75% !important;
}

.order-toast-module__h-100___2_IRU {
  height: 100% !important;
}

.order-toast-module__h-auto___oA-a9 {
  height: auto !important;
}

.order-toast-module__mw-100____7_ci {
  max-width: 100% !important;
}

.order-toast-module__mh-100___1dde_ {
  max-height: 100% !important;
}

.order-toast-module__min-vw-100___3MQw8 {
  min-width: 100vw !important;
}

.order-toast-module__min-vh-100___22Dip {
  min-height: 100vh !important;
}

.order-toast-module__vw-100___3bo_g {
  width: 100vw !important;
}

.order-toast-module__vh-100___QG7rC {
  height: 100vh !important;
}

.order-toast-module__stretched-link___3ctP7::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.order-toast-module__m-0___1eSPU {
  margin: 0 !important;
}

.order-toast-module__mt-0___1Tzhv,
.order-toast-module__my-0___21A84 {
  margin-top: 0 !important;
}

.order-toast-module__mr-0___2VGd6,
.order-toast-module__mx-0___3boG4 {
  margin-right: 0 !important;
}

.order-toast-module__mb-0___1Qzyr,
.order-toast-module__my-0___21A84 {
  margin-bottom: 0 !important;
}

.order-toast-module__ml-0___3_4qj,
.order-toast-module__mx-0___3boG4 {
  margin-left: 0 !important;
}

.order-toast-module__m-1___16nFz {
  margin: 0.25rem !important;
}

.order-toast-module__mt-1___2jBJd,
.order-toast-module__my-1___2xGVU {
  margin-top: 0.25rem !important;
}

.order-toast-module__mr-1___GgYzM,
.order-toast-module__mx-1___2j0Us {
  margin-right: 0.25rem !important;
}

.order-toast-module__mb-1___uAHWv,
.order-toast-module__my-1___2xGVU {
  margin-bottom: 0.25rem !important;
}

.order-toast-module__ml-1___1vzoZ,
.order-toast-module__mx-1___2j0Us {
  margin-left: 0.25rem !important;
}

.order-toast-module__m-2___BFHK2 {
  margin: 0.5rem !important;
}

.order-toast-module__mt-2___1VAMj,
.order-toast-module__my-2___3PPsp {
  margin-top: 0.5rem !important;
}

.order-toast-module__mr-2___1zIn4,
.order-toast-module__mx-2___6DztJ {
  margin-right: 0.5rem !important;
}

.order-toast-module__mb-2___YpYa1,
.order-toast-module__my-2___3PPsp {
  margin-bottom: 0.5rem !important;
}

.order-toast-module__ml-2___10zk8,
.order-toast-module__mx-2___6DztJ {
  margin-left: 0.5rem !important;
}

.order-toast-module__m-3___2Y7c6 {
  margin: 1rem !important;
}

.order-toast-module__mt-3___3p-Th,
.order-toast-module__my-3___3k7Jy {
  margin-top: 1rem !important;
}

.order-toast-module__mr-3___2wCcT,
.order-toast-module__mx-3___1BmOK {
  margin-right: 1rem !important;
}

.order-toast-module__mb-3___3AZ1h,
.order-toast-module__my-3___3k7Jy {
  margin-bottom: 1rem !important;
}

.order-toast-module__ml-3___Ihlbc,
.order-toast-module__mx-3___1BmOK {
  margin-left: 1rem !important;
}

.order-toast-module__m-4___1phAD, .order-toast-module__toast___3Of6e {
  margin: 1.5rem !important;
}

.order-toast-module__mt-4___mZP4i,
.order-toast-module__my-4___2YX8R {
  margin-top: 1.5rem !important;
}

.order-toast-module__mr-4___2FaKz,
.order-toast-module__mx-4___66vwP {
  margin-right: 1.5rem !important;
}

.order-toast-module__mb-4___3y3ZT,
.order-toast-module__my-4___2YX8R {
  margin-bottom: 1.5rem !important;
}

.order-toast-module__ml-4___BC4PG,
.order-toast-module__mx-4___66vwP {
  margin-left: 1.5rem !important;
}

.order-toast-module__m-5___2ahGO {
  margin: 3rem !important;
}

.order-toast-module__mt-5___3upbZ,
.order-toast-module__my-5___1lE7W {
  margin-top: 3rem !important;
}

.order-toast-module__mr-5___eozqi,
.order-toast-module__mx-5___3ZgAi {
  margin-right: 3rem !important;
}

.order-toast-module__mb-5___jF92E,
.order-toast-module__my-5___1lE7W {
  margin-bottom: 3rem !important;
}

.order-toast-module__ml-5___22JIg,
.order-toast-module__mx-5___3ZgAi {
  margin-left: 3rem !important;
}

.order-toast-module__p-0___1rjxM {
  padding: 0 !important;
}

.order-toast-module__pt-0___2zQdV,
.order-toast-module__py-0___1phzd {
  padding-top: 0 !important;
}

.order-toast-module__pr-0___3DCFV,
.order-toast-module__px-0___RgpXU {
  padding-right: 0 !important;
}

.order-toast-module__pb-0___1GBxs,
.order-toast-module__py-0___1phzd {
  padding-bottom: 0 !important;
}

.order-toast-module__pl-0___36d0p,
.order-toast-module__px-0___RgpXU {
  padding-left: 0 !important;
}

.order-toast-module__p-1___-rcVQ {
  padding: 0.25rem !important;
}

.order-toast-module__pt-1___1WwLE,
.order-toast-module__py-1___3E5Zi {
  padding-top: 0.25rem !important;
}

.order-toast-module__pr-1___1Wdmi,
.order-toast-module__px-1___3xXXW {
  padding-right: 0.25rem !important;
}

.order-toast-module__pb-1___213pV,
.order-toast-module__py-1___3E5Zi {
  padding-bottom: 0.25rem !important;
}

.order-toast-module__pl-1___2V9dQ,
.order-toast-module__px-1___3xXXW {
  padding-left: 0.25rem !important;
}

.order-toast-module__p-2___3lygx {
  padding: 0.5rem !important;
}

.order-toast-module__pt-2___2Ts8E,
.order-toast-module__py-2___1iOTc {
  padding-top: 0.5rem !important;
}

.order-toast-module__pr-2___2Rt4Y,
.order-toast-module__px-2___1eElf {
  padding-right: 0.5rem !important;
}

.order-toast-module__pb-2___2FKnq,
.order-toast-module__py-2___1iOTc {
  padding-bottom: 0.5rem !important;
}

.order-toast-module__pl-2___2BcZe,
.order-toast-module__px-2___1eElf {
  padding-left: 0.5rem !important;
}

.order-toast-module__p-3___3B7zC {
  padding: 1rem !important;
}

.order-toast-module__pt-3___3QaSG,
.order-toast-module__py-3___MeAaL {
  padding-top: 1rem !important;
}

.order-toast-module__pr-3___3DmA6,
.order-toast-module__px-3___11jMz {
  padding-right: 1rem !important;
}

.order-toast-module__pb-3___3S_Gs,
.order-toast-module__py-3___MeAaL {
  padding-bottom: 1rem !important;
}

.order-toast-module__pl-3___3Ovt3,
.order-toast-module__px-3___11jMz {
  padding-left: 1rem !important;
}

.order-toast-module__p-4___3YXQq {
  padding: 1.5rem !important;
}

.order-toast-module__pt-4___2ynDb,
.order-toast-module__py-4___1-Q0w {
  padding-top: 1.5rem !important;
}

.order-toast-module__pr-4___15Tdq,
.order-toast-module__px-4___2B3O3 {
  padding-right: 1.5rem !important;
}

.order-toast-module__pb-4___1XUkZ,
.order-toast-module__py-4___1-Q0w {
  padding-bottom: 1.5rem !important;
}

.order-toast-module__pl-4___JjzBi,
.order-toast-module__px-4___2B3O3 {
  padding-left: 1.5rem !important;
}

.order-toast-module__p-5___17BgJ {
  padding: 3rem !important;
}

.order-toast-module__pt-5___22Gbl,
.order-toast-module__py-5___3Aq2H {
  padding-top: 3rem !important;
}

.order-toast-module__pr-5___1XFNQ,
.order-toast-module__px-5___-Qf_H {
  padding-right: 3rem !important;
}

.order-toast-module__pb-5___2jDHL,
.order-toast-module__py-5___3Aq2H {
  padding-bottom: 3rem !important;
}

.order-toast-module__pl-5___1rBDe,
.order-toast-module__px-5___-Qf_H {
  padding-left: 3rem !important;
}

.order-toast-module__m-n1___36N0j {
  margin: -0.25rem !important;
}

.order-toast-module__mt-n1___1r53L,
.order-toast-module__my-n1___2WeQP {
  margin-top: -0.25rem !important;
}

.order-toast-module__mr-n1___2tnaa,
.order-toast-module__mx-n1___1Z2sB {
  margin-right: -0.25rem !important;
}

.order-toast-module__mb-n1___CNXh7,
.order-toast-module__my-n1___2WeQP {
  margin-bottom: -0.25rem !important;
}

.order-toast-module__ml-n1___29ZVF,
.order-toast-module__mx-n1___1Z2sB {
  margin-left: -0.25rem !important;
}

.order-toast-module__m-n2___22M6K {
  margin: -0.5rem !important;
}

.order-toast-module__mt-n2___3nC4A,
.order-toast-module__my-n2___1mNnX {
  margin-top: -0.5rem !important;
}

.order-toast-module__mr-n2___1K4A7,
.order-toast-module__mx-n2___2q7FE {
  margin-right: -0.5rem !important;
}

.order-toast-module__mb-n2___2Yzdf,
.order-toast-module__my-n2___1mNnX {
  margin-bottom: -0.5rem !important;
}

.order-toast-module__ml-n2___1L9iE,
.order-toast-module__mx-n2___2q7FE {
  margin-left: -0.5rem !important;
}

.order-toast-module__m-n3___1Qx5S {
  margin: -1rem !important;
}

.order-toast-module__mt-n3___3rhVK,
.order-toast-module__my-n3___1g5zG {
  margin-top: -1rem !important;
}

.order-toast-module__mr-n3___3s_6s,
.order-toast-module__mx-n3___1QZ53 {
  margin-right: -1rem !important;
}

.order-toast-module__mb-n3___2Jzhj,
.order-toast-module__my-n3___1g5zG {
  margin-bottom: -1rem !important;
}

.order-toast-module__ml-n3___3tKLX,
.order-toast-module__mx-n3___1QZ53 {
  margin-left: -1rem !important;
}

.order-toast-module__m-n4___1RL2e {
  margin: -1.5rem !important;
}

.order-toast-module__mt-n4___2beuP,
.order-toast-module__my-n4___4tIde {
  margin-top: -1.5rem !important;
}

.order-toast-module__mr-n4___1AJIl,
.order-toast-module__mx-n4___1QHA9 {
  margin-right: -1.5rem !important;
}

.order-toast-module__mb-n4___2wwPv,
.order-toast-module__my-n4___4tIde {
  margin-bottom: -1.5rem !important;
}

.order-toast-module__ml-n4___x73Qc,
.order-toast-module__mx-n4___1QHA9 {
  margin-left: -1.5rem !important;
}

.order-toast-module__m-n5___2HDUl {
  margin: -3rem !important;
}

.order-toast-module__mt-n5___3Nsav,
.order-toast-module__my-n5___3_6pZ {
  margin-top: -3rem !important;
}

.order-toast-module__mr-n5___2CqYu,
.order-toast-module__mx-n5___2Wxjr {
  margin-right: -3rem !important;
}

.order-toast-module__mb-n5___36aei,
.order-toast-module__my-n5___3_6pZ {
  margin-bottom: -3rem !important;
}

.order-toast-module__ml-n5___1Y1_-,
.order-toast-module__mx-n5___2Wxjr {
  margin-left: -3rem !important;
}

.order-toast-module__m-auto___1A9be {
  margin: auto !important;
}

.order-toast-module__mt-auto___1yrCN,
.order-toast-module__my-auto___2K1P0 {
  margin-top: auto !important;
}

.order-toast-module__mr-auto___nvTml,
.order-toast-module__mx-auto___3Vrg6 {
  margin-right: auto !important;
}

.order-toast-module__mb-auto___2wElz,
.order-toast-module__my-auto___2K1P0 {
  margin-bottom: auto !important;
}

.order-toast-module__ml-auto___RstSN,
.order-toast-module__mx-auto___3Vrg6 {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .order-toast-module__m-sm-0___ULlcq {
    margin: 0 !important;
  }

  .order-toast-module__mt-sm-0___3iaXB,
.order-toast-module__my-sm-0___3gxAV {
    margin-top: 0 !important;
  }

  .order-toast-module__mr-sm-0___27nW3,
.order-toast-module__mx-sm-0___25PSn {
    margin-right: 0 !important;
  }

  .order-toast-module__mb-sm-0___H1Yfu,
.order-toast-module__my-sm-0___3gxAV {
    margin-bottom: 0 !important;
  }

  .order-toast-module__ml-sm-0___2Jei8,
.order-toast-module__mx-sm-0___25PSn {
    margin-left: 0 !important;
  }

  .order-toast-module__m-sm-1___1Ab_6 {
    margin: 0.25rem !important;
  }

  .order-toast-module__mt-sm-1___2edxX,
.order-toast-module__my-sm-1___3kvFH {
    margin-top: 0.25rem !important;
  }

  .order-toast-module__mr-sm-1___1ykpR,
.order-toast-module__mx-sm-1___1XRiG {
    margin-right: 0.25rem !important;
  }

  .order-toast-module__mb-sm-1___3-4GN,
.order-toast-module__my-sm-1___3kvFH {
    margin-bottom: 0.25rem !important;
  }

  .order-toast-module__ml-sm-1___3wid-,
.order-toast-module__mx-sm-1___1XRiG {
    margin-left: 0.25rem !important;
  }

  .order-toast-module__m-sm-2___FD9QT {
    margin: 0.5rem !important;
  }

  .order-toast-module__mt-sm-2___1F2OY,
.order-toast-module__my-sm-2___IQJi4 {
    margin-top: 0.5rem !important;
  }

  .order-toast-module__mr-sm-2___3sivY,
.order-toast-module__mx-sm-2___17LqE {
    margin-right: 0.5rem !important;
  }

  .order-toast-module__mb-sm-2___3La_j,
.order-toast-module__my-sm-2___IQJi4 {
    margin-bottom: 0.5rem !important;
  }

  .order-toast-module__ml-sm-2___2dPni,
.order-toast-module__mx-sm-2___17LqE {
    margin-left: 0.5rem !important;
  }

  .order-toast-module__m-sm-3___2Au8_ {
    margin: 1rem !important;
  }

  .order-toast-module__mt-sm-3___2aS6V,
.order-toast-module__my-sm-3___Hgnx3 {
    margin-top: 1rem !important;
  }

  .order-toast-module__mr-sm-3___TVKXy,
.order-toast-module__mx-sm-3___1rZ_A {
    margin-right: 1rem !important;
  }

  .order-toast-module__mb-sm-3___7xDU5,
.order-toast-module__my-sm-3___Hgnx3 {
    margin-bottom: 1rem !important;
  }

  .order-toast-module__ml-sm-3___2m1M-,
.order-toast-module__mx-sm-3___1rZ_A {
    margin-left: 1rem !important;
  }

  .order-toast-module__m-sm-4___Oihp2 {
    margin: 1.5rem !important;
  }

  .order-toast-module__mt-sm-4___22cNc,
.order-toast-module__my-sm-4___3j_WT {
    margin-top: 1.5rem !important;
  }

  .order-toast-module__mr-sm-4___1aQXQ,
.order-toast-module__mx-sm-4___1Ln1- {
    margin-right: 1.5rem !important;
  }

  .order-toast-module__mb-sm-4___14F3m,
.order-toast-module__my-sm-4___3j_WT {
    margin-bottom: 1.5rem !important;
  }

  .order-toast-module__ml-sm-4___3IT9X,
.order-toast-module__mx-sm-4___1Ln1- {
    margin-left: 1.5rem !important;
  }

  .order-toast-module__m-sm-5___25jvu {
    margin: 3rem !important;
  }

  .order-toast-module__mt-sm-5___1jr72,
.order-toast-module__my-sm-5___28GFT {
    margin-top: 3rem !important;
  }

  .order-toast-module__mr-sm-5____4Bgo,
.order-toast-module__mx-sm-5___PsYqC {
    margin-right: 3rem !important;
  }

  .order-toast-module__mb-sm-5___3Fy8W,
.order-toast-module__my-sm-5___28GFT {
    margin-bottom: 3rem !important;
  }

  .order-toast-module__ml-sm-5___jfPId,
.order-toast-module__mx-sm-5___PsYqC {
    margin-left: 3rem !important;
  }

  .order-toast-module__p-sm-0___1Ujns {
    padding: 0 !important;
  }

  .order-toast-module__pt-sm-0___8dE3p,
.order-toast-module__py-sm-0___23j92 {
    padding-top: 0 !important;
  }

  .order-toast-module__pr-sm-0___CDa6F,
.order-toast-module__px-sm-0___-3mg2 {
    padding-right: 0 !important;
  }

  .order-toast-module__pb-sm-0___3nWe0,
.order-toast-module__py-sm-0___23j92 {
    padding-bottom: 0 !important;
  }

  .order-toast-module__pl-sm-0___EOYMJ,
.order-toast-module__px-sm-0___-3mg2 {
    padding-left: 0 !important;
  }

  .order-toast-module__p-sm-1___1Qn1C {
    padding: 0.25rem !important;
  }

  .order-toast-module__pt-sm-1___3yE0N,
.order-toast-module__py-sm-1___3ndpH {
    padding-top: 0.25rem !important;
  }

  .order-toast-module__pr-sm-1___xAtpm,
.order-toast-module__px-sm-1___2h2qD {
    padding-right: 0.25rem !important;
  }

  .order-toast-module__pb-sm-1___2ejyL,
.order-toast-module__py-sm-1___3ndpH {
    padding-bottom: 0.25rem !important;
  }

  .order-toast-module__pl-sm-1___FcZR1,
.order-toast-module__px-sm-1___2h2qD {
    padding-left: 0.25rem !important;
  }

  .order-toast-module__p-sm-2___1EwkK {
    padding: 0.5rem !important;
  }

  .order-toast-module__pt-sm-2___2X2SK,
.order-toast-module__py-sm-2___1C4zz {
    padding-top: 0.5rem !important;
  }

  .order-toast-module__pr-sm-2___MWKtO,
.order-toast-module__px-sm-2___Q5kOf {
    padding-right: 0.5rem !important;
  }

  .order-toast-module__pb-sm-2___3wJvE,
.order-toast-module__py-sm-2___1C4zz {
    padding-bottom: 0.5rem !important;
  }

  .order-toast-module__pl-sm-2___36r3b,
.order-toast-module__px-sm-2___Q5kOf {
    padding-left: 0.5rem !important;
  }

  .order-toast-module__p-sm-3___14k_l {
    padding: 1rem !important;
  }

  .order-toast-module__pt-sm-3___HaO4r,
.order-toast-module__py-sm-3___1qOEI {
    padding-top: 1rem !important;
  }

  .order-toast-module__pr-sm-3___37nt8,
.order-toast-module__px-sm-3___XIwj_ {
    padding-right: 1rem !important;
  }

  .order-toast-module__pb-sm-3___LPF4H,
.order-toast-module__py-sm-3___1qOEI {
    padding-bottom: 1rem !important;
  }

  .order-toast-module__pl-sm-3___1NTrU,
.order-toast-module__px-sm-3___XIwj_ {
    padding-left: 1rem !important;
  }

  .order-toast-module__p-sm-4___17egf {
    padding: 1.5rem !important;
  }

  .order-toast-module__pt-sm-4___2bTXb,
.order-toast-module__py-sm-4___2s2OJ {
    padding-top: 1.5rem !important;
  }

  .order-toast-module__pr-sm-4___hgk2S,
.order-toast-module__px-sm-4___28Mxi {
    padding-right: 1.5rem !important;
  }

  .order-toast-module__pb-sm-4___17eYc,
.order-toast-module__py-sm-4___2s2OJ {
    padding-bottom: 1.5rem !important;
  }

  .order-toast-module__pl-sm-4___2KHF1,
.order-toast-module__px-sm-4___28Mxi {
    padding-left: 1.5rem !important;
  }

  .order-toast-module__p-sm-5___2kQcO {
    padding: 3rem !important;
  }

  .order-toast-module__pt-sm-5___1kk3v,
.order-toast-module__py-sm-5___5k6Ap {
    padding-top: 3rem !important;
  }

  .order-toast-module__pr-sm-5___13f0O,
.order-toast-module__px-sm-5___3YlT6 {
    padding-right: 3rem !important;
  }

  .order-toast-module__pb-sm-5___1scQH,
.order-toast-module__py-sm-5___5k6Ap {
    padding-bottom: 3rem !important;
  }

  .order-toast-module__pl-sm-5___2V3mt,
.order-toast-module__px-sm-5___3YlT6 {
    padding-left: 3rem !important;
  }

  .order-toast-module__m-sm-n1___2GwJv {
    margin: -0.25rem !important;
  }

  .order-toast-module__mt-sm-n1___3Xcnu,
.order-toast-module__my-sm-n1___FZKNi {
    margin-top: -0.25rem !important;
  }

  .order-toast-module__mr-sm-n1___20Qvi,
.order-toast-module__mx-sm-n1___3AtuP {
    margin-right: -0.25rem !important;
  }

  .order-toast-module__mb-sm-n1___2cQ5u,
.order-toast-module__my-sm-n1___FZKNi {
    margin-bottom: -0.25rem !important;
  }

  .order-toast-module__ml-sm-n1___zI-L6,
.order-toast-module__mx-sm-n1___3AtuP {
    margin-left: -0.25rem !important;
  }

  .order-toast-module__m-sm-n2___yhYll {
    margin: -0.5rem !important;
  }

  .order-toast-module__mt-sm-n2___ytX9a,
.order-toast-module__my-sm-n2___n-syM {
    margin-top: -0.5rem !important;
  }

  .order-toast-module__mr-sm-n2___K7KYi,
.order-toast-module__mx-sm-n2___4XebW {
    margin-right: -0.5rem !important;
  }

  .order-toast-module__mb-sm-n2___3MFP-,
.order-toast-module__my-sm-n2___n-syM {
    margin-bottom: -0.5rem !important;
  }

  .order-toast-module__ml-sm-n2___2rDfM,
.order-toast-module__mx-sm-n2___4XebW {
    margin-left: -0.5rem !important;
  }

  .order-toast-module__m-sm-n3___1rjkI {
    margin: -1rem !important;
  }

  .order-toast-module__mt-sm-n3___10S6l,
.order-toast-module__my-sm-n3___1JTbJ {
    margin-top: -1rem !important;
  }

  .order-toast-module__mr-sm-n3___aq0MU,
.order-toast-module__mx-sm-n3___1cUvQ {
    margin-right: -1rem !important;
  }

  .order-toast-module__mb-sm-n3___303gV,
.order-toast-module__my-sm-n3___1JTbJ {
    margin-bottom: -1rem !important;
  }

  .order-toast-module__ml-sm-n3___3BttW,
.order-toast-module__mx-sm-n3___1cUvQ {
    margin-left: -1rem !important;
  }

  .order-toast-module__m-sm-n4___2V8K4 {
    margin: -1.5rem !important;
  }

  .order-toast-module__mt-sm-n4___1zjUl,
.order-toast-module__my-sm-n4___21Ie- {
    margin-top: -1.5rem !important;
  }

  .order-toast-module__mr-sm-n4___17rsM,
.order-toast-module__mx-sm-n4___37gZ4 {
    margin-right: -1.5rem !important;
  }

  .order-toast-module__mb-sm-n4___1Nq80,
.order-toast-module__my-sm-n4___21Ie- {
    margin-bottom: -1.5rem !important;
  }

  .order-toast-module__ml-sm-n4___2dggX,
.order-toast-module__mx-sm-n4___37gZ4 {
    margin-left: -1.5rem !important;
  }

  .order-toast-module__m-sm-n5___3fUHL {
    margin: -3rem !important;
  }

  .order-toast-module__mt-sm-n5___3tNvT,
.order-toast-module__my-sm-n5___3VCP8 {
    margin-top: -3rem !important;
  }

  .order-toast-module__mr-sm-n5___2vKtI,
.order-toast-module__mx-sm-n5___3dF-R {
    margin-right: -3rem !important;
  }

  .order-toast-module__mb-sm-n5___1ht3i,
.order-toast-module__my-sm-n5___3VCP8 {
    margin-bottom: -3rem !important;
  }

  .order-toast-module__ml-sm-n5___IJd2B,
.order-toast-module__mx-sm-n5___3dF-R {
    margin-left: -3rem !important;
  }

  .order-toast-module__m-sm-auto___32iKF {
    margin: auto !important;
  }

  .order-toast-module__mt-sm-auto___1wxMI,
.order-toast-module__my-sm-auto___fPSiL {
    margin-top: auto !important;
  }

  .order-toast-module__mr-sm-auto___1yN-1,
.order-toast-module__mx-sm-auto___jSZ3D {
    margin-right: auto !important;
  }

  .order-toast-module__mb-sm-auto___3IUto,
.order-toast-module__my-sm-auto___fPSiL {
    margin-bottom: auto !important;
  }

  .order-toast-module__ml-sm-auto___9znGP,
.order-toast-module__mx-sm-auto___jSZ3D {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .order-toast-module__m-md-0___1NzIs {
    margin: 0 !important;
  }

  .order-toast-module__mt-md-0___1OUyd,
.order-toast-module__my-md-0___1XEBj {
    margin-top: 0 !important;
  }

  .order-toast-module__mr-md-0___32EMy,
.order-toast-module__mx-md-0___212GC {
    margin-right: 0 !important;
  }

  .order-toast-module__mb-md-0___1n_Ao,
.order-toast-module__my-md-0___1XEBj {
    margin-bottom: 0 !important;
  }

  .order-toast-module__ml-md-0___2Wz6f,
.order-toast-module__mx-md-0___212GC {
    margin-left: 0 !important;
  }

  .order-toast-module__m-md-1___1lbfC {
    margin: 0.25rem !important;
  }

  .order-toast-module__mt-md-1___147cL,
.order-toast-module__my-md-1___2gMdx {
    margin-top: 0.25rem !important;
  }

  .order-toast-module__mr-md-1___3d4Jc,
.order-toast-module__mx-md-1___1JJ31 {
    margin-right: 0.25rem !important;
  }

  .order-toast-module__mb-md-1___K-HTM,
.order-toast-module__my-md-1___2gMdx {
    margin-bottom: 0.25rem !important;
  }

  .order-toast-module__ml-md-1___18xlG,
.order-toast-module__mx-md-1___1JJ31 {
    margin-left: 0.25rem !important;
  }

  .order-toast-module__m-md-2___9GlmA {
    margin: 0.5rem !important;
  }

  .order-toast-module__mt-md-2___29Zs2,
.order-toast-module__my-md-2___39XVZ {
    margin-top: 0.5rem !important;
  }

  .order-toast-module__mr-md-2___39Wh8,
.order-toast-module__mx-md-2___3wvzV {
    margin-right: 0.5rem !important;
  }

  .order-toast-module__mb-md-2___fBzyE,
.order-toast-module__my-md-2___39XVZ {
    margin-bottom: 0.5rem !important;
  }

  .order-toast-module__ml-md-2___Mp6Wh,
.order-toast-module__mx-md-2___3wvzV {
    margin-left: 0.5rem !important;
  }

  .order-toast-module__m-md-3___3kT0b {
    margin: 1rem !important;
  }

  .order-toast-module__mt-md-3___1xZYg,
.order-toast-module__my-md-3___iBmek {
    margin-top: 1rem !important;
  }

  .order-toast-module__mr-md-3___3cmeI,
.order-toast-module__mx-md-3___35hSk {
    margin-right: 1rem !important;
  }

  .order-toast-module__mb-md-3___VcS6J,
.order-toast-module__my-md-3___iBmek {
    margin-bottom: 1rem !important;
  }

  .order-toast-module__ml-md-3___1zjL3,
.order-toast-module__mx-md-3___35hSk {
    margin-left: 1rem !important;
  }

  .order-toast-module__m-md-4___1G7R3 {
    margin: 1.5rem !important;
  }

  .order-toast-module__mt-md-4___2Quf4,
.order-toast-module__my-md-4___TASxD {
    margin-top: 1.5rem !important;
  }

  .order-toast-module__mr-md-4___253b2,
.order-toast-module__mx-md-4___1lt_f {
    margin-right: 1.5rem !important;
  }

  .order-toast-module__mb-md-4___1aqmg,
.order-toast-module__my-md-4___TASxD {
    margin-bottom: 1.5rem !important;
  }

  .order-toast-module__ml-md-4___1WnLn,
.order-toast-module__mx-md-4___1lt_f {
    margin-left: 1.5rem !important;
  }

  .order-toast-module__m-md-5___3Eoqe {
    margin: 3rem !important;
  }

  .order-toast-module__mt-md-5___2sgdt,
.order-toast-module__my-md-5___32qYe {
    margin-top: 3rem !important;
  }

  .order-toast-module__mr-md-5___NBALI,
.order-toast-module__mx-md-5___37xGE {
    margin-right: 3rem !important;
  }

  .order-toast-module__mb-md-5___3Np3B,
.order-toast-module__my-md-5___32qYe {
    margin-bottom: 3rem !important;
  }

  .order-toast-module__ml-md-5___2qniW,
.order-toast-module__mx-md-5___37xGE {
    margin-left: 3rem !important;
  }

  .order-toast-module__p-md-0___1cwGo {
    padding: 0 !important;
  }

  .order-toast-module__pt-md-0___2yAOC,
.order-toast-module__py-md-0___2ETFp {
    padding-top: 0 !important;
  }

  .order-toast-module__pr-md-0___1bjvq,
.order-toast-module__px-md-0___1p5Me {
    padding-right: 0 !important;
  }

  .order-toast-module__pb-md-0___2zXqu,
.order-toast-module__py-md-0___2ETFp {
    padding-bottom: 0 !important;
  }

  .order-toast-module__pl-md-0___ShDUM,
.order-toast-module__px-md-0___1p5Me {
    padding-left: 0 !important;
  }

  .order-toast-module__p-md-1___35ppm {
    padding: 0.25rem !important;
  }

  .order-toast-module__pt-md-1___AxLb6,
.order-toast-module__py-md-1___3GqOK {
    padding-top: 0.25rem !important;
  }

  .order-toast-module__pr-md-1___3azqF,
.order-toast-module__px-md-1___3vAOw {
    padding-right: 0.25rem !important;
  }

  .order-toast-module__pb-md-1___1Y6R3,
.order-toast-module__py-md-1___3GqOK {
    padding-bottom: 0.25rem !important;
  }

  .order-toast-module__pl-md-1___o0Wmu,
.order-toast-module__px-md-1___3vAOw {
    padding-left: 0.25rem !important;
  }

  .order-toast-module__p-md-2___3A4q_ {
    padding: 0.5rem !important;
  }

  .order-toast-module__pt-md-2___3RYJf,
.order-toast-module__py-md-2___O37s0 {
    padding-top: 0.5rem !important;
  }

  .order-toast-module__pr-md-2___3gjza,
.order-toast-module__px-md-2___v3Spe {
    padding-right: 0.5rem !important;
  }

  .order-toast-module__pb-md-2___mKkSx,
.order-toast-module__py-md-2___O37s0 {
    padding-bottom: 0.5rem !important;
  }

  .order-toast-module__pl-md-2___L-_hw,
.order-toast-module__px-md-2___v3Spe {
    padding-left: 0.5rem !important;
  }

  .order-toast-module__p-md-3___2TXSv {
    padding: 1rem !important;
  }

  .order-toast-module__pt-md-3___3BNbS,
.order-toast-module__py-md-3___2BM7I {
    padding-top: 1rem !important;
  }

  .order-toast-module__pr-md-3___39pP1,
.order-toast-module__px-md-3___3_iBF {
    padding-right: 1rem !important;
  }

  .order-toast-module__pb-md-3___1xDas,
.order-toast-module__py-md-3___2BM7I {
    padding-bottom: 1rem !important;
  }

  .order-toast-module__pl-md-3___3ioIi,
.order-toast-module__px-md-3___3_iBF {
    padding-left: 1rem !important;
  }

  .order-toast-module__p-md-4___3AbAG {
    padding: 1.5rem !important;
  }

  .order-toast-module__pt-md-4___2hQsk,
.order-toast-module__py-md-4___3xHOi {
    padding-top: 1.5rem !important;
  }

  .order-toast-module__pr-md-4___3BP7k,
.order-toast-module__px-md-4___1msNg {
    padding-right: 1.5rem !important;
  }

  .order-toast-module__pb-md-4___37oJk,
.order-toast-module__py-md-4___3xHOi {
    padding-bottom: 1.5rem !important;
  }

  .order-toast-module__pl-md-4___1oC5G,
.order-toast-module__px-md-4___1msNg {
    padding-left: 1.5rem !important;
  }

  .order-toast-module__p-md-5___3eu7- {
    padding: 3rem !important;
  }

  .order-toast-module__pt-md-5___2XyU5,
.order-toast-module__py-md-5___WIhCp {
    padding-top: 3rem !important;
  }

  .order-toast-module__pr-md-5___3lLAE,
.order-toast-module__px-md-5___XAWd6 {
    padding-right: 3rem !important;
  }

  .order-toast-module__pb-md-5___2TSXd,
.order-toast-module__py-md-5___WIhCp {
    padding-bottom: 3rem !important;
  }

  .order-toast-module__pl-md-5___kjqm-,
.order-toast-module__px-md-5___XAWd6 {
    padding-left: 3rem !important;
  }

  .order-toast-module__m-md-n1___8g7gM {
    margin: -0.25rem !important;
  }

  .order-toast-module__mt-md-n1___3_l22,
.order-toast-module__my-md-n1___3L5q_ {
    margin-top: -0.25rem !important;
  }

  .order-toast-module__mr-md-n1___-u31R,
.order-toast-module__mx-md-n1___1Uo6o {
    margin-right: -0.25rem !important;
  }

  .order-toast-module__mb-md-n1___-4HPL,
.order-toast-module__my-md-n1___3L5q_ {
    margin-bottom: -0.25rem !important;
  }

  .order-toast-module__ml-md-n1___1c0tr,
.order-toast-module__mx-md-n1___1Uo6o {
    margin-left: -0.25rem !important;
  }

  .order-toast-module__m-md-n2___1MLlD {
    margin: -0.5rem !important;
  }

  .order-toast-module__mt-md-n2___2h1wv,
.order-toast-module__my-md-n2___3coRp {
    margin-top: -0.5rem !important;
  }

  .order-toast-module__mr-md-n2___2Fz_J,
.order-toast-module__mx-md-n2___3y7KQ {
    margin-right: -0.5rem !important;
  }

  .order-toast-module__mb-md-n2___6CURR,
.order-toast-module__my-md-n2___3coRp {
    margin-bottom: -0.5rem !important;
  }

  .order-toast-module__ml-md-n2___xih0I,
.order-toast-module__mx-md-n2___3y7KQ {
    margin-left: -0.5rem !important;
  }

  .order-toast-module__m-md-n3___26-uR {
    margin: -1rem !important;
  }

  .order-toast-module__mt-md-n3___1H4vy,
.order-toast-module__my-md-n3___2kMFU {
    margin-top: -1rem !important;
  }

  .order-toast-module__mr-md-n3___hw8ZL,
.order-toast-module__mx-md-n3___2TI8r {
    margin-right: -1rem !important;
  }

  .order-toast-module__mb-md-n3___3XmII,
.order-toast-module__my-md-n3___2kMFU {
    margin-bottom: -1rem !important;
  }

  .order-toast-module__ml-md-n3___OEEiu,
.order-toast-module__mx-md-n3___2TI8r {
    margin-left: -1rem !important;
  }

  .order-toast-module__m-md-n4___2NW10 {
    margin: -1.5rem !important;
  }

  .order-toast-module__mt-md-n4___18abc,
.order-toast-module__my-md-n4___Vgqxb {
    margin-top: -1.5rem !important;
  }

  .order-toast-module__mr-md-n4___3gxXo,
.order-toast-module__mx-md-n4___13GAG {
    margin-right: -1.5rem !important;
  }

  .order-toast-module__mb-md-n4___1RNIs,
.order-toast-module__my-md-n4___Vgqxb {
    margin-bottom: -1.5rem !important;
  }

  .order-toast-module__ml-md-n4___2owX6,
.order-toast-module__mx-md-n4___13GAG {
    margin-left: -1.5rem !important;
  }

  .order-toast-module__m-md-n5___18v-W {
    margin: -3rem !important;
  }

  .order-toast-module__mt-md-n5___3VJZB,
.order-toast-module__my-md-n5___1-gus {
    margin-top: -3rem !important;
  }

  .order-toast-module__mr-md-n5___Is6mk,
.order-toast-module__mx-md-n5___4YRQR {
    margin-right: -3rem !important;
  }

  .order-toast-module__mb-md-n5___L5lTM,
.order-toast-module__my-md-n5___1-gus {
    margin-bottom: -3rem !important;
  }

  .order-toast-module__ml-md-n5___1Hk38,
.order-toast-module__mx-md-n5___4YRQR {
    margin-left: -3rem !important;
  }

  .order-toast-module__m-md-auto___1O_Pp {
    margin: auto !important;
  }

  .order-toast-module__mt-md-auto___2wod6,
.order-toast-module__my-md-auto___2rmXL {
    margin-top: auto !important;
  }

  .order-toast-module__mr-md-auto___ZNo76,
.order-toast-module__mx-md-auto___3FDuo {
    margin-right: auto !important;
  }

  .order-toast-module__mb-md-auto___2zaIu,
.order-toast-module__my-md-auto___2rmXL {
    margin-bottom: auto !important;
  }

  .order-toast-module__ml-md-auto___AEO3S,
.order-toast-module__mx-md-auto___3FDuo {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .order-toast-module__m-lg-0___3UkPY {
    margin: 0 !important;
  }

  .order-toast-module__mt-lg-0___1qCma,
.order-toast-module__my-lg-0___3p1SQ {
    margin-top: 0 !important;
  }

  .order-toast-module__mr-lg-0___14tPf,
.order-toast-module__mx-lg-0___AKjjp {
    margin-right: 0 !important;
  }

  .order-toast-module__mb-lg-0___nkPCH,
.order-toast-module__my-lg-0___3p1SQ {
    margin-bottom: 0 !important;
  }

  .order-toast-module__ml-lg-0___3zLug,
.order-toast-module__mx-lg-0___AKjjp {
    margin-left: 0 !important;
  }

  .order-toast-module__m-lg-1___3si37 {
    margin: 0.25rem !important;
  }

  .order-toast-module__mt-lg-1___3eCSX,
.order-toast-module__my-lg-1___2IGdq {
    margin-top: 0.25rem !important;
  }

  .order-toast-module__mr-lg-1___1OAhN,
.order-toast-module__mx-lg-1___JjIZa {
    margin-right: 0.25rem !important;
  }

  .order-toast-module__mb-lg-1___1Eu5Z,
.order-toast-module__my-lg-1___2IGdq {
    margin-bottom: 0.25rem !important;
  }

  .order-toast-module__ml-lg-1___1eZne,
.order-toast-module__mx-lg-1___JjIZa {
    margin-left: 0.25rem !important;
  }

  .order-toast-module__m-lg-2___3tfyi {
    margin: 0.5rem !important;
  }

  .order-toast-module__mt-lg-2___8o4uc,
.order-toast-module__my-lg-2___2hJQm {
    margin-top: 0.5rem !important;
  }

  .order-toast-module__mr-lg-2___2sJ2C,
.order-toast-module__mx-lg-2___1_9fm {
    margin-right: 0.5rem !important;
  }

  .order-toast-module__mb-lg-2___2PLZ_,
.order-toast-module__my-lg-2___2hJQm {
    margin-bottom: 0.5rem !important;
  }

  .order-toast-module__ml-lg-2___gQPyr,
.order-toast-module__mx-lg-2___1_9fm {
    margin-left: 0.5rem !important;
  }

  .order-toast-module__m-lg-3___iN3yX {
    margin: 1rem !important;
  }

  .order-toast-module__mt-lg-3___2H7i3,
.order-toast-module__my-lg-3___38i1O {
    margin-top: 1rem !important;
  }

  .order-toast-module__mr-lg-3___GnYph,
.order-toast-module__mx-lg-3___12wKo {
    margin-right: 1rem !important;
  }

  .order-toast-module__mb-lg-3___2pQWx,
.order-toast-module__my-lg-3___38i1O {
    margin-bottom: 1rem !important;
  }

  .order-toast-module__ml-lg-3___1d7vJ,
.order-toast-module__mx-lg-3___12wKo {
    margin-left: 1rem !important;
  }

  .order-toast-module__m-lg-4___31_ry {
    margin: 1.5rem !important;
  }

  .order-toast-module__mt-lg-4___PS9-S,
.order-toast-module__my-lg-4___2jEVQ {
    margin-top: 1.5rem !important;
  }

  .order-toast-module__mr-lg-4___23bwi,
.order-toast-module__mx-lg-4___2QUfn {
    margin-right: 1.5rem !important;
  }

  .order-toast-module__mb-lg-4___BIdAk,
.order-toast-module__my-lg-4___2jEVQ {
    margin-bottom: 1.5rem !important;
  }

  .order-toast-module__ml-lg-4___2bsJX,
.order-toast-module__mx-lg-4___2QUfn {
    margin-left: 1.5rem !important;
  }

  .order-toast-module__m-lg-5___2BK5b {
    margin: 3rem !important;
  }

  .order-toast-module__mt-lg-5___3pK_6,
.order-toast-module__my-lg-5___3o7WH {
    margin-top: 3rem !important;
  }

  .order-toast-module__mr-lg-5___poRTL,
.order-toast-module__mx-lg-5___3xIox {
    margin-right: 3rem !important;
  }

  .order-toast-module__mb-lg-5___2o3MS,
.order-toast-module__my-lg-5___3o7WH {
    margin-bottom: 3rem !important;
  }

  .order-toast-module__ml-lg-5___3ZtkV,
.order-toast-module__mx-lg-5___3xIox {
    margin-left: 3rem !important;
  }

  .order-toast-module__p-lg-0___B0OL3 {
    padding: 0 !important;
  }

  .order-toast-module__pt-lg-0___1VAx2,
.order-toast-module__py-lg-0___3Tcod {
    padding-top: 0 !important;
  }

  .order-toast-module__pr-lg-0___1H1nt,
.order-toast-module__px-lg-0___31wod {
    padding-right: 0 !important;
  }

  .order-toast-module__pb-lg-0___22zm4,
.order-toast-module__py-lg-0___3Tcod {
    padding-bottom: 0 !important;
  }

  .order-toast-module__pl-lg-0___1JW-Q,
.order-toast-module__px-lg-0___31wod {
    padding-left: 0 !important;
  }

  .order-toast-module__p-lg-1___1-jT- {
    padding: 0.25rem !important;
  }

  .order-toast-module__pt-lg-1___3SgWT,
.order-toast-module__py-lg-1___2y0-Q {
    padding-top: 0.25rem !important;
  }

  .order-toast-module__pr-lg-1___8OGH-,
.order-toast-module__px-lg-1___1SLYr {
    padding-right: 0.25rem !important;
  }

  .order-toast-module__pb-lg-1___ocEAg,
.order-toast-module__py-lg-1___2y0-Q {
    padding-bottom: 0.25rem !important;
  }

  .order-toast-module__pl-lg-1___z4MCc,
.order-toast-module__px-lg-1___1SLYr {
    padding-left: 0.25rem !important;
  }

  .order-toast-module__p-lg-2___QK1jV {
    padding: 0.5rem !important;
  }

  .order-toast-module__pt-lg-2___1XpZa,
.order-toast-module__py-lg-2___17E4I {
    padding-top: 0.5rem !important;
  }

  .order-toast-module__pr-lg-2___hmWfT,
.order-toast-module__px-lg-2___1pzoy {
    padding-right: 0.5rem !important;
  }

  .order-toast-module__pb-lg-2___3oYXs,
.order-toast-module__py-lg-2___17E4I {
    padding-bottom: 0.5rem !important;
  }

  .order-toast-module__pl-lg-2___1Xtzm,
.order-toast-module__px-lg-2___1pzoy {
    padding-left: 0.5rem !important;
  }

  .order-toast-module__p-lg-3___3xEL4 {
    padding: 1rem !important;
  }

  .order-toast-module__pt-lg-3___2-Qkr,
.order-toast-module__py-lg-3___2FQxx {
    padding-top: 1rem !important;
  }

  .order-toast-module__pr-lg-3___1rpBt,
.order-toast-module__px-lg-3___2Dh3s {
    padding-right: 1rem !important;
  }

  .order-toast-module__pb-lg-3___309IR,
.order-toast-module__py-lg-3___2FQxx {
    padding-bottom: 1rem !important;
  }

  .order-toast-module__pl-lg-3___P3apt,
.order-toast-module__px-lg-3___2Dh3s {
    padding-left: 1rem !important;
  }

  .order-toast-module__p-lg-4___3JRdb {
    padding: 1.5rem !important;
  }

  .order-toast-module__pt-lg-4___2YSto,
.order-toast-module__py-lg-4___3YCBd {
    padding-top: 1.5rem !important;
  }

  .order-toast-module__pr-lg-4___2SFy5,
.order-toast-module__px-lg-4___2PC0r {
    padding-right: 1.5rem !important;
  }

  .order-toast-module__pb-lg-4___79_Zo,
.order-toast-module__py-lg-4___3YCBd {
    padding-bottom: 1.5rem !important;
  }

  .order-toast-module__pl-lg-4___2s500,
.order-toast-module__px-lg-4___2PC0r {
    padding-left: 1.5rem !important;
  }

  .order-toast-module__p-lg-5___3zbiK {
    padding: 3rem !important;
  }

  .order-toast-module__pt-lg-5___3nLyR,
.order-toast-module__py-lg-5___7qvOu {
    padding-top: 3rem !important;
  }

  .order-toast-module__pr-lg-5___25U-B,
.order-toast-module__px-lg-5___3NY8j {
    padding-right: 3rem !important;
  }

  .order-toast-module__pb-lg-5___3Ii2s,
.order-toast-module__py-lg-5___7qvOu {
    padding-bottom: 3rem !important;
  }

  .order-toast-module__pl-lg-5___rMxtJ,
.order-toast-module__px-lg-5___3NY8j {
    padding-left: 3rem !important;
  }

  .order-toast-module__m-lg-n1___398SB {
    margin: -0.25rem !important;
  }

  .order-toast-module__mt-lg-n1___2wVlT,
.order-toast-module__my-lg-n1___3w3Nu {
    margin-top: -0.25rem !important;
  }

  .order-toast-module__mr-lg-n1___eLDlh,
.order-toast-module__mx-lg-n1___4rnSJ {
    margin-right: -0.25rem !important;
  }

  .order-toast-module__mb-lg-n1___3jzh5,
.order-toast-module__my-lg-n1___3w3Nu {
    margin-bottom: -0.25rem !important;
  }

  .order-toast-module__ml-lg-n1___iwbBr,
.order-toast-module__mx-lg-n1___4rnSJ {
    margin-left: -0.25rem !important;
  }

  .order-toast-module__m-lg-n2___2Dp9A {
    margin: -0.5rem !important;
  }

  .order-toast-module__mt-lg-n2___3QDzP,
.order-toast-module__my-lg-n2___dmcfc {
    margin-top: -0.5rem !important;
  }

  .order-toast-module__mr-lg-n2___30ggP,
.order-toast-module__mx-lg-n2___3lgwv {
    margin-right: -0.5rem !important;
  }

  .order-toast-module__mb-lg-n2___lidCi,
.order-toast-module__my-lg-n2___dmcfc {
    margin-bottom: -0.5rem !important;
  }

  .order-toast-module__ml-lg-n2___vqKyh,
.order-toast-module__mx-lg-n2___3lgwv {
    margin-left: -0.5rem !important;
  }

  .order-toast-module__m-lg-n3___1yOWG {
    margin: -1rem !important;
  }

  .order-toast-module__mt-lg-n3___7P9fA,
.order-toast-module__my-lg-n3___3iyoD {
    margin-top: -1rem !important;
  }

  .order-toast-module__mr-lg-n3___3MEeP,
.order-toast-module__mx-lg-n3___2tGey {
    margin-right: -1rem !important;
  }

  .order-toast-module__mb-lg-n3___1ljtY,
.order-toast-module__my-lg-n3___3iyoD {
    margin-bottom: -1rem !important;
  }

  .order-toast-module__ml-lg-n3___2pXZf,
.order-toast-module__mx-lg-n3___2tGey {
    margin-left: -1rem !important;
  }

  .order-toast-module__m-lg-n4___1ywSP {
    margin: -1.5rem !important;
  }

  .order-toast-module__mt-lg-n4___Kz2Mh,
.order-toast-module__my-lg-n4___2Y8wr {
    margin-top: -1.5rem !important;
  }

  .order-toast-module__mr-lg-n4___1lpFJ,
.order-toast-module__mx-lg-n4___2rGNX {
    margin-right: -1.5rem !important;
  }

  .order-toast-module__mb-lg-n4___26MG9,
.order-toast-module__my-lg-n4___2Y8wr {
    margin-bottom: -1.5rem !important;
  }

  .order-toast-module__ml-lg-n4___1KZ__,
.order-toast-module__mx-lg-n4___2rGNX {
    margin-left: -1.5rem !important;
  }

  .order-toast-module__m-lg-n5___bVSrI {
    margin: -3rem !important;
  }

  .order-toast-module__mt-lg-n5___OjNaH,
.order-toast-module__my-lg-n5___O3z-n {
    margin-top: -3rem !important;
  }

  .order-toast-module__mr-lg-n5___3Oaqb,
.order-toast-module__mx-lg-n5___1plP- {
    margin-right: -3rem !important;
  }

  .order-toast-module__mb-lg-n5___17SuZ,
.order-toast-module__my-lg-n5___O3z-n {
    margin-bottom: -3rem !important;
  }

  .order-toast-module__ml-lg-n5___Eoo0V,
.order-toast-module__mx-lg-n5___1plP- {
    margin-left: -3rem !important;
  }

  .order-toast-module__m-lg-auto___Z0mS_ {
    margin: auto !important;
  }

  .order-toast-module__mt-lg-auto___3d3aI,
.order-toast-module__my-lg-auto___3KN24 {
    margin-top: auto !important;
  }

  .order-toast-module__mr-lg-auto___39bls,
.order-toast-module__mx-lg-auto___2JyQ7 {
    margin-right: auto !important;
  }

  .order-toast-module__mb-lg-auto___3FIAL,
.order-toast-module__my-lg-auto___3KN24 {
    margin-bottom: auto !important;
  }

  .order-toast-module__ml-lg-auto___c1OWc,
.order-toast-module__mx-lg-auto___2JyQ7 {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .order-toast-module__m-xl-0___1OjB9 {
    margin: 0 !important;
  }

  .order-toast-module__mt-xl-0___154z9,
.order-toast-module__my-xl-0___2GLQe {
    margin-top: 0 !important;
  }

  .order-toast-module__mr-xl-0___3De6H,
.order-toast-module__mx-xl-0___VKTWP {
    margin-right: 0 !important;
  }

  .order-toast-module__mb-xl-0___Jy8o9,
.order-toast-module__my-xl-0___2GLQe {
    margin-bottom: 0 !important;
  }

  .order-toast-module__ml-xl-0___15WY4,
.order-toast-module__mx-xl-0___VKTWP {
    margin-left: 0 !important;
  }

  .order-toast-module__m-xl-1___-do11 {
    margin: 0.25rem !important;
  }

  .order-toast-module__mt-xl-1___3NBfT,
.order-toast-module__my-xl-1___3FdB8 {
    margin-top: 0.25rem !important;
  }

  .order-toast-module__mr-xl-1___2u37j,
.order-toast-module__mx-xl-1___YfF_R {
    margin-right: 0.25rem !important;
  }

  .order-toast-module__mb-xl-1___oa_Xi,
.order-toast-module__my-xl-1___3FdB8 {
    margin-bottom: 0.25rem !important;
  }

  .order-toast-module__ml-xl-1___18jM7,
.order-toast-module__mx-xl-1___YfF_R {
    margin-left: 0.25rem !important;
  }

  .order-toast-module__m-xl-2___3m6_d {
    margin: 0.5rem !important;
  }

  .order-toast-module__mt-xl-2___21lA2,
.order-toast-module__my-xl-2___1MRjG {
    margin-top: 0.5rem !important;
  }

  .order-toast-module__mr-xl-2___1qAqC,
.order-toast-module__mx-xl-2___273Nl {
    margin-right: 0.5rem !important;
  }

  .order-toast-module__mb-xl-2___3fiDD,
.order-toast-module__my-xl-2___1MRjG {
    margin-bottom: 0.5rem !important;
  }

  .order-toast-module__ml-xl-2___GKvAo,
.order-toast-module__mx-xl-2___273Nl {
    margin-left: 0.5rem !important;
  }

  .order-toast-module__m-xl-3___pMLhR {
    margin: 1rem !important;
  }

  .order-toast-module__mt-xl-3___2G7yi,
.order-toast-module__my-xl-3___hPSV3 {
    margin-top: 1rem !important;
  }

  .order-toast-module__mr-xl-3___3ot90,
.order-toast-module__mx-xl-3___2oSK9 {
    margin-right: 1rem !important;
  }

  .order-toast-module__mb-xl-3___2EOfX,
.order-toast-module__my-xl-3___hPSV3 {
    margin-bottom: 1rem !important;
  }

  .order-toast-module__ml-xl-3___21tFQ,
.order-toast-module__mx-xl-3___2oSK9 {
    margin-left: 1rem !important;
  }

  .order-toast-module__m-xl-4___1QlS_ {
    margin: 1.5rem !important;
  }

  .order-toast-module__mt-xl-4___1YKIs,
.order-toast-module__my-xl-4___1PFQo {
    margin-top: 1.5rem !important;
  }

  .order-toast-module__mr-xl-4___3Tu_k,
.order-toast-module__mx-xl-4___1aaoU {
    margin-right: 1.5rem !important;
  }

  .order-toast-module__mb-xl-4___1m546,
.order-toast-module__my-xl-4___1PFQo {
    margin-bottom: 1.5rem !important;
  }

  .order-toast-module__ml-xl-4___1criP,
.order-toast-module__mx-xl-4___1aaoU {
    margin-left: 1.5rem !important;
  }

  .order-toast-module__m-xl-5___1R8PA {
    margin: 3rem !important;
  }

  .order-toast-module__mt-xl-5___23E-b,
.order-toast-module__my-xl-5___2heb1 {
    margin-top: 3rem !important;
  }

  .order-toast-module__mr-xl-5___falpH,
.order-toast-module__mx-xl-5____nJED {
    margin-right: 3rem !important;
  }

  .order-toast-module__mb-xl-5___2st5I,
.order-toast-module__my-xl-5___2heb1 {
    margin-bottom: 3rem !important;
  }

  .order-toast-module__ml-xl-5___1r1D0,
.order-toast-module__mx-xl-5____nJED {
    margin-left: 3rem !important;
  }

  .order-toast-module__p-xl-0___uEwif {
    padding: 0 !important;
  }

  .order-toast-module__pt-xl-0___BhkoM,
.order-toast-module__py-xl-0___22e_Y {
    padding-top: 0 !important;
  }

  .order-toast-module__pr-xl-0___J4JB1,
.order-toast-module__px-xl-0___3d_oC {
    padding-right: 0 !important;
  }

  .order-toast-module__pb-xl-0___27gyk,
.order-toast-module__py-xl-0___22e_Y {
    padding-bottom: 0 !important;
  }

  .order-toast-module__pl-xl-0___38Flh,
.order-toast-module__px-xl-0___3d_oC {
    padding-left: 0 !important;
  }

  .order-toast-module__p-xl-1___1P4Vr {
    padding: 0.25rem !important;
  }

  .order-toast-module__pt-xl-1___3Fbka,
.order-toast-module__py-xl-1___J33HF {
    padding-top: 0.25rem !important;
  }

  .order-toast-module__pr-xl-1___2JdVl,
.order-toast-module__px-xl-1___fzDPK {
    padding-right: 0.25rem !important;
  }

  .order-toast-module__pb-xl-1___2NLAQ,
.order-toast-module__py-xl-1___J33HF {
    padding-bottom: 0.25rem !important;
  }

  .order-toast-module__pl-xl-1___2xhcx,
.order-toast-module__px-xl-1___fzDPK {
    padding-left: 0.25rem !important;
  }

  .order-toast-module__p-xl-2___2RBBQ {
    padding: 0.5rem !important;
  }

  .order-toast-module__pt-xl-2___1Qq5U,
.order-toast-module__py-xl-2___1bwkZ {
    padding-top: 0.5rem !important;
  }

  .order-toast-module__pr-xl-2___3FdiF,
.order-toast-module__px-xl-2___2M_OJ {
    padding-right: 0.5rem !important;
  }

  .order-toast-module__pb-xl-2___1AJNU,
.order-toast-module__py-xl-2___1bwkZ {
    padding-bottom: 0.5rem !important;
  }

  .order-toast-module__pl-xl-2___3Yr7M,
.order-toast-module__px-xl-2___2M_OJ {
    padding-left: 0.5rem !important;
  }

  .order-toast-module__p-xl-3___1sJMt {
    padding: 1rem !important;
  }

  .order-toast-module__pt-xl-3___1-j_M,
.order-toast-module__py-xl-3___3H-nY {
    padding-top: 1rem !important;
  }

  .order-toast-module__pr-xl-3___2d5qG,
.order-toast-module__px-xl-3___3HBiJ {
    padding-right: 1rem !important;
  }

  .order-toast-module__pb-xl-3___3jBGR,
.order-toast-module__py-xl-3___3H-nY {
    padding-bottom: 1rem !important;
  }

  .order-toast-module__pl-xl-3___14J9m,
.order-toast-module__px-xl-3___3HBiJ {
    padding-left: 1rem !important;
  }

  .order-toast-module__p-xl-4___1aYbd {
    padding: 1.5rem !important;
  }

  .order-toast-module__pt-xl-4___3TMWT,
.order-toast-module__py-xl-4___28b2q {
    padding-top: 1.5rem !important;
  }

  .order-toast-module__pr-xl-4___18e_B,
.order-toast-module__px-xl-4___1qea2 {
    padding-right: 1.5rem !important;
  }

  .order-toast-module__pb-xl-4___2vL9w,
.order-toast-module__py-xl-4___28b2q {
    padding-bottom: 1.5rem !important;
  }

  .order-toast-module__pl-xl-4___3_xsd,
.order-toast-module__px-xl-4___1qea2 {
    padding-left: 1.5rem !important;
  }

  .order-toast-module__p-xl-5___1xXkw {
    padding: 3rem !important;
  }

  .order-toast-module__pt-xl-5___1ZIxa,
.order-toast-module__py-xl-5___mYYCZ {
    padding-top: 3rem !important;
  }

  .order-toast-module__pr-xl-5___2J0L-,
.order-toast-module__px-xl-5___1cyyK {
    padding-right: 3rem !important;
  }

  .order-toast-module__pb-xl-5___2ekJR,
.order-toast-module__py-xl-5___mYYCZ {
    padding-bottom: 3rem !important;
  }

  .order-toast-module__pl-xl-5___2Sg7G,
.order-toast-module__px-xl-5___1cyyK {
    padding-left: 3rem !important;
  }

  .order-toast-module__m-xl-n1___6gZby {
    margin: -0.25rem !important;
  }

  .order-toast-module__mt-xl-n1___3n8Ky,
.order-toast-module__my-xl-n1___24u5M {
    margin-top: -0.25rem !important;
  }

  .order-toast-module__mr-xl-n1___32Ij7,
.order-toast-module__mx-xl-n1___1olj_ {
    margin-right: -0.25rem !important;
  }

  .order-toast-module__mb-xl-n1___25hI4,
.order-toast-module__my-xl-n1___24u5M {
    margin-bottom: -0.25rem !important;
  }

  .order-toast-module__ml-xl-n1___2gLgn,
.order-toast-module__mx-xl-n1___1olj_ {
    margin-left: -0.25rem !important;
  }

  .order-toast-module__m-xl-n2___3JHTb {
    margin: -0.5rem !important;
  }

  .order-toast-module__mt-xl-n2___2Azjq,
.order-toast-module__my-xl-n2___2nzw4 {
    margin-top: -0.5rem !important;
  }

  .order-toast-module__mr-xl-n2___3CHwS,
.order-toast-module__mx-xl-n2___151Wv {
    margin-right: -0.5rem !important;
  }

  .order-toast-module__mb-xl-n2___29mZB,
.order-toast-module__my-xl-n2___2nzw4 {
    margin-bottom: -0.5rem !important;
  }

  .order-toast-module__ml-xl-n2___1u36V,
.order-toast-module__mx-xl-n2___151Wv {
    margin-left: -0.5rem !important;
  }

  .order-toast-module__m-xl-n3___3EBCK {
    margin: -1rem !important;
  }

  .order-toast-module__mt-xl-n3___LhSFE,
.order-toast-module__my-xl-n3___1pJdp {
    margin-top: -1rem !important;
  }

  .order-toast-module__mr-xl-n3___3mC4m,
.order-toast-module__mx-xl-n3___2P4Ne {
    margin-right: -1rem !important;
  }

  .order-toast-module__mb-xl-n3___2Wfpx,
.order-toast-module__my-xl-n3___1pJdp {
    margin-bottom: -1rem !important;
  }

  .order-toast-module__ml-xl-n3___3HCSC,
.order-toast-module__mx-xl-n3___2P4Ne {
    margin-left: -1rem !important;
  }

  .order-toast-module__m-xl-n4___3IlRc {
    margin: -1.5rem !important;
  }

  .order-toast-module__mt-xl-n4___2fYvg,
.order-toast-module__my-xl-n4___2HsH3 {
    margin-top: -1.5rem !important;
  }

  .order-toast-module__mr-xl-n4___3mJiH,
.order-toast-module__mx-xl-n4___3LnHr {
    margin-right: -1.5rem !important;
  }

  .order-toast-module__mb-xl-n4___24ZA6,
.order-toast-module__my-xl-n4___2HsH3 {
    margin-bottom: -1.5rem !important;
  }

  .order-toast-module__ml-xl-n4___1B1Z2,
.order-toast-module__mx-xl-n4___3LnHr {
    margin-left: -1.5rem !important;
  }

  .order-toast-module__m-xl-n5___1KcCx {
    margin: -3rem !important;
  }

  .order-toast-module__mt-xl-n5___2T2CM,
.order-toast-module__my-xl-n5___15Wxe {
    margin-top: -3rem !important;
  }

  .order-toast-module__mr-xl-n5___j-65O,
.order-toast-module__mx-xl-n5___2biX5 {
    margin-right: -3rem !important;
  }

  .order-toast-module__mb-xl-n5___2YG4G,
.order-toast-module__my-xl-n5___15Wxe {
    margin-bottom: -3rem !important;
  }

  .order-toast-module__ml-xl-n5___2X9PX,
.order-toast-module__mx-xl-n5___2biX5 {
    margin-left: -3rem !important;
  }

  .order-toast-module__m-xl-auto___2xsoH {
    margin: auto !important;
  }

  .order-toast-module__mt-xl-auto___1Vpz1,
.order-toast-module__my-xl-auto___1cXkb {
    margin-top: auto !important;
  }

  .order-toast-module__mr-xl-auto___eeSxg,
.order-toast-module__mx-xl-auto___oH6o2 {
    margin-right: auto !important;
  }

  .order-toast-module__mb-xl-auto___3H1OO,
.order-toast-module__my-xl-auto___1cXkb {
    margin-bottom: auto !important;
  }

  .order-toast-module__ml-xl-auto___1dOUf,
.order-toast-module__mx-xl-auto___oH6o2 {
    margin-left: auto !important;
  }
}
.order-toast-module__text-monospace___1O-JL {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.order-toast-module__text-justify___19auU {
  text-align: justify !important;
}

.order-toast-module__text-wrap___3PS47 {
  white-space: normal !important;
}

.order-toast-module__text-nowrap___1v_VA {
  white-space: nowrap !important;
}

.order-toast-module__text-truncate___1NNl- {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order-toast-module__text-left___2DGo2 {
  text-align: left !important;
}

.order-toast-module__text-right___3IC3v {
  text-align: right !important;
}

.order-toast-module__text-center___1YLg_ {
  text-align: center !important;
}

@media (min-width: 576px) {
  .order-toast-module__text-sm-left___1dwiO {
    text-align: left !important;
  }

  .order-toast-module__text-sm-right___3VBlD {
    text-align: right !important;
  }

  .order-toast-module__text-sm-center___1UAlj {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .order-toast-module__text-md-left___1z6Za {
    text-align: left !important;
  }

  .order-toast-module__text-md-right___NGI5_ {
    text-align: right !important;
  }

  .order-toast-module__text-md-center___3ionx {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .order-toast-module__text-lg-left___1j1oZ {
    text-align: left !important;
  }

  .order-toast-module__text-lg-right___2ahfs {
    text-align: right !important;
  }

  .order-toast-module__text-lg-center___3cjL3 {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .order-toast-module__text-xl-left___15FPE {
    text-align: left !important;
  }

  .order-toast-module__text-xl-right___nk4ol {
    text-align: right !important;
  }

  .order-toast-module__text-xl-center___1pI0U {
    text-align: center !important;
  }
}
.order-toast-module__text-lowercase___14qb_ {
  text-transform: lowercase !important;
}

.order-toast-module__text-uppercase___13lhA {
  text-transform: uppercase !important;
}

.order-toast-module__text-capitalize___3MJHR {
  text-transform: capitalize !important;
}

.order-toast-module__font-weight-light___1805E {
  font-weight: 300 !important;
}

.order-toast-module__font-weight-lighter___2gdQ8 {
  font-weight: lighter !important;
}

.order-toast-module__font-weight-normal___2nj3D {
  font-weight: 400 !important;
}

.order-toast-module__font-weight-bold___1RcUA {
  font-weight: 700 !important;
}

.order-toast-module__font-weight-bolder___3pUru {
  font-weight: bolder !important;
}

.order-toast-module__font-italic___aL5-k {
  font-style: italic !important;
}

.order-toast-module__text-white___1XX5E {
  color: #fff !important;
}

.order-toast-module__text-primary___3vMpi {
  color: #007bff !important;
}

a.order-toast-module__text-primary___3vMpi:hover, a.order-toast-module__text-primary___3vMpi:focus {
  color: #0056b3 !important;
}

.order-toast-module__text-secondary___241r_ {
  color: #6c757d !important;
}

a.order-toast-module__text-secondary___241r_:hover, a.order-toast-module__text-secondary___241r_:focus {
  color: #494f54 !important;
}

.order-toast-module__text-success___37gb9 {
  color: #28a745 !important;
}

a.order-toast-module__text-success___37gb9:hover, a.order-toast-module__text-success___37gb9:focus {
  color: #19692c !important;
}

.order-toast-module__text-info___2dyVi {
  color: #17a2b8 !important;
}

a.order-toast-module__text-info___2dyVi:hover, a.order-toast-module__text-info___2dyVi:focus {
  color: #0f6674 !important;
}

.order-toast-module__text-warning___162jh {
  color: #ffc107 !important;
}

a.order-toast-module__text-warning___162jh:hover, a.order-toast-module__text-warning___162jh:focus {
  color: #ba8b00 !important;
}

.order-toast-module__text-danger___23DOi {
  color: #dc3545 !important;
}

a.order-toast-module__text-danger___23DOi:hover, a.order-toast-module__text-danger___23DOi:focus {
  color: #a71d2a !important;
}

.order-toast-module__text-light___3yp6T {
  color: #f8f9fa !important;
}

a.order-toast-module__text-light___3yp6T:hover, a.order-toast-module__text-light___3yp6T:focus {
  color: #cbd3da !important;
}

.order-toast-module__text-dark___20wup {
  color: #343a40 !important;
}

a.order-toast-module__text-dark___20wup:hover, a.order-toast-module__text-dark___20wup:focus {
  color: #121416 !important;
}

.order-toast-module__text-body___3TnJi {
  color: #212529 !important;
}

.order-toast-module__text-muted___3fmsc {
  color: #6c757d !important;
}

.order-toast-module__text-black-50___2rLWB {
  color: rgba(0, 0, 0, 0.5) !important;
}

.order-toast-module__text-white-50___kU7Pg {
  color: rgba(255, 255, 255, 0.5) !important;
}

.order-toast-module__text-hide___2-aCA {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.order-toast-module__text-decoration-none___1M2Sm {
  text-decoration: none !important;
}

.order-toast-module__text-break___1j7j2 {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.order-toast-module__text-reset___1hOHX {
  color: inherit !important;
}

.order-toast-module__visible___8D-TV {
  visibility: visible !important;
}

.order-toast-module__invisible___3OjTo {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.order-toast-module__btn___3K_89) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .order-toast-module__container___hNC4l {
    min-width: 992px !important;
  }

  .order-toast-module__navbar___3xkcF {
    display: none;
  }

  .order-toast-module__badge___gp07i {
    border: 1px solid #000;
  }

  .order-toast-module__table___2bBye {
    border-collapse: collapse !important;
  }
  .order-toast-module__table___2bBye td,
.order-toast-module__table___2bBye th {
    background-color: #fff !important;
  }

  .order-toast-module__table-bordered___ZOdBm th,
.order-toast-module__table-bordered___ZOdBm td {
    border: 1px solid #dee2e6 !important;
  }

  .order-toast-module__table-dark___2VXWH {
    color: inherit;
  }
  .order-toast-module__table-dark___2VXWH th,
.order-toast-module__table-dark___2VXWH td,
.order-toast-module__table-dark___2VXWH thead th,
.order-toast-module__table-dark___2VXWH tbody + tbody {
    border-color: #dee2e6;
  }

  .order-toast-module__table___2bBye .order-toast-module__thead-dark___3ocCw th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.order-toast-module__toast___3Of6e {
  bottom: 0;
  right: 0;
  min-width: 400px;
  max-width: 600px;
}