.invite-module__invite-background___1_fxG {
  background-color: #dee2e6;
}

.invite-module__invite___2S7J- {
  -webkit-box-shadow: 5px 5px 25px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 25px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 25px 1px rgba(0, 0, 0, 0.2);
  height: 300px;
  width: 600px;
  border-radius: 5px;
  background-color: white;
}