.done-notify-view-module__done-notify-view___1ZsxO {
  height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.done-notify-view-module__done-notify-view-content___1evOi {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.done-notify-view-module__done-notify-view-button___2wt8E {
  margin-top: 10px;
}
.done-notify-view-module__done-notify-view-icon___1z6qE {
  font-size: 3.5em;
  color: #ffc728;
  margin-bottom: 20px;
}