.layout-module__layout___2ecuK {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.layout-module__sidebar___2xf8P, .layout-module__sidebar-collapsed___Xc0ui {
  background-color: #262B31;
  width: 280px;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  transition: width 250ms;
  -webkit-transition: width 250ms;
  z-index: 1030;
}

.layout-module__sidebar-collapsed___Xc0ui {
  width: 55px;
}

.layout-module__main-activity___1l9jf {
  display: flex;
  flex-direction: column;
  background-color: #dee2e6;
  width: 100%;
  height: 100%;
  overflow: scroll;
}