.uploader-module__uploader-wrapper___3Ks4- {
  width: 100%;
}

.uploader-module__uploader___5JRl2 {
  background-color: #F2F3F4;
  border: 1px dashed #BDC3C7;
  border-radius: 5px;
  height: 200px;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 200ms;
  -webkit-transition: all 200ms;
  background-color: #262B31;
  color: rgba(255, 255, 255, 0.8);
}
.uploader-module__uploader___5JRl2 .uploader-module__icon___10dC5 {
  font-size: 2em;
  margin-bottom: 15px;
  color: #ffc728;
}
.uploader-module__uploader___5JRl2 .uploader-module__label___1iqvA {
  font-size: 0.9em;
  opacity: 0.7;
}
.uploader-module__uploader___5JRl2 .uploader-module__under-label___xzOiN {
  font-size: 0.8em;
  opacity: 0.4;
}
.uploader-module__uploader___5JRl2:hover {
  opacity: 0.95;
}