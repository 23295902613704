.upload-list-module__upload-list___2bH3Y {
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
  margin-top: 5px;
  overflow: scroll;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}
.upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 {
  padding: 2px 5px 2px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 200ms;
  -webkit-transition: all 200ms;
  border-radius: 5px;
}
.upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt:hover, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8:hover {
  background-color: rgba(255, 199, 40, 0.5);
}
.upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 {
  color: #E74C3C;
}
.upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icons-wrapper___yQLfR, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icons-wrapper___yQLfR {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon___3CybP, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-warning___2Jwaw, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-done___1coZS, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-error___3lsn0, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-remove___1mje_, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon___3CybP, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-warning___2Jwaw, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-done___1coZS, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-error___3lsn0, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-remove___1mje_ {
  opacity: 0.8;
  cursor: pointer;
  margin-left: 3px;
  transition: all 300ms;
  -webkit-transition: all 300ms;
}
.upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon___3CybP:hover, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-warning___2Jwaw:hover, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-done___1coZS:hover, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-error___3lsn0:hover, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-remove___1mje_:hover, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon___3CybP:hover, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-warning___2Jwaw:hover, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-done___1coZS:hover, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-error___3lsn0:hover, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-remove___1mje_:hover {
  opacity: 1;
}
.upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-remove___1mje_, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-remove___1mje_ {
  color: #E74C3C;
}
.upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-error___3lsn0, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-error___3lsn0 {
  color: #E74C3C;
}
.upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-done___1coZS, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-done___1coZS {
  color: #2ECC71;
}
.upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item___3yamt .upload-list-module__item-icon-warning___2Jwaw, .upload-list-module__upload-list___2bH3Y .upload-list-module__upload-list-item-error___26lN8 .upload-list-module__item-icon-warning___2Jwaw {
  color: #F1C40F;
}