.cell-style-module__fake-cell-wrapper___2lP47, .cell-style-module__fake-cell-wrapper-left___3YyPS, .cell-style-module__fake-cell-wrapper-center___1uAhK {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  line-height: 1.5;
}
.cell-style-module__fake-cell-wrapper-center___1uAhK {
  align-items: center;
}
.cell-style-module__fake-cell-wrapper-left___3YyPS {
  align-items: left;
}