.custom-cell-module__wrapper___1HaZ- {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}