.activity-drawer-module__activity-drawer-hidden___3ZhWA {
  position: fixed;
  top: 0;
  right: -300px;
  height: 100vh;
  width: 300px;
  background-color: white;
  z-index: 1023;
  transition: right 150ms;
  -webkit-transition: right 150ms;
  color: #3d4348;
}

.activity-drawer-module__activity-drawer___1g2z6 {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 450px;
  background-color: white;
  z-index: 1023;
  -webkit-box-shadow: -2px 0px 15px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -2px 0px 15px 3px rgba(0, 0, 0, 0.25);
  transition: right 250ms;
  -webkit-transition: right 250ms;
  color: #3d4348;
}
.activity-drawer-module__activity-drawer___1g2z6 .activity-drawer-module__header___2x8Ax {
  border-bottom: 1px solid #ebebeb;
}
.activity-drawer-module__activity-drawer___1g2z6 .activity-drawer-module__close-icon___FgKi4 {
  cursor: pointer;
  opacity: 0.6;
  vertical-align: middle;
  margin-top: 5px;
  transition: opacity 300ms;
  -webkit-transition: 300ms;
}
.activity-drawer-module__activity-drawer___1g2z6 .activity-drawer-module__close-icon___FgKi4:hover {
  opacity: 1;
}
.activity-drawer-module__activity-drawer___1g2z6 .activity-drawer-module__activity-drawer-content-wrapper___1kIEz {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.activity-drawer-module__activity-drawer___1g2z6 .activity-drawer-module__activity-drawer-content___2klYk {
  overflow: scroll;
  height: 92vh;
  padding: 1rem;
}
.activity-drawer-module__activity-drawer___1g2z6 .activity-drawer-module__notification-item-header___3tk7Y {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.activity-drawer-module__activity-drawer___1g2z6 .activity-drawer-module__activity-group-item___lrV_0 {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
}
.activity-drawer-module__activity-drawer___1g2z6 .activity-drawer-module__activity-group-item___lrV_0 .activity-drawer-module__date___3hEOy {
  font-size: 12px;
}

.activity-drawer-module__activity-item___1Pb8U {
  width: 100%;
  display: flex;
  flex-direction: column;
  letter-spacing: normal;
  line-height: normal;
  font-weight: 400;
  font-family: "Rubik";
}
.activity-drawer-module__activity-item___1Pb8U .activity-drawer-module__activity-item-header___MB7Ht {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.activity-drawer-module__activity-item___1Pb8U .activity-drawer-module__activity-item-header___MB7Ht .activity-drawer-module__icon-wrapper___35Ola {
  flex: 0 0 auto;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}
.activity-drawer-module__activity-item___1Pb8U .activity-drawer-module__activity-item-header___MB7Ht .activity-drawer-module__activity-item-header-text___2cHgN {
  flex: 1 0 auto;
  margin-left: 15px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.activity-drawer-module__activity-item___1Pb8U .activity-drawer-module__activity-item-content-wrapper___DefLU {
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  margin-left: 18px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.activity-drawer-module__activity-item___1Pb8U .activity-drawer-module__activity-item-content-wrapper___DefLU .activity-drawer-module__activity-item-content___1lc1a {
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 15px;
  font-size: 14px;
}
.activity-drawer-module__activity-item___1Pb8U .activity-drawer-module__activity-item-content-wrapper___DefLU .activity-drawer-module__activity-item-content___1lc1a .activity-drawer-module__activity-item-content-secondary___3xtCk {
  font-size: 0.9em;
  opacity: 0.7;
  margin-top: 8px;
}