@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.sidebar-module__h1___NiPvb, .sidebar-module__h2___3uDQs, .sidebar-module__h3___1a1YT, .sidebar-module__h4___phWWj, .sidebar-module__h5___10GQr, .sidebar-module__h6___39vE_ {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .sidebar-module__h1___NiPvb {
  font-size: 2.5rem;
}

h2, .sidebar-module__h2___3uDQs {
  font-size: 2rem;
}

h3, .sidebar-module__h3___1a1YT {
  font-size: 1.75rem;
}

h4, .sidebar-module__h4___phWWj {
  font-size: 1.5rem;
}

h5, .sidebar-module__h5___10GQr {
  font-size: 1.25rem;
}

h6, .sidebar-module__h6___39vE_ {
  font-size: 1rem;
}

.sidebar-module__lead___1AfFk {
  font-size: 1.25rem;
  font-weight: 300;
}

.sidebar-module__display-1___2rkKV {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.sidebar-module__display-2___398Mw {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.sidebar-module__display-3___2nL1G {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.sidebar-module__display-4___1kxMz {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.sidebar-module__small___iR-F- {
  font-size: 80%;
  font-weight: 400;
}

mark,
.sidebar-module__mark___3Ax9N {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.sidebar-module__list-unstyled___3dO5R {
  padding-left: 0;
  list-style: none;
}

.sidebar-module__list-inline___1RwDn {
  padding-left: 0;
  list-style: none;
}

.sidebar-module__list-inline-item___3ktLz {
  display: inline-block;
}
.sidebar-module__list-inline-item___3ktLz:not(:last-child) {
  margin-right: 0.5rem;
}

.sidebar-module__initialism___3q2rA {
  font-size: 90%;
  text-transform: uppercase;
}

.sidebar-module__blockquote___G9rTk {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.sidebar-module__blockquote-footer___JZsLg {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.sidebar-module__blockquote-footer___JZsLg::before {
  content: "— ";
}

.sidebar-module__img-fluid___2V-bf {
  max-width: 100%;
  height: auto;
}

.sidebar-module__img-thumbnail___1KmlJ {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.sidebar-module__figure___1w1E8 {
  display: inline-block;
}

.sidebar-module__figure-img___3tSu7 {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.sidebar-module__figure-caption___2MH8Z {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.sidebar-module__pre-scrollable___2X-kP {
  max-height: 340px;
  overflow-y: scroll;
}

.sidebar-module__container___1J6db {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .sidebar-module__container___1J6db {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .sidebar-module__container___1J6db {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .sidebar-module__container___1J6db {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .sidebar-module__container___1J6db {
    max-width: 1140px;
  }
}

.sidebar-module__container-fluid___kuGz- {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.sidebar-module__row___uG0AL {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.sidebar-module__no-gutters___Ijd1v {
  margin-right: 0;
  margin-left: 0;
}
.sidebar-module__no-gutters___Ijd1v > .sidebar-module__col___1XvN8,
.sidebar-module__no-gutters___Ijd1v > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.sidebar-module__col-xl___30iGe,
.sidebar-module__col-xl-auto___1wxUT, .sidebar-module__col-xl-12___2em08, .sidebar-module__col-xl-11___22Bls, .sidebar-module__col-xl-10___3V-rp, .sidebar-module__col-xl-9___3VFqv, .sidebar-module__col-xl-8___36ExY, .sidebar-module__col-xl-7___3nO93, .sidebar-module__col-xl-6___2k-UC, .sidebar-module__col-xl-5___3KaMf, .sidebar-module__col-xl-4___2OrsO, .sidebar-module__col-xl-3___3-9mO, .sidebar-module__col-xl-2___1NWN9, .sidebar-module__col-xl-1___1AJSf, .sidebar-module__col-lg___34Pzo,
.sidebar-module__col-lg-auto___1lDDn, .sidebar-module__col-lg-12___2lmZb, .sidebar-module__col-lg-11___3FoNH, .sidebar-module__col-lg-10___1uxWW, .sidebar-module__col-lg-9___25fNh, .sidebar-module__col-lg-8___38cuo, .sidebar-module__col-lg-7___1sbI-, .sidebar-module__col-lg-6___2WTE_, .sidebar-module__col-lg-5___3kn1A, .sidebar-module__col-lg-4___3JPqE, .sidebar-module__col-lg-3___1dRkG, .sidebar-module__col-lg-2___3gYjn, .sidebar-module__col-lg-1___1BaFj, .sidebar-module__col-md___2kaD8,
.sidebar-module__col-md-auto___pokdb, .sidebar-module__col-md-12___2bynS, .sidebar-module__col-md-11___3WcOD, .sidebar-module__col-md-10___f0L2G, .sidebar-module__col-md-9___2tsbB, .sidebar-module__col-md-8___2lPyy, .sidebar-module__col-md-7___1_6vx, .sidebar-module__col-md-6___1O7Xo, .sidebar-module__col-md-5___1WltO, .sidebar-module__col-md-4___291p-, .sidebar-module__col-md-3___160fQ, .sidebar-module__col-md-2___2YX-B, .sidebar-module__col-md-1___2jBke, .sidebar-module__col-sm___3abXp,
.sidebar-module__col-sm-auto___3aZTD, .sidebar-module__col-sm-12___1B6Xq, .sidebar-module__col-sm-11___2pN1P, .sidebar-module__col-sm-10___37CMi, .sidebar-module__col-sm-9___1RMoO, .sidebar-module__col-sm-8___3vuiE, .sidebar-module__col-sm-7___1AMOz, .sidebar-module__col-sm-6___D8tht, .sidebar-module__col-sm-5___3W0ov, .sidebar-module__col-sm-4___3xEW_, .sidebar-module__col-sm-3___QJH_q, .sidebar-module__col-sm-2___2hz5Q, .sidebar-module__col-sm-1___39keY, .sidebar-module__col___1XvN8,
.sidebar-module__col-auto___X9PcP, .sidebar-module__col-12___M9C7M, .sidebar-module__col-11___3SarW, .sidebar-module__col-10___Z1B1C, .sidebar-module__col-9___1LY69, .sidebar-module__col-8___dE3zT, .sidebar-module__col-7___1kuYn, .sidebar-module__col-6___1dVFO, .sidebar-module__col-5___1LQmL, .sidebar-module__col-4___1mmVC, .sidebar-module__col-3___3Vz2y, .sidebar-module__col-2___2_ORy, .sidebar-module__col-1___lvUg2 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.sidebar-module__col___1XvN8 {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.sidebar-module__col-auto___X9PcP {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.sidebar-module__col-1___lvUg2 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.sidebar-module__col-2___2_ORy {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.sidebar-module__col-3___3Vz2y {
  flex: 0 0 25%;
  max-width: 25%;
}

.sidebar-module__col-4___1mmVC {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.sidebar-module__col-5___1LQmL {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.sidebar-module__col-6___1dVFO {
  flex: 0 0 50%;
  max-width: 50%;
}

.sidebar-module__col-7___1kuYn {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.sidebar-module__col-8___dE3zT {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.sidebar-module__col-9___1LY69 {
  flex: 0 0 75%;
  max-width: 75%;
}

.sidebar-module__col-10___Z1B1C {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.sidebar-module__col-11___3SarW {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.sidebar-module__col-12___M9C7M {
  flex: 0 0 100%;
  max-width: 100%;
}

.sidebar-module__order-first___27xfX {
  order: -1;
}

.sidebar-module__order-last___1o0Rv {
  order: 13;
}

.sidebar-module__order-0___2gPgl {
  order: 0;
}

.sidebar-module__order-1___16CEE {
  order: 1;
}

.sidebar-module__order-2___3benj {
  order: 2;
}

.sidebar-module__order-3___3sdrw {
  order: 3;
}

.sidebar-module__order-4___1M0Fy {
  order: 4;
}

.sidebar-module__order-5___3u3bD {
  order: 5;
}

.sidebar-module__order-6___zVOFv {
  order: 6;
}

.sidebar-module__order-7___3r_hN {
  order: 7;
}

.sidebar-module__order-8___N8O7Y {
  order: 8;
}

.sidebar-module__order-9___9Nfpb {
  order: 9;
}

.sidebar-module__order-10___1jiXt {
  order: 10;
}

.sidebar-module__order-11___3jKmF {
  order: 11;
}

.sidebar-module__order-12___26SiW {
  order: 12;
}

.sidebar-module__offset-1___2AMac {
  margin-left: 8.3333333333%;
}

.sidebar-module__offset-2___17OsK {
  margin-left: 16.6666666667%;
}

.sidebar-module__offset-3___GWeFD {
  margin-left: 25%;
}

.sidebar-module__offset-4___1-4cK {
  margin-left: 33.3333333333%;
}

.sidebar-module__offset-5___2h024 {
  margin-left: 41.6666666667%;
}

.sidebar-module__offset-6___EZiti {
  margin-left: 50%;
}

.sidebar-module__offset-7___3LItX {
  margin-left: 58.3333333333%;
}

.sidebar-module__offset-8___P0kbk {
  margin-left: 66.6666666667%;
}

.sidebar-module__offset-9___BfuNH {
  margin-left: 75%;
}

.sidebar-module__offset-10___2dn6A {
  margin-left: 83.3333333333%;
}

.sidebar-module__offset-11___bE1ix {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .sidebar-module__col-sm___3abXp {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .sidebar-module__col-sm-auto___3aZTD {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .sidebar-module__col-sm-1___39keY {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .sidebar-module__col-sm-2___2hz5Q {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .sidebar-module__col-sm-3___QJH_q {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sidebar-module__col-sm-4___3xEW_ {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .sidebar-module__col-sm-5___3W0ov {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .sidebar-module__col-sm-6___D8tht {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .sidebar-module__col-sm-7___1AMOz {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .sidebar-module__col-sm-8___3vuiE {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .sidebar-module__col-sm-9___1RMoO {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .sidebar-module__col-sm-10___37CMi {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .sidebar-module__col-sm-11___2pN1P {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .sidebar-module__col-sm-12___1B6Xq {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sidebar-module__order-sm-first___2ljrU {
    order: -1;
  }

  .sidebar-module__order-sm-last___13Elg {
    order: 13;
  }

  .sidebar-module__order-sm-0___2Bfdk {
    order: 0;
  }

  .sidebar-module__order-sm-1___1oggy {
    order: 1;
  }

  .sidebar-module__order-sm-2___3YEDv {
    order: 2;
  }

  .sidebar-module__order-sm-3___3oE2P {
    order: 3;
  }

  .sidebar-module__order-sm-4___8Ct4X {
    order: 4;
  }

  .sidebar-module__order-sm-5___3dg3u {
    order: 5;
  }

  .sidebar-module__order-sm-6___1rEii {
    order: 6;
  }

  .sidebar-module__order-sm-7___hBJjt {
    order: 7;
  }

  .sidebar-module__order-sm-8___2yKF0 {
    order: 8;
  }

  .sidebar-module__order-sm-9___12370 {
    order: 9;
  }

  .sidebar-module__order-sm-10___2ZYCH {
    order: 10;
  }

  .sidebar-module__order-sm-11___1BE3L {
    order: 11;
  }

  .sidebar-module__order-sm-12___1PAZD {
    order: 12;
  }

  .sidebar-module__offset-sm-0___2JuZT {
    margin-left: 0;
  }

  .sidebar-module__offset-sm-1___IIPYD {
    margin-left: 8.3333333333%;
  }

  .sidebar-module__offset-sm-2___34ewo {
    margin-left: 16.6666666667%;
  }

  .sidebar-module__offset-sm-3___S9xwV {
    margin-left: 25%;
  }

  .sidebar-module__offset-sm-4___1j5h8 {
    margin-left: 33.3333333333%;
  }

  .sidebar-module__offset-sm-5___2CATu {
    margin-left: 41.6666666667%;
  }

  .sidebar-module__offset-sm-6___2L7aV {
    margin-left: 50%;
  }

  .sidebar-module__offset-sm-7___2v3VW {
    margin-left: 58.3333333333%;
  }

  .sidebar-module__offset-sm-8___3nfVP {
    margin-left: 66.6666666667%;
  }

  .sidebar-module__offset-sm-9___sxlJz {
    margin-left: 75%;
  }

  .sidebar-module__offset-sm-10___1iV8U {
    margin-left: 83.3333333333%;
  }

  .sidebar-module__offset-sm-11___37DFN {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .sidebar-module__col-md___2kaD8 {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .sidebar-module__col-md-auto___pokdb {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .sidebar-module__col-md-1___2jBke {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .sidebar-module__col-md-2___2YX-B {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .sidebar-module__col-md-3___160fQ {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sidebar-module__col-md-4___291p- {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .sidebar-module__col-md-5___1WltO {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .sidebar-module__col-md-6___1O7Xo {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .sidebar-module__col-md-7___1_6vx {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .sidebar-module__col-md-8___2lPyy {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .sidebar-module__col-md-9___2tsbB {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .sidebar-module__col-md-10___f0L2G {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .sidebar-module__col-md-11___3WcOD {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .sidebar-module__col-md-12___2bynS {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sidebar-module__order-md-first___2TLHT {
    order: -1;
  }

  .sidebar-module__order-md-last___WxfjT {
    order: 13;
  }

  .sidebar-module__order-md-0___1gFPK {
    order: 0;
  }

  .sidebar-module__order-md-1___VoVVz {
    order: 1;
  }

  .sidebar-module__order-md-2___FZ9ps {
    order: 2;
  }

  .sidebar-module__order-md-3___3mjQV {
    order: 3;
  }

  .sidebar-module__order-md-4___2_qjy {
    order: 4;
  }

  .sidebar-module__order-md-5___3W705 {
    order: 5;
  }

  .sidebar-module__order-md-6___2YNdY {
    order: 6;
  }

  .sidebar-module__order-md-7___1efTE {
    order: 7;
  }

  .sidebar-module__order-md-8___Upmvv {
    order: 8;
  }

  .sidebar-module__order-md-9___2a_ft {
    order: 9;
  }

  .sidebar-module__order-md-10___1K9_z {
    order: 10;
  }

  .sidebar-module__order-md-11___2V3IP {
    order: 11;
  }

  .sidebar-module__order-md-12___2c_zp {
    order: 12;
  }

  .sidebar-module__offset-md-0___nLdJh {
    margin-left: 0;
  }

  .sidebar-module__offset-md-1___AARXg {
    margin-left: 8.3333333333%;
  }

  .sidebar-module__offset-md-2___bmfEU {
    margin-left: 16.6666666667%;
  }

  .sidebar-module__offset-md-3___ikvp_ {
    margin-left: 25%;
  }

  .sidebar-module__offset-md-4___2fhb3 {
    margin-left: 33.3333333333%;
  }

  .sidebar-module__offset-md-5___3daLU {
    margin-left: 41.6666666667%;
  }

  .sidebar-module__offset-md-6___sTfPF {
    margin-left: 50%;
  }

  .sidebar-module__offset-md-7___3JHoo {
    margin-left: 58.3333333333%;
  }

  .sidebar-module__offset-md-8___1hYhw {
    margin-left: 66.6666666667%;
  }

  .sidebar-module__offset-md-9___Xstx2 {
    margin-left: 75%;
  }

  .sidebar-module__offset-md-10___1Rbgo {
    margin-left: 83.3333333333%;
  }

  .sidebar-module__offset-md-11___LDgMX {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .sidebar-module__col-lg___34Pzo {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .sidebar-module__col-lg-auto___1lDDn {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .sidebar-module__col-lg-1___1BaFj {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .sidebar-module__col-lg-2___3gYjn {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .sidebar-module__col-lg-3___1dRkG {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sidebar-module__col-lg-4___3JPqE {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .sidebar-module__col-lg-5___3kn1A {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .sidebar-module__col-lg-6___2WTE_ {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .sidebar-module__col-lg-7___1sbI- {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .sidebar-module__col-lg-8___38cuo {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .sidebar-module__col-lg-9___25fNh {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .sidebar-module__col-lg-10___1uxWW {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .sidebar-module__col-lg-11___3FoNH {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .sidebar-module__col-lg-12___2lmZb {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sidebar-module__order-lg-first___1fY_x {
    order: -1;
  }

  .sidebar-module__order-lg-last___901WZ {
    order: 13;
  }

  .sidebar-module__order-lg-0___3fgCe {
    order: 0;
  }

  .sidebar-module__order-lg-1___24whT {
    order: 1;
  }

  .sidebar-module__order-lg-2___3qqML {
    order: 2;
  }

  .sidebar-module__order-lg-3___1nNaU {
    order: 3;
  }

  .sidebar-module__order-lg-4___3QbLs {
    order: 4;
  }

  .sidebar-module__order-lg-5___2IPt3 {
    order: 5;
  }

  .sidebar-module__order-lg-6___14g2A {
    order: 6;
  }

  .sidebar-module__order-lg-7___Um30Q {
    order: 7;
  }

  .sidebar-module__order-lg-8___3RgoP {
    order: 8;
  }

  .sidebar-module__order-lg-9___3D-K8 {
    order: 9;
  }

  .sidebar-module__order-lg-10___3hW4_ {
    order: 10;
  }

  .sidebar-module__order-lg-11___2lOkQ {
    order: 11;
  }

  .sidebar-module__order-lg-12___2TMfJ {
    order: 12;
  }

  .sidebar-module__offset-lg-0___3kd_0 {
    margin-left: 0;
  }

  .sidebar-module__offset-lg-1___2dPcy {
    margin-left: 8.3333333333%;
  }

  .sidebar-module__offset-lg-2___zH-v7 {
    margin-left: 16.6666666667%;
  }

  .sidebar-module__offset-lg-3___1mYw8 {
    margin-left: 25%;
  }

  .sidebar-module__offset-lg-4___13LSp {
    margin-left: 33.3333333333%;
  }

  .sidebar-module__offset-lg-5___GkrEV {
    margin-left: 41.6666666667%;
  }

  .sidebar-module__offset-lg-6___3qBAo {
    margin-left: 50%;
  }

  .sidebar-module__offset-lg-7___Luhmy {
    margin-left: 58.3333333333%;
  }

  .sidebar-module__offset-lg-8___ZppZP {
    margin-left: 66.6666666667%;
  }

  .sidebar-module__offset-lg-9___2ORQu {
    margin-left: 75%;
  }

  .sidebar-module__offset-lg-10___3MQwQ {
    margin-left: 83.3333333333%;
  }

  .sidebar-module__offset-lg-11___yRaX8 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .sidebar-module__col-xl___30iGe {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .sidebar-module__col-xl-auto___1wxUT {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .sidebar-module__col-xl-1___1AJSf {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .sidebar-module__col-xl-2___1NWN9 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .sidebar-module__col-xl-3___3-9mO {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sidebar-module__col-xl-4___2OrsO {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .sidebar-module__col-xl-5___3KaMf {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .sidebar-module__col-xl-6___2k-UC {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .sidebar-module__col-xl-7___3nO93 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .sidebar-module__col-xl-8___36ExY {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .sidebar-module__col-xl-9___3VFqv {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .sidebar-module__col-xl-10___3V-rp {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .sidebar-module__col-xl-11___22Bls {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .sidebar-module__col-xl-12___2em08 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sidebar-module__order-xl-first___2KWVc {
    order: -1;
  }

  .sidebar-module__order-xl-last___3koPf {
    order: 13;
  }

  .sidebar-module__order-xl-0___2V2W9 {
    order: 0;
  }

  .sidebar-module__order-xl-1___1TiCX {
    order: 1;
  }

  .sidebar-module__order-xl-2___3B69d {
    order: 2;
  }

  .sidebar-module__order-xl-3___5Pd8j {
    order: 3;
  }

  .sidebar-module__order-xl-4___3GPOK {
    order: 4;
  }

  .sidebar-module__order-xl-5___2vjJ- {
    order: 5;
  }

  .sidebar-module__order-xl-6___2yFHc {
    order: 6;
  }

  .sidebar-module__order-xl-7___3xZlm {
    order: 7;
  }

  .sidebar-module__order-xl-8___24N_h {
    order: 8;
  }

  .sidebar-module__order-xl-9___hnxOo {
    order: 9;
  }

  .sidebar-module__order-xl-10___33IYL {
    order: 10;
  }

  .sidebar-module__order-xl-11___3DmaR {
    order: 11;
  }

  .sidebar-module__order-xl-12____ty6E {
    order: 12;
  }

  .sidebar-module__offset-xl-0___2W1-p {
    margin-left: 0;
  }

  .sidebar-module__offset-xl-1___19-ET {
    margin-left: 8.3333333333%;
  }

  .sidebar-module__offset-xl-2___2vsmn {
    margin-left: 16.6666666667%;
  }

  .sidebar-module__offset-xl-3___-pSnW {
    margin-left: 25%;
  }

  .sidebar-module__offset-xl-4___l43vw {
    margin-left: 33.3333333333%;
  }

  .sidebar-module__offset-xl-5___ok3KG {
    margin-left: 41.6666666667%;
  }

  .sidebar-module__offset-xl-6___31DAG {
    margin-left: 50%;
  }

  .sidebar-module__offset-xl-7___3owJ3 {
    margin-left: 58.3333333333%;
  }

  .sidebar-module__offset-xl-8___3tk-d {
    margin-left: 66.6666666667%;
  }

  .sidebar-module__offset-xl-9___ZXlae {
    margin-left: 75%;
  }

  .sidebar-module__offset-xl-10___3nBrk {
    margin-left: 83.3333333333%;
  }

  .sidebar-module__offset-xl-11___3e3dq {
    margin-left: 91.6666666667%;
  }
}
.sidebar-module__table___3ayQQ {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.sidebar-module__table___3ayQQ th,
.sidebar-module__table___3ayQQ td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.sidebar-module__table___3ayQQ thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.sidebar-module__table___3ayQQ tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.sidebar-module__table-sm___1cR6X th,
.sidebar-module__table-sm___1cR6X td {
  padding: 0.3rem;
}

.sidebar-module__table-bordered___Wlwuo {
  border: 1px solid #dee2e6;
}
.sidebar-module__table-bordered___Wlwuo th,
.sidebar-module__table-bordered___Wlwuo td {
  border: 1px solid #dee2e6;
}
.sidebar-module__table-bordered___Wlwuo thead th,
.sidebar-module__table-bordered___Wlwuo thead td {
  border-bottom-width: 2px;
}

.sidebar-module__table-borderless___2uEKo th,
.sidebar-module__table-borderless___2uEKo td,
.sidebar-module__table-borderless___2uEKo thead th,
.sidebar-module__table-borderless___2uEKo tbody + tbody {
  border: 0;
}

.sidebar-module__table-striped___Mw_s8 tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.sidebar-module__table-hover___2AArh tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.sidebar-module__table-primary___1YYOS,
.sidebar-module__table-primary___1YYOS > th,
.sidebar-module__table-primary___1YYOS > td {
  background-color: #b8daff;
}
.sidebar-module__table-primary___1YYOS th,
.sidebar-module__table-primary___1YYOS td,
.sidebar-module__table-primary___1YYOS thead th,
.sidebar-module__table-primary___1YYOS tbody + tbody {
  border-color: #7abaff;
}

.sidebar-module__table-hover___2AArh .sidebar-module__table-primary___1YYOS:hover {
  background-color: #9fcdff;
}
.sidebar-module__table-hover___2AArh .sidebar-module__table-primary___1YYOS:hover > td,
.sidebar-module__table-hover___2AArh .sidebar-module__table-primary___1YYOS:hover > th {
  background-color: #9fcdff;
}

.sidebar-module__table-secondary___2mTt9,
.sidebar-module__table-secondary___2mTt9 > th,
.sidebar-module__table-secondary___2mTt9 > td {
  background-color: #d6d8db;
}
.sidebar-module__table-secondary___2mTt9 th,
.sidebar-module__table-secondary___2mTt9 td,
.sidebar-module__table-secondary___2mTt9 thead th,
.sidebar-module__table-secondary___2mTt9 tbody + tbody {
  border-color: #b3b7bb;
}

.sidebar-module__table-hover___2AArh .sidebar-module__table-secondary___2mTt9:hover {
  background-color: #c8cbcf;
}
.sidebar-module__table-hover___2AArh .sidebar-module__table-secondary___2mTt9:hover > td,
.sidebar-module__table-hover___2AArh .sidebar-module__table-secondary___2mTt9:hover > th {
  background-color: #c8cbcf;
}

.sidebar-module__table-success___HAAlP,
.sidebar-module__table-success___HAAlP > th,
.sidebar-module__table-success___HAAlP > td {
  background-color: #c3e6cb;
}
.sidebar-module__table-success___HAAlP th,
.sidebar-module__table-success___HAAlP td,
.sidebar-module__table-success___HAAlP thead th,
.sidebar-module__table-success___HAAlP tbody + tbody {
  border-color: #8fd19e;
}

.sidebar-module__table-hover___2AArh .sidebar-module__table-success___HAAlP:hover {
  background-color: #b1dfbb;
}
.sidebar-module__table-hover___2AArh .sidebar-module__table-success___HAAlP:hover > td,
.sidebar-module__table-hover___2AArh .sidebar-module__table-success___HAAlP:hover > th {
  background-color: #b1dfbb;
}

.sidebar-module__table-info___6qlKD,
.sidebar-module__table-info___6qlKD > th,
.sidebar-module__table-info___6qlKD > td {
  background-color: #bee5eb;
}
.sidebar-module__table-info___6qlKD th,
.sidebar-module__table-info___6qlKD td,
.sidebar-module__table-info___6qlKD thead th,
.sidebar-module__table-info___6qlKD tbody + tbody {
  border-color: #86cfda;
}

.sidebar-module__table-hover___2AArh .sidebar-module__table-info___6qlKD:hover {
  background-color: #abdde5;
}
.sidebar-module__table-hover___2AArh .sidebar-module__table-info___6qlKD:hover > td,
.sidebar-module__table-hover___2AArh .sidebar-module__table-info___6qlKD:hover > th {
  background-color: #abdde5;
}

.sidebar-module__table-warning___290oO,
.sidebar-module__table-warning___290oO > th,
.sidebar-module__table-warning___290oO > td {
  background-color: #ffeeba;
}
.sidebar-module__table-warning___290oO th,
.sidebar-module__table-warning___290oO td,
.sidebar-module__table-warning___290oO thead th,
.sidebar-module__table-warning___290oO tbody + tbody {
  border-color: #ffdf7e;
}

.sidebar-module__table-hover___2AArh .sidebar-module__table-warning___290oO:hover {
  background-color: #ffe8a1;
}
.sidebar-module__table-hover___2AArh .sidebar-module__table-warning___290oO:hover > td,
.sidebar-module__table-hover___2AArh .sidebar-module__table-warning___290oO:hover > th {
  background-color: #ffe8a1;
}

.sidebar-module__table-danger___nQ_mb,
.sidebar-module__table-danger___nQ_mb > th,
.sidebar-module__table-danger___nQ_mb > td {
  background-color: #f5c6cb;
}
.sidebar-module__table-danger___nQ_mb th,
.sidebar-module__table-danger___nQ_mb td,
.sidebar-module__table-danger___nQ_mb thead th,
.sidebar-module__table-danger___nQ_mb tbody + tbody {
  border-color: #ed969e;
}

.sidebar-module__table-hover___2AArh .sidebar-module__table-danger___nQ_mb:hover {
  background-color: #f1b0b7;
}
.sidebar-module__table-hover___2AArh .sidebar-module__table-danger___nQ_mb:hover > td,
.sidebar-module__table-hover___2AArh .sidebar-module__table-danger___nQ_mb:hover > th {
  background-color: #f1b0b7;
}

.sidebar-module__table-light___3WEQj,
.sidebar-module__table-light___3WEQj > th,
.sidebar-module__table-light___3WEQj > td {
  background-color: #fdfdfe;
}
.sidebar-module__table-light___3WEQj th,
.sidebar-module__table-light___3WEQj td,
.sidebar-module__table-light___3WEQj thead th,
.sidebar-module__table-light___3WEQj tbody + tbody {
  border-color: #fbfcfc;
}

.sidebar-module__table-hover___2AArh .sidebar-module__table-light___3WEQj:hover {
  background-color: #ececf6;
}
.sidebar-module__table-hover___2AArh .sidebar-module__table-light___3WEQj:hover > td,
.sidebar-module__table-hover___2AArh .sidebar-module__table-light___3WEQj:hover > th {
  background-color: #ececf6;
}

.sidebar-module__table-dark___2sfa7,
.sidebar-module__table-dark___2sfa7 > th,
.sidebar-module__table-dark___2sfa7 > td {
  background-color: #c6c8ca;
}
.sidebar-module__table-dark___2sfa7 th,
.sidebar-module__table-dark___2sfa7 td,
.sidebar-module__table-dark___2sfa7 thead th,
.sidebar-module__table-dark___2sfa7 tbody + tbody {
  border-color: #95999c;
}

.sidebar-module__table-hover___2AArh .sidebar-module__table-dark___2sfa7:hover {
  background-color: #b9bbbe;
}
.sidebar-module__table-hover___2AArh .sidebar-module__table-dark___2sfa7:hover > td,
.sidebar-module__table-hover___2AArh .sidebar-module__table-dark___2sfa7:hover > th {
  background-color: #b9bbbe;
}

.sidebar-module__table-active___2Jg0M,
.sidebar-module__table-active___2Jg0M > th,
.sidebar-module__table-active___2Jg0M > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.sidebar-module__table-hover___2AArh .sidebar-module__table-active___2Jg0M:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.sidebar-module__table-hover___2AArh .sidebar-module__table-active___2Jg0M:hover > td,
.sidebar-module__table-hover___2AArh .sidebar-module__table-active___2Jg0M:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.sidebar-module__table___3ayQQ .sidebar-module__thead-dark___2oOir th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.sidebar-module__table___3ayQQ .sidebar-module__thead-light___1rDKP th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.sidebar-module__table-dark___2sfa7 {
  color: #fff;
  background-color: #343a40;
}
.sidebar-module__table-dark___2sfa7 th,
.sidebar-module__table-dark___2sfa7 td,
.sidebar-module__table-dark___2sfa7 thead th {
  border-color: #454d55;
}
.sidebar-module__table-dark___2sfa7.sidebar-module__table-bordered___Wlwuo {
  border: 0;
}
.sidebar-module__table-dark___2sfa7.sidebar-module__table-striped___Mw_s8 tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.sidebar-module__table-dark___2sfa7.sidebar-module__table-hover___2AArh tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .sidebar-module__table-responsive-sm___2MNPO {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .sidebar-module__table-responsive-sm___2MNPO > .sidebar-module__table-bordered___Wlwuo {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .sidebar-module__table-responsive-md___gHdLO {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .sidebar-module__table-responsive-md___gHdLO > .sidebar-module__table-bordered___Wlwuo {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .sidebar-module__table-responsive-lg___2x6Ib {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .sidebar-module__table-responsive-lg___2x6Ib > .sidebar-module__table-bordered___Wlwuo {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .sidebar-module__table-responsive-xl___3o2kX {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .sidebar-module__table-responsive-xl___3o2kX > .sidebar-module__table-bordered___Wlwuo {
    border: 0;
  }
}
.sidebar-module__table-responsive___1e82z {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.sidebar-module__table-responsive___1e82z > .sidebar-module__table-bordered___Wlwuo {
  border: 0;
}

.sidebar-module__form-control___2-ndG {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__form-control___2-ndG {
    transition: none;
  }
}
.sidebar-module__form-control___2-ndG::-ms-expand {
  background-color: transparent;
  border: 0;
}
.sidebar-module__form-control___2-ndG:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.sidebar-module__form-control___2-ndG::placeholder {
  color: #6c757d;
  opacity: 1;
}
.sidebar-module__form-control___2-ndG:disabled, .sidebar-module__form-control___2-ndG[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.sidebar-module__form-control___2-ndG:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.sidebar-module__form-control-file___17V9t,
.sidebar-module__form-control-range___2wv8o {
  display: block;
  width: 100%;
}

.sidebar-module__col-form-label___JZJ0k {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.sidebar-module__col-form-label-lg___1QHaB {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.sidebar-module__col-form-label-sm___1p55Y {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.sidebar-module__form-control-plaintext___3a8cs {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.sidebar-module__form-control-plaintext___3a8cs.sidebar-module__form-control-sm___3WJr3, .sidebar-module__form-control-plaintext___3a8cs.sidebar-module__form-control-lg___3wM5p {
  padding-right: 0;
  padding-left: 0;
}

.sidebar-module__form-control-sm___3WJr3 {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.sidebar-module__form-control-lg___3wM5p {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.sidebar-module__form-control___2-ndG[size], select.sidebar-module__form-control___2-ndG[multiple] {
  height: auto;
}

textarea.sidebar-module__form-control___2-ndG {
  height: auto;
}

.sidebar-module__form-group___haN6z {
  margin-bottom: 1rem;
}

.sidebar-module__form-text___1z_zj {
  display: block;
  margin-top: 0.25rem;
}

.sidebar-module__form-row___ifBqU {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.sidebar-module__form-row___ifBqU > .sidebar-module__col___1XvN8,
.sidebar-module__form-row___ifBqU > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.sidebar-module__form-check___B8BfF {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.sidebar-module__form-check-input___2ZgF3 {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.sidebar-module__form-check-input___2ZgF3:disabled ~ .sidebar-module__form-check-label___36JoJ {
  color: #6c757d;
}

.sidebar-module__form-check-label___36JoJ {
  margin-bottom: 0;
}

.sidebar-module__form-check-inline___38TT0 {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.sidebar-module__form-check-inline___38TT0 .sidebar-module__form-check-input___2ZgF3 {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.sidebar-module__valid-feedback___2NARo {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.sidebar-module__valid-tooltip___a1NIS {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.sidebar-module__was-validated___2_ovm .sidebar-module__form-control___2-ndG:valid, .sidebar-module__form-control___2-ndG.sidebar-module__is-valid___ZiDOL {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.sidebar-module__was-validated___2_ovm .sidebar-module__form-control___2-ndG:valid:focus, .sidebar-module__form-control___2-ndG.sidebar-module__is-valid___ZiDOL:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.sidebar-module__was-validated___2_ovm .sidebar-module__form-control___2-ndG:valid ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__was-validated___2_ovm .sidebar-module__form-control___2-ndG:valid ~ .sidebar-module__valid-tooltip___a1NIS, .sidebar-module__form-control___2-ndG.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__form-control___2-ndG.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-tooltip___a1NIS {
  display: block;
}

.sidebar-module__was-validated___2_ovm textarea.sidebar-module__form-control___2-ndG:valid, textarea.sidebar-module__form-control___2-ndG.sidebar-module__is-valid___ZiDOL {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.sidebar-module__was-validated___2_ovm .sidebar-module__custom-select___W5MqO:valid, .sidebar-module__custom-select___W5MqO.sidebar-module__is-valid___ZiDOL {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-select___W5MqO:valid:focus, .sidebar-module__custom-select___W5MqO.sidebar-module__is-valid___ZiDOL:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-select___W5MqO:valid ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-select___W5MqO:valid ~ .sidebar-module__valid-tooltip___a1NIS, .sidebar-module__custom-select___W5MqO.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__custom-select___W5MqO.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-tooltip___a1NIS {
  display: block;
}

.sidebar-module__was-validated___2_ovm .sidebar-module__form-control-file___17V9t:valid ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__was-validated___2_ovm .sidebar-module__form-control-file___17V9t:valid ~ .sidebar-module__valid-tooltip___a1NIS, .sidebar-module__form-control-file___17V9t.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__form-control-file___17V9t.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-tooltip___a1NIS {
  display: block;
}

.sidebar-module__was-validated___2_ovm .sidebar-module__form-check-input___2ZgF3:valid ~ .sidebar-module__form-check-label___36JoJ, .sidebar-module__form-check-input___2ZgF3.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__form-check-label___36JoJ {
  color: #28a745;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__form-check-input___2ZgF3:valid ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__was-validated___2_ovm .sidebar-module__form-check-input___2ZgF3:valid ~ .sidebar-module__valid-tooltip___a1NIS, .sidebar-module__form-check-input___2ZgF3.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__form-check-input___2ZgF3.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-tooltip___a1NIS {
  display: block;
}

.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:valid ~ .sidebar-module__custom-control-label___28Za9, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__custom-control-label___28Za9 {
  color: #28a745;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:valid ~ .sidebar-module__custom-control-label___28Za9::before, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__custom-control-label___28Za9::before {
  border-color: #28a745;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:valid ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:valid ~ .sidebar-module__valid-tooltip___a1NIS, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__custom-control-input___1QZEE.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-tooltip___a1NIS {
  display: block;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:valid:checked ~ .sidebar-module__custom-control-label___28Za9::before, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-valid___ZiDOL:checked ~ .sidebar-module__custom-control-label___28Za9::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:valid:focus ~ .sidebar-module__custom-control-label___28Za9::before, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-valid___ZiDOL:focus ~ .sidebar-module__custom-control-label___28Za9::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:valid:focus:not(:checked) ~ .sidebar-module__custom-control-label___28Za9::before, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-valid___ZiDOL:focus:not(:checked) ~ .sidebar-module__custom-control-label___28Za9::before {
  border-color: #28a745;
}

.sidebar-module__was-validated___2_ovm .sidebar-module__custom-file-input___1yKjP:valid ~ .sidebar-module__custom-file-label___96nTh, .sidebar-module__custom-file-input___1yKjP.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__custom-file-label___96nTh {
  border-color: #28a745;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-file-input___1yKjP:valid ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-file-input___1yKjP:valid ~ .sidebar-module__valid-tooltip___a1NIS, .sidebar-module__custom-file-input___1yKjP.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-feedback___2NARo,
.sidebar-module__custom-file-input___1yKjP.sidebar-module__is-valid___ZiDOL ~ .sidebar-module__valid-tooltip___a1NIS {
  display: block;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-file-input___1yKjP:valid:focus ~ .sidebar-module__custom-file-label___96nTh, .sidebar-module__custom-file-input___1yKjP.sidebar-module__is-valid___ZiDOL:focus ~ .sidebar-module__custom-file-label___96nTh {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.sidebar-module__invalid-feedback___129AG {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.sidebar-module__invalid-tooltip___2pDhy {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.sidebar-module__was-validated___2_ovm .sidebar-module__form-control___2-ndG:invalid, .sidebar-module__form-control___2-ndG.sidebar-module__is-invalid___3uGay {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.sidebar-module__was-validated___2_ovm .sidebar-module__form-control___2-ndG:invalid:focus, .sidebar-module__form-control___2-ndG.sidebar-module__is-invalid___3uGay:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.sidebar-module__was-validated___2_ovm .sidebar-module__form-control___2-ndG:invalid ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__was-validated___2_ovm .sidebar-module__form-control___2-ndG:invalid ~ .sidebar-module__invalid-tooltip___2pDhy, .sidebar-module__form-control___2-ndG.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__form-control___2-ndG.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-tooltip___2pDhy {
  display: block;
}

.sidebar-module__was-validated___2_ovm textarea.sidebar-module__form-control___2-ndG:invalid, textarea.sidebar-module__form-control___2-ndG.sidebar-module__is-invalid___3uGay {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.sidebar-module__was-validated___2_ovm .sidebar-module__custom-select___W5MqO:invalid, .sidebar-module__custom-select___W5MqO.sidebar-module__is-invalid___3uGay {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-select___W5MqO:invalid:focus, .sidebar-module__custom-select___W5MqO.sidebar-module__is-invalid___3uGay:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-select___W5MqO:invalid ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-select___W5MqO:invalid ~ .sidebar-module__invalid-tooltip___2pDhy, .sidebar-module__custom-select___W5MqO.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__custom-select___W5MqO.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-tooltip___2pDhy {
  display: block;
}

.sidebar-module__was-validated___2_ovm .sidebar-module__form-control-file___17V9t:invalid ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__was-validated___2_ovm .sidebar-module__form-control-file___17V9t:invalid ~ .sidebar-module__invalid-tooltip___2pDhy, .sidebar-module__form-control-file___17V9t.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__form-control-file___17V9t.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-tooltip___2pDhy {
  display: block;
}

.sidebar-module__was-validated___2_ovm .sidebar-module__form-check-input___2ZgF3:invalid ~ .sidebar-module__form-check-label___36JoJ, .sidebar-module__form-check-input___2ZgF3.sidebar-module__is-invalid___3uGay ~ .sidebar-module__form-check-label___36JoJ {
  color: #dc3545;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__form-check-input___2ZgF3:invalid ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__was-validated___2_ovm .sidebar-module__form-check-input___2ZgF3:invalid ~ .sidebar-module__invalid-tooltip___2pDhy, .sidebar-module__form-check-input___2ZgF3.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__form-check-input___2ZgF3.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-tooltip___2pDhy {
  display: block;
}

.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:invalid ~ .sidebar-module__custom-control-label___28Za9, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-invalid___3uGay ~ .sidebar-module__custom-control-label___28Za9 {
  color: #dc3545;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:invalid ~ .sidebar-module__custom-control-label___28Za9::before, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-invalid___3uGay ~ .sidebar-module__custom-control-label___28Za9::before {
  border-color: #dc3545;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:invalid ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:invalid ~ .sidebar-module__invalid-tooltip___2pDhy, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__custom-control-input___1QZEE.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-tooltip___2pDhy {
  display: block;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:invalid:checked ~ .sidebar-module__custom-control-label___28Za9::before, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-invalid___3uGay:checked ~ .sidebar-module__custom-control-label___28Za9::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:invalid:focus ~ .sidebar-module__custom-control-label___28Za9::before, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-invalid___3uGay:focus ~ .sidebar-module__custom-control-label___28Za9::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-control-input___1QZEE:invalid:focus:not(:checked) ~ .sidebar-module__custom-control-label___28Za9::before, .sidebar-module__custom-control-input___1QZEE.sidebar-module__is-invalid___3uGay:focus:not(:checked) ~ .sidebar-module__custom-control-label___28Za9::before {
  border-color: #dc3545;
}

.sidebar-module__was-validated___2_ovm .sidebar-module__custom-file-input___1yKjP:invalid ~ .sidebar-module__custom-file-label___96nTh, .sidebar-module__custom-file-input___1yKjP.sidebar-module__is-invalid___3uGay ~ .sidebar-module__custom-file-label___96nTh {
  border-color: #dc3545;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-file-input___1yKjP:invalid ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-file-input___1yKjP:invalid ~ .sidebar-module__invalid-tooltip___2pDhy, .sidebar-module__custom-file-input___1yKjP.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-feedback___129AG,
.sidebar-module__custom-file-input___1yKjP.sidebar-module__is-invalid___3uGay ~ .sidebar-module__invalid-tooltip___2pDhy {
  display: block;
}
.sidebar-module__was-validated___2_ovm .sidebar-module__custom-file-input___1yKjP:invalid:focus ~ .sidebar-module__custom-file-label___96nTh, .sidebar-module__custom-file-input___1yKjP.sidebar-module__is-invalid___3uGay:focus ~ .sidebar-module__custom-file-label___96nTh {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.sidebar-module__form-inline___JWgZD {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.sidebar-module__form-inline___JWgZD .sidebar-module__form-check___B8BfF {
  width: 100%;
}
@media (min-width: 576px) {
  .sidebar-module__form-inline___JWgZD label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .sidebar-module__form-inline___JWgZD .sidebar-module__form-group___haN6z {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .sidebar-module__form-inline___JWgZD .sidebar-module__form-control___2-ndG {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .sidebar-module__form-inline___JWgZD .sidebar-module__form-control-plaintext___3a8cs {
    display: inline-block;
  }
  .sidebar-module__form-inline___JWgZD .sidebar-module__input-group___1Q5Ix,
.sidebar-module__form-inline___JWgZD .sidebar-module__custom-select___W5MqO {
    width: auto;
  }
  .sidebar-module__form-inline___JWgZD .sidebar-module__form-check___B8BfF {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .sidebar-module__form-inline___JWgZD .sidebar-module__form-check-input___2ZgF3 {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .sidebar-module__form-inline___JWgZD .sidebar-module__custom-control___2oURW {
    align-items: center;
    justify-content: center;
  }
  .sidebar-module__form-inline___JWgZD .sidebar-module__custom-control-label___28Za9 {
    margin-bottom: 0;
  }
}

.sidebar-module__btn___1PSTB {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__btn___1PSTB {
    transition: none;
  }
}
.sidebar-module__btn___1PSTB:hover {
  color: #212529;
  text-decoration: none;
}
.sidebar-module__btn___1PSTB:focus, .sidebar-module__btn___1PSTB.sidebar-module__focus___2xn2F {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.sidebar-module__btn___1PSTB.sidebar-module__disabled___m8NWA, .sidebar-module__btn___1PSTB:disabled {
  opacity: 0.65;
}
a.sidebar-module__btn___1PSTB.sidebar-module__disabled___m8NWA,
fieldset:disabled a.sidebar-module__btn___1PSTB {
  pointer-events: none;
}

.sidebar-module__btn-primary___3EosG {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.sidebar-module__btn-primary___3EosG:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.sidebar-module__btn-primary___3EosG:focus, .sidebar-module__btn-primary___3EosG.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.sidebar-module__btn-primary___3EosG.sidebar-module__disabled___m8NWA, .sidebar-module__btn-primary___3EosG:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.sidebar-module__btn-primary___3EosG:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-primary___3EosG:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-primary___3EosG.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.sidebar-module__btn-primary___3EosG:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-primary___3EosG:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-primary___3EosG.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.sidebar-module__btn-secondary___3kD-i {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.sidebar-module__btn-secondary___3kD-i:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.sidebar-module__btn-secondary___3kD-i:focus, .sidebar-module__btn-secondary___3kD-i.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.sidebar-module__btn-secondary___3kD-i.sidebar-module__disabled___m8NWA, .sidebar-module__btn-secondary___3kD-i:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.sidebar-module__btn-secondary___3kD-i:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-secondary___3kD-i:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-secondary___3kD-i.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.sidebar-module__btn-secondary___3kD-i:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-secondary___3kD-i:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-secondary___3kD-i.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.sidebar-module__btn-success___3N5Zz {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.sidebar-module__btn-success___3N5Zz:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.sidebar-module__btn-success___3N5Zz:focus, .sidebar-module__btn-success___3N5Zz.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.sidebar-module__btn-success___3N5Zz.sidebar-module__disabled___m8NWA, .sidebar-module__btn-success___3N5Zz:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.sidebar-module__btn-success___3N5Zz:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-success___3N5Zz:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-success___3N5Zz.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.sidebar-module__btn-success___3N5Zz:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-success___3N5Zz:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-success___3N5Zz.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.sidebar-module__btn-info___1E5Fp {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.sidebar-module__btn-info___1E5Fp:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.sidebar-module__btn-info___1E5Fp:focus, .sidebar-module__btn-info___1E5Fp.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.sidebar-module__btn-info___1E5Fp.sidebar-module__disabled___m8NWA, .sidebar-module__btn-info___1E5Fp:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.sidebar-module__btn-info___1E5Fp:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-info___1E5Fp:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-info___1E5Fp.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.sidebar-module__btn-info___1E5Fp:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-info___1E5Fp:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-info___1E5Fp.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.sidebar-module__btn-warning___1sqyA {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.sidebar-module__btn-warning___1sqyA:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.sidebar-module__btn-warning___1sqyA:focus, .sidebar-module__btn-warning___1sqyA.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.sidebar-module__btn-warning___1sqyA.sidebar-module__disabled___m8NWA, .sidebar-module__btn-warning___1sqyA:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.sidebar-module__btn-warning___1sqyA:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-warning___1sqyA:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-warning___1sqyA.sidebar-module__dropdown-toggle___3-b52 {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.sidebar-module__btn-warning___1sqyA:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-warning___1sqyA:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-warning___1sqyA.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.sidebar-module__btn-danger___U-PgK {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.sidebar-module__btn-danger___U-PgK:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.sidebar-module__btn-danger___U-PgK:focus, .sidebar-module__btn-danger___U-PgK.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.sidebar-module__btn-danger___U-PgK.sidebar-module__disabled___m8NWA, .sidebar-module__btn-danger___U-PgK:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.sidebar-module__btn-danger___U-PgK:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-danger___U-PgK:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-danger___U-PgK.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.sidebar-module__btn-danger___U-PgK:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-danger___U-PgK:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-danger___U-PgK.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.sidebar-module__btn-light___Sv1a4 {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.sidebar-module__btn-light___Sv1a4:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.sidebar-module__btn-light___Sv1a4:focus, .sidebar-module__btn-light___Sv1a4.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.sidebar-module__btn-light___Sv1a4.sidebar-module__disabled___m8NWA, .sidebar-module__btn-light___Sv1a4:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.sidebar-module__btn-light___Sv1a4:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-light___Sv1a4:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-light___Sv1a4.sidebar-module__dropdown-toggle___3-b52 {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.sidebar-module__btn-light___Sv1a4:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-light___Sv1a4:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-light___Sv1a4.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.sidebar-module__btn-dark___3yvpm {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.sidebar-module__btn-dark___3yvpm:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.sidebar-module__btn-dark___3yvpm:focus, .sidebar-module__btn-dark___3yvpm.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.sidebar-module__btn-dark___3yvpm.sidebar-module__disabled___m8NWA, .sidebar-module__btn-dark___3yvpm:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.sidebar-module__btn-dark___3yvpm:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-dark___3yvpm:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-dark___3yvpm.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.sidebar-module__btn-dark___3yvpm:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-dark___3yvpm:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-dark___3yvpm.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.sidebar-module__btn-outline-primary___3_qpd {
  color: #007bff;
  border-color: #007bff;
}
.sidebar-module__btn-outline-primary___3_qpd:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.sidebar-module__btn-outline-primary___3_qpd:focus, .sidebar-module__btn-outline-primary___3_qpd.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.sidebar-module__btn-outline-primary___3_qpd.sidebar-module__disabled___m8NWA, .sidebar-module__btn-outline-primary___3_qpd:disabled {
  color: #007bff;
  background-color: transparent;
}
.sidebar-module__btn-outline-primary___3_qpd:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-outline-primary___3_qpd:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-primary___3_qpd.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.sidebar-module__btn-outline-primary___3_qpd:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-outline-primary___3_qpd:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-primary___3_qpd.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.sidebar-module__btn-outline-secondary___103fE {
  color: #6c757d;
  border-color: #6c757d;
}
.sidebar-module__btn-outline-secondary___103fE:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.sidebar-module__btn-outline-secondary___103fE:focus, .sidebar-module__btn-outline-secondary___103fE.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.sidebar-module__btn-outline-secondary___103fE.sidebar-module__disabled___m8NWA, .sidebar-module__btn-outline-secondary___103fE:disabled {
  color: #6c757d;
  background-color: transparent;
}
.sidebar-module__btn-outline-secondary___103fE:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-outline-secondary___103fE:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-secondary___103fE.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.sidebar-module__btn-outline-secondary___103fE:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-outline-secondary___103fE:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-secondary___103fE.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.sidebar-module__btn-outline-success___2acrM {
  color: #28a745;
  border-color: #28a745;
}
.sidebar-module__btn-outline-success___2acrM:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.sidebar-module__btn-outline-success___2acrM:focus, .sidebar-module__btn-outline-success___2acrM.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.sidebar-module__btn-outline-success___2acrM.sidebar-module__disabled___m8NWA, .sidebar-module__btn-outline-success___2acrM:disabled {
  color: #28a745;
  background-color: transparent;
}
.sidebar-module__btn-outline-success___2acrM:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-outline-success___2acrM:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-success___2acrM.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.sidebar-module__btn-outline-success___2acrM:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-outline-success___2acrM:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-success___2acrM.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.sidebar-module__btn-outline-info___1utJx {
  color: #17a2b8;
  border-color: #17a2b8;
}
.sidebar-module__btn-outline-info___1utJx:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.sidebar-module__btn-outline-info___1utJx:focus, .sidebar-module__btn-outline-info___1utJx.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.sidebar-module__btn-outline-info___1utJx.sidebar-module__disabled___m8NWA, .sidebar-module__btn-outline-info___1utJx:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.sidebar-module__btn-outline-info___1utJx:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-outline-info___1utJx:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-info___1utJx.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.sidebar-module__btn-outline-info___1utJx:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-outline-info___1utJx:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-info___1utJx.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.sidebar-module__btn-outline-warning___1ZStZ {
  color: #ffc107;
  border-color: #ffc107;
}
.sidebar-module__btn-outline-warning___1ZStZ:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.sidebar-module__btn-outline-warning___1ZStZ:focus, .sidebar-module__btn-outline-warning___1ZStZ.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.sidebar-module__btn-outline-warning___1ZStZ.sidebar-module__disabled___m8NWA, .sidebar-module__btn-outline-warning___1ZStZ:disabled {
  color: #ffc107;
  background-color: transparent;
}
.sidebar-module__btn-outline-warning___1ZStZ:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-outline-warning___1ZStZ:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-warning___1ZStZ.sidebar-module__dropdown-toggle___3-b52 {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.sidebar-module__btn-outline-warning___1ZStZ:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-outline-warning___1ZStZ:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-warning___1ZStZ.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.sidebar-module__btn-outline-danger___v5Xzr {
  color: #dc3545;
  border-color: #dc3545;
}
.sidebar-module__btn-outline-danger___v5Xzr:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.sidebar-module__btn-outline-danger___v5Xzr:focus, .sidebar-module__btn-outline-danger___v5Xzr.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.sidebar-module__btn-outline-danger___v5Xzr.sidebar-module__disabled___m8NWA, .sidebar-module__btn-outline-danger___v5Xzr:disabled {
  color: #dc3545;
  background-color: transparent;
}
.sidebar-module__btn-outline-danger___v5Xzr:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-outline-danger___v5Xzr:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-danger___v5Xzr.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.sidebar-module__btn-outline-danger___v5Xzr:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-outline-danger___v5Xzr:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-danger___v5Xzr.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.sidebar-module__btn-outline-light___3mXfh {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.sidebar-module__btn-outline-light___3mXfh:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.sidebar-module__btn-outline-light___3mXfh:focus, .sidebar-module__btn-outline-light___3mXfh.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.sidebar-module__btn-outline-light___3mXfh.sidebar-module__disabled___m8NWA, .sidebar-module__btn-outline-light___3mXfh:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.sidebar-module__btn-outline-light___3mXfh:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-outline-light___3mXfh:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-light___3mXfh.sidebar-module__dropdown-toggle___3-b52 {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.sidebar-module__btn-outline-light___3mXfh:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-outline-light___3mXfh:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-light___3mXfh.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.sidebar-module__btn-outline-dark___2tmp3 {
  color: #343a40;
  border-color: #343a40;
}
.sidebar-module__btn-outline-dark___2tmp3:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.sidebar-module__btn-outline-dark___2tmp3:focus, .sidebar-module__btn-outline-dark___2tmp3.sidebar-module__focus___2xn2F {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.sidebar-module__btn-outline-dark___2tmp3.sidebar-module__disabled___m8NWA, .sidebar-module__btn-outline-dark___2tmp3:disabled {
  color: #343a40;
  background-color: transparent;
}
.sidebar-module__btn-outline-dark___2tmp3:not(:disabled):not(.sidebar-module__disabled___m8NWA):active, .sidebar-module__btn-outline-dark___2tmp3:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-dark___2tmp3.sidebar-module__dropdown-toggle___3-b52 {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.sidebar-module__btn-outline-dark___2tmp3:not(:disabled):not(.sidebar-module__disabled___m8NWA):active:focus, .sidebar-module__btn-outline-dark___2tmp3:not(:disabled):not(.sidebar-module__disabled___m8NWA).sidebar-module__active___1kKQT:focus, .sidebar-module__show___1jqoA > .sidebar-module__btn-outline-dark___2tmp3.sidebar-module__dropdown-toggle___3-b52:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.sidebar-module__btn-link___3r5vU {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.sidebar-module__btn-link___3r5vU:hover {
  color: #0056b3;
  text-decoration: underline;
}
.sidebar-module__btn-link___3r5vU:focus, .sidebar-module__btn-link___3r5vU.sidebar-module__focus___2xn2F {
  text-decoration: underline;
  box-shadow: none;
}
.sidebar-module__btn-link___3r5vU:disabled, .sidebar-module__btn-link___3r5vU.sidebar-module__disabled___m8NWA {
  color: #6c757d;
  pointer-events: none;
}

.sidebar-module__btn-lg___6LETx, .sidebar-module__btn-group-lg___wFPDV > .sidebar-module__btn___1PSTB {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.sidebar-module__btn-sm___3IgpT, .sidebar-module__btn-group-sm___1ae45 > .sidebar-module__btn___1PSTB {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.sidebar-module__btn-block___tjhaw {
  display: block;
  width: 100%;
}
.sidebar-module__btn-block___tjhaw + .sidebar-module__btn-block___tjhaw {
  margin-top: 0.5rem;
}

input[type=submit].sidebar-module__btn-block___tjhaw,
input[type=reset].sidebar-module__btn-block___tjhaw,
input[type=button].sidebar-module__btn-block___tjhaw {
  width: 100%;
}

.sidebar-module__fade___2Ilxx {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__fade___2Ilxx {
    transition: none;
  }
}
.sidebar-module__fade___2Ilxx:not(.sidebar-module__show___1jqoA) {
  opacity: 0;
}

.sidebar-module__collapse___3r4Pr:not(.sidebar-module__show___1jqoA) {
  display: none;
}

.sidebar-module__collapsing___24kc8 {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__collapsing___24kc8 {
    transition: none;
  }
}

.sidebar-module__dropup___1uzCj,
.sidebar-module__dropright___3G2un,
.sidebar-module__dropdown___GBzg-,
.sidebar-module__dropleft___R9HRc {
  position: relative;
}

.sidebar-module__dropdown-toggle___3-b52 {
  white-space: nowrap;
}
.sidebar-module__dropdown-toggle___3-b52::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.sidebar-module__dropdown-toggle___3-b52:empty::after {
  margin-left: 0;
}

.sidebar-module__dropdown-menu___LJmme {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.sidebar-module__dropdown-menu-left___hTC5_ {
  right: auto;
  left: 0;
}

.sidebar-module__dropdown-menu-right___ggD4E {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .sidebar-module__dropdown-menu-sm-left___30Qrw {
    right: auto;
    left: 0;
  }

  .sidebar-module__dropdown-menu-sm-right___3x6XN {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .sidebar-module__dropdown-menu-md-left___2AfeE {
    right: auto;
    left: 0;
  }

  .sidebar-module__dropdown-menu-md-right___2WzFh {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .sidebar-module__dropdown-menu-lg-left___39KEC {
    right: auto;
    left: 0;
  }

  .sidebar-module__dropdown-menu-lg-right___OQ3cj {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .sidebar-module__dropdown-menu-xl-left___3yFJK {
    right: auto;
    left: 0;
  }

  .sidebar-module__dropdown-menu-xl-right___1ZMGO {
    right: 0;
    left: auto;
  }
}
.sidebar-module__dropup___1uzCj .sidebar-module__dropdown-menu___LJmme {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.sidebar-module__dropup___1uzCj .sidebar-module__dropdown-toggle___3-b52::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.sidebar-module__dropup___1uzCj .sidebar-module__dropdown-toggle___3-b52:empty::after {
  margin-left: 0;
}

.sidebar-module__dropright___3G2un .sidebar-module__dropdown-menu___LJmme {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.sidebar-module__dropright___3G2un .sidebar-module__dropdown-toggle___3-b52::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.sidebar-module__dropright___3G2un .sidebar-module__dropdown-toggle___3-b52:empty::after {
  margin-left: 0;
}
.sidebar-module__dropright___3G2un .sidebar-module__dropdown-toggle___3-b52::after {
  vertical-align: 0;
}

.sidebar-module__dropleft___R9HRc .sidebar-module__dropdown-menu___LJmme {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.sidebar-module__dropleft___R9HRc .sidebar-module__dropdown-toggle___3-b52::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.sidebar-module__dropleft___R9HRc .sidebar-module__dropdown-toggle___3-b52::after {
  display: none;
}
.sidebar-module__dropleft___R9HRc .sidebar-module__dropdown-toggle___3-b52::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.sidebar-module__dropleft___R9HRc .sidebar-module__dropdown-toggle___3-b52:empty::after {
  margin-left: 0;
}
.sidebar-module__dropleft___R9HRc .sidebar-module__dropdown-toggle___3-b52::before {
  vertical-align: 0;
}

.sidebar-module__dropdown-menu___LJmme[x-placement^=top], .sidebar-module__dropdown-menu___LJmme[x-placement^=right], .sidebar-module__dropdown-menu___LJmme[x-placement^=bottom], .sidebar-module__dropdown-menu___LJmme[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.sidebar-module__dropdown-divider___1B2Y_ {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.sidebar-module__dropdown-item___1nB3M {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.sidebar-module__dropdown-item___1nB3M:hover, .sidebar-module__dropdown-item___1nB3M:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.sidebar-module__dropdown-item___1nB3M.sidebar-module__active___1kKQT, .sidebar-module__dropdown-item___1nB3M:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.sidebar-module__dropdown-item___1nB3M.sidebar-module__disabled___m8NWA, .sidebar-module__dropdown-item___1nB3M:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.sidebar-module__dropdown-menu___LJmme.sidebar-module__show___1jqoA {
  display: block;
}

.sidebar-module__dropdown-header___1N9aH {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.sidebar-module__dropdown-item-text___2kddZ {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.sidebar-module__btn-group___2FP_m,
.sidebar-module__btn-group-vertical___1BdSJ {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.sidebar-module__btn-group___2FP_m > .sidebar-module__btn___1PSTB,
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn___1PSTB {
  position: relative;
  flex: 1 1 auto;
}
.sidebar-module__btn-group___2FP_m > .sidebar-module__btn___1PSTB:hover,
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn___1PSTB:hover {
  z-index: 1;
}
.sidebar-module__btn-group___2FP_m > .sidebar-module__btn___1PSTB:focus, .sidebar-module__btn-group___2FP_m > .sidebar-module__btn___1PSTB:active, .sidebar-module__btn-group___2FP_m > .sidebar-module__btn___1PSTB.sidebar-module__active___1kKQT,
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn___1PSTB:focus,
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn___1PSTB:active,
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn___1PSTB.sidebar-module__active___1kKQT {
  z-index: 1;
}

.sidebar-module__btn-toolbar___3IxIb {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.sidebar-module__btn-toolbar___3IxIb .sidebar-module__input-group___1Q5Ix {
  width: auto;
}

.sidebar-module__btn-group___2FP_m > .sidebar-module__btn___1PSTB:not(:first-child),
.sidebar-module__btn-group___2FP_m > .sidebar-module__btn-group___2FP_m:not(:first-child) {
  margin-left: -1px;
}
.sidebar-module__btn-group___2FP_m > .sidebar-module__btn___1PSTB:not(:last-child):not(.sidebar-module__dropdown-toggle___3-b52),
.sidebar-module__btn-group___2FP_m > .sidebar-module__btn-group___2FP_m:not(:last-child) > .sidebar-module__btn___1PSTB {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.sidebar-module__btn-group___2FP_m > .sidebar-module__btn___1PSTB:not(:first-child),
.sidebar-module__btn-group___2FP_m > .sidebar-module__btn-group___2FP_m:not(:first-child) > .sidebar-module__btn___1PSTB {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.sidebar-module__dropdown-toggle-split___2fn_k {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.sidebar-module__dropdown-toggle-split___2fn_k::after, .sidebar-module__dropup___1uzCj .sidebar-module__dropdown-toggle-split___2fn_k::after, .sidebar-module__dropright___3G2un .sidebar-module__dropdown-toggle-split___2fn_k::after {
  margin-left: 0;
}
.sidebar-module__dropleft___R9HRc .sidebar-module__dropdown-toggle-split___2fn_k::before {
  margin-right: 0;
}

.sidebar-module__btn-sm___3IgpT + .sidebar-module__dropdown-toggle-split___2fn_k, .sidebar-module__btn-group-sm___1ae45 > .sidebar-module__btn___1PSTB + .sidebar-module__dropdown-toggle-split___2fn_k {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.sidebar-module__btn-lg___6LETx + .sidebar-module__dropdown-toggle-split___2fn_k, .sidebar-module__btn-group-lg___wFPDV > .sidebar-module__btn___1PSTB + .sidebar-module__dropdown-toggle-split___2fn_k {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.sidebar-module__btn-group-vertical___1BdSJ {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn___1PSTB,
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn-group___2FP_m {
  width: 100%;
}
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn___1PSTB:not(:first-child),
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn-group___2FP_m:not(:first-child) {
  margin-top: -1px;
}
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn___1PSTB:not(:last-child):not(.sidebar-module__dropdown-toggle___3-b52),
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn-group___2FP_m:not(:last-child) > .sidebar-module__btn___1PSTB {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn___1PSTB:not(:first-child),
.sidebar-module__btn-group-vertical___1BdSJ > .sidebar-module__btn-group___2FP_m:not(:first-child) > .sidebar-module__btn___1PSTB {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.sidebar-module__btn-group-toggle___1r7Uf > .sidebar-module__btn___1PSTB,
.sidebar-module__btn-group-toggle___1r7Uf > .sidebar-module__btn-group___2FP_m > .sidebar-module__btn___1PSTB {
  margin-bottom: 0;
}
.sidebar-module__btn-group-toggle___1r7Uf > .sidebar-module__btn___1PSTB input[type=radio],
.sidebar-module__btn-group-toggle___1r7Uf > .sidebar-module__btn___1PSTB input[type=checkbox],
.sidebar-module__btn-group-toggle___1r7Uf > .sidebar-module__btn-group___2FP_m > .sidebar-module__btn___1PSTB input[type=radio],
.sidebar-module__btn-group-toggle___1r7Uf > .sidebar-module__btn-group___2FP_m > .sidebar-module__btn___1PSTB input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.sidebar-module__input-group___1Q5Ix {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.sidebar-module__input-group___1Q5Ix > .sidebar-module__form-control___2-ndG,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__form-control-plaintext___3a8cs,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-select___W5MqO,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-file___189Zr {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.sidebar-module__input-group___1Q5Ix > .sidebar-module__form-control___2-ndG + .sidebar-module__form-control___2-ndG,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__form-control___2-ndG + .sidebar-module__custom-select___W5MqO,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__form-control___2-ndG + .sidebar-module__custom-file___189Zr,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__form-control-plaintext___3a8cs + .sidebar-module__form-control___2-ndG,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__form-control-plaintext___3a8cs + .sidebar-module__custom-select___W5MqO,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__form-control-plaintext___3a8cs + .sidebar-module__custom-file___189Zr,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-select___W5MqO + .sidebar-module__form-control___2-ndG,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-select___W5MqO + .sidebar-module__custom-select___W5MqO,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-select___W5MqO + .sidebar-module__custom-file___189Zr,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-file___189Zr + .sidebar-module__form-control___2-ndG,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-file___189Zr + .sidebar-module__custom-select___W5MqO,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-file___189Zr + .sidebar-module__custom-file___189Zr {
  margin-left: -1px;
}
.sidebar-module__input-group___1Q5Ix > .sidebar-module__form-control___2-ndG:focus,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-select___W5MqO:focus,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-file___189Zr .sidebar-module__custom-file-input___1yKjP:focus ~ .sidebar-module__custom-file-label___96nTh {
  z-index: 3;
}
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-file___189Zr .sidebar-module__custom-file-input___1yKjP:focus {
  z-index: 4;
}
.sidebar-module__input-group___1Q5Ix > .sidebar-module__form-control___2-ndG:not(:last-child),
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-select___W5MqO:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.sidebar-module__input-group___1Q5Ix > .sidebar-module__form-control___2-ndG:not(:first-child),
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-select___W5MqO:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-file___189Zr {
  display: flex;
  align-items: center;
}
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-file___189Zr:not(:last-child) .sidebar-module__custom-file-label___96nTh, .sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-file___189Zr:not(:last-child) .sidebar-module__custom-file-label___96nTh::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.sidebar-module__input-group___1Q5Ix > .sidebar-module__custom-file___189Zr:not(:first-child) .sidebar-module__custom-file-label___96nTh {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.sidebar-module__input-group-prepend___gEp1B,
.sidebar-module__input-group-append___P3CZI {
  display: flex;
}
.sidebar-module__input-group-prepend___gEp1B .sidebar-module__btn___1PSTB,
.sidebar-module__input-group-append___P3CZI .sidebar-module__btn___1PSTB {
  position: relative;
  z-index: 2;
}
.sidebar-module__input-group-prepend___gEp1B .sidebar-module__btn___1PSTB:focus,
.sidebar-module__input-group-append___P3CZI .sidebar-module__btn___1PSTB:focus {
  z-index: 3;
}
.sidebar-module__input-group-prepend___gEp1B .sidebar-module__btn___1PSTB + .sidebar-module__btn___1PSTB,
.sidebar-module__input-group-prepend___gEp1B .sidebar-module__btn___1PSTB + .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group-prepend___gEp1B .sidebar-module__input-group-text___ubwqi + .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group-prepend___gEp1B .sidebar-module__input-group-text___ubwqi + .sidebar-module__btn___1PSTB,
.sidebar-module__input-group-append___P3CZI .sidebar-module__btn___1PSTB + .sidebar-module__btn___1PSTB,
.sidebar-module__input-group-append___P3CZI .sidebar-module__btn___1PSTB + .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group-append___P3CZI .sidebar-module__input-group-text___ubwqi + .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group-append___P3CZI .sidebar-module__input-group-text___ubwqi + .sidebar-module__btn___1PSTB {
  margin-left: -1px;
}

.sidebar-module__input-group-prepend___gEp1B {
  margin-right: -1px;
}

.sidebar-module__input-group-append___P3CZI {
  margin-left: -1px;
}

.sidebar-module__input-group-text___ubwqi {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.sidebar-module__input-group-text___ubwqi input[type=radio],
.sidebar-module__input-group-text___ubwqi input[type=checkbox] {
  margin-top: 0;
}

.sidebar-module__input-group-lg___1f6Yu > .sidebar-module__form-control___2-ndG:not(textarea),
.sidebar-module__input-group-lg___1f6Yu > .sidebar-module__custom-select___W5MqO {
  height: calc(1.5em + 1rem + 2px);
}

.sidebar-module__input-group-lg___1f6Yu > .sidebar-module__form-control___2-ndG,
.sidebar-module__input-group-lg___1f6Yu > .sidebar-module__custom-select___W5MqO,
.sidebar-module__input-group-lg___1f6Yu > .sidebar-module__input-group-prepend___gEp1B > .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group-lg___1f6Yu > .sidebar-module__input-group-append___P3CZI > .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group-lg___1f6Yu > .sidebar-module__input-group-prepend___gEp1B > .sidebar-module__btn___1PSTB,
.sidebar-module__input-group-lg___1f6Yu > .sidebar-module__input-group-append___P3CZI > .sidebar-module__btn___1PSTB {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.sidebar-module__input-group-sm___jUs7u > .sidebar-module__form-control___2-ndG:not(textarea),
.sidebar-module__input-group-sm___jUs7u > .sidebar-module__custom-select___W5MqO {
  height: calc(1.5em + 0.5rem + 2px);
}

.sidebar-module__input-group-sm___jUs7u > .sidebar-module__form-control___2-ndG,
.sidebar-module__input-group-sm___jUs7u > .sidebar-module__custom-select___W5MqO,
.sidebar-module__input-group-sm___jUs7u > .sidebar-module__input-group-prepend___gEp1B > .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group-sm___jUs7u > .sidebar-module__input-group-append___P3CZI > .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group-sm___jUs7u > .sidebar-module__input-group-prepend___gEp1B > .sidebar-module__btn___1PSTB,
.sidebar-module__input-group-sm___jUs7u > .sidebar-module__input-group-append___P3CZI > .sidebar-module__btn___1PSTB {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.sidebar-module__input-group-lg___1f6Yu > .sidebar-module__custom-select___W5MqO,
.sidebar-module__input-group-sm___jUs7u > .sidebar-module__custom-select___W5MqO {
  padding-right: 1.75rem;
}

.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-prepend___gEp1B > .sidebar-module__btn___1PSTB,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-prepend___gEp1B > .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-append___P3CZI:not(:last-child) > .sidebar-module__btn___1PSTB,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-append___P3CZI:not(:last-child) > .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-append___P3CZI:last-child > .sidebar-module__btn___1PSTB:not(:last-child):not(.sidebar-module__dropdown-toggle___3-b52),
.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-append___P3CZI:last-child > .sidebar-module__input-group-text___ubwqi:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-append___P3CZI > .sidebar-module__btn___1PSTB,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-append___P3CZI > .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-prepend___gEp1B:not(:first-child) > .sidebar-module__btn___1PSTB,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-prepend___gEp1B:not(:first-child) > .sidebar-module__input-group-text___ubwqi,
.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-prepend___gEp1B:first-child > .sidebar-module__btn___1PSTB:not(:first-child),
.sidebar-module__input-group___1Q5Ix > .sidebar-module__input-group-prepend___gEp1B:first-child > .sidebar-module__input-group-text___ubwqi:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.sidebar-module__custom-control___2oURW {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.sidebar-module__custom-control-inline___4uvUn {
  display: inline-flex;
  margin-right: 1rem;
}

.sidebar-module__custom-control-input___1QZEE {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.sidebar-module__custom-control-input___1QZEE:checked ~ .sidebar-module__custom-control-label___28Za9::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.sidebar-module__custom-control-input___1QZEE:focus ~ .sidebar-module__custom-control-label___28Za9::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.sidebar-module__custom-control-input___1QZEE:focus:not(:checked) ~ .sidebar-module__custom-control-label___28Za9::before {
  border-color: #80bdff;
}
.sidebar-module__custom-control-input___1QZEE:not(:disabled):active ~ .sidebar-module__custom-control-label___28Za9::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.sidebar-module__custom-control-input___1QZEE:disabled ~ .sidebar-module__custom-control-label___28Za9 {
  color: #6c757d;
}
.sidebar-module__custom-control-input___1QZEE:disabled ~ .sidebar-module__custom-control-label___28Za9::before {
  background-color: #e9ecef;
}

.sidebar-module__custom-control-label___28Za9 {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.sidebar-module__custom-control-label___28Za9::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.sidebar-module__custom-control-label___28Za9::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.sidebar-module__custom-checkbox___2HH2J .sidebar-module__custom-control-label___28Za9::before {
  border-radius: 0.25rem;
}
.sidebar-module__custom-checkbox___2HH2J .sidebar-module__custom-control-input___1QZEE:checked ~ .sidebar-module__custom-control-label___28Za9::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.sidebar-module__custom-checkbox___2HH2J .sidebar-module__custom-control-input___1QZEE:indeterminate ~ .sidebar-module__custom-control-label___28Za9::before {
  border-color: #007bff;
  background-color: #007bff;
}
.sidebar-module__custom-checkbox___2HH2J .sidebar-module__custom-control-input___1QZEE:indeterminate ~ .sidebar-module__custom-control-label___28Za9::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.sidebar-module__custom-checkbox___2HH2J .sidebar-module__custom-control-input___1QZEE:disabled:checked ~ .sidebar-module__custom-control-label___28Za9::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.sidebar-module__custom-checkbox___2HH2J .sidebar-module__custom-control-input___1QZEE:disabled:indeterminate ~ .sidebar-module__custom-control-label___28Za9::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.sidebar-module__custom-radio___1-3Gq .sidebar-module__custom-control-label___28Za9::before {
  border-radius: 50%;
}
.sidebar-module__custom-radio___1-3Gq .sidebar-module__custom-control-input___1QZEE:checked ~ .sidebar-module__custom-control-label___28Za9::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.sidebar-module__custom-radio___1-3Gq .sidebar-module__custom-control-input___1QZEE:disabled:checked ~ .sidebar-module__custom-control-label___28Za9::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.sidebar-module__custom-switch___3gA43 {
  padding-left: 2.25rem;
}
.sidebar-module__custom-switch___3gA43 .sidebar-module__custom-control-label___28Za9::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.sidebar-module__custom-switch___3gA43 .sidebar-module__custom-control-label___28Za9::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__custom-switch___3gA43 .sidebar-module__custom-control-label___28Za9::after {
    transition: none;
  }
}
.sidebar-module__custom-switch___3gA43 .sidebar-module__custom-control-input___1QZEE:checked ~ .sidebar-module__custom-control-label___28Za9::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.sidebar-module__custom-switch___3gA43 .sidebar-module__custom-control-input___1QZEE:disabled:checked ~ .sidebar-module__custom-control-label___28Za9::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.sidebar-module__custom-select___W5MqO {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.sidebar-module__custom-select___W5MqO:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.sidebar-module__custom-select___W5MqO:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.sidebar-module__custom-select___W5MqO[multiple], .sidebar-module__custom-select___W5MqO[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.sidebar-module__custom-select___W5MqO:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.sidebar-module__custom-select___W5MqO::-ms-expand {
  display: none;
}

.sidebar-module__custom-select-sm___3gIyr {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.sidebar-module__custom-select-lg___2b1fk {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.sidebar-module__custom-file___189Zr {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.sidebar-module__custom-file-input___1yKjP {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.sidebar-module__custom-file-input___1yKjP:focus ~ .sidebar-module__custom-file-label___96nTh {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.sidebar-module__custom-file-input___1yKjP:disabled ~ .sidebar-module__custom-file-label___96nTh {
  background-color: #e9ecef;
}
.sidebar-module__custom-file-input___1yKjP:lang(en) ~ .sidebar-module__custom-file-label___96nTh::after {
  content: "Browse";
}
.sidebar-module__custom-file-input___1yKjP ~ .sidebar-module__custom-file-label___96nTh[data-browse]::after {
  content: attr(data-browse);
}

.sidebar-module__custom-file-label___96nTh {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.sidebar-module__custom-file-label___96nTh::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.sidebar-module__custom-range___2NKxK {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.sidebar-module__custom-range___2NKxK:focus {
  outline: none;
}
.sidebar-module__custom-range___2NKxK:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.sidebar-module__custom-range___2NKxK:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.sidebar-module__custom-range___2NKxK:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.sidebar-module__custom-range___2NKxK::-moz-focus-outer {
  border: 0;
}
.sidebar-module__custom-range___2NKxK::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__custom-range___2NKxK::-webkit-slider-thumb {
    transition: none;
  }
}
.sidebar-module__custom-range___2NKxK::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.sidebar-module__custom-range___2NKxK::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.sidebar-module__custom-range___2NKxK::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__custom-range___2NKxK::-moz-range-thumb {
    transition: none;
  }
}
.sidebar-module__custom-range___2NKxK::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.sidebar-module__custom-range___2NKxK::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.sidebar-module__custom-range___2NKxK::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__custom-range___2NKxK::-ms-thumb {
    transition: none;
  }
}
.sidebar-module__custom-range___2NKxK::-ms-thumb:active {
  background-color: #b3d7ff;
}
.sidebar-module__custom-range___2NKxK::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.sidebar-module__custom-range___2NKxK::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.sidebar-module__custom-range___2NKxK::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.sidebar-module__custom-range___2NKxK:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.sidebar-module__custom-range___2NKxK:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.sidebar-module__custom-range___2NKxK:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.sidebar-module__custom-range___2NKxK:disabled::-moz-range-track {
  cursor: default;
}
.sidebar-module__custom-range___2NKxK:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.sidebar-module__custom-control-label___28Za9::before,
.sidebar-module__custom-file-label___96nTh,
.sidebar-module__custom-select___W5MqO {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__custom-control-label___28Za9::before,
.sidebar-module__custom-file-label___96nTh,
.sidebar-module__custom-select___W5MqO {
    transition: none;
  }
}

.sidebar-module__nav___2TqWm, .sidebar-module__context-menu___3TiWZ, .sidebar-module__main-menu___XmrdH {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.sidebar-module__nav-link___3l-zJ, .sidebar-module__link___2BPkD, .sidebar-module__link-active___1qkkj, .sidebar-module__link-collapsed___2uj3s {
  display: block;
  padding: 0.5rem 1rem;
}
.sidebar-module__nav-link___3l-zJ:hover, .sidebar-module__link___2BPkD:hover, .sidebar-module__link-active___1qkkj:hover, .sidebar-module__link-collapsed___2uj3s:hover, .sidebar-module__nav-link___3l-zJ:focus, .sidebar-module__link___2BPkD:focus, .sidebar-module__link-active___1qkkj:focus, .sidebar-module__link-collapsed___2uj3s:focus {
  text-decoration: none;
}
.sidebar-module__nav-link___3l-zJ.sidebar-module__disabled___m8NWA, .sidebar-module__disabled___m8NWA.sidebar-module__link___2BPkD, .sidebar-module__disabled___m8NWA.sidebar-module__link-active___1qkkj, .sidebar-module__disabled___m8NWA.sidebar-module__link-collapsed___2uj3s {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.sidebar-module__nav-tabs___3NWTr {
  border-bottom: 1px solid #dee2e6;
}
.sidebar-module__nav-tabs___3NWTr .sidebar-module__nav-item___4TJZs {
  margin-bottom: -1px;
}
.sidebar-module__nav-tabs___3NWTr .sidebar-module__nav-link___3l-zJ, .sidebar-module__nav-tabs___3NWTr .sidebar-module__link___2BPkD, .sidebar-module__nav-tabs___3NWTr .sidebar-module__link-active___1qkkj, .sidebar-module__nav-tabs___3NWTr .sidebar-module__link-collapsed___2uj3s {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.sidebar-module__nav-tabs___3NWTr .sidebar-module__nav-link___3l-zJ:hover, .sidebar-module__nav-tabs___3NWTr .sidebar-module__link___2BPkD:hover, .sidebar-module__nav-tabs___3NWTr .sidebar-module__link-active___1qkkj:hover, .sidebar-module__nav-tabs___3NWTr .sidebar-module__link-collapsed___2uj3s:hover, .sidebar-module__nav-tabs___3NWTr .sidebar-module__nav-link___3l-zJ:focus, .sidebar-module__nav-tabs___3NWTr .sidebar-module__link___2BPkD:focus, .sidebar-module__nav-tabs___3NWTr .sidebar-module__link-active___1qkkj:focus, .sidebar-module__nav-tabs___3NWTr .sidebar-module__link-collapsed___2uj3s:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.sidebar-module__nav-tabs___3NWTr .sidebar-module__nav-link___3l-zJ.sidebar-module__disabled___m8NWA, .sidebar-module__nav-tabs___3NWTr .sidebar-module__disabled___m8NWA.sidebar-module__link___2BPkD, .sidebar-module__nav-tabs___3NWTr .sidebar-module__disabled___m8NWA.sidebar-module__link-active___1qkkj, .sidebar-module__nav-tabs___3NWTr .sidebar-module__disabled___m8NWA.sidebar-module__link-collapsed___2uj3s {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.sidebar-module__nav-tabs___3NWTr .sidebar-module__nav-link___3l-zJ.sidebar-module__active___1kKQT, .sidebar-module__nav-tabs___3NWTr .sidebar-module__active___1kKQT.sidebar-module__link___2BPkD, .sidebar-module__nav-tabs___3NWTr .sidebar-module__active___1kKQT.sidebar-module__link-active___1qkkj, .sidebar-module__nav-tabs___3NWTr .sidebar-module__active___1kKQT.sidebar-module__link-collapsed___2uj3s,
.sidebar-module__nav-tabs___3NWTr .sidebar-module__nav-item___4TJZs.sidebar-module__show___1jqoA .sidebar-module__nav-link___3l-zJ,
.sidebar-module__nav-tabs___3NWTr .sidebar-module__nav-item___4TJZs.sidebar-module__show___1jqoA .sidebar-module__link___2BPkD,
.sidebar-module__nav-tabs___3NWTr .sidebar-module__nav-item___4TJZs.sidebar-module__show___1jqoA .sidebar-module__link-active___1qkkj,
.sidebar-module__nav-tabs___3NWTr .sidebar-module__nav-item___4TJZs.sidebar-module__show___1jqoA .sidebar-module__link-collapsed___2uj3s {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.sidebar-module__nav-tabs___3NWTr .sidebar-module__dropdown-menu___LJmme {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.sidebar-module__nav-pills___1oQ1h .sidebar-module__nav-link___3l-zJ, .sidebar-module__nav-pills___1oQ1h .sidebar-module__link___2BPkD, .sidebar-module__nav-pills___1oQ1h .sidebar-module__link-active___1qkkj, .sidebar-module__nav-pills___1oQ1h .sidebar-module__link-collapsed___2uj3s {
  border-radius: 0.25rem;
}
.sidebar-module__nav-pills___1oQ1h .sidebar-module__nav-link___3l-zJ.sidebar-module__active___1kKQT, .sidebar-module__nav-pills___1oQ1h .sidebar-module__active___1kKQT.sidebar-module__link___2BPkD, .sidebar-module__nav-pills___1oQ1h .sidebar-module__active___1kKQT.sidebar-module__link-active___1qkkj, .sidebar-module__nav-pills___1oQ1h .sidebar-module__active___1kKQT.sidebar-module__link-collapsed___2uj3s,
.sidebar-module__nav-pills___1oQ1h .sidebar-module__show___1jqoA > .sidebar-module__nav-link___3l-zJ,
.sidebar-module__nav-pills___1oQ1h .sidebar-module__show___1jqoA > .sidebar-module__link___2BPkD,
.sidebar-module__nav-pills___1oQ1h .sidebar-module__show___1jqoA > .sidebar-module__link-active___1qkkj,
.sidebar-module__nav-pills___1oQ1h .sidebar-module__show___1jqoA > .sidebar-module__link-collapsed___2uj3s {
  color: #fff;
  background-color: #007bff;
}

.sidebar-module__nav-fill___1TH0s .sidebar-module__nav-item___4TJZs {
  flex: 1 1 auto;
  text-align: center;
}

.sidebar-module__nav-justified___8IIqW .sidebar-module__nav-item___4TJZs {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.sidebar-module__tab-content___1g-35 > .sidebar-module__tab-pane___QZ7-T {
  display: none;
}
.sidebar-module__tab-content___1g-35 > .sidebar-module__active___1kKQT {
  display: block;
}

.sidebar-module__navbar___2f9Fr {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.sidebar-module__navbar___2f9Fr > .sidebar-module__container___1J6db,
.sidebar-module__navbar___2f9Fr > .sidebar-module__container-fluid___kuGz- {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.sidebar-module__navbar-brand___1Tx7R {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.sidebar-module__navbar-brand___1Tx7R:hover, .sidebar-module__navbar-brand___1Tx7R:focus {
  text-decoration: none;
}

.sidebar-module__navbar-nav___1hmHN {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ, .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD, .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj, .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s {
  padding-right: 0;
  padding-left: 0;
}
.sidebar-module__navbar-nav___1hmHN .sidebar-module__dropdown-menu___LJmme {
  position: static;
  float: none;
}

.sidebar-module__navbar-text___IZpA9 {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.sidebar-module__navbar-collapse___3Li89 {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.sidebar-module__navbar-toggler___3gIPw {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.sidebar-module__navbar-toggler___3gIPw:hover, .sidebar-module__navbar-toggler___3gIPw:focus {
  text-decoration: none;
}

.sidebar-module__navbar-toggler-icon___16rrj {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .sidebar-module__navbar-expand-sm___1EUIE > .sidebar-module__container___1J6db,
.sidebar-module__navbar-expand-sm___1EUIE > .sidebar-module__container-fluid___kuGz- {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .sidebar-module__navbar-expand-sm___1EUIE {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .sidebar-module__navbar-expand-sm___1EUIE .sidebar-module__navbar-nav___1hmHN {
    flex-direction: row;
  }
  .sidebar-module__navbar-expand-sm___1EUIE .sidebar-module__navbar-nav___1hmHN .sidebar-module__dropdown-menu___LJmme {
    position: absolute;
  }
  .sidebar-module__navbar-expand-sm___1EUIE .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ, .sidebar-module__navbar-expand-sm___1EUIE .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD, .sidebar-module__navbar-expand-sm___1EUIE .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj, .sidebar-module__navbar-expand-sm___1EUIE .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .sidebar-module__navbar-expand-sm___1EUIE > .sidebar-module__container___1J6db,
.sidebar-module__navbar-expand-sm___1EUIE > .sidebar-module__container-fluid___kuGz- {
    flex-wrap: nowrap;
  }
  .sidebar-module__navbar-expand-sm___1EUIE .sidebar-module__navbar-collapse___3Li89 {
    display: flex !important;
    flex-basis: auto;
  }
  .sidebar-module__navbar-expand-sm___1EUIE .sidebar-module__navbar-toggler___3gIPw {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .sidebar-module__navbar-expand-md___34mbu > .sidebar-module__container___1J6db,
.sidebar-module__navbar-expand-md___34mbu > .sidebar-module__container-fluid___kuGz- {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .sidebar-module__navbar-expand-md___34mbu {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .sidebar-module__navbar-expand-md___34mbu .sidebar-module__navbar-nav___1hmHN {
    flex-direction: row;
  }
  .sidebar-module__navbar-expand-md___34mbu .sidebar-module__navbar-nav___1hmHN .sidebar-module__dropdown-menu___LJmme {
    position: absolute;
  }
  .sidebar-module__navbar-expand-md___34mbu .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ, .sidebar-module__navbar-expand-md___34mbu .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD, .sidebar-module__navbar-expand-md___34mbu .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj, .sidebar-module__navbar-expand-md___34mbu .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .sidebar-module__navbar-expand-md___34mbu > .sidebar-module__container___1J6db,
.sidebar-module__navbar-expand-md___34mbu > .sidebar-module__container-fluid___kuGz- {
    flex-wrap: nowrap;
  }
  .sidebar-module__navbar-expand-md___34mbu .sidebar-module__navbar-collapse___3Li89 {
    display: flex !important;
    flex-basis: auto;
  }
  .sidebar-module__navbar-expand-md___34mbu .sidebar-module__navbar-toggler___3gIPw {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .sidebar-module__navbar-expand-lg___32ZHW > .sidebar-module__container___1J6db,
.sidebar-module__navbar-expand-lg___32ZHW > .sidebar-module__container-fluid___kuGz- {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .sidebar-module__navbar-expand-lg___32ZHW {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .sidebar-module__navbar-expand-lg___32ZHW .sidebar-module__navbar-nav___1hmHN {
    flex-direction: row;
  }
  .sidebar-module__navbar-expand-lg___32ZHW .sidebar-module__navbar-nav___1hmHN .sidebar-module__dropdown-menu___LJmme {
    position: absolute;
  }
  .sidebar-module__navbar-expand-lg___32ZHW .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ, .sidebar-module__navbar-expand-lg___32ZHW .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD, .sidebar-module__navbar-expand-lg___32ZHW .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj, .sidebar-module__navbar-expand-lg___32ZHW .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .sidebar-module__navbar-expand-lg___32ZHW > .sidebar-module__container___1J6db,
.sidebar-module__navbar-expand-lg___32ZHW > .sidebar-module__container-fluid___kuGz- {
    flex-wrap: nowrap;
  }
  .sidebar-module__navbar-expand-lg___32ZHW .sidebar-module__navbar-collapse___3Li89 {
    display: flex !important;
    flex-basis: auto;
  }
  .sidebar-module__navbar-expand-lg___32ZHW .sidebar-module__navbar-toggler___3gIPw {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .sidebar-module__navbar-expand-xl___YcX7- > .sidebar-module__container___1J6db,
.sidebar-module__navbar-expand-xl___YcX7- > .sidebar-module__container-fluid___kuGz- {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .sidebar-module__navbar-expand-xl___YcX7- {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .sidebar-module__navbar-expand-xl___YcX7- .sidebar-module__navbar-nav___1hmHN {
    flex-direction: row;
  }
  .sidebar-module__navbar-expand-xl___YcX7- .sidebar-module__navbar-nav___1hmHN .sidebar-module__dropdown-menu___LJmme {
    position: absolute;
  }
  .sidebar-module__navbar-expand-xl___YcX7- .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ, .sidebar-module__navbar-expand-xl___YcX7- .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD, .sidebar-module__navbar-expand-xl___YcX7- .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj, .sidebar-module__navbar-expand-xl___YcX7- .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .sidebar-module__navbar-expand-xl___YcX7- > .sidebar-module__container___1J6db,
.sidebar-module__navbar-expand-xl___YcX7- > .sidebar-module__container-fluid___kuGz- {
    flex-wrap: nowrap;
  }
  .sidebar-module__navbar-expand-xl___YcX7- .sidebar-module__navbar-collapse___3Li89 {
    display: flex !important;
    flex-basis: auto;
  }
  .sidebar-module__navbar-expand-xl___YcX7- .sidebar-module__navbar-toggler___3gIPw {
    display: none;
  }
}
.sidebar-module__navbar-expand___1dAbk {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.sidebar-module__navbar-expand___1dAbk > .sidebar-module__container___1J6db,
.sidebar-module__navbar-expand___1dAbk > .sidebar-module__container-fluid___kuGz- {
  padding-right: 0;
  padding-left: 0;
}
.sidebar-module__navbar-expand___1dAbk .sidebar-module__navbar-nav___1hmHN {
  flex-direction: row;
}
.sidebar-module__navbar-expand___1dAbk .sidebar-module__navbar-nav___1hmHN .sidebar-module__dropdown-menu___LJmme {
  position: absolute;
}
.sidebar-module__navbar-expand___1dAbk .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ, .sidebar-module__navbar-expand___1dAbk .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD, .sidebar-module__navbar-expand___1dAbk .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj, .sidebar-module__navbar-expand___1dAbk .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.sidebar-module__navbar-expand___1dAbk > .sidebar-module__container___1J6db,
.sidebar-module__navbar-expand___1dAbk > .sidebar-module__container-fluid___kuGz- {
  flex-wrap: nowrap;
}
.sidebar-module__navbar-expand___1dAbk .sidebar-module__navbar-collapse___3Li89 {
  display: flex !important;
  flex-basis: auto;
}
.sidebar-module__navbar-expand___1dAbk .sidebar-module__navbar-toggler___3gIPw {
  display: none;
}

.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-brand___1Tx7R {
  color: rgba(0, 0, 0, 0.9);
}
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-brand___1Tx7R:hover, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-brand___1Tx7R:focus {
  color: rgba(0, 0, 0, 0.9);
}
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s {
  color: rgba(0, 0, 0, 0.5);
}
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ:hover, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD:hover, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj:hover, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s:hover, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ:focus, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD:focus, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj:focus, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s:focus {
  color: rgba(0, 0, 0, 0.7);
}
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ.sidebar-module__disabled___m8NWA, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__disabled___m8NWA.sidebar-module__link___2BPkD, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__disabled___m8NWA.sidebar-module__link-active___1qkkj, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__disabled___m8NWA.sidebar-module__link-collapsed___2uj3s {
  color: rgba(0, 0, 0, 0.3);
}
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA > .sidebar-module__nav-link___3l-zJ, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA > .sidebar-module__link___2BPkD, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA > .sidebar-module__link-active___1qkkj, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA > .sidebar-module__link-collapsed___2uj3s,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT > .sidebar-module__nav-link___3l-zJ,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT > .sidebar-module__link___2BPkD,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT > .sidebar-module__link-active___1qkkj,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT > .sidebar-module__link-collapsed___2uj3s,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ.sidebar-module__show___1jqoA,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA.sidebar-module__link___2BPkD,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA.sidebar-module__link-active___1qkkj,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA.sidebar-module__link-collapsed___2uj3s,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ.sidebar-module__active___1kKQT,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT.sidebar-module__link___2BPkD,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT.sidebar-module__link-active___1qkkj,
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT.sidebar-module__link-collapsed___2uj3s {
  color: rgba(0, 0, 0, 0.9);
}
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-toggler___3gIPw {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-toggler-icon___16rrj {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-text___IZpA9 {
  color: rgba(0, 0, 0, 0.5);
}
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-text___IZpA9 a {
  color: rgba(0, 0, 0, 0.9);
}
.sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-text___IZpA9 a:hover, .sidebar-module__navbar-light___2jL0I .sidebar-module__navbar-text___IZpA9 a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-brand___1Tx7R {
  color: #fff;
}
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-brand___1Tx7R:hover, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-brand___1Tx7R:focus {
  color: #fff;
}
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ:hover, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD:hover, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj:hover, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s:hover, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ:focus, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__link___2BPkD:focus, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-active___1qkkj:focus, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__link-collapsed___2uj3s:focus {
  color: rgba(255, 255, 255, 0.75);
}
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ.sidebar-module__disabled___m8NWA, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__disabled___m8NWA.sidebar-module__link___2BPkD, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__disabled___m8NWA.sidebar-module__link-active___1qkkj, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__disabled___m8NWA.sidebar-module__link-collapsed___2uj3s {
  color: rgba(255, 255, 255, 0.25);
}
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA > .sidebar-module__nav-link___3l-zJ, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA > .sidebar-module__link___2BPkD, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA > .sidebar-module__link-active___1qkkj, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA > .sidebar-module__link-collapsed___2uj3s,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT > .sidebar-module__nav-link___3l-zJ,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT > .sidebar-module__link___2BPkD,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT > .sidebar-module__link-active___1qkkj,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT > .sidebar-module__link-collapsed___2uj3s,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ.sidebar-module__show___1jqoA,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA.sidebar-module__link___2BPkD,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA.sidebar-module__link-active___1qkkj,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__show___1jqoA.sidebar-module__link-collapsed___2uj3s,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__nav-link___3l-zJ.sidebar-module__active___1kKQT,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT.sidebar-module__link___2BPkD,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT.sidebar-module__link-active___1qkkj,
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-nav___1hmHN .sidebar-module__active___1kKQT.sidebar-module__link-collapsed___2uj3s {
  color: #fff;
}
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-toggler___3gIPw {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-toggler-icon___16rrj {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-text___IZpA9 {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-text___IZpA9 a {
  color: #fff;
}
.sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-text___IZpA9 a:hover, .sidebar-module__navbar-dark___3wfVN .sidebar-module__navbar-text___IZpA9 a:focus {
  color: #fff;
}

.sidebar-module__card___HNjNQ {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.sidebar-module__card___HNjNQ > hr {
  margin-right: 0;
  margin-left: 0;
}
.sidebar-module__card___HNjNQ > .sidebar-module__list-group___p-8RK:first-child .sidebar-module__list-group-item___1IFh4:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.sidebar-module__card___HNjNQ > .sidebar-module__list-group___p-8RK:last-child .sidebar-module__list-group-item___1IFh4:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.sidebar-module__card-body___1kO3c {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.sidebar-module__card-title___2zwQE {
  margin-bottom: 0.75rem;
}

.sidebar-module__card-subtitle___1nC4l {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.sidebar-module__card-text___oabay:last-child {
  margin-bottom: 0;
}

.sidebar-module__card-link___2e3kd:hover {
  text-decoration: none;
}
.sidebar-module__card-link___2e3kd + .sidebar-module__card-link___2e3kd {
  margin-left: 1.25rem;
}

.sidebar-module__card-header___3OxGd {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.sidebar-module__card-header___3OxGd:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.sidebar-module__card-header___3OxGd + .sidebar-module__list-group___p-8RK .sidebar-module__list-group-item___1IFh4:first-child {
  border-top: 0;
}

.sidebar-module__card-footer___1HT_Q {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.sidebar-module__card-footer___1HT_Q:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.sidebar-module__card-header-tabs___2e7T1 {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.sidebar-module__card-header-pills___2qAGH {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.sidebar-module__card-img-overlay___-xusZ {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.sidebar-module__card-img___1IlSd {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.sidebar-module__card-img-top___23qc0 {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.sidebar-module__card-img-bottom___SFM0A {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.sidebar-module__card-deck___2AyuB {
  display: flex;
  flex-direction: column;
}
.sidebar-module__card-deck___2AyuB .sidebar-module__card___HNjNQ {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .sidebar-module__card-deck___2AyuB {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .sidebar-module__card-deck___2AyuB .sidebar-module__card___HNjNQ {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.sidebar-module__card-group___148aS {
  display: flex;
  flex-direction: column;
}
.sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .sidebar-module__card-group___148aS {
    flex-flow: row wrap;
  }
  .sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ + .sidebar-module__card___HNjNQ {
    margin-left: 0;
    border-left: 0;
  }
  .sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ:not(:last-child) .sidebar-module__card-img-top___23qc0,
.sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ:not(:last-child) .sidebar-module__card-header___3OxGd {
    border-top-right-radius: 0;
  }
  .sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ:not(:last-child) .sidebar-module__card-img-bottom___SFM0A,
.sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ:not(:last-child) .sidebar-module__card-footer___1HT_Q {
    border-bottom-right-radius: 0;
  }
  .sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ:not(:first-child) .sidebar-module__card-img-top___23qc0,
.sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ:not(:first-child) .sidebar-module__card-header___3OxGd {
    border-top-left-radius: 0;
  }
  .sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ:not(:first-child) .sidebar-module__card-img-bottom___SFM0A,
.sidebar-module__card-group___148aS > .sidebar-module__card___HNjNQ:not(:first-child) .sidebar-module__card-footer___1HT_Q {
    border-bottom-left-radius: 0;
  }
}

.sidebar-module__card-columns___CMy7c .sidebar-module__card___HNjNQ {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .sidebar-module__card-columns___CMy7c {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .sidebar-module__card-columns___CMy7c .sidebar-module__card___HNjNQ {
    display: inline-block;
    width: 100%;
  }
}

.sidebar-module__accordion___1lSzp > .sidebar-module__card___HNjNQ {
  overflow: hidden;
}
.sidebar-module__accordion___1lSzp > .sidebar-module__card___HNjNQ:not(:first-of-type) .sidebar-module__card-header___3OxGd:first-child {
  border-radius: 0;
}
.sidebar-module__accordion___1lSzp > .sidebar-module__card___HNjNQ:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.sidebar-module__accordion___1lSzp > .sidebar-module__card___HNjNQ:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.sidebar-module__accordion___1lSzp > .sidebar-module__card___HNjNQ:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.sidebar-module__accordion___1lSzp > .sidebar-module__card___HNjNQ .sidebar-module__card-header___3OxGd {
  margin-bottom: -1px;
}

.sidebar-module__breadcrumb___2MMRh {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.sidebar-module__breadcrumb-item___2eMEG + .sidebar-module__breadcrumb-item___2eMEG {
  padding-left: 0.5rem;
}
.sidebar-module__breadcrumb-item___2eMEG + .sidebar-module__breadcrumb-item___2eMEG::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.sidebar-module__breadcrumb-item___2eMEG + .sidebar-module__breadcrumb-item___2eMEG:hover::before {
  text-decoration: underline;
}
.sidebar-module__breadcrumb-item___2eMEG + .sidebar-module__breadcrumb-item___2eMEG:hover::before {
  text-decoration: none;
}
.sidebar-module__breadcrumb-item___2eMEG.sidebar-module__active___1kKQT {
  color: #6c757d;
}

.sidebar-module__pagination___2fKtq {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.sidebar-module__page-link___1JrYa {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.sidebar-module__page-link___1JrYa:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.sidebar-module__page-link___1JrYa:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.sidebar-module__page-item___1c0OJ:first-child .sidebar-module__page-link___1JrYa {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.sidebar-module__page-item___1c0OJ:last-child .sidebar-module__page-link___1JrYa {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.sidebar-module__page-item___1c0OJ.sidebar-module__active___1kKQT .sidebar-module__page-link___1JrYa {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.sidebar-module__page-item___1c0OJ.sidebar-module__disabled___m8NWA .sidebar-module__page-link___1JrYa {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.sidebar-module__pagination-lg___31EqI .sidebar-module__page-link___1JrYa {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.sidebar-module__pagination-lg___31EqI .sidebar-module__page-item___1c0OJ:first-child .sidebar-module__page-link___1JrYa {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.sidebar-module__pagination-lg___31EqI .sidebar-module__page-item___1c0OJ:last-child .sidebar-module__page-link___1JrYa {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.sidebar-module__pagination-sm___UuRUR .sidebar-module__page-link___1JrYa {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.sidebar-module__pagination-sm___UuRUR .sidebar-module__page-item___1c0OJ:first-child .sidebar-module__page-link___1JrYa {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.sidebar-module__pagination-sm___UuRUR .sidebar-module__page-item___1c0OJ:last-child .sidebar-module__page-link___1JrYa {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.sidebar-module__badge___PuvBG {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__badge___PuvBG {
    transition: none;
  }
}
a.sidebar-module__badge___PuvBG:hover, a.sidebar-module__badge___PuvBG:focus {
  text-decoration: none;
}

.sidebar-module__badge___PuvBG:empty {
  display: none;
}

.sidebar-module__btn___1PSTB .sidebar-module__badge___PuvBG {
  position: relative;
  top: -1px;
}

.sidebar-module__badge-pill___toQ9V {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.sidebar-module__badge-primary___2fy_4 {
  color: #fff;
  background-color: #007bff;
}
a.sidebar-module__badge-primary___2fy_4:hover, a.sidebar-module__badge-primary___2fy_4:focus {
  color: #fff;
  background-color: #0062cc;
}
a.sidebar-module__badge-primary___2fy_4:focus, a.sidebar-module__badge-primary___2fy_4.sidebar-module__focus___2xn2F {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.sidebar-module__badge-secondary___1_TlU {
  color: #fff;
  background-color: #6c757d;
}
a.sidebar-module__badge-secondary___1_TlU:hover, a.sidebar-module__badge-secondary___1_TlU:focus {
  color: #fff;
  background-color: #545b62;
}
a.sidebar-module__badge-secondary___1_TlU:focus, a.sidebar-module__badge-secondary___1_TlU.sidebar-module__focus___2xn2F {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.sidebar-module__badge-success___rvQrt {
  color: #fff;
  background-color: #28a745;
}
a.sidebar-module__badge-success___rvQrt:hover, a.sidebar-module__badge-success___rvQrt:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.sidebar-module__badge-success___rvQrt:focus, a.sidebar-module__badge-success___rvQrt.sidebar-module__focus___2xn2F {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.sidebar-module__badge-info___2hOt4 {
  color: #fff;
  background-color: #17a2b8;
}
a.sidebar-module__badge-info___2hOt4:hover, a.sidebar-module__badge-info___2hOt4:focus {
  color: #fff;
  background-color: #117a8b;
}
a.sidebar-module__badge-info___2hOt4:focus, a.sidebar-module__badge-info___2hOt4.sidebar-module__focus___2xn2F {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.sidebar-module__badge-warning___1628t {
  color: #212529;
  background-color: #ffc107;
}
a.sidebar-module__badge-warning___1628t:hover, a.sidebar-module__badge-warning___1628t:focus {
  color: #212529;
  background-color: #d39e00;
}
a.sidebar-module__badge-warning___1628t:focus, a.sidebar-module__badge-warning___1628t.sidebar-module__focus___2xn2F {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.sidebar-module__badge-danger___2ejnT {
  color: #fff;
  background-color: #dc3545;
}
a.sidebar-module__badge-danger___2ejnT:hover, a.sidebar-module__badge-danger___2ejnT:focus {
  color: #fff;
  background-color: #bd2130;
}
a.sidebar-module__badge-danger___2ejnT:focus, a.sidebar-module__badge-danger___2ejnT.sidebar-module__focus___2xn2F {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.sidebar-module__badge-light___CBFrC {
  color: #212529;
  background-color: #f8f9fa;
}
a.sidebar-module__badge-light___CBFrC:hover, a.sidebar-module__badge-light___CBFrC:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.sidebar-module__badge-light___CBFrC:focus, a.sidebar-module__badge-light___CBFrC.sidebar-module__focus___2xn2F {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.sidebar-module__badge-dark___1s4In {
  color: #fff;
  background-color: #343a40;
}
a.sidebar-module__badge-dark___1s4In:hover, a.sidebar-module__badge-dark___1s4In:focus {
  color: #fff;
  background-color: #1d2124;
}
a.sidebar-module__badge-dark___1s4In:focus, a.sidebar-module__badge-dark___1s4In.sidebar-module__focus___2xn2F {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.sidebar-module__jumbotron___2ip6D {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .sidebar-module__jumbotron___2ip6D {
    padding: 4rem 2rem;
  }
}

.sidebar-module__jumbotron-fluid___2RsYa {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.sidebar-module__alert___1CltC {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.sidebar-module__alert-heading___3NLwt {
  color: inherit;
}

.sidebar-module__alert-link___31HUp {
  font-weight: 700;
}

.sidebar-module__alert-dismissible___1ZZCc {
  padding-right: 4rem;
}
.sidebar-module__alert-dismissible___1ZZCc .sidebar-module__close___ubu4o {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.sidebar-module__alert-primary___1wedK {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.sidebar-module__alert-primary___1wedK hr {
  border-top-color: #9fcdff;
}
.sidebar-module__alert-primary___1wedK .sidebar-module__alert-link___31HUp {
  color: #002752;
}

.sidebar-module__alert-secondary___T-j58 {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.sidebar-module__alert-secondary___T-j58 hr {
  border-top-color: #c8cbcf;
}
.sidebar-module__alert-secondary___T-j58 .sidebar-module__alert-link___31HUp {
  color: #202326;
}

.sidebar-module__alert-success___2H8YH {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.sidebar-module__alert-success___2H8YH hr {
  border-top-color: #b1dfbb;
}
.sidebar-module__alert-success___2H8YH .sidebar-module__alert-link___31HUp {
  color: #0b2e13;
}

.sidebar-module__alert-info___3hcFR {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.sidebar-module__alert-info___3hcFR hr {
  border-top-color: #abdde5;
}
.sidebar-module__alert-info___3hcFR .sidebar-module__alert-link___31HUp {
  color: #062c33;
}

.sidebar-module__alert-warning___icv_A {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.sidebar-module__alert-warning___icv_A hr {
  border-top-color: #ffe8a1;
}
.sidebar-module__alert-warning___icv_A .sidebar-module__alert-link___31HUp {
  color: #533f03;
}

.sidebar-module__alert-danger___tbHYf {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.sidebar-module__alert-danger___tbHYf hr {
  border-top-color: #f1b0b7;
}
.sidebar-module__alert-danger___tbHYf .sidebar-module__alert-link___31HUp {
  color: #491217;
}

.sidebar-module__alert-light___1VR1Z {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.sidebar-module__alert-light___1VR1Z hr {
  border-top-color: #ececf6;
}
.sidebar-module__alert-light___1VR1Z .sidebar-module__alert-link___31HUp {
  color: #686868;
}

.sidebar-module__alert-dark___1bGjV {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.sidebar-module__alert-dark___1bGjV hr {
  border-top-color: #b9bbbe;
}
.sidebar-module__alert-dark___1bGjV .sidebar-module__alert-link___31HUp {
  color: #040505;
}

@keyframes sidebar-module__progress-bar-stripes___eUhPM {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.sidebar-module__progress___jXhsp {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.sidebar-module__progress-bar___44u2N {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__progress-bar___44u2N {
    transition: none;
  }
}

.sidebar-module__progress-bar-striped___2p21R {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.sidebar-module__progress-bar-animated___RRkZm {
  animation: sidebar-module__progress-bar-stripes___eUhPM 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__progress-bar-animated___RRkZm {
    animation: none;
  }
}

.sidebar-module__media___18Ng_ {
  display: flex;
  align-items: flex-start;
}

.sidebar-module__media-body___1zC_g {
  flex: 1;
}

.sidebar-module__list-group___p-8RK {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.sidebar-module__list-group-item-action___YbID5 {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.sidebar-module__list-group-item-action___YbID5:hover, .sidebar-module__list-group-item-action___YbID5:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.sidebar-module__list-group-item-action___YbID5:active {
  color: #212529;
  background-color: #e9ecef;
}

.sidebar-module__list-group-item___1IFh4 {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.sidebar-module__list-group-item___1IFh4:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.sidebar-module__list-group-item___1IFh4:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.sidebar-module__list-group-item___1IFh4.sidebar-module__disabled___m8NWA, .sidebar-module__list-group-item___1IFh4:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.sidebar-module__list-group-item___1IFh4.sidebar-module__active___1kKQT {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.sidebar-module__list-group-horizontal___mnl-6 {
  flex-direction: row;
}
.sidebar-module__list-group-horizontal___mnl-6 .sidebar-module__list-group-item___1IFh4 {
  margin-right: -1px;
  margin-bottom: 0;
}
.sidebar-module__list-group-horizontal___mnl-6 .sidebar-module__list-group-item___1IFh4:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.sidebar-module__list-group-horizontal___mnl-6 .sidebar-module__list-group-item___1IFh4:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .sidebar-module__list-group-horizontal-sm___3PZK_ {
    flex-direction: row;
  }
  .sidebar-module__list-group-horizontal-sm___3PZK_ .sidebar-module__list-group-item___1IFh4 {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .sidebar-module__list-group-horizontal-sm___3PZK_ .sidebar-module__list-group-item___1IFh4:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .sidebar-module__list-group-horizontal-sm___3PZK_ .sidebar-module__list-group-item___1IFh4:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .sidebar-module__list-group-horizontal-md___294t8 {
    flex-direction: row;
  }
  .sidebar-module__list-group-horizontal-md___294t8 .sidebar-module__list-group-item___1IFh4 {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .sidebar-module__list-group-horizontal-md___294t8 .sidebar-module__list-group-item___1IFh4:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .sidebar-module__list-group-horizontal-md___294t8 .sidebar-module__list-group-item___1IFh4:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .sidebar-module__list-group-horizontal-lg____vJJl {
    flex-direction: row;
  }
  .sidebar-module__list-group-horizontal-lg____vJJl .sidebar-module__list-group-item___1IFh4 {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .sidebar-module__list-group-horizontal-lg____vJJl .sidebar-module__list-group-item___1IFh4:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .sidebar-module__list-group-horizontal-lg____vJJl .sidebar-module__list-group-item___1IFh4:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .sidebar-module__list-group-horizontal-xl___2xVqE {
    flex-direction: row;
  }
  .sidebar-module__list-group-horizontal-xl___2xVqE .sidebar-module__list-group-item___1IFh4 {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .sidebar-module__list-group-horizontal-xl___2xVqE .sidebar-module__list-group-item___1IFh4:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .sidebar-module__list-group-horizontal-xl___2xVqE .sidebar-module__list-group-item___1IFh4:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.sidebar-module__list-group-flush___CWFLX .sidebar-module__list-group-item___1IFh4 {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.sidebar-module__list-group-flush___CWFLX .sidebar-module__list-group-item___1IFh4:last-child {
  margin-bottom: -1px;
}
.sidebar-module__list-group-flush___CWFLX:first-child .sidebar-module__list-group-item___1IFh4:first-child {
  border-top: 0;
}
.sidebar-module__list-group-flush___CWFLX:last-child .sidebar-module__list-group-item___1IFh4:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.sidebar-module__list-group-item-primary___23EcX {
  color: #004085;
  background-color: #b8daff;
}
.sidebar-module__list-group-item-primary___23EcX.sidebar-module__list-group-item-action___YbID5:hover, .sidebar-module__list-group-item-primary___23EcX.sidebar-module__list-group-item-action___YbID5:focus {
  color: #004085;
  background-color: #9fcdff;
}
.sidebar-module__list-group-item-primary___23EcX.sidebar-module__list-group-item-action___YbID5.sidebar-module__active___1kKQT {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.sidebar-module__list-group-item-secondary___11N-2 {
  color: #383d41;
  background-color: #d6d8db;
}
.sidebar-module__list-group-item-secondary___11N-2.sidebar-module__list-group-item-action___YbID5:hover, .sidebar-module__list-group-item-secondary___11N-2.sidebar-module__list-group-item-action___YbID5:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.sidebar-module__list-group-item-secondary___11N-2.sidebar-module__list-group-item-action___YbID5.sidebar-module__active___1kKQT {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.sidebar-module__list-group-item-success___FNFIr {
  color: #155724;
  background-color: #c3e6cb;
}
.sidebar-module__list-group-item-success___FNFIr.sidebar-module__list-group-item-action___YbID5:hover, .sidebar-module__list-group-item-success___FNFIr.sidebar-module__list-group-item-action___YbID5:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.sidebar-module__list-group-item-success___FNFIr.sidebar-module__list-group-item-action___YbID5.sidebar-module__active___1kKQT {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.sidebar-module__list-group-item-info___1mrEP {
  color: #0c5460;
  background-color: #bee5eb;
}
.sidebar-module__list-group-item-info___1mrEP.sidebar-module__list-group-item-action___YbID5:hover, .sidebar-module__list-group-item-info___1mrEP.sidebar-module__list-group-item-action___YbID5:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.sidebar-module__list-group-item-info___1mrEP.sidebar-module__list-group-item-action___YbID5.sidebar-module__active___1kKQT {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.sidebar-module__list-group-item-warning___1e2YA {
  color: #856404;
  background-color: #ffeeba;
}
.sidebar-module__list-group-item-warning___1e2YA.sidebar-module__list-group-item-action___YbID5:hover, .sidebar-module__list-group-item-warning___1e2YA.sidebar-module__list-group-item-action___YbID5:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.sidebar-module__list-group-item-warning___1e2YA.sidebar-module__list-group-item-action___YbID5.sidebar-module__active___1kKQT {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.sidebar-module__list-group-item-danger___39381 {
  color: #721c24;
  background-color: #f5c6cb;
}
.sidebar-module__list-group-item-danger___39381.sidebar-module__list-group-item-action___YbID5:hover, .sidebar-module__list-group-item-danger___39381.sidebar-module__list-group-item-action___YbID5:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.sidebar-module__list-group-item-danger___39381.sidebar-module__list-group-item-action___YbID5.sidebar-module__active___1kKQT {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.sidebar-module__list-group-item-light___JXmSl {
  color: #818182;
  background-color: #fdfdfe;
}
.sidebar-module__list-group-item-light___JXmSl.sidebar-module__list-group-item-action___YbID5:hover, .sidebar-module__list-group-item-light___JXmSl.sidebar-module__list-group-item-action___YbID5:focus {
  color: #818182;
  background-color: #ececf6;
}
.sidebar-module__list-group-item-light___JXmSl.sidebar-module__list-group-item-action___YbID5.sidebar-module__active___1kKQT {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.sidebar-module__list-group-item-dark___2Y74a {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.sidebar-module__list-group-item-dark___2Y74a.sidebar-module__list-group-item-action___YbID5:hover, .sidebar-module__list-group-item-dark___2Y74a.sidebar-module__list-group-item-action___YbID5:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.sidebar-module__list-group-item-dark___2Y74a.sidebar-module__list-group-item-action___YbID5.sidebar-module__active___1kKQT {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.sidebar-module__close___ubu4o {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.sidebar-module__close___ubu4o:hover {
  color: #000;
  text-decoration: none;
}
.sidebar-module__close___ubu4o:not(:disabled):not(.sidebar-module__disabled___m8NWA):hover, .sidebar-module__close___ubu4o:not(:disabled):not(.sidebar-module__disabled___m8NWA):focus {
  opacity: 0.75;
}

button.sidebar-module__close___ubu4o {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.sidebar-module__close___ubu4o.sidebar-module__disabled___m8NWA {
  pointer-events: none;
}

.sidebar-module__toast___26spp {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.sidebar-module__toast___26spp:not(:last-child) {
  margin-bottom: 0.75rem;
}
.sidebar-module__toast___26spp.sidebar-module__showing___GDht0 {
  opacity: 1;
}
.sidebar-module__toast___26spp.sidebar-module__show___1jqoA {
  display: block;
  opacity: 1;
}
.sidebar-module__toast___26spp.sidebar-module__hide___1D2ue {
  display: none;
}

.sidebar-module__toast-header___1uXlF {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.sidebar-module__toast-body___2G76E {
  padding: 0.75rem;
}

.sidebar-module__modal-open___3FQCJ {
  overflow: hidden;
}
.sidebar-module__modal-open___3FQCJ .sidebar-module__modal___3a6eh {
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-module__modal___3a6eh {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.sidebar-module__modal-dialog___3Vjyd {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.sidebar-module__modal___3a6eh.sidebar-module__fade___2Ilxx .sidebar-module__modal-dialog___3Vjyd {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__modal___3a6eh.sidebar-module__fade___2Ilxx .sidebar-module__modal-dialog___3Vjyd {
    transition: none;
  }
}
.sidebar-module__modal___3a6eh.sidebar-module__show___1jqoA .sidebar-module__modal-dialog___3Vjyd {
  transform: none;
}

.sidebar-module__modal-dialog-scrollable___2zrqL {
  display: flex;
  max-height: calc(100% - 1rem);
}
.sidebar-module__modal-dialog-scrollable___2zrqL .sidebar-module__modal-content___2kfxv {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.sidebar-module__modal-dialog-scrollable___2zrqL .sidebar-module__modal-header___1IwSI,
.sidebar-module__modal-dialog-scrollable___2zrqL .sidebar-module__modal-footer___3xJ72 {
  flex-shrink: 0;
}
.sidebar-module__modal-dialog-scrollable___2zrqL .sidebar-module__modal-body___1kBpb {
  overflow-y: auto;
}

.sidebar-module__modal-dialog-centered___14S03 {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.sidebar-module__modal-dialog-centered___14S03::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.sidebar-module__modal-dialog-centered___14S03.sidebar-module__modal-dialog-scrollable___2zrqL {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.sidebar-module__modal-dialog-centered___14S03.sidebar-module__modal-dialog-scrollable___2zrqL .sidebar-module__modal-content___2kfxv {
  max-height: none;
}
.sidebar-module__modal-dialog-centered___14S03.sidebar-module__modal-dialog-scrollable___2zrqL::before {
  content: none;
}

.sidebar-module__modal-content___2kfxv {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.sidebar-module__modal-backdrop___C5esX {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.sidebar-module__modal-backdrop___C5esX.sidebar-module__fade___2Ilxx {
  opacity: 0;
}
.sidebar-module__modal-backdrop___C5esX.sidebar-module__show___1jqoA {
  opacity: 0.5;
}

.sidebar-module__modal-header___1IwSI {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.sidebar-module__modal-header___1IwSI .sidebar-module__close___ubu4o {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.sidebar-module__modal-title___3hHaa {
  margin-bottom: 0;
  line-height: 1.5;
}

.sidebar-module__modal-body___1kBpb {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.sidebar-module__modal-footer___3xJ72 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.sidebar-module__modal-footer___3xJ72 > :not(:first-child) {
  margin-left: 0.25rem;
}
.sidebar-module__modal-footer___3xJ72 > :not(:last-child) {
  margin-right: 0.25rem;
}

.sidebar-module__modal-scrollbar-measure___TmMuw {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .sidebar-module__modal-dialog___3Vjyd {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .sidebar-module__modal-dialog-scrollable___2zrqL {
    max-height: calc(100% - 3.5rem);
  }
  .sidebar-module__modal-dialog-scrollable___2zrqL .sidebar-module__modal-content___2kfxv {
    max-height: calc(100vh - 3.5rem);
  }

  .sidebar-module__modal-dialog-centered___14S03 {
    min-height: calc(100% - 3.5rem);
  }
  .sidebar-module__modal-dialog-centered___14S03::before {
    height: calc(100vh - 3.5rem);
  }

  .sidebar-module__modal-sm___3Vnkh {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .sidebar-module__modal-lg___1kkKj,
.sidebar-module__modal-xl___dIZuS {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .sidebar-module__modal-xl___dIZuS {
    max-width: 1140px;
  }
}
.sidebar-module__tooltip___2J8lG {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.sidebar-module__tooltip___2J8lG.sidebar-module__show___1jqoA {
  opacity: 0.9;
}
.sidebar-module__tooltip___2J8lG .sidebar-module__arrow___1nMVL {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.sidebar-module__tooltip___2J8lG .sidebar-module__arrow___1nMVL::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.sidebar-module__bs-tooltip-top___2vvYM, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=top] {
  padding: 0.4rem 0;
}
.sidebar-module__bs-tooltip-top___2vvYM .sidebar-module__arrow___1nMVL, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=top] .sidebar-module__arrow___1nMVL {
  bottom: 0;
}
.sidebar-module__bs-tooltip-top___2vvYM .sidebar-module__arrow___1nMVL::before, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=top] .sidebar-module__arrow___1nMVL::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.sidebar-module__bs-tooltip-right___3HKcp, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=right] {
  padding: 0 0.4rem;
}
.sidebar-module__bs-tooltip-right___3HKcp .sidebar-module__arrow___1nMVL, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=right] .sidebar-module__arrow___1nMVL {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.sidebar-module__bs-tooltip-right___3HKcp .sidebar-module__arrow___1nMVL::before, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=right] .sidebar-module__arrow___1nMVL::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.sidebar-module__bs-tooltip-bottom___3-zlv, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.sidebar-module__bs-tooltip-bottom___3-zlv .sidebar-module__arrow___1nMVL, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=bottom] .sidebar-module__arrow___1nMVL {
  top: 0;
}
.sidebar-module__bs-tooltip-bottom___3-zlv .sidebar-module__arrow___1nMVL::before, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=bottom] .sidebar-module__arrow___1nMVL::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.sidebar-module__bs-tooltip-left___1Fknm, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=left] {
  padding: 0 0.4rem;
}
.sidebar-module__bs-tooltip-left___1Fknm .sidebar-module__arrow___1nMVL, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=left] .sidebar-module__arrow___1nMVL {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.sidebar-module__bs-tooltip-left___1Fknm .sidebar-module__arrow___1nMVL::before, .sidebar-module__bs-tooltip-auto___1bHCj[x-placement^=left] .sidebar-module__arrow___1nMVL::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.sidebar-module__tooltip-inner___3Zbrc {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.sidebar-module__popover___1Rx7- {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.sidebar-module__popover___1Rx7- .sidebar-module__arrow___1nMVL {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.sidebar-module__popover___1Rx7- .sidebar-module__arrow___1nMVL::before, .sidebar-module__popover___1Rx7- .sidebar-module__arrow___1nMVL::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.sidebar-module__bs-popover-top___2-OJp, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.sidebar-module__bs-popover-top___2-OJp > .sidebar-module__arrow___1nMVL, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=top] > .sidebar-module__arrow___1nMVL {
  bottom: calc((0.5rem + 1px) * -1);
}
.sidebar-module__bs-popover-top___2-OJp > .sidebar-module__arrow___1nMVL::before, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=top] > .sidebar-module__arrow___1nMVL::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.sidebar-module__bs-popover-top___2-OJp > .sidebar-module__arrow___1nMVL::after, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=top] > .sidebar-module__arrow___1nMVL::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.sidebar-module__bs-popover-right___1g5bj, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=right] {
  margin-left: 0.5rem;
}
.sidebar-module__bs-popover-right___1g5bj > .sidebar-module__arrow___1nMVL, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=right] > .sidebar-module__arrow___1nMVL {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.sidebar-module__bs-popover-right___1g5bj > .sidebar-module__arrow___1nMVL::before, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=right] > .sidebar-module__arrow___1nMVL::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.sidebar-module__bs-popover-right___1g5bj > .sidebar-module__arrow___1nMVL::after, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=right] > .sidebar-module__arrow___1nMVL::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.sidebar-module__bs-popover-bottom___2c88u, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.sidebar-module__bs-popover-bottom___2c88u > .sidebar-module__arrow___1nMVL, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=bottom] > .sidebar-module__arrow___1nMVL {
  top: calc((0.5rem + 1px) * -1);
}
.sidebar-module__bs-popover-bottom___2c88u > .sidebar-module__arrow___1nMVL::before, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=bottom] > .sidebar-module__arrow___1nMVL::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.sidebar-module__bs-popover-bottom___2c88u > .sidebar-module__arrow___1nMVL::after, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=bottom] > .sidebar-module__arrow___1nMVL::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.sidebar-module__bs-popover-bottom___2c88u .sidebar-module__popover-header___n9Hv2::before, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=bottom] .sidebar-module__popover-header___n9Hv2::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.sidebar-module__bs-popover-left___1dqN5, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=left] {
  margin-right: 0.5rem;
}
.sidebar-module__bs-popover-left___1dqN5 > .sidebar-module__arrow___1nMVL, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=left] > .sidebar-module__arrow___1nMVL {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.sidebar-module__bs-popover-left___1dqN5 > .sidebar-module__arrow___1nMVL::before, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=left] > .sidebar-module__arrow___1nMVL::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.sidebar-module__bs-popover-left___1dqN5 > .sidebar-module__arrow___1nMVL::after, .sidebar-module__bs-popover-auto___2ZKDE[x-placement^=left] > .sidebar-module__arrow___1nMVL::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.sidebar-module__popover-header___n9Hv2 {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.sidebar-module__popover-header___n9Hv2:empty {
  display: none;
}

.sidebar-module__popover-body___2S48y {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.sidebar-module__carousel___3Xr-Z {
  position: relative;
}

.sidebar-module__carousel___3Xr-Z.sidebar-module__pointer-event___352B6 {
  touch-action: pan-y;
}

.sidebar-module__carousel-inner___1M_Vn {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.sidebar-module__carousel-inner___1M_Vn::after {
  display: block;
  clear: both;
  content: "";
}

.sidebar-module__carousel-item___dLNTR {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__carousel-item___dLNTR {
    transition: none;
  }
}

.sidebar-module__carousel-item___dLNTR.sidebar-module__active___1kKQT,
.sidebar-module__carousel-item-next___uvUur,
.sidebar-module__carousel-item-prev___3YuHR {
  display: block;
}

.sidebar-module__carousel-item-next___uvUur:not(.sidebar-module__carousel-item-left___28Kdn),
.sidebar-module__active___1kKQT.sidebar-module__carousel-item-right___2spe6 {
  transform: translateX(100%);
}

.sidebar-module__carousel-item-prev___3YuHR:not(.sidebar-module__carousel-item-right___2spe6),
.sidebar-module__active___1kKQT.sidebar-module__carousel-item-left___28Kdn {
  transform: translateX(-100%);
}

.sidebar-module__carousel-fade___iMPbD .sidebar-module__carousel-item___dLNTR {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.sidebar-module__carousel-fade___iMPbD .sidebar-module__carousel-item___dLNTR.sidebar-module__active___1kKQT,
.sidebar-module__carousel-fade___iMPbD .sidebar-module__carousel-item-next___uvUur.sidebar-module__carousel-item-left___28Kdn,
.sidebar-module__carousel-fade___iMPbD .sidebar-module__carousel-item-prev___3YuHR.sidebar-module__carousel-item-right___2spe6 {
  z-index: 1;
  opacity: 1;
}
.sidebar-module__carousel-fade___iMPbD .sidebar-module__active___1kKQT.sidebar-module__carousel-item-left___28Kdn,
.sidebar-module__carousel-fade___iMPbD .sidebar-module__active___1kKQT.sidebar-module__carousel-item-right___2spe6 {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__carousel-fade___iMPbD .sidebar-module__active___1kKQT.sidebar-module__carousel-item-left___28Kdn,
.sidebar-module__carousel-fade___iMPbD .sidebar-module__active___1kKQT.sidebar-module__carousel-item-right___2spe6 {
    transition: none;
  }
}

.sidebar-module__carousel-control-prev___v35ho,
.sidebar-module__carousel-control-next___1mMrH {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__carousel-control-prev___v35ho,
.sidebar-module__carousel-control-next___1mMrH {
    transition: none;
  }
}
.sidebar-module__carousel-control-prev___v35ho:hover, .sidebar-module__carousel-control-prev___v35ho:focus,
.sidebar-module__carousel-control-next___1mMrH:hover,
.sidebar-module__carousel-control-next___1mMrH:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.sidebar-module__carousel-control-prev___v35ho {
  left: 0;
}

.sidebar-module__carousel-control-next___1mMrH {
  right: 0;
}

.sidebar-module__carousel-control-prev-icon___2YhjI,
.sidebar-module__carousel-control-next-icon___i0BAY {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.sidebar-module__carousel-control-prev-icon___2YhjI {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.sidebar-module__carousel-control-next-icon___i0BAY {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.sidebar-module__carousel-indicators___33CIC {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.sidebar-module__carousel-indicators___33CIC li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-module__carousel-indicators___33CIC li {
    transition: none;
  }
}
.sidebar-module__carousel-indicators___33CIC .sidebar-module__active___1kKQT {
  opacity: 1;
}

.sidebar-module__carousel-caption___-2TdN {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes sidebar-module__spinner-border___2VCIL {
  to {
    transform: rotate(360deg);
  }
}
.sidebar-module__spinner-border___2VCIL {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: sidebar-module__spinner-border___2VCIL 0.75s linear infinite;
}

.sidebar-module__spinner-border-sm___3bXpS {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes sidebar-module__spinner-grow___14Ngj {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.sidebar-module__spinner-grow___14Ngj {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: sidebar-module__spinner-grow___14Ngj 0.75s linear infinite;
}

.sidebar-module__spinner-grow-sm___3XraM {
  width: 1rem;
  height: 1rem;
}

.sidebar-module__align-baseline___3lFE3 {
  vertical-align: baseline !important;
}

.sidebar-module__align-top___2o7QP {
  vertical-align: top !important;
}

.sidebar-module__align-middle___3rq1I {
  vertical-align: middle !important;
}

.sidebar-module__align-bottom___2FpZ5 {
  vertical-align: bottom !important;
}

.sidebar-module__align-text-bottom___1uzzS {
  vertical-align: text-bottom !important;
}

.sidebar-module__align-text-top___1MxsN {
  vertical-align: text-top !important;
}

.sidebar-module__bg-primary___2hRmQ {
  background-color: #007bff !important;
}

a.sidebar-module__bg-primary___2hRmQ:hover, a.sidebar-module__bg-primary___2hRmQ:focus,
button.sidebar-module__bg-primary___2hRmQ:hover,
button.sidebar-module__bg-primary___2hRmQ:focus {
  background-color: #0062cc !important;
}

.sidebar-module__bg-secondary___2muhu {
  background-color: #6c757d !important;
}

a.sidebar-module__bg-secondary___2muhu:hover, a.sidebar-module__bg-secondary___2muhu:focus,
button.sidebar-module__bg-secondary___2muhu:hover,
button.sidebar-module__bg-secondary___2muhu:focus {
  background-color: #545b62 !important;
}

.sidebar-module__bg-success___1MsxU {
  background-color: #28a745 !important;
}

a.sidebar-module__bg-success___1MsxU:hover, a.sidebar-module__bg-success___1MsxU:focus,
button.sidebar-module__bg-success___1MsxU:hover,
button.sidebar-module__bg-success___1MsxU:focus {
  background-color: #1e7e34 !important;
}

.sidebar-module__bg-info___zPMvp {
  background-color: #17a2b8 !important;
}

a.sidebar-module__bg-info___zPMvp:hover, a.sidebar-module__bg-info___zPMvp:focus,
button.sidebar-module__bg-info___zPMvp:hover,
button.sidebar-module__bg-info___zPMvp:focus {
  background-color: #117a8b !important;
}

.sidebar-module__bg-warning___1fuwL {
  background-color: #ffc107 !important;
}

a.sidebar-module__bg-warning___1fuwL:hover, a.sidebar-module__bg-warning___1fuwL:focus,
button.sidebar-module__bg-warning___1fuwL:hover,
button.sidebar-module__bg-warning___1fuwL:focus {
  background-color: #d39e00 !important;
}

.sidebar-module__bg-danger___1TxRf {
  background-color: #dc3545 !important;
}

a.sidebar-module__bg-danger___1TxRf:hover, a.sidebar-module__bg-danger___1TxRf:focus,
button.sidebar-module__bg-danger___1TxRf:hover,
button.sidebar-module__bg-danger___1TxRf:focus {
  background-color: #bd2130 !important;
}

.sidebar-module__bg-light___30dIC {
  background-color: #f8f9fa !important;
}

a.sidebar-module__bg-light___30dIC:hover, a.sidebar-module__bg-light___30dIC:focus,
button.sidebar-module__bg-light___30dIC:hover,
button.sidebar-module__bg-light___30dIC:focus {
  background-color: #dae0e5 !important;
}

.sidebar-module__bg-dark___29HBN {
  background-color: #343a40 !important;
}

a.sidebar-module__bg-dark___29HBN:hover, a.sidebar-module__bg-dark___29HBN:focus,
button.sidebar-module__bg-dark___29HBN:hover,
button.sidebar-module__bg-dark___29HBN:focus {
  background-color: #1d2124 !important;
}

.sidebar-module__bg-white___32Stb {
  background-color: #fff !important;
}

.sidebar-module__bg-transparent___1zCAn {
  background-color: transparent !important;
}

.sidebar-module__border___3Al7H {
  border: 1px solid #dee2e6 !important;
}

.sidebar-module__border-top___1LTmf {
  border-top: 1px solid #dee2e6 !important;
}

.sidebar-module__border-right___3blQz {
  border-right: 1px solid #dee2e6 !important;
}

.sidebar-module__border-bottom___132Fu {
  border-bottom: 1px solid #dee2e6 !important;
}

.sidebar-module__border-left___1NT2x {
  border-left: 1px solid #dee2e6 !important;
}

.sidebar-module__border-0___Ab9Eq {
  border: 0 !important;
}

.sidebar-module__border-top-0___1cuow {
  border-top: 0 !important;
}

.sidebar-module__border-right-0___3db-E {
  border-right: 0 !important;
}

.sidebar-module__border-bottom-0___17w2J {
  border-bottom: 0 !important;
}

.sidebar-module__border-left-0___3cLh3 {
  border-left: 0 !important;
}

.sidebar-module__border-primary___3I9ao {
  border-color: #007bff !important;
}

.sidebar-module__border-secondary___1EZ4Q {
  border-color: #6c757d !important;
}

.sidebar-module__border-success___vtcWn {
  border-color: #28a745 !important;
}

.sidebar-module__border-info___bBfiF {
  border-color: #17a2b8 !important;
}

.sidebar-module__border-warning___DnaWD {
  border-color: #ffc107 !important;
}

.sidebar-module__border-danger___1rxmI {
  border-color: #dc3545 !important;
}

.sidebar-module__border-light___1yfkw {
  border-color: #f8f9fa !important;
}

.sidebar-module__border-dark___3tJjZ {
  border-color: #343a40 !important;
}

.sidebar-module__border-white___2zaUi {
  border-color: #fff !important;
}

.sidebar-module__rounded-sm___3L6fl {
  border-radius: 0.2rem !important;
}

.sidebar-module__rounded___1MEIu {
  border-radius: 0.25rem !important;
}

.sidebar-module__rounded-top___2DJHI {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.sidebar-module__rounded-right___T0BtQ {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.sidebar-module__rounded-bottom___1YJ90 {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.sidebar-module__rounded-left___323ud {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.sidebar-module__rounded-lg___1t2mp {
  border-radius: 0.3rem !important;
}

.sidebar-module__rounded-circle___13YWb {
  border-radius: 50% !important;
}

.sidebar-module__rounded-pill___Qlnxh {
  border-radius: 50rem !important;
}

.sidebar-module__rounded-0___2-2Dk {
  border-radius: 0 !important;
}

.sidebar-module__clearfix___3IAKW::after {
  display: block;
  clear: both;
  content: "";
}

.sidebar-module__d-none___3bjKD {
  display: none !important;
}

.sidebar-module__d-inline___U968L {
  display: inline !important;
}

.sidebar-module__d-inline-block___2m_Hj {
  display: inline-block !important;
}

.sidebar-module__d-block___K36Ow {
  display: block !important;
}

.sidebar-module__d-table___Gqfuy {
  display: table !important;
}

.sidebar-module__d-table-row___3ML3q {
  display: table-row !important;
}

.sidebar-module__d-table-cell___2Hg96 {
  display: table-cell !important;
}

.sidebar-module__d-flex___h4OEy, .sidebar-module__separator-wrapper___2CUH1 {
  display: flex !important;
}

.sidebar-module__d-inline-flex___1-DCK {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .sidebar-module__d-sm-none___1j0bv {
    display: none !important;
  }

  .sidebar-module__d-sm-inline___2VtUe {
    display: inline !important;
  }

  .sidebar-module__d-sm-inline-block___B7POL {
    display: inline-block !important;
  }

  .sidebar-module__d-sm-block___3Z-49 {
    display: block !important;
  }

  .sidebar-module__d-sm-table___22Z3y {
    display: table !important;
  }

  .sidebar-module__d-sm-table-row___2XHKv {
    display: table-row !important;
  }

  .sidebar-module__d-sm-table-cell___3QC1H {
    display: table-cell !important;
  }

  .sidebar-module__d-sm-flex___lmIhN {
    display: flex !important;
  }

  .sidebar-module__d-sm-inline-flex___25gem {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .sidebar-module__d-md-none___uSg16 {
    display: none !important;
  }

  .sidebar-module__d-md-inline___1-IZ6 {
    display: inline !important;
  }

  .sidebar-module__d-md-inline-block___1I2JO {
    display: inline-block !important;
  }

  .sidebar-module__d-md-block___nht2e {
    display: block !important;
  }

  .sidebar-module__d-md-table___192mZ {
    display: table !important;
  }

  .sidebar-module__d-md-table-row___23uog {
    display: table-row !important;
  }

  .sidebar-module__d-md-table-cell___qELiG {
    display: table-cell !important;
  }

  .sidebar-module__d-md-flex___1zWhV {
    display: flex !important;
  }

  .sidebar-module__d-md-inline-flex___kTVH5 {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .sidebar-module__d-lg-none___15WH8 {
    display: none !important;
  }

  .sidebar-module__d-lg-inline___1IJzu {
    display: inline !important;
  }

  .sidebar-module__d-lg-inline-block___btSXK {
    display: inline-block !important;
  }

  .sidebar-module__d-lg-block___V6Xtb {
    display: block !important;
  }

  .sidebar-module__d-lg-table___27SW0 {
    display: table !important;
  }

  .sidebar-module__d-lg-table-row___3GWhF {
    display: table-row !important;
  }

  .sidebar-module__d-lg-table-cell___1a9X5 {
    display: table-cell !important;
  }

  .sidebar-module__d-lg-flex___1b_jj {
    display: flex !important;
  }

  .sidebar-module__d-lg-inline-flex___1Wiy5 {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .sidebar-module__d-xl-none___3hQjY {
    display: none !important;
  }

  .sidebar-module__d-xl-inline___zROAj {
    display: inline !important;
  }

  .sidebar-module__d-xl-inline-block___nNs36 {
    display: inline-block !important;
  }

  .sidebar-module__d-xl-block___3-L9l {
    display: block !important;
  }

  .sidebar-module__d-xl-table___1kehK {
    display: table !important;
  }

  .sidebar-module__d-xl-table-row___ptvql {
    display: table-row !important;
  }

  .sidebar-module__d-xl-table-cell___1Q7d- {
    display: table-cell !important;
  }

  .sidebar-module__d-xl-flex___QwcEq {
    display: flex !important;
  }

  .sidebar-module__d-xl-inline-flex___bF2rZ {
    display: inline-flex !important;
  }
}
@media print {
  .sidebar-module__d-print-none___1Saaq {
    display: none !important;
  }

  .sidebar-module__d-print-inline___1OCfZ {
    display: inline !important;
  }

  .sidebar-module__d-print-inline-block___3n7A7 {
    display: inline-block !important;
  }

  .sidebar-module__d-print-block___2AVI8 {
    display: block !important;
  }

  .sidebar-module__d-print-table___1Dqhy {
    display: table !important;
  }

  .sidebar-module__d-print-table-row___368qB {
    display: table-row !important;
  }

  .sidebar-module__d-print-table-cell___W7ZJz {
    display: table-cell !important;
  }

  .sidebar-module__d-print-flex___cdPdj {
    display: flex !important;
  }

  .sidebar-module__d-print-inline-flex___cwq1S {
    display: inline-flex !important;
  }
}
.sidebar-module__embed-responsive___1acdi {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.sidebar-module__embed-responsive___1acdi::before {
  display: block;
  content: "";
}
.sidebar-module__embed-responsive___1acdi .sidebar-module__embed-responsive-item___1ae3t,
.sidebar-module__embed-responsive___1acdi iframe,
.sidebar-module__embed-responsive___1acdi embed,
.sidebar-module__embed-responsive___1acdi object,
.sidebar-module__embed-responsive___1acdi video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.sidebar-module__embed-responsive-21by9___3fiVw::before {
  padding-top: 42.8571428571%;
}

.sidebar-module__embed-responsive-16by9___1NrpQ::before {
  padding-top: 56.25%;
}

.sidebar-module__embed-responsive-4by3___1jl8V::before {
  padding-top: 75%;
}

.sidebar-module__embed-responsive-1by1___O53U2::before {
  padding-top: 100%;
}

.sidebar-module__flex-row___hsMLJ, .sidebar-module__separator-wrapper___2CUH1 {
  flex-direction: row !important;
}

.sidebar-module__flex-column___qvwJo, .sidebar-module__context-menu___3TiWZ, .sidebar-module__main-menu___XmrdH {
  flex-direction: column !important;
}

.sidebar-module__flex-row-reverse___1lAls {
  flex-direction: row-reverse !important;
}

.sidebar-module__flex-column-reverse___aUlLM {
  flex-direction: column-reverse !important;
}

.sidebar-module__flex-wrap___bMLqT {
  flex-wrap: wrap !important;
}

.sidebar-module__flex-nowrap___1byVp {
  flex-wrap: nowrap !important;
}

.sidebar-module__flex-wrap-reverse___3cVn- {
  flex-wrap: wrap-reverse !important;
}

.sidebar-module__flex-fill___aI65I {
  flex: 1 1 auto !important;
}

.sidebar-module__flex-grow-0___6SxMN {
  flex-grow: 0 !important;
}

.sidebar-module__flex-grow-1___VUcJ0 {
  flex-grow: 1 !important;
}

.sidebar-module__flex-shrink-0___1Z0cu {
  flex-shrink: 0 !important;
}

.sidebar-module__flex-shrink-1___3-Z7H {
  flex-shrink: 1 !important;
}

.sidebar-module__justify-content-start___YnaYL {
  justify-content: flex-start !important;
}

.sidebar-module__justify-content-end___3NXu- {
  justify-content: flex-end !important;
}

.sidebar-module__justify-content-center___3wGFI, .sidebar-module__separator-wrapper___2CUH1 {
  justify-content: center !important;
}

.sidebar-module__justify-content-between___3wgVJ {
  justify-content: space-between !important;
}

.sidebar-module__justify-content-around___2oXgw {
  justify-content: space-around !important;
}

.sidebar-module__align-items-start___3etwO {
  align-items: flex-start !important;
}

.sidebar-module__align-items-end___2cwoN {
  align-items: flex-end !important;
}

.sidebar-module__align-items-center___2k01H {
  align-items: center !important;
}

.sidebar-module__align-items-baseline___1vOfB {
  align-items: baseline !important;
}

.sidebar-module__align-items-stretch___Jqcoy {
  align-items: stretch !important;
}

.sidebar-module__align-content-start___us5gJ {
  align-content: flex-start !important;
}

.sidebar-module__align-content-end___3LAri {
  align-content: flex-end !important;
}

.sidebar-module__align-content-center___3Jvs4 {
  align-content: center !important;
}

.sidebar-module__align-content-between___29vv9 {
  align-content: space-between !important;
}

.sidebar-module__align-content-around___1Eh9v {
  align-content: space-around !important;
}

.sidebar-module__align-content-stretch___1bj-B {
  align-content: stretch !important;
}

.sidebar-module__align-self-auto___2tOKN {
  align-self: auto !important;
}

.sidebar-module__align-self-start___3y8KZ {
  align-self: flex-start !important;
}

.sidebar-module__align-self-end___1IE5_ {
  align-self: flex-end !important;
}

.sidebar-module__align-self-center___36kZW {
  align-self: center !important;
}

.sidebar-module__align-self-baseline___cwgUs {
  align-self: baseline !important;
}

.sidebar-module__align-self-stretch___3VnRD {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .sidebar-module__flex-sm-row___8H0K7 {
    flex-direction: row !important;
  }

  .sidebar-module__flex-sm-column___1St2I {
    flex-direction: column !important;
  }

  .sidebar-module__flex-sm-row-reverse___7uKWC {
    flex-direction: row-reverse !important;
  }

  .sidebar-module__flex-sm-column-reverse___CXxoU {
    flex-direction: column-reverse !important;
  }

  .sidebar-module__flex-sm-wrap___1cgjk {
    flex-wrap: wrap !important;
  }

  .sidebar-module__flex-sm-nowrap___k3M34 {
    flex-wrap: nowrap !important;
  }

  .sidebar-module__flex-sm-wrap-reverse___1pC28 {
    flex-wrap: wrap-reverse !important;
  }

  .sidebar-module__flex-sm-fill___1y9Yy {
    flex: 1 1 auto !important;
  }

  .sidebar-module__flex-sm-grow-0___802aA {
    flex-grow: 0 !important;
  }

  .sidebar-module__flex-sm-grow-1___2Yt2- {
    flex-grow: 1 !important;
  }

  .sidebar-module__flex-sm-shrink-0___96wC1 {
    flex-shrink: 0 !important;
  }

  .sidebar-module__flex-sm-shrink-1___3zUcc {
    flex-shrink: 1 !important;
  }

  .sidebar-module__justify-content-sm-start___2vhp5 {
    justify-content: flex-start !important;
  }

  .sidebar-module__justify-content-sm-end___RSVyn {
    justify-content: flex-end !important;
  }

  .sidebar-module__justify-content-sm-center___2G07Y {
    justify-content: center !important;
  }

  .sidebar-module__justify-content-sm-between___2Eutm {
    justify-content: space-between !important;
  }

  .sidebar-module__justify-content-sm-around___3WU34 {
    justify-content: space-around !important;
  }

  .sidebar-module__align-items-sm-start___2lEca {
    align-items: flex-start !important;
  }

  .sidebar-module__align-items-sm-end___1jDC9 {
    align-items: flex-end !important;
  }

  .sidebar-module__align-items-sm-center___10ifj {
    align-items: center !important;
  }

  .sidebar-module__align-items-sm-baseline___11Hhb {
    align-items: baseline !important;
  }

  .sidebar-module__align-items-sm-stretch___m2ENP {
    align-items: stretch !important;
  }

  .sidebar-module__align-content-sm-start___3gJBy {
    align-content: flex-start !important;
  }

  .sidebar-module__align-content-sm-end___1t-ap {
    align-content: flex-end !important;
  }

  .sidebar-module__align-content-sm-center___bH6t_ {
    align-content: center !important;
  }

  .sidebar-module__align-content-sm-between___1K9nC {
    align-content: space-between !important;
  }

  .sidebar-module__align-content-sm-around___2yFhZ {
    align-content: space-around !important;
  }

  .sidebar-module__align-content-sm-stretch___KNCkM {
    align-content: stretch !important;
  }

  .sidebar-module__align-self-sm-auto___2-MXO {
    align-self: auto !important;
  }

  .sidebar-module__align-self-sm-start___2dKP8 {
    align-self: flex-start !important;
  }

  .sidebar-module__align-self-sm-end___SlImx {
    align-self: flex-end !important;
  }

  .sidebar-module__align-self-sm-center___1t4rS {
    align-self: center !important;
  }

  .sidebar-module__align-self-sm-baseline___1TLI8 {
    align-self: baseline !important;
  }

  .sidebar-module__align-self-sm-stretch___p6Gh9 {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .sidebar-module__flex-md-row___2Y6GC {
    flex-direction: row !important;
  }

  .sidebar-module__flex-md-column___BYYtK {
    flex-direction: column !important;
  }

  .sidebar-module__flex-md-row-reverse___2c-yn {
    flex-direction: row-reverse !important;
  }

  .sidebar-module__flex-md-column-reverse___tfb9z {
    flex-direction: column-reverse !important;
  }

  .sidebar-module__flex-md-wrap___2xZd- {
    flex-wrap: wrap !important;
  }

  .sidebar-module__flex-md-nowrap___1L15D {
    flex-wrap: nowrap !important;
  }

  .sidebar-module__flex-md-wrap-reverse___3Qt5F {
    flex-wrap: wrap-reverse !important;
  }

  .sidebar-module__flex-md-fill___3o7Nt {
    flex: 1 1 auto !important;
  }

  .sidebar-module__flex-md-grow-0___KNcFU {
    flex-grow: 0 !important;
  }

  .sidebar-module__flex-md-grow-1___1cx1g {
    flex-grow: 1 !important;
  }

  .sidebar-module__flex-md-shrink-0___21gF2 {
    flex-shrink: 0 !important;
  }

  .sidebar-module__flex-md-shrink-1___2umvX {
    flex-shrink: 1 !important;
  }

  .sidebar-module__justify-content-md-start___1t3AV {
    justify-content: flex-start !important;
  }

  .sidebar-module__justify-content-md-end___49Obp {
    justify-content: flex-end !important;
  }

  .sidebar-module__justify-content-md-center___3l-Qs {
    justify-content: center !important;
  }

  .sidebar-module__justify-content-md-between___2KHaM {
    justify-content: space-between !important;
  }

  .sidebar-module__justify-content-md-around___2myw6 {
    justify-content: space-around !important;
  }

  .sidebar-module__align-items-md-start___2nXMm {
    align-items: flex-start !important;
  }

  .sidebar-module__align-items-md-end___sGfL2 {
    align-items: flex-end !important;
  }

  .sidebar-module__align-items-md-center___WTegf {
    align-items: center !important;
  }

  .sidebar-module__align-items-md-baseline___3cAVo {
    align-items: baseline !important;
  }

  .sidebar-module__align-items-md-stretch___2FTax {
    align-items: stretch !important;
  }

  .sidebar-module__align-content-md-start___3zeit {
    align-content: flex-start !important;
  }

  .sidebar-module__align-content-md-end___2TAvr {
    align-content: flex-end !important;
  }

  .sidebar-module__align-content-md-center___pvlgI {
    align-content: center !important;
  }

  .sidebar-module__align-content-md-between___tk4TQ {
    align-content: space-between !important;
  }

  .sidebar-module__align-content-md-around___1nV5i {
    align-content: space-around !important;
  }

  .sidebar-module__align-content-md-stretch___1Oahs {
    align-content: stretch !important;
  }

  .sidebar-module__align-self-md-auto___2mHKz {
    align-self: auto !important;
  }

  .sidebar-module__align-self-md-start___y1VRr {
    align-self: flex-start !important;
  }

  .sidebar-module__align-self-md-end___2VsM_ {
    align-self: flex-end !important;
  }

  .sidebar-module__align-self-md-center___2eWXT {
    align-self: center !important;
  }

  .sidebar-module__align-self-md-baseline___tDu3D {
    align-self: baseline !important;
  }

  .sidebar-module__align-self-md-stretch___1rFz- {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .sidebar-module__flex-lg-row___p4f-w {
    flex-direction: row !important;
  }

  .sidebar-module__flex-lg-column___1cZRc {
    flex-direction: column !important;
  }

  .sidebar-module__flex-lg-row-reverse___23i0N {
    flex-direction: row-reverse !important;
  }

  .sidebar-module__flex-lg-column-reverse___2JsmL {
    flex-direction: column-reverse !important;
  }

  .sidebar-module__flex-lg-wrap____TF8s {
    flex-wrap: wrap !important;
  }

  .sidebar-module__flex-lg-nowrap___3FCuy {
    flex-wrap: nowrap !important;
  }

  .sidebar-module__flex-lg-wrap-reverse___2N91y {
    flex-wrap: wrap-reverse !important;
  }

  .sidebar-module__flex-lg-fill___2AgyR {
    flex: 1 1 auto !important;
  }

  .sidebar-module__flex-lg-grow-0___YHhGz {
    flex-grow: 0 !important;
  }

  .sidebar-module__flex-lg-grow-1___2qTNG {
    flex-grow: 1 !important;
  }

  .sidebar-module__flex-lg-shrink-0___1S_12 {
    flex-shrink: 0 !important;
  }

  .sidebar-module__flex-lg-shrink-1___3uaWP {
    flex-shrink: 1 !important;
  }

  .sidebar-module__justify-content-lg-start___2PnZx {
    justify-content: flex-start !important;
  }

  .sidebar-module__justify-content-lg-end___1Wh4e {
    justify-content: flex-end !important;
  }

  .sidebar-module__justify-content-lg-center___2pwNf {
    justify-content: center !important;
  }

  .sidebar-module__justify-content-lg-between___epBb4 {
    justify-content: space-between !important;
  }

  .sidebar-module__justify-content-lg-around___1PdJU {
    justify-content: space-around !important;
  }

  .sidebar-module__align-items-lg-start___ElD9g {
    align-items: flex-start !important;
  }

  .sidebar-module__align-items-lg-end___1koc3 {
    align-items: flex-end !important;
  }

  .sidebar-module__align-items-lg-center___2EfR6 {
    align-items: center !important;
  }

  .sidebar-module__align-items-lg-baseline___3dAOE {
    align-items: baseline !important;
  }

  .sidebar-module__align-items-lg-stretch___88b2J {
    align-items: stretch !important;
  }

  .sidebar-module__align-content-lg-start___3wkiZ {
    align-content: flex-start !important;
  }

  .sidebar-module__align-content-lg-end___iIeEp {
    align-content: flex-end !important;
  }

  .sidebar-module__align-content-lg-center___18JFi {
    align-content: center !important;
  }

  .sidebar-module__align-content-lg-between___1OgqJ {
    align-content: space-between !important;
  }

  .sidebar-module__align-content-lg-around___2q269 {
    align-content: space-around !important;
  }

  .sidebar-module__align-content-lg-stretch___2NiN9 {
    align-content: stretch !important;
  }

  .sidebar-module__align-self-lg-auto___2Cf8B {
    align-self: auto !important;
  }

  .sidebar-module__align-self-lg-start___35BwW {
    align-self: flex-start !important;
  }

  .sidebar-module__align-self-lg-end___3IRpR {
    align-self: flex-end !important;
  }

  .sidebar-module__align-self-lg-center___1yx96 {
    align-self: center !important;
  }

  .sidebar-module__align-self-lg-baseline___mU7wR {
    align-self: baseline !important;
  }

  .sidebar-module__align-self-lg-stretch___3X3lY {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .sidebar-module__flex-xl-row___2CxCv {
    flex-direction: row !important;
  }

  .sidebar-module__flex-xl-column___XOoam {
    flex-direction: column !important;
  }

  .sidebar-module__flex-xl-row-reverse___3pyoy {
    flex-direction: row-reverse !important;
  }

  .sidebar-module__flex-xl-column-reverse___2vXmY {
    flex-direction: column-reverse !important;
  }

  .sidebar-module__flex-xl-wrap___1RS0X {
    flex-wrap: wrap !important;
  }

  .sidebar-module__flex-xl-nowrap___1qpJE {
    flex-wrap: nowrap !important;
  }

  .sidebar-module__flex-xl-wrap-reverse___2QK7X {
    flex-wrap: wrap-reverse !important;
  }

  .sidebar-module__flex-xl-fill___3uRDX {
    flex: 1 1 auto !important;
  }

  .sidebar-module__flex-xl-grow-0___2zLaJ {
    flex-grow: 0 !important;
  }

  .sidebar-module__flex-xl-grow-1___3i3hB {
    flex-grow: 1 !important;
  }

  .sidebar-module__flex-xl-shrink-0___P6Qhh {
    flex-shrink: 0 !important;
  }

  .sidebar-module__flex-xl-shrink-1___YCk1N {
    flex-shrink: 1 !important;
  }

  .sidebar-module__justify-content-xl-start___1kzlq {
    justify-content: flex-start !important;
  }

  .sidebar-module__justify-content-xl-end___enMo7 {
    justify-content: flex-end !important;
  }

  .sidebar-module__justify-content-xl-center___3iIHw {
    justify-content: center !important;
  }

  .sidebar-module__justify-content-xl-between___3aneM {
    justify-content: space-between !important;
  }

  .sidebar-module__justify-content-xl-around___YKExM {
    justify-content: space-around !important;
  }

  .sidebar-module__align-items-xl-start___1_ji1 {
    align-items: flex-start !important;
  }

  .sidebar-module__align-items-xl-end___15d34 {
    align-items: flex-end !important;
  }

  .sidebar-module__align-items-xl-center___2oxXz {
    align-items: center !important;
  }

  .sidebar-module__align-items-xl-baseline___1DVql {
    align-items: baseline !important;
  }

  .sidebar-module__align-items-xl-stretch___CJL_k {
    align-items: stretch !important;
  }

  .sidebar-module__align-content-xl-start___DYgK1 {
    align-content: flex-start !important;
  }

  .sidebar-module__align-content-xl-end___OogJP {
    align-content: flex-end !important;
  }

  .sidebar-module__align-content-xl-center___2gt7m {
    align-content: center !important;
  }

  .sidebar-module__align-content-xl-between___2Djh9 {
    align-content: space-between !important;
  }

  .sidebar-module__align-content-xl-around___1ulj3 {
    align-content: space-around !important;
  }

  .sidebar-module__align-content-xl-stretch___3XXJm {
    align-content: stretch !important;
  }

  .sidebar-module__align-self-xl-auto___1HqPh {
    align-self: auto !important;
  }

  .sidebar-module__align-self-xl-start___282sW {
    align-self: flex-start !important;
  }

  .sidebar-module__align-self-xl-end___34CIV {
    align-self: flex-end !important;
  }

  .sidebar-module__align-self-xl-center___1HVrH {
    align-self: center !important;
  }

  .sidebar-module__align-self-xl-baseline___H4vIc {
    align-self: baseline !important;
  }

  .sidebar-module__align-self-xl-stretch___1qmxr {
    align-self: stretch !important;
  }
}
.sidebar-module__float-left___3-aOd {
  float: left !important;
}

.sidebar-module__float-right___2q_Vh {
  float: right !important;
}

.sidebar-module__float-none___2vZdg {
  float: none !important;
}

@media (min-width: 576px) {
  .sidebar-module__float-sm-left___c8eiU {
    float: left !important;
  }

  .sidebar-module__float-sm-right___nUYCW {
    float: right !important;
  }

  .sidebar-module__float-sm-none___1PvYn {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .sidebar-module__float-md-left___1_P5V {
    float: left !important;
  }

  .sidebar-module__float-md-right___1mcK9 {
    float: right !important;
  }

  .sidebar-module__float-md-none___337Mi {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .sidebar-module__float-lg-left___m3xlL {
    float: left !important;
  }

  .sidebar-module__float-lg-right___2XIXt {
    float: right !important;
  }

  .sidebar-module__float-lg-none___giT33 {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .sidebar-module__float-xl-left___1DunC {
    float: left !important;
  }

  .sidebar-module__float-xl-right___cxe1A {
    float: right !important;
  }

  .sidebar-module__float-xl-none___3m2uF {
    float: none !important;
  }
}
.sidebar-module__overflow-auto___1ll8I {
  overflow: auto !important;
}

.sidebar-module__overflow-hidden___2q36E {
  overflow: hidden !important;
}

.sidebar-module__position-static___2ktXW {
  position: static !important;
}

.sidebar-module__position-relative___1KRG6 {
  position: relative !important;
}

.sidebar-module__position-absolute___HdJYv {
  position: absolute !important;
}

.sidebar-module__position-fixed___2W94w {
  position: fixed !important;
}

.sidebar-module__position-sticky___21kxO {
  position: sticky !important;
}

.sidebar-module__fixed-top___EO_m8 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.sidebar-module__fixed-bottom___1M9D1 {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sidebar-module__sticky-top___Q4Z_- {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sidebar-module__sr-only___3RFBz {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sidebar-module__sr-only-focusable___1Qz1j:active, .sidebar-module__sr-only-focusable___1Qz1j:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.sidebar-module__shadow-sm___2eUG9 {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.sidebar-module__shadow___20b7X {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.sidebar-module__shadow-lg___3Ow4m {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.sidebar-module__shadow-none___2s5zM {
  box-shadow: none !important;
}

.sidebar-module__w-25___3GNTz {
  width: 25% !important;
}

.sidebar-module__w-50___utFbY {
  width: 50% !important;
}

.sidebar-module__w-75___1SkW9, .sidebar-module__separator___2ufjp {
  width: 75% !important;
}

.sidebar-module__w-100___2jQvd, .sidebar-module__context-menu___3TiWZ, .sidebar-module__main-menu___XmrdH, .sidebar-module__separator-wrapper___2CUH1, .sidebar-module__link___2BPkD, .sidebar-module__link-active___1qkkj, .sidebar-module__link-collapsed___2uj3s {
  width: 100% !important;
}

.sidebar-module__w-auto___tams3 {
  width: auto !important;
}

.sidebar-module__h-25___MRMfe {
  height: 25% !important;
}

.sidebar-module__h-50___1Qfdt {
  height: 50% !important;
}

.sidebar-module__h-75___TDtaI {
  height: 75% !important;
}

.sidebar-module__h-100___3su0N, .sidebar-module__separator___2ufjp {
  height: 100% !important;
}

.sidebar-module__h-auto___2jLSi {
  height: auto !important;
}

.sidebar-module__mw-100___QJKjR {
  max-width: 100% !important;
}

.sidebar-module__mh-100___24MMV {
  max-height: 100% !important;
}

.sidebar-module__min-vw-100___2RREN {
  min-width: 100vw !important;
}

.sidebar-module__min-vh-100___12nca {
  min-height: 100vh !important;
}

.sidebar-module__vw-100___9Cvd1 {
  width: 100vw !important;
}

.sidebar-module__vh-100___3iX_x {
  height: 100vh !important;
}

.sidebar-module__stretched-link___eR9_5::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.sidebar-module__m-0___3bkb0 {
  margin: 0 !important;
}

.sidebar-module__mt-0___hinzp,
.sidebar-module__my-0___2eYJT {
  margin-top: 0 !important;
}

.sidebar-module__mr-0___3nibu,
.sidebar-module__mx-0___3clP_ {
  margin-right: 0 !important;
}

.sidebar-module__mb-0___31-e3,
.sidebar-module__my-0___2eYJT {
  margin-bottom: 0 !important;
}

.sidebar-module__ml-0___2F4cn,
.sidebar-module__mx-0___3clP_ {
  margin-left: 0 !important;
}

.sidebar-module__m-1___Xma2t {
  margin: 0.25rem !important;
}

.sidebar-module__mt-1___2lRDI,
.sidebar-module__my-1___wtWq9 {
  margin-top: 0.25rem !important;
}

.sidebar-module__mr-1___1BmFV,
.sidebar-module__mx-1___2bKMV {
  margin-right: 0.25rem !important;
}

.sidebar-module__mb-1___6sVPZ,
.sidebar-module__my-1___wtWq9 {
  margin-bottom: 0.25rem !important;
}

.sidebar-module__ml-1___2JSPr,
.sidebar-module__mx-1___2bKMV {
  margin-left: 0.25rem !important;
}

.sidebar-module__m-2___1wuuz {
  margin: 0.5rem !important;
}

.sidebar-module__mt-2___2Whkv,
.sidebar-module__my-2___1rTLc {
  margin-top: 0.5rem !important;
}

.sidebar-module__mr-2___2Ih1y,
.sidebar-module__mx-2___21ax1 {
  margin-right: 0.5rem !important;
}

.sidebar-module__mb-2___3DG61,
.sidebar-module__my-2___1rTLc {
  margin-bottom: 0.5rem !important;
}

.sidebar-module__ml-2___jLGO1,
.sidebar-module__mx-2___21ax1 {
  margin-left: 0.5rem !important;
}

.sidebar-module__m-3___3Z3ue {
  margin: 1rem !important;
}

.sidebar-module__mt-3___3JqWT, .sidebar-module__separator-wrapper___2CUH1, .sidebar-module__link___2BPkD, .sidebar-module__link-active___1qkkj, .sidebar-module__link-collapsed___2uj3s,
.sidebar-module__my-3___3o0-- {
  margin-top: 1rem !important;
}

.sidebar-module__mr-3___2mY9P,
.sidebar-module__mx-3___2L8xW {
  margin-right: 1rem !important;
}

.sidebar-module__mb-3___OUz7F,
.sidebar-module__my-3___3o0-- {
  margin-bottom: 1rem !important;
}

.sidebar-module__ml-3___ROZ9s,
.sidebar-module__mx-3___2L8xW {
  margin-left: 1rem !important;
}

.sidebar-module__m-4___15Yq0 {
  margin: 1.5rem !important;
}

.sidebar-module__mt-4___2BBzI,
.sidebar-module__my-4___2JUj0 {
  margin-top: 1.5rem !important;
}

.sidebar-module__mr-4___3aMMc,
.sidebar-module__mx-4___3FANL {
  margin-right: 1.5rem !important;
}

.sidebar-module__mb-4___ynzTZ,
.sidebar-module__my-4___2JUj0 {
  margin-bottom: 1.5rem !important;
}

.sidebar-module__ml-4___u4816,
.sidebar-module__mx-4___3FANL {
  margin-left: 1.5rem !important;
}

.sidebar-module__m-5___qKTHm {
  margin: 3rem !important;
}

.sidebar-module__mt-5___326ju,
.sidebar-module__my-5___3ECF6 {
  margin-top: 3rem !important;
}

.sidebar-module__mr-5___371Xd,
.sidebar-module__mx-5___12V_n {
  margin-right: 3rem !important;
}

.sidebar-module__mb-5___2yJO6,
.sidebar-module__my-5___3ECF6 {
  margin-bottom: 3rem !important;
}

.sidebar-module__ml-5___t2UyE,
.sidebar-module__mx-5___12V_n {
  margin-left: 3rem !important;
}

.sidebar-module__p-0___2ml_Y {
  padding: 0 !important;
}

.sidebar-module__pt-0___2Na-v,
.sidebar-module__py-0___1IKv_ {
  padding-top: 0 !important;
}

.sidebar-module__pr-0___1qCHK,
.sidebar-module__px-0___1NFGl {
  padding-right: 0 !important;
}

.sidebar-module__pb-0___1sNUR,
.sidebar-module__py-0___1IKv_ {
  padding-bottom: 0 !important;
}

.sidebar-module__pl-0___2h9Y8,
.sidebar-module__px-0___1NFGl {
  padding-left: 0 !important;
}

.sidebar-module__p-1___3tEno, .sidebar-module__context-menu___3TiWZ {
  padding: 0.25rem !important;
}

.sidebar-module__pt-1___3wroZ,
.sidebar-module__py-1___hXIte {
  padding-top: 0.25rem !important;
}

.sidebar-module__pr-1___PaHVH,
.sidebar-module__px-1___2yI7r {
  padding-right: 0.25rem !important;
}

.sidebar-module__pb-1___AUr67,
.sidebar-module__py-1___hXIte {
  padding-bottom: 0.25rem !important;
}

.sidebar-module__pl-1___1E7wr,
.sidebar-module__px-1___2yI7r {
  padding-left: 0.25rem !important;
}

.sidebar-module__p-2___MUhKd {
  padding: 0.5rem !important;
}

.sidebar-module__pt-2___NYhXH,
.sidebar-module__py-2___1wMQp,
.sidebar-module__link___2BPkD,
.sidebar-module__link-active___1qkkj,
.sidebar-module__link-collapsed___2uj3s {
  padding-top: 0.5rem !important;
}

.sidebar-module__pr-2___3_JP6,
.sidebar-module__px-2___2u53L {
  padding-right: 0.5rem !important;
}

.sidebar-module__pb-2___1IwuF,
.sidebar-module__py-2___1wMQp,
.sidebar-module__link___2BPkD,
.sidebar-module__link-active___1qkkj,
.sidebar-module__link-collapsed___2uj3s {
  padding-bottom: 0.5rem !important;
}

.sidebar-module__pl-2___JChzQ,
.sidebar-module__px-2___2u53L {
  padding-left: 0.5rem !important;
}

.sidebar-module__p-3___30fx- {
  padding: 1rem !important;
}

.sidebar-module__pt-3___2Srtl, .sidebar-module__context-menu___3TiWZ,
.sidebar-module__py-3___1AgRF {
  padding-top: 1rem !important;
}

.sidebar-module__pr-3___2AgDy,
.sidebar-module__px-3___2f0jP,
.sidebar-module__link___2BPkD,
.sidebar-module__link-active___1qkkj,
.sidebar-module__link-collapsed___2uj3s {
  padding-right: 1rem !important;
}

.sidebar-module__pb-3___oHRKt, .sidebar-module__main-menu___XmrdH,
.sidebar-module__py-3___1AgRF {
  padding-bottom: 1rem !important;
}

.sidebar-module__pl-3___QlCnQ,
.sidebar-module__px-3___2f0jP,
.sidebar-module__link___2BPkD,
.sidebar-module__link-active___1qkkj,
.sidebar-module__link-collapsed___2uj3s {
  padding-left: 1rem !important;
}

.sidebar-module__p-4___VAxBS {
  padding: 1.5rem !important;
}

.sidebar-module__pt-4___3POgL,
.sidebar-module__py-4___BfLNG {
  padding-top: 1.5rem !important;
}

.sidebar-module__pr-4___3Hkr5,
.sidebar-module__px-4___18uws {
  padding-right: 1.5rem !important;
}

.sidebar-module__pb-4___3hD1m,
.sidebar-module__py-4___BfLNG {
  padding-bottom: 1.5rem !important;
}

.sidebar-module__pl-4___qqGtv,
.sidebar-module__px-4___18uws {
  padding-left: 1.5rem !important;
}

.sidebar-module__p-5___bZM_X {
  padding: 3rem !important;
}

.sidebar-module__pt-5___INXfR,
.sidebar-module__py-5___1klJ6 {
  padding-top: 3rem !important;
}

.sidebar-module__pr-5___1HXRO,
.sidebar-module__px-5___1jAVL {
  padding-right: 3rem !important;
}

.sidebar-module__pb-5___1-eAQ,
.sidebar-module__py-5___1klJ6 {
  padding-bottom: 3rem !important;
}

.sidebar-module__pl-5___3kyF7,
.sidebar-module__px-5___1jAVL {
  padding-left: 3rem !important;
}

.sidebar-module__m-n1___11gDX {
  margin: -0.25rem !important;
}

.sidebar-module__mt-n1___3zGgd,
.sidebar-module__my-n1___2aQag {
  margin-top: -0.25rem !important;
}

.sidebar-module__mr-n1___1Hiab,
.sidebar-module__mx-n1___2VEIH {
  margin-right: -0.25rem !important;
}

.sidebar-module__mb-n1___1pFwV,
.sidebar-module__my-n1___2aQag {
  margin-bottom: -0.25rem !important;
}

.sidebar-module__ml-n1___3qGwR,
.sidebar-module__mx-n1___2VEIH {
  margin-left: -0.25rem !important;
}

.sidebar-module__m-n2___2Bcv6 {
  margin: -0.5rem !important;
}

.sidebar-module__mt-n2___43vfG,
.sidebar-module__my-n2___2OoXC {
  margin-top: -0.5rem !important;
}

.sidebar-module__mr-n2___2ZahD,
.sidebar-module__mx-n2___1Vx8U {
  margin-right: -0.5rem !important;
}

.sidebar-module__mb-n2___2avkJ,
.sidebar-module__my-n2___2OoXC {
  margin-bottom: -0.5rem !important;
}

.sidebar-module__ml-n2___6etOF,
.sidebar-module__mx-n2___1Vx8U {
  margin-left: -0.5rem !important;
}

.sidebar-module__m-n3___3Hc7I {
  margin: -1rem !important;
}

.sidebar-module__mt-n3___1CR6j,
.sidebar-module__my-n3___1tq8t {
  margin-top: -1rem !important;
}

.sidebar-module__mr-n3___1j0zE,
.sidebar-module__mx-n3___3ULIe {
  margin-right: -1rem !important;
}

.sidebar-module__mb-n3___1vmiM,
.sidebar-module__my-n3___1tq8t {
  margin-bottom: -1rem !important;
}

.sidebar-module__ml-n3___xIzvW,
.sidebar-module__mx-n3___3ULIe {
  margin-left: -1rem !important;
}

.sidebar-module__m-n4___3fM1z {
  margin: -1.5rem !important;
}

.sidebar-module__mt-n4___Dv1jN,
.sidebar-module__my-n4___2X-tY {
  margin-top: -1.5rem !important;
}

.sidebar-module__mr-n4___8DZc_,
.sidebar-module__mx-n4___3eMSF {
  margin-right: -1.5rem !important;
}

.sidebar-module__mb-n4___1Rsk2,
.sidebar-module__my-n4___2X-tY {
  margin-bottom: -1.5rem !important;
}

.sidebar-module__ml-n4___Mz2FQ,
.sidebar-module__mx-n4___3eMSF {
  margin-left: -1.5rem !important;
}

.sidebar-module__m-n5___3bP00 {
  margin: -3rem !important;
}

.sidebar-module__mt-n5___35XAB,
.sidebar-module__my-n5___3v2Xn {
  margin-top: -3rem !important;
}

.sidebar-module__mr-n5___3BfYo,
.sidebar-module__mx-n5___31wvp {
  margin-right: -3rem !important;
}

.sidebar-module__mb-n5___Lao2l,
.sidebar-module__my-n5___3v2Xn {
  margin-bottom: -3rem !important;
}

.sidebar-module__ml-n5___3ewg0,
.sidebar-module__mx-n5___31wvp {
  margin-left: -3rem !important;
}

.sidebar-module__m-auto___3up1p {
  margin: auto !important;
}

.sidebar-module__mt-auto___wsAwL,
.sidebar-module__my-auto___PC2Ab {
  margin-top: auto !important;
}

.sidebar-module__mr-auto___m3of4,
.sidebar-module__mx-auto___2QJZc {
  margin-right: auto !important;
}

.sidebar-module__mb-auto___1ExQO,
.sidebar-module__my-auto___PC2Ab {
  margin-bottom: auto !important;
}

.sidebar-module__ml-auto___QfgiT,
.sidebar-module__mx-auto___2QJZc {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .sidebar-module__m-sm-0___1d5-4 {
    margin: 0 !important;
  }

  .sidebar-module__mt-sm-0___1cgQd,
.sidebar-module__my-sm-0___3DVtQ {
    margin-top: 0 !important;
  }

  .sidebar-module__mr-sm-0___2yRuO,
.sidebar-module__mx-sm-0___3djFf {
    margin-right: 0 !important;
  }

  .sidebar-module__mb-sm-0___2PNCS,
.sidebar-module__my-sm-0___3DVtQ {
    margin-bottom: 0 !important;
  }

  .sidebar-module__ml-sm-0___1Iquk,
.sidebar-module__mx-sm-0___3djFf {
    margin-left: 0 !important;
  }

  .sidebar-module__m-sm-1___3QWnA {
    margin: 0.25rem !important;
  }

  .sidebar-module__mt-sm-1___3Oyl0,
.sidebar-module__my-sm-1___xKQF6 {
    margin-top: 0.25rem !important;
  }

  .sidebar-module__mr-sm-1___kn-gN,
.sidebar-module__mx-sm-1___2i_e9 {
    margin-right: 0.25rem !important;
  }

  .sidebar-module__mb-sm-1___3RaI7,
.sidebar-module__my-sm-1___xKQF6 {
    margin-bottom: 0.25rem !important;
  }

  .sidebar-module__ml-sm-1___3j6YP,
.sidebar-module__mx-sm-1___2i_e9 {
    margin-left: 0.25rem !important;
  }

  .sidebar-module__m-sm-2___1lPfS {
    margin: 0.5rem !important;
  }

  .sidebar-module__mt-sm-2___2bWlS,
.sidebar-module__my-sm-2___2Somt {
    margin-top: 0.5rem !important;
  }

  .sidebar-module__mr-sm-2___1aCTm,
.sidebar-module__mx-sm-2___16ifG {
    margin-right: 0.5rem !important;
  }

  .sidebar-module__mb-sm-2___2uaOR,
.sidebar-module__my-sm-2___2Somt {
    margin-bottom: 0.5rem !important;
  }

  .sidebar-module__ml-sm-2___hS1Af,
.sidebar-module__mx-sm-2___16ifG {
    margin-left: 0.5rem !important;
  }

  .sidebar-module__m-sm-3___XGJ-5 {
    margin: 1rem !important;
  }

  .sidebar-module__mt-sm-3___A9QrL,
.sidebar-module__my-sm-3___1JZ7w {
    margin-top: 1rem !important;
  }

  .sidebar-module__mr-sm-3___1bBTu,
.sidebar-module__mx-sm-3___Yi4B8 {
    margin-right: 1rem !important;
  }

  .sidebar-module__mb-sm-3___NDfYU,
.sidebar-module__my-sm-3___1JZ7w {
    margin-bottom: 1rem !important;
  }

  .sidebar-module__ml-sm-3___2YpXI,
.sidebar-module__mx-sm-3___Yi4B8 {
    margin-left: 1rem !important;
  }

  .sidebar-module__m-sm-4___1Hesi {
    margin: 1.5rem !important;
  }

  .sidebar-module__mt-sm-4___1EiNM,
.sidebar-module__my-sm-4___3nG7Y {
    margin-top: 1.5rem !important;
  }

  .sidebar-module__mr-sm-4___G1P7r,
.sidebar-module__mx-sm-4___1GL5J {
    margin-right: 1.5rem !important;
  }

  .sidebar-module__mb-sm-4___nnWf4,
.sidebar-module__my-sm-4___3nG7Y {
    margin-bottom: 1.5rem !important;
  }

  .sidebar-module__ml-sm-4___3d9Ng,
.sidebar-module__mx-sm-4___1GL5J {
    margin-left: 1.5rem !important;
  }

  .sidebar-module__m-sm-5___2vzME {
    margin: 3rem !important;
  }

  .sidebar-module__mt-sm-5___1SYhL,
.sidebar-module__my-sm-5___3p8Mz {
    margin-top: 3rem !important;
  }

  .sidebar-module__mr-sm-5___23rfv,
.sidebar-module__mx-sm-5___3ifHi {
    margin-right: 3rem !important;
  }

  .sidebar-module__mb-sm-5___P3m_n,
.sidebar-module__my-sm-5___3p8Mz {
    margin-bottom: 3rem !important;
  }

  .sidebar-module__ml-sm-5___1AtD-,
.sidebar-module__mx-sm-5___3ifHi {
    margin-left: 3rem !important;
  }

  .sidebar-module__p-sm-0___3XXvh {
    padding: 0 !important;
  }

  .sidebar-module__pt-sm-0___3cIjn,
.sidebar-module__py-sm-0___36GAy {
    padding-top: 0 !important;
  }

  .sidebar-module__pr-sm-0___1hrqt,
.sidebar-module__px-sm-0___EiD0P {
    padding-right: 0 !important;
  }

  .sidebar-module__pb-sm-0___1I2Yp,
.sidebar-module__py-sm-0___36GAy {
    padding-bottom: 0 !important;
  }

  .sidebar-module__pl-sm-0___F1oZ5,
.sidebar-module__px-sm-0___EiD0P {
    padding-left: 0 !important;
  }

  .sidebar-module__p-sm-1___s8d4b {
    padding: 0.25rem !important;
  }

  .sidebar-module__pt-sm-1___3Bl_Z,
.sidebar-module__py-sm-1___2JCYN {
    padding-top: 0.25rem !important;
  }

  .sidebar-module__pr-sm-1___25wDT,
.sidebar-module__px-sm-1___2c04j {
    padding-right: 0.25rem !important;
  }

  .sidebar-module__pb-sm-1___1wxry,
.sidebar-module__py-sm-1___2JCYN {
    padding-bottom: 0.25rem !important;
  }

  .sidebar-module__pl-sm-1___OuJrx,
.sidebar-module__px-sm-1___2c04j {
    padding-left: 0.25rem !important;
  }

  .sidebar-module__p-sm-2___39GYP {
    padding: 0.5rem !important;
  }

  .sidebar-module__pt-sm-2___JOxw6,
.sidebar-module__py-sm-2___3yIH2 {
    padding-top: 0.5rem !important;
  }

  .sidebar-module__pr-sm-2___33QIr,
.sidebar-module__px-sm-2___2oVHN {
    padding-right: 0.5rem !important;
  }

  .sidebar-module__pb-sm-2___3R74g,
.sidebar-module__py-sm-2___3yIH2 {
    padding-bottom: 0.5rem !important;
  }

  .sidebar-module__pl-sm-2___3TXjf,
.sidebar-module__px-sm-2___2oVHN {
    padding-left: 0.5rem !important;
  }

  .sidebar-module__p-sm-3___L41OT {
    padding: 1rem !important;
  }

  .sidebar-module__pt-sm-3___9m_Gr,
.sidebar-module__py-sm-3___17tsz {
    padding-top: 1rem !important;
  }

  .sidebar-module__pr-sm-3___3Yexr,
.sidebar-module__px-sm-3___1VPJs {
    padding-right: 1rem !important;
  }

  .sidebar-module__pb-sm-3___3NxeV,
.sidebar-module__py-sm-3___17tsz {
    padding-bottom: 1rem !important;
  }

  .sidebar-module__pl-sm-3___3lFNB,
.sidebar-module__px-sm-3___1VPJs {
    padding-left: 1rem !important;
  }

  .sidebar-module__p-sm-4___28jEs {
    padding: 1.5rem !important;
  }

  .sidebar-module__pt-sm-4___3iGT_,
.sidebar-module__py-sm-4___atNcC {
    padding-top: 1.5rem !important;
  }

  .sidebar-module__pr-sm-4___hlM9-,
.sidebar-module__px-sm-4___19sqW {
    padding-right: 1.5rem !important;
  }

  .sidebar-module__pb-sm-4___37Zpq,
.sidebar-module__py-sm-4___atNcC {
    padding-bottom: 1.5rem !important;
  }

  .sidebar-module__pl-sm-4___OBCvV,
.sidebar-module__px-sm-4___19sqW {
    padding-left: 1.5rem !important;
  }

  .sidebar-module__p-sm-5___28YLv {
    padding: 3rem !important;
  }

  .sidebar-module__pt-sm-5___Th2MO,
.sidebar-module__py-sm-5___2kXmO {
    padding-top: 3rem !important;
  }

  .sidebar-module__pr-sm-5___3LRWt,
.sidebar-module__px-sm-5___pWx0n {
    padding-right: 3rem !important;
  }

  .sidebar-module__pb-sm-5___OJZhW,
.sidebar-module__py-sm-5___2kXmO {
    padding-bottom: 3rem !important;
  }

  .sidebar-module__pl-sm-5___NDLpt,
.sidebar-module__px-sm-5___pWx0n {
    padding-left: 3rem !important;
  }

  .sidebar-module__m-sm-n1___3wknq {
    margin: -0.25rem !important;
  }

  .sidebar-module__mt-sm-n1___yNxlw,
.sidebar-module__my-sm-n1___1LM__ {
    margin-top: -0.25rem !important;
  }

  .sidebar-module__mr-sm-n1___AMLEd,
.sidebar-module__mx-sm-n1___1bXal {
    margin-right: -0.25rem !important;
  }

  .sidebar-module__mb-sm-n1___1FykZ,
.sidebar-module__my-sm-n1___1LM__ {
    margin-bottom: -0.25rem !important;
  }

  .sidebar-module__ml-sm-n1___2meBc,
.sidebar-module__mx-sm-n1___1bXal {
    margin-left: -0.25rem !important;
  }

  .sidebar-module__m-sm-n2___YN3ZW {
    margin: -0.5rem !important;
  }

  .sidebar-module__mt-sm-n2___1tQNj,
.sidebar-module__my-sm-n2___1NPcy {
    margin-top: -0.5rem !important;
  }

  .sidebar-module__mr-sm-n2___3k2ck,
.sidebar-module__mx-sm-n2___3cKtH {
    margin-right: -0.5rem !important;
  }

  .sidebar-module__mb-sm-n2___396Mf,
.sidebar-module__my-sm-n2___1NPcy {
    margin-bottom: -0.5rem !important;
  }

  .sidebar-module__ml-sm-n2___3kqXi,
.sidebar-module__mx-sm-n2___3cKtH {
    margin-left: -0.5rem !important;
  }

  .sidebar-module__m-sm-n3___9ef1Y {
    margin: -1rem !important;
  }

  .sidebar-module__mt-sm-n3___14vVN,
.sidebar-module__my-sm-n3___39HLQ {
    margin-top: -1rem !important;
  }

  .sidebar-module__mr-sm-n3___158o9,
.sidebar-module__mx-sm-n3___G4DWR {
    margin-right: -1rem !important;
  }

  .sidebar-module__mb-sm-n3___2DBuO,
.sidebar-module__my-sm-n3___39HLQ {
    margin-bottom: -1rem !important;
  }

  .sidebar-module__ml-sm-n3___1hWiC,
.sidebar-module__mx-sm-n3___G4DWR {
    margin-left: -1rem !important;
  }

  .sidebar-module__m-sm-n4___1H_tR {
    margin: -1.5rem !important;
  }

  .sidebar-module__mt-sm-n4___2pPE6,
.sidebar-module__my-sm-n4___1j_jX {
    margin-top: -1.5rem !important;
  }

  .sidebar-module__mr-sm-n4___35ZwW,
.sidebar-module__mx-sm-n4___1D6_n {
    margin-right: -1.5rem !important;
  }

  .sidebar-module__mb-sm-n4___2KC4o,
.sidebar-module__my-sm-n4___1j_jX {
    margin-bottom: -1.5rem !important;
  }

  .sidebar-module__ml-sm-n4___XIf50,
.sidebar-module__mx-sm-n4___1D6_n {
    margin-left: -1.5rem !important;
  }

  .sidebar-module__m-sm-n5___2Oofr {
    margin: -3rem !important;
  }

  .sidebar-module__mt-sm-n5___1uprD,
.sidebar-module__my-sm-n5___JmRLq {
    margin-top: -3rem !important;
  }

  .sidebar-module__mr-sm-n5___2tTxH,
.sidebar-module__mx-sm-n5___1q_xS {
    margin-right: -3rem !important;
  }

  .sidebar-module__mb-sm-n5___3J7G6,
.sidebar-module__my-sm-n5___JmRLq {
    margin-bottom: -3rem !important;
  }

  .sidebar-module__ml-sm-n5___227FS,
.sidebar-module__mx-sm-n5___1q_xS {
    margin-left: -3rem !important;
  }

  .sidebar-module__m-sm-auto___1-HGZ {
    margin: auto !important;
  }

  .sidebar-module__mt-sm-auto___AdbFv,
.sidebar-module__my-sm-auto___2vHop {
    margin-top: auto !important;
  }

  .sidebar-module__mr-sm-auto___2mOJB,
.sidebar-module__mx-sm-auto___3cff5 {
    margin-right: auto !important;
  }

  .sidebar-module__mb-sm-auto___2HHTF,
.sidebar-module__my-sm-auto___2vHop {
    margin-bottom: auto !important;
  }

  .sidebar-module__ml-sm-auto___C5Zii,
.sidebar-module__mx-sm-auto___3cff5 {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .sidebar-module__m-md-0___1wt2t {
    margin: 0 !important;
  }

  .sidebar-module__mt-md-0___3-dAe,
.sidebar-module__my-md-0___1A0CD {
    margin-top: 0 !important;
  }

  .sidebar-module__mr-md-0___266nm,
.sidebar-module__mx-md-0___6k9y1 {
    margin-right: 0 !important;
  }

  .sidebar-module__mb-md-0___jUCHS,
.sidebar-module__my-md-0___1A0CD {
    margin-bottom: 0 !important;
  }

  .sidebar-module__ml-md-0___3_rJu,
.sidebar-module__mx-md-0___6k9y1 {
    margin-left: 0 !important;
  }

  .sidebar-module__m-md-1___3Dk_z {
    margin: 0.25rem !important;
  }

  .sidebar-module__mt-md-1___3DQfm,
.sidebar-module__my-md-1___1HUF_ {
    margin-top: 0.25rem !important;
  }

  .sidebar-module__mr-md-1___Uzwxl,
.sidebar-module__mx-md-1___1VYD9 {
    margin-right: 0.25rem !important;
  }

  .sidebar-module__mb-md-1___39ZgD,
.sidebar-module__my-md-1___1HUF_ {
    margin-bottom: 0.25rem !important;
  }

  .sidebar-module__ml-md-1___248N5,
.sidebar-module__mx-md-1___1VYD9 {
    margin-left: 0.25rem !important;
  }

  .sidebar-module__m-md-2___3Y4YS {
    margin: 0.5rem !important;
  }

  .sidebar-module__mt-md-2___lHpAy,
.sidebar-module__my-md-2___3LGZD {
    margin-top: 0.5rem !important;
  }

  .sidebar-module__mr-md-2___3ap2G,
.sidebar-module__mx-md-2___1CjKK {
    margin-right: 0.5rem !important;
  }

  .sidebar-module__mb-md-2___38p8R,
.sidebar-module__my-md-2___3LGZD {
    margin-bottom: 0.5rem !important;
  }

  .sidebar-module__ml-md-2___18C8m,
.sidebar-module__mx-md-2___1CjKK {
    margin-left: 0.5rem !important;
  }

  .sidebar-module__m-md-3___wgL8J {
    margin: 1rem !important;
  }

  .sidebar-module__mt-md-3___23xJ5,
.sidebar-module__my-md-3___E7AS1 {
    margin-top: 1rem !important;
  }

  .sidebar-module__mr-md-3___NE7eJ,
.sidebar-module__mx-md-3___3kxr3 {
    margin-right: 1rem !important;
  }

  .sidebar-module__mb-md-3___3ues_,
.sidebar-module__my-md-3___E7AS1 {
    margin-bottom: 1rem !important;
  }

  .sidebar-module__ml-md-3___3n4Rk,
.sidebar-module__mx-md-3___3kxr3 {
    margin-left: 1rem !important;
  }

  .sidebar-module__m-md-4___1Buo0 {
    margin: 1.5rem !important;
  }

  .sidebar-module__mt-md-4___jP8tH,
.sidebar-module__my-md-4___czluH {
    margin-top: 1.5rem !important;
  }

  .sidebar-module__mr-md-4___3WYwn,
.sidebar-module__mx-md-4___2lzOA {
    margin-right: 1.5rem !important;
  }

  .sidebar-module__mb-md-4___3mJYy,
.sidebar-module__my-md-4___czluH {
    margin-bottom: 1.5rem !important;
  }

  .sidebar-module__ml-md-4___1fXZL,
.sidebar-module__mx-md-4___2lzOA {
    margin-left: 1.5rem !important;
  }

  .sidebar-module__m-md-5___2q7MI {
    margin: 3rem !important;
  }

  .sidebar-module__mt-md-5___112Rp,
.sidebar-module__my-md-5___15Mgf {
    margin-top: 3rem !important;
  }

  .sidebar-module__mr-md-5___21LCI,
.sidebar-module__mx-md-5___1dPo- {
    margin-right: 3rem !important;
  }

  .sidebar-module__mb-md-5___15xXZ,
.sidebar-module__my-md-5___15Mgf {
    margin-bottom: 3rem !important;
  }

  .sidebar-module__ml-md-5___2M9FF,
.sidebar-module__mx-md-5___1dPo- {
    margin-left: 3rem !important;
  }

  .sidebar-module__p-md-0___1aAqT {
    padding: 0 !important;
  }

  .sidebar-module__pt-md-0___1hhNs,
.sidebar-module__py-md-0___2mYVD {
    padding-top: 0 !important;
  }

  .sidebar-module__pr-md-0___1KsSO,
.sidebar-module__px-md-0___321GM {
    padding-right: 0 !important;
  }

  .sidebar-module__pb-md-0___1QRJk,
.sidebar-module__py-md-0___2mYVD {
    padding-bottom: 0 !important;
  }

  .sidebar-module__pl-md-0___B9LPP,
.sidebar-module__px-md-0___321GM {
    padding-left: 0 !important;
  }

  .sidebar-module__p-md-1___JKgSR {
    padding: 0.25rem !important;
  }

  .sidebar-module__pt-md-1___1-SsI,
.sidebar-module__py-md-1___3CBmx {
    padding-top: 0.25rem !important;
  }

  .sidebar-module__pr-md-1___2AsIL,
.sidebar-module__px-md-1___2bpNc {
    padding-right: 0.25rem !important;
  }

  .sidebar-module__pb-md-1___18FUw,
.sidebar-module__py-md-1___3CBmx {
    padding-bottom: 0.25rem !important;
  }

  .sidebar-module__pl-md-1___1flPj,
.sidebar-module__px-md-1___2bpNc {
    padding-left: 0.25rem !important;
  }

  .sidebar-module__p-md-2___1g57W {
    padding: 0.5rem !important;
  }

  .sidebar-module__pt-md-2___2nu1c,
.sidebar-module__py-md-2___rW57f {
    padding-top: 0.5rem !important;
  }

  .sidebar-module__pr-md-2___33myC,
.sidebar-module__px-md-2___2W0k7 {
    padding-right: 0.5rem !important;
  }

  .sidebar-module__pb-md-2___3SM2h,
.sidebar-module__py-md-2___rW57f {
    padding-bottom: 0.5rem !important;
  }

  .sidebar-module__pl-md-2___3bb27,
.sidebar-module__px-md-2___2W0k7 {
    padding-left: 0.5rem !important;
  }

  .sidebar-module__p-md-3___2h4eY {
    padding: 1rem !important;
  }

  .sidebar-module__pt-md-3___uXDD6,
.sidebar-module__py-md-3___1nTcp {
    padding-top: 1rem !important;
  }

  .sidebar-module__pr-md-3___3fdaN,
.sidebar-module__px-md-3___wsKyT {
    padding-right: 1rem !important;
  }

  .sidebar-module__pb-md-3___BjWUi,
.sidebar-module__py-md-3___1nTcp {
    padding-bottom: 1rem !important;
  }

  .sidebar-module__pl-md-3___3dtAb,
.sidebar-module__px-md-3___wsKyT {
    padding-left: 1rem !important;
  }

  .sidebar-module__p-md-4___1c3MV {
    padding: 1.5rem !important;
  }

  .sidebar-module__pt-md-4___2Pj7Q,
.sidebar-module__py-md-4___WMxTy {
    padding-top: 1.5rem !important;
  }

  .sidebar-module__pr-md-4___1dXpM,
.sidebar-module__px-md-4___3eBMx {
    padding-right: 1.5rem !important;
  }

  .sidebar-module__pb-md-4___2ANkY,
.sidebar-module__py-md-4___WMxTy {
    padding-bottom: 1.5rem !important;
  }

  .sidebar-module__pl-md-4___3efS5,
.sidebar-module__px-md-4___3eBMx {
    padding-left: 1.5rem !important;
  }

  .sidebar-module__p-md-5___39wOR {
    padding: 3rem !important;
  }

  .sidebar-module__pt-md-5___1iTZu,
.sidebar-module__py-md-5___2uLR2 {
    padding-top: 3rem !important;
  }

  .sidebar-module__pr-md-5___20WRr,
.sidebar-module__px-md-5___3sOhA {
    padding-right: 3rem !important;
  }

  .sidebar-module__pb-md-5___sfxFZ,
.sidebar-module__py-md-5___2uLR2 {
    padding-bottom: 3rem !important;
  }

  .sidebar-module__pl-md-5___3mDwU,
.sidebar-module__px-md-5___3sOhA {
    padding-left: 3rem !important;
  }

  .sidebar-module__m-md-n1___2fLom {
    margin: -0.25rem !important;
  }

  .sidebar-module__mt-md-n1___2wonz,
.sidebar-module__my-md-n1___11FEW {
    margin-top: -0.25rem !important;
  }

  .sidebar-module__mr-md-n1___3zr5h,
.sidebar-module__mx-md-n1___2lT0Z {
    margin-right: -0.25rem !important;
  }

  .sidebar-module__mb-md-n1___3ncEJ,
.sidebar-module__my-md-n1___11FEW {
    margin-bottom: -0.25rem !important;
  }

  .sidebar-module__ml-md-n1___3ypn3,
.sidebar-module__mx-md-n1___2lT0Z {
    margin-left: -0.25rem !important;
  }

  .sidebar-module__m-md-n2___hHGHr {
    margin: -0.5rem !important;
  }

  .sidebar-module__mt-md-n2___26Nqf,
.sidebar-module__my-md-n2___2ZqQJ {
    margin-top: -0.5rem !important;
  }

  .sidebar-module__mr-md-n2___QZEgN,
.sidebar-module__mx-md-n2___dmCda {
    margin-right: -0.5rem !important;
  }

  .sidebar-module__mb-md-n2___38srA,
.sidebar-module__my-md-n2___2ZqQJ {
    margin-bottom: -0.5rem !important;
  }

  .sidebar-module__ml-md-n2___2yNWf,
.sidebar-module__mx-md-n2___dmCda {
    margin-left: -0.5rem !important;
  }

  .sidebar-module__m-md-n3___3K613 {
    margin: -1rem !important;
  }

  .sidebar-module__mt-md-n3___31S13,
.sidebar-module__my-md-n3___2hJNI {
    margin-top: -1rem !important;
  }

  .sidebar-module__mr-md-n3___242b1,
.sidebar-module__mx-md-n3___1LPMq {
    margin-right: -1rem !important;
  }

  .sidebar-module__mb-md-n3___3va6e,
.sidebar-module__my-md-n3___2hJNI {
    margin-bottom: -1rem !important;
  }

  .sidebar-module__ml-md-n3___15_qI,
.sidebar-module__mx-md-n3___1LPMq {
    margin-left: -1rem !important;
  }

  .sidebar-module__m-md-n4___WHyBN {
    margin: -1.5rem !important;
  }

  .sidebar-module__mt-md-n4___3KVZI,
.sidebar-module__my-md-n4___3BdD1 {
    margin-top: -1.5rem !important;
  }

  .sidebar-module__mr-md-n4___JAJxR,
.sidebar-module__mx-md-n4___3-WXs {
    margin-right: -1.5rem !important;
  }

  .sidebar-module__mb-md-n4___2Fd9n,
.sidebar-module__my-md-n4___3BdD1 {
    margin-bottom: -1.5rem !important;
  }

  .sidebar-module__ml-md-n4___1tsY3,
.sidebar-module__mx-md-n4___3-WXs {
    margin-left: -1.5rem !important;
  }

  .sidebar-module__m-md-n5___PO7_J {
    margin: -3rem !important;
  }

  .sidebar-module__mt-md-n5___2tEuW,
.sidebar-module__my-md-n5___2ypDG {
    margin-top: -3rem !important;
  }

  .sidebar-module__mr-md-n5___2NS8n,
.sidebar-module__mx-md-n5___1_Onw {
    margin-right: -3rem !important;
  }

  .sidebar-module__mb-md-n5___Pm4qK,
.sidebar-module__my-md-n5___2ypDG {
    margin-bottom: -3rem !important;
  }

  .sidebar-module__ml-md-n5___3wbQ4,
.sidebar-module__mx-md-n5___1_Onw {
    margin-left: -3rem !important;
  }

  .sidebar-module__m-md-auto___2cU0l {
    margin: auto !important;
  }

  .sidebar-module__mt-md-auto___30Pvj,
.sidebar-module__my-md-auto___1QOOu {
    margin-top: auto !important;
  }

  .sidebar-module__mr-md-auto___34bIX,
.sidebar-module__mx-md-auto___3hy52 {
    margin-right: auto !important;
  }

  .sidebar-module__mb-md-auto___3RlV_,
.sidebar-module__my-md-auto___1QOOu {
    margin-bottom: auto !important;
  }

  .sidebar-module__ml-md-auto___1umen,
.sidebar-module__mx-md-auto___3hy52 {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .sidebar-module__m-lg-0___2IYII {
    margin: 0 !important;
  }

  .sidebar-module__mt-lg-0___24l-G,
.sidebar-module__my-lg-0___1v2La {
    margin-top: 0 !important;
  }

  .sidebar-module__mr-lg-0___3n6Wq,
.sidebar-module__mx-lg-0___3NaQI {
    margin-right: 0 !important;
  }

  .sidebar-module__mb-lg-0___1TP5E,
.sidebar-module__my-lg-0___1v2La {
    margin-bottom: 0 !important;
  }

  .sidebar-module__ml-lg-0___bLETd,
.sidebar-module__mx-lg-0___3NaQI {
    margin-left: 0 !important;
  }

  .sidebar-module__m-lg-1___4Rfsy {
    margin: 0.25rem !important;
  }

  .sidebar-module__mt-lg-1___3wSTE,
.sidebar-module__my-lg-1___2vehT {
    margin-top: 0.25rem !important;
  }

  .sidebar-module__mr-lg-1___XfmPw,
.sidebar-module__mx-lg-1___3t_Gq {
    margin-right: 0.25rem !important;
  }

  .sidebar-module__mb-lg-1___3nt-u,
.sidebar-module__my-lg-1___2vehT {
    margin-bottom: 0.25rem !important;
  }

  .sidebar-module__ml-lg-1___3_Abm,
.sidebar-module__mx-lg-1___3t_Gq {
    margin-left: 0.25rem !important;
  }

  .sidebar-module__m-lg-2___2mBIg {
    margin: 0.5rem !important;
  }

  .sidebar-module__mt-lg-2___2dtfo,
.sidebar-module__my-lg-2___3Ic0p {
    margin-top: 0.5rem !important;
  }

  .sidebar-module__mr-lg-2___1asOK,
.sidebar-module__mx-lg-2___3JoyH {
    margin-right: 0.5rem !important;
  }

  .sidebar-module__mb-lg-2___pCs7V,
.sidebar-module__my-lg-2___3Ic0p {
    margin-bottom: 0.5rem !important;
  }

  .sidebar-module__ml-lg-2___5NKGv,
.sidebar-module__mx-lg-2___3JoyH {
    margin-left: 0.5rem !important;
  }

  .sidebar-module__m-lg-3___25_gs {
    margin: 1rem !important;
  }

  .sidebar-module__mt-lg-3___1WTbC,
.sidebar-module__my-lg-3___waKk2 {
    margin-top: 1rem !important;
  }

  .sidebar-module__mr-lg-3___39l2O,
.sidebar-module__mx-lg-3___nWs7h {
    margin-right: 1rem !important;
  }

  .sidebar-module__mb-lg-3___NsPT0,
.sidebar-module__my-lg-3___waKk2 {
    margin-bottom: 1rem !important;
  }

  .sidebar-module__ml-lg-3___3GwRm,
.sidebar-module__mx-lg-3___nWs7h {
    margin-left: 1rem !important;
  }

  .sidebar-module__m-lg-4___xPblT {
    margin: 1.5rem !important;
  }

  .sidebar-module__mt-lg-4___237WV,
.sidebar-module__my-lg-4___nqtkH {
    margin-top: 1.5rem !important;
  }

  .sidebar-module__mr-lg-4___36yR3,
.sidebar-module__mx-lg-4___2FQ3F {
    margin-right: 1.5rem !important;
  }

  .sidebar-module__mb-lg-4___37Qzr,
.sidebar-module__my-lg-4___nqtkH {
    margin-bottom: 1.5rem !important;
  }

  .sidebar-module__ml-lg-4___1VFvm,
.sidebar-module__mx-lg-4___2FQ3F {
    margin-left: 1.5rem !important;
  }

  .sidebar-module__m-lg-5___3iLJl {
    margin: 3rem !important;
  }

  .sidebar-module__mt-lg-5___LQjIe,
.sidebar-module__my-lg-5___3Jfc1 {
    margin-top: 3rem !important;
  }

  .sidebar-module__mr-lg-5___3VsOK,
.sidebar-module__mx-lg-5___3jugE {
    margin-right: 3rem !important;
  }

  .sidebar-module__mb-lg-5___3alFq,
.sidebar-module__my-lg-5___3Jfc1 {
    margin-bottom: 3rem !important;
  }

  .sidebar-module__ml-lg-5___oz4Wa,
.sidebar-module__mx-lg-5___3jugE {
    margin-left: 3rem !important;
  }

  .sidebar-module__p-lg-0___g4LKa {
    padding: 0 !important;
  }

  .sidebar-module__pt-lg-0___1PGQP,
.sidebar-module__py-lg-0___2XSvY {
    padding-top: 0 !important;
  }

  .sidebar-module__pr-lg-0___2AqoY,
.sidebar-module__px-lg-0___2z-oP {
    padding-right: 0 !important;
  }

  .sidebar-module__pb-lg-0___1m8TK,
.sidebar-module__py-lg-0___2XSvY {
    padding-bottom: 0 !important;
  }

  .sidebar-module__pl-lg-0___2d2LW,
.sidebar-module__px-lg-0___2z-oP {
    padding-left: 0 !important;
  }

  .sidebar-module__p-lg-1___2kUIL {
    padding: 0.25rem !important;
  }

  .sidebar-module__pt-lg-1___2ZYAw,
.sidebar-module__py-lg-1___CV6VE {
    padding-top: 0.25rem !important;
  }

  .sidebar-module__pr-lg-1___1jk4D,
.sidebar-module__px-lg-1___2lYo7 {
    padding-right: 0.25rem !important;
  }

  .sidebar-module__pb-lg-1___VFFrJ,
.sidebar-module__py-lg-1___CV6VE {
    padding-bottom: 0.25rem !important;
  }

  .sidebar-module__pl-lg-1___3173H,
.sidebar-module__px-lg-1___2lYo7 {
    padding-left: 0.25rem !important;
  }

  .sidebar-module__p-lg-2___3FF3h {
    padding: 0.5rem !important;
  }

  .sidebar-module__pt-lg-2___1P0fW,
.sidebar-module__py-lg-2___2K2T- {
    padding-top: 0.5rem !important;
  }

  .sidebar-module__pr-lg-2___rCKiP,
.sidebar-module__px-lg-2___2nujm {
    padding-right: 0.5rem !important;
  }

  .sidebar-module__pb-lg-2___1YW7r,
.sidebar-module__py-lg-2___2K2T- {
    padding-bottom: 0.5rem !important;
  }

  .sidebar-module__pl-lg-2___25e3l,
.sidebar-module__px-lg-2___2nujm {
    padding-left: 0.5rem !important;
  }

  .sidebar-module__p-lg-3___39mqq {
    padding: 1rem !important;
  }

  .sidebar-module__pt-lg-3___vry4u,
.sidebar-module__py-lg-3___7K50C {
    padding-top: 1rem !important;
  }

  .sidebar-module__pr-lg-3___3wWP5,
.sidebar-module__px-lg-3___3pq73 {
    padding-right: 1rem !important;
  }

  .sidebar-module__pb-lg-3___1ZLCV,
.sidebar-module__py-lg-3___7K50C {
    padding-bottom: 1rem !important;
  }

  .sidebar-module__pl-lg-3___10dJK,
.sidebar-module__px-lg-3___3pq73 {
    padding-left: 1rem !important;
  }

  .sidebar-module__p-lg-4___3Suha {
    padding: 1.5rem !important;
  }

  .sidebar-module__pt-lg-4___rLeDw,
.sidebar-module__py-lg-4___1Q5Ht {
    padding-top: 1.5rem !important;
  }

  .sidebar-module__pr-lg-4___ZOwsJ,
.sidebar-module__px-lg-4___L2hSI {
    padding-right: 1.5rem !important;
  }

  .sidebar-module__pb-lg-4___1Wtak,
.sidebar-module__py-lg-4___1Q5Ht {
    padding-bottom: 1.5rem !important;
  }

  .sidebar-module__pl-lg-4___3Qd27,
.sidebar-module__px-lg-4___L2hSI {
    padding-left: 1.5rem !important;
  }

  .sidebar-module__p-lg-5___3N6R- {
    padding: 3rem !important;
  }

  .sidebar-module__pt-lg-5___32oCf,
.sidebar-module__py-lg-5___JAf3U {
    padding-top: 3rem !important;
  }

  .sidebar-module__pr-lg-5___11B-m,
.sidebar-module__px-lg-5___Y1qbe {
    padding-right: 3rem !important;
  }

  .sidebar-module__pb-lg-5___3-BWu,
.sidebar-module__py-lg-5___JAf3U {
    padding-bottom: 3rem !important;
  }

  .sidebar-module__pl-lg-5___2Abnk,
.sidebar-module__px-lg-5___Y1qbe {
    padding-left: 3rem !important;
  }

  .sidebar-module__m-lg-n1___bPlni {
    margin: -0.25rem !important;
  }

  .sidebar-module__mt-lg-n1___1w5US,
.sidebar-module__my-lg-n1___1vbxh {
    margin-top: -0.25rem !important;
  }

  .sidebar-module__mr-lg-n1___27Y8i,
.sidebar-module__mx-lg-n1___-YIYc {
    margin-right: -0.25rem !important;
  }

  .sidebar-module__mb-lg-n1___1X57F,
.sidebar-module__my-lg-n1___1vbxh {
    margin-bottom: -0.25rem !important;
  }

  .sidebar-module__ml-lg-n1___1e4yG,
.sidebar-module__mx-lg-n1___-YIYc {
    margin-left: -0.25rem !important;
  }

  .sidebar-module__m-lg-n2___3Olks {
    margin: -0.5rem !important;
  }

  .sidebar-module__mt-lg-n2___3jTU2,
.sidebar-module__my-lg-n2___11Zyd {
    margin-top: -0.5rem !important;
  }

  .sidebar-module__mr-lg-n2___npHuG,
.sidebar-module__mx-lg-n2___1UE_v {
    margin-right: -0.5rem !important;
  }

  .sidebar-module__mb-lg-n2___33dbu,
.sidebar-module__my-lg-n2___11Zyd {
    margin-bottom: -0.5rem !important;
  }

  .sidebar-module__ml-lg-n2___xNY6M,
.sidebar-module__mx-lg-n2___1UE_v {
    margin-left: -0.5rem !important;
  }

  .sidebar-module__m-lg-n3___1xHtI {
    margin: -1rem !important;
  }

  .sidebar-module__mt-lg-n3___2HiYz,
.sidebar-module__my-lg-n3___21ZQ2 {
    margin-top: -1rem !important;
  }

  .sidebar-module__mr-lg-n3___1uLBC,
.sidebar-module__mx-lg-n3___3l8F- {
    margin-right: -1rem !important;
  }

  .sidebar-module__mb-lg-n3___1jOSL,
.sidebar-module__my-lg-n3___21ZQ2 {
    margin-bottom: -1rem !important;
  }

  .sidebar-module__ml-lg-n3___1JRvG,
.sidebar-module__mx-lg-n3___3l8F- {
    margin-left: -1rem !important;
  }

  .sidebar-module__m-lg-n4___sU3Y4 {
    margin: -1.5rem !important;
  }

  .sidebar-module__mt-lg-n4___10L1M,
.sidebar-module__my-lg-n4___2_U9- {
    margin-top: -1.5rem !important;
  }

  .sidebar-module__mr-lg-n4___1ILNl,
.sidebar-module__mx-lg-n4___1-SLU {
    margin-right: -1.5rem !important;
  }

  .sidebar-module__mb-lg-n4___1plBq,
.sidebar-module__my-lg-n4___2_U9- {
    margin-bottom: -1.5rem !important;
  }

  .sidebar-module__ml-lg-n4___K8KJk,
.sidebar-module__mx-lg-n4___1-SLU {
    margin-left: -1.5rem !important;
  }

  .sidebar-module__m-lg-n5___S_Jt9 {
    margin: -3rem !important;
  }

  .sidebar-module__mt-lg-n5___33OjE,
.sidebar-module__my-lg-n5___3lWxx {
    margin-top: -3rem !important;
  }

  .sidebar-module__mr-lg-n5___2udmJ,
.sidebar-module__mx-lg-n5___2lIob {
    margin-right: -3rem !important;
  }

  .sidebar-module__mb-lg-n5___1vtlG,
.sidebar-module__my-lg-n5___3lWxx {
    margin-bottom: -3rem !important;
  }

  .sidebar-module__ml-lg-n5___1G-TX,
.sidebar-module__mx-lg-n5___2lIob {
    margin-left: -3rem !important;
  }

  .sidebar-module__m-lg-auto___1oOAa {
    margin: auto !important;
  }

  .sidebar-module__mt-lg-auto___29-ZH,
.sidebar-module__my-lg-auto___2HS3K {
    margin-top: auto !important;
  }

  .sidebar-module__mr-lg-auto___2fZvw,
.sidebar-module__mx-lg-auto___3L36u {
    margin-right: auto !important;
  }

  .sidebar-module__mb-lg-auto___3M0dn,
.sidebar-module__my-lg-auto___2HS3K {
    margin-bottom: auto !important;
  }

  .sidebar-module__ml-lg-auto___33vnH,
.sidebar-module__mx-lg-auto___3L36u {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .sidebar-module__m-xl-0___qOlmD {
    margin: 0 !important;
  }

  .sidebar-module__mt-xl-0___3GJ9J,
.sidebar-module__my-xl-0___3MGuC {
    margin-top: 0 !important;
  }

  .sidebar-module__mr-xl-0___1YQ48,
.sidebar-module__mx-xl-0___1f3ho {
    margin-right: 0 !important;
  }

  .sidebar-module__mb-xl-0___3JWle,
.sidebar-module__my-xl-0___3MGuC {
    margin-bottom: 0 !important;
  }

  .sidebar-module__ml-xl-0___3EVO8,
.sidebar-module__mx-xl-0___1f3ho {
    margin-left: 0 !important;
  }

  .sidebar-module__m-xl-1___39wTe {
    margin: 0.25rem !important;
  }

  .sidebar-module__mt-xl-1___1Hc7X,
.sidebar-module__my-xl-1___2r51d {
    margin-top: 0.25rem !important;
  }

  .sidebar-module__mr-xl-1___l-r3A,
.sidebar-module__mx-xl-1___2Wn0a {
    margin-right: 0.25rem !important;
  }

  .sidebar-module__mb-xl-1___3-jWp,
.sidebar-module__my-xl-1___2r51d {
    margin-bottom: 0.25rem !important;
  }

  .sidebar-module__ml-xl-1___s6x1r,
.sidebar-module__mx-xl-1___2Wn0a {
    margin-left: 0.25rem !important;
  }

  .sidebar-module__m-xl-2___31LbO {
    margin: 0.5rem !important;
  }

  .sidebar-module__mt-xl-2___3UB3L,
.sidebar-module__my-xl-2___9oBy1 {
    margin-top: 0.5rem !important;
  }

  .sidebar-module__mr-xl-2___3fqQh,
.sidebar-module__mx-xl-2___2VfXW {
    margin-right: 0.5rem !important;
  }

  .sidebar-module__mb-xl-2___OZf-N,
.sidebar-module__my-xl-2___9oBy1 {
    margin-bottom: 0.5rem !important;
  }

  .sidebar-module__ml-xl-2___1UVHl,
.sidebar-module__mx-xl-2___2VfXW {
    margin-left: 0.5rem !important;
  }

  .sidebar-module__m-xl-3___23jZY {
    margin: 1rem !important;
  }

  .sidebar-module__mt-xl-3___M__ua,
.sidebar-module__my-xl-3___1WUtg {
    margin-top: 1rem !important;
  }

  .sidebar-module__mr-xl-3___3lyGp,
.sidebar-module__mx-xl-3___xACXe {
    margin-right: 1rem !important;
  }

  .sidebar-module__mb-xl-3___PZQo0,
.sidebar-module__my-xl-3___1WUtg {
    margin-bottom: 1rem !important;
  }

  .sidebar-module__ml-xl-3___3YS5z,
.sidebar-module__mx-xl-3___xACXe {
    margin-left: 1rem !important;
  }

  .sidebar-module__m-xl-4___JUqy4 {
    margin: 1.5rem !important;
  }

  .sidebar-module__mt-xl-4___dEZnS,
.sidebar-module__my-xl-4___1gcNk {
    margin-top: 1.5rem !important;
  }

  .sidebar-module__mr-xl-4___2xl__,
.sidebar-module__mx-xl-4___2U_MM {
    margin-right: 1.5rem !important;
  }

  .sidebar-module__mb-xl-4___3m4aJ,
.sidebar-module__my-xl-4___1gcNk {
    margin-bottom: 1.5rem !important;
  }

  .sidebar-module__ml-xl-4___3rTYC,
.sidebar-module__mx-xl-4___2U_MM {
    margin-left: 1.5rem !important;
  }

  .sidebar-module__m-xl-5___2TQgh {
    margin: 3rem !important;
  }

  .sidebar-module__mt-xl-5___320hS,
.sidebar-module__my-xl-5___1i6QG {
    margin-top: 3rem !important;
  }

  .sidebar-module__mr-xl-5___2IoEK,
.sidebar-module__mx-xl-5___1HLji {
    margin-right: 3rem !important;
  }

  .sidebar-module__mb-xl-5___1OkXO,
.sidebar-module__my-xl-5___1i6QG {
    margin-bottom: 3rem !important;
  }

  .sidebar-module__ml-xl-5___1V8um,
.sidebar-module__mx-xl-5___1HLji {
    margin-left: 3rem !important;
  }

  .sidebar-module__p-xl-0___3Rm_v {
    padding: 0 !important;
  }

  .sidebar-module__pt-xl-0___-otlR,
.sidebar-module__py-xl-0___3WSn6 {
    padding-top: 0 !important;
  }

  .sidebar-module__pr-xl-0___SnKSy,
.sidebar-module__px-xl-0___3lQbU {
    padding-right: 0 !important;
  }

  .sidebar-module__pb-xl-0___TswMT,
.sidebar-module__py-xl-0___3WSn6 {
    padding-bottom: 0 !important;
  }

  .sidebar-module__pl-xl-0___I5Gof,
.sidebar-module__px-xl-0___3lQbU {
    padding-left: 0 !important;
  }

  .sidebar-module__p-xl-1___kgDhr {
    padding: 0.25rem !important;
  }

  .sidebar-module__pt-xl-1___3JuS4,
.sidebar-module__py-xl-1___cBIIy {
    padding-top: 0.25rem !important;
  }

  .sidebar-module__pr-xl-1___1wkxD,
.sidebar-module__px-xl-1___2V3_m {
    padding-right: 0.25rem !important;
  }

  .sidebar-module__pb-xl-1___2DqA1,
.sidebar-module__py-xl-1___cBIIy {
    padding-bottom: 0.25rem !important;
  }

  .sidebar-module__pl-xl-1___LuLIK,
.sidebar-module__px-xl-1___2V3_m {
    padding-left: 0.25rem !important;
  }

  .sidebar-module__p-xl-2___3suAy {
    padding: 0.5rem !important;
  }

  .sidebar-module__pt-xl-2___2gefh,
.sidebar-module__py-xl-2___1m36g {
    padding-top: 0.5rem !important;
  }

  .sidebar-module__pr-xl-2___3ucMv,
.sidebar-module__px-xl-2___TQYnR {
    padding-right: 0.5rem !important;
  }

  .sidebar-module__pb-xl-2___2_rx7,
.sidebar-module__py-xl-2___1m36g {
    padding-bottom: 0.5rem !important;
  }

  .sidebar-module__pl-xl-2___1M6BD,
.sidebar-module__px-xl-2___TQYnR {
    padding-left: 0.5rem !important;
  }

  .sidebar-module__p-xl-3___3XeKu {
    padding: 1rem !important;
  }

  .sidebar-module__pt-xl-3___1EHRU,
.sidebar-module__py-xl-3___1iCa_ {
    padding-top: 1rem !important;
  }

  .sidebar-module__pr-xl-3___13jcz,
.sidebar-module__px-xl-3___2NUlt {
    padding-right: 1rem !important;
  }

  .sidebar-module__pb-xl-3___28oaJ,
.sidebar-module__py-xl-3___1iCa_ {
    padding-bottom: 1rem !important;
  }

  .sidebar-module__pl-xl-3___2kmlX,
.sidebar-module__px-xl-3___2NUlt {
    padding-left: 1rem !important;
  }

  .sidebar-module__p-xl-4___3Gj_k {
    padding: 1.5rem !important;
  }

  .sidebar-module__pt-xl-4___YkH0s,
.sidebar-module__py-xl-4___2QADl {
    padding-top: 1.5rem !important;
  }

  .sidebar-module__pr-xl-4___11KxT,
.sidebar-module__px-xl-4___2vIr8 {
    padding-right: 1.5rem !important;
  }

  .sidebar-module__pb-xl-4___s-J-M,
.sidebar-module__py-xl-4___2QADl {
    padding-bottom: 1.5rem !important;
  }

  .sidebar-module__pl-xl-4___2xCxj,
.sidebar-module__px-xl-4___2vIr8 {
    padding-left: 1.5rem !important;
  }

  .sidebar-module__p-xl-5___2LDit {
    padding: 3rem !important;
  }

  .sidebar-module__pt-xl-5___2xXnF,
.sidebar-module__py-xl-5___2QU07 {
    padding-top: 3rem !important;
  }

  .sidebar-module__pr-xl-5___14FsV,
.sidebar-module__px-xl-5___2tKpb {
    padding-right: 3rem !important;
  }

  .sidebar-module__pb-xl-5___375g6,
.sidebar-module__py-xl-5___2QU07 {
    padding-bottom: 3rem !important;
  }

  .sidebar-module__pl-xl-5___1LglV,
.sidebar-module__px-xl-5___2tKpb {
    padding-left: 3rem !important;
  }

  .sidebar-module__m-xl-n1___bHsQe {
    margin: -0.25rem !important;
  }

  .sidebar-module__mt-xl-n1___1NCEr,
.sidebar-module__my-xl-n1___1JNfb {
    margin-top: -0.25rem !important;
  }

  .sidebar-module__mr-xl-n1___3zxim,
.sidebar-module__mx-xl-n1___3DeGm {
    margin-right: -0.25rem !important;
  }

  .sidebar-module__mb-xl-n1___2jCA-,
.sidebar-module__my-xl-n1___1JNfb {
    margin-bottom: -0.25rem !important;
  }

  .sidebar-module__ml-xl-n1___1n4EX,
.sidebar-module__mx-xl-n1___3DeGm {
    margin-left: -0.25rem !important;
  }

  .sidebar-module__m-xl-n2___1xMKJ {
    margin: -0.5rem !important;
  }

  .sidebar-module__mt-xl-n2___TKPmk,
.sidebar-module__my-xl-n2___bx7je {
    margin-top: -0.5rem !important;
  }

  .sidebar-module__mr-xl-n2___I0VGh,
.sidebar-module__mx-xl-n2___2nyBY {
    margin-right: -0.5rem !important;
  }

  .sidebar-module__mb-xl-n2___3uZDw,
.sidebar-module__my-xl-n2___bx7je {
    margin-bottom: -0.5rem !important;
  }

  .sidebar-module__ml-xl-n2___3g1F9,
.sidebar-module__mx-xl-n2___2nyBY {
    margin-left: -0.5rem !important;
  }

  .sidebar-module__m-xl-n3___jrb4V {
    margin: -1rem !important;
  }

  .sidebar-module__mt-xl-n3___fbHEs,
.sidebar-module__my-xl-n3___3SJXE {
    margin-top: -1rem !important;
  }

  .sidebar-module__mr-xl-n3___38emK,
.sidebar-module__mx-xl-n3___tpCIR {
    margin-right: -1rem !important;
  }

  .sidebar-module__mb-xl-n3___1BeRl,
.sidebar-module__my-xl-n3___3SJXE {
    margin-bottom: -1rem !important;
  }

  .sidebar-module__ml-xl-n3___1kj5p,
.sidebar-module__mx-xl-n3___tpCIR {
    margin-left: -1rem !important;
  }

  .sidebar-module__m-xl-n4___2A9Ga {
    margin: -1.5rem !important;
  }

  .sidebar-module__mt-xl-n4___Lmlbg,
.sidebar-module__my-xl-n4___P2-uQ {
    margin-top: -1.5rem !important;
  }

  .sidebar-module__mr-xl-n4___3lJ5U,
.sidebar-module__mx-xl-n4___1It4h {
    margin-right: -1.5rem !important;
  }

  .sidebar-module__mb-xl-n4___2xRfd,
.sidebar-module__my-xl-n4___P2-uQ {
    margin-bottom: -1.5rem !important;
  }

  .sidebar-module__ml-xl-n4___Q4ZF9,
.sidebar-module__mx-xl-n4___1It4h {
    margin-left: -1.5rem !important;
  }

  .sidebar-module__m-xl-n5___2tyDg {
    margin: -3rem !important;
  }

  .sidebar-module__mt-xl-n5___2x0QN,
.sidebar-module__my-xl-n5___2f-Sk {
    margin-top: -3rem !important;
  }

  .sidebar-module__mr-xl-n5___3y-aC,
.sidebar-module__mx-xl-n5___3nDf0 {
    margin-right: -3rem !important;
  }

  .sidebar-module__mb-xl-n5___2MvJi,
.sidebar-module__my-xl-n5___2f-Sk {
    margin-bottom: -3rem !important;
  }

  .sidebar-module__ml-xl-n5___3XUyf,
.sidebar-module__mx-xl-n5___3nDf0 {
    margin-left: -3rem !important;
  }

  .sidebar-module__m-xl-auto___PdDBT {
    margin: auto !important;
  }

  .sidebar-module__mt-xl-auto___24A-E,
.sidebar-module__my-xl-auto___2o9-7 {
    margin-top: auto !important;
  }

  .sidebar-module__mr-xl-auto___2Zmpo,
.sidebar-module__mx-xl-auto___1OPiC {
    margin-right: auto !important;
  }

  .sidebar-module__mb-xl-auto___2_HuA,
.sidebar-module__my-xl-auto___2o9-7 {
    margin-bottom: auto !important;
  }

  .sidebar-module__ml-xl-auto___2SF8x,
.sidebar-module__mx-xl-auto___1OPiC {
    margin-left: auto !important;
  }
}
.sidebar-module__text-monospace___2nVKt {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.sidebar-module__text-justify___1SjKG {
  text-align: justify !important;
}

.sidebar-module__text-wrap___2DTDW {
  white-space: normal !important;
}

.sidebar-module__text-nowrap___1SZKa {
  white-space: nowrap !important;
}

.sidebar-module__text-truncate___31bk5, .sidebar-module__link___2BPkD, .sidebar-module__link-active___1qkkj, .sidebar-module__link-collapsed___2uj3s {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-module__text-left___1RhvY {
  text-align: left !important;
}

.sidebar-module__text-right___152MF {
  text-align: right !important;
}

.sidebar-module__text-center___3pf0h {
  text-align: center !important;
}

@media (min-width: 576px) {
  .sidebar-module__text-sm-left___3Tlmm {
    text-align: left !important;
  }

  .sidebar-module__text-sm-right___3TNV0 {
    text-align: right !important;
  }

  .sidebar-module__text-sm-center___3NOf5 {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .sidebar-module__text-md-left___3hkyf {
    text-align: left !important;
  }

  .sidebar-module__text-md-right___2HqOO {
    text-align: right !important;
  }

  .sidebar-module__text-md-center___3XhrQ {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .sidebar-module__text-lg-left___1vBK- {
    text-align: left !important;
  }

  .sidebar-module__text-lg-right___TmYkn {
    text-align: right !important;
  }

  .sidebar-module__text-lg-center___3pR7- {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .sidebar-module__text-xl-left___ssy7B {
    text-align: left !important;
  }

  .sidebar-module__text-xl-right___3tddt {
    text-align: right !important;
  }

  .sidebar-module__text-xl-center___1LxEj {
    text-align: center !important;
  }
}
.sidebar-module__text-lowercase___3em-V {
  text-transform: lowercase !important;
}

.sidebar-module__text-uppercase___1k0ku {
  text-transform: uppercase !important;
}

.sidebar-module__text-capitalize___3h_nj {
  text-transform: capitalize !important;
}

.sidebar-module__font-weight-light___wYsQd {
  font-weight: 300 !important;
}

.sidebar-module__font-weight-lighter___WLuKT {
  font-weight: lighter !important;
}

.sidebar-module__font-weight-normal___JmifT {
  font-weight: 400 !important;
}

.sidebar-module__font-weight-bold___1A-3C {
  font-weight: 700 !important;
}

.sidebar-module__font-weight-bolder___3jCMU {
  font-weight: bolder !important;
}

.sidebar-module__font-italic___2c71P {
  font-style: italic !important;
}

.sidebar-module__text-white___21uP9 {
  color: #fff !important;
}

.sidebar-module__text-primary___3LgwT {
  color: #007bff !important;
}

a.sidebar-module__text-primary___3LgwT:hover, a.sidebar-module__text-primary___3LgwT:focus {
  color: #0056b3 !important;
}

.sidebar-module__text-secondary___1CZVR {
  color: #6c757d !important;
}

a.sidebar-module__text-secondary___1CZVR:hover, a.sidebar-module__text-secondary___1CZVR:focus {
  color: #494f54 !important;
}

.sidebar-module__text-success___35gJ2 {
  color: #28a745 !important;
}

a.sidebar-module__text-success___35gJ2:hover, a.sidebar-module__text-success___35gJ2:focus {
  color: #19692c !important;
}

.sidebar-module__text-info___1WQaN {
  color: #17a2b8 !important;
}

a.sidebar-module__text-info___1WQaN:hover, a.sidebar-module__text-info___1WQaN:focus {
  color: #0f6674 !important;
}

.sidebar-module__text-warning___3Qq_p {
  color: #ffc107 !important;
}

a.sidebar-module__text-warning___3Qq_p:hover, a.sidebar-module__text-warning___3Qq_p:focus {
  color: #ba8b00 !important;
}

.sidebar-module__text-danger___So7Y0 {
  color: #dc3545 !important;
}

a.sidebar-module__text-danger___So7Y0:hover, a.sidebar-module__text-danger___So7Y0:focus {
  color: #a71d2a !important;
}

.sidebar-module__text-light___2JFd9 {
  color: #f8f9fa !important;
}

a.sidebar-module__text-light___2JFd9:hover, a.sidebar-module__text-light___2JFd9:focus {
  color: #cbd3da !important;
}

.sidebar-module__text-dark___2C-Pv {
  color: #343a40 !important;
}

a.sidebar-module__text-dark___2C-Pv:hover, a.sidebar-module__text-dark___2C-Pv:focus {
  color: #121416 !important;
}

.sidebar-module__text-body___1z0mV {
  color: #212529 !important;
}

.sidebar-module__text-muted___LAqnD {
  color: #6c757d !important;
}

.sidebar-module__text-black-50___FMAkO {
  color: rgba(0, 0, 0, 0.5) !important;
}

.sidebar-module__text-white-50___gNELD {
  color: rgba(255, 255, 255, 0.5) !important;
}

.sidebar-module__text-hide___CI0DZ {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.sidebar-module__text-decoration-none___XWJJz {
  text-decoration: none !important;
}

.sidebar-module__text-break___1jzd1 {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.sidebar-module__text-reset___2lRKM {
  color: inherit !important;
}

.sidebar-module__visible___2Di_y {
  visibility: visible !important;
}

.sidebar-module__invisible___FqeD_ {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.sidebar-module__btn___1PSTB) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .sidebar-module__container___1J6db {
    min-width: 992px !important;
  }

  .sidebar-module__navbar___2f9Fr {
    display: none;
  }

  .sidebar-module__badge___PuvBG {
    border: 1px solid #000;
  }

  .sidebar-module__table___3ayQQ {
    border-collapse: collapse !important;
  }
  .sidebar-module__table___3ayQQ td,
.sidebar-module__table___3ayQQ th {
    background-color: #fff !important;
  }

  .sidebar-module__table-bordered___Wlwuo th,
.sidebar-module__table-bordered___Wlwuo td {
    border: 1px solid #dee2e6 !important;
  }

  .sidebar-module__table-dark___2sfa7 {
    color: inherit;
  }
  .sidebar-module__table-dark___2sfa7 th,
.sidebar-module__table-dark___2sfa7 td,
.sidebar-module__table-dark___2sfa7 thead th,
.sidebar-module__table-dark___2sfa7 tbody + tbody {
    border-color: #dee2e6;
  }

  .sidebar-module__table___3ayQQ .sidebar-module__thead-dark___2oOir th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.sidebar-module__link___2BPkD, .sidebar-module__link-active___1qkkj, .sidebar-module__link-collapsed___2uj3s {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  vertical-align: middle;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  color: #cccccc;
  text-align: left;
  cursor: pointer;
  -webkit-transition: color 150ms;
  transition: color 150ms;
}
.sidebar-module__link___2BPkD:hover, .sidebar-module__link-active___1qkkj:hover, .sidebar-module__link-collapsed___2uj3s:hover {
  color: #e4cb85;
}

.sidebar-module__link-collapsed___2uj3s {
  justify-content: center;
}

.sidebar-module__link-active___1qkkj {
  color: #ffc728;
}
.sidebar-module__link-active___1qkkj:hover {
  color: #ffc728;
}

.sidebar-module__link-context___1AdFo {
  font-size: 14px;
}

.sidebar-module__separator___2ufjp {
  background-color: #dee2e6;
}

.sidebar-module__separator-wrapper___2CUH1 {
  height: 1px;
}

.sidebar-module__logo-img___svqjw {
  width: 32px;
  height: 32px;
  transition: width 250ms, height 250ms;
  -webkit-transition: width 250ms, height 250ms;
}

.sidebar-module__logo-img-collapsed___hjxyW {
  width: 36px;
  height: 36px;
  transition-delay: 250ms;
  transition: width 250ms, height 250ms;
  -webkit-transition: width 250ms, height 250ms;
  -webkit-transition-delay: 250ms;
}

.sidebar-module__main-menu___XmrdH {
  background-color: rgba(255, 255, 255, 0.07);
  z-index: 1030;
}