.container-module__card___2BwdC {
  padding-top: 8px;
  padding-bottom: 8px;
}
.container-module__card___2BwdC h3 {
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  color: #3a3a3a;
}

.container-module__card-compact___kR0P8 {
  padding: 8px;
}
.container-module__card-compact___kR0P8 h3 {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  color: #3a3a3a;
}

.container-module__container___tt9ta {
  display: flex;
  flex-direction: column;
  justify-content: center;
}