.import-switch-module__main-wrapper___1R5fu, .import-switch-module__main-wrapper-flex___2UNaS {
  padding: 0 5px;
}

.import-switch-module__main-wrapper-flex___2UNaS {
  height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
}

.import-switch-module__header-wrapper___2GXf_ {
  padding: 50px;
}